const getProps = (props) => {
  return { body: props.text };
};

const RichTextBlock = () => ({
  type: 'rich_text_block',
  props: getProps
});

export default RichTextBlock;
