import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { isServer } from 'common/utils'

export class BroadcastChannelStrategy {
  private channel: BroadcastChannel;

  public static available () {
    return typeof window?.BroadcastChannel === 'function';
  }

  public constructor ({ sharingKey }: { sharingKey: string }) {
    if (isServer) return null;

    const storeView = currentStoreView();
    const channelNamePrefix = storeView?.storeCode ? storeView.storeCode + '-' : '';
    const key = `${channelNamePrefix}shop/${sharingKey || ''}`;

    if (!BroadcastChannelStrategy.available()) {
      throw new Error('Broadcast strategy not available');
    }

    this.channel = new BroadcastChannel(key);
  }

  public addEventListener (fn) {
    this.channel.addEventListener('message', e => {
      fn(e.data);
    });
  }

  public share (message) {
    return this.channel.postMessage(message);
  }
}
