import config from 'config'
import {
  getProductGallery as getCoreProductGallery
} from '@vue-storefront/core/modules/catalog/helpers';
import { configurableChildrenImages } from './';
import uniqBy from 'lodash-es/uniqBy'
import Product from '@vue-storefront/core/modules/catalog/types/Product';

export default function getProductGallery (product: Product) {
  if (product.type_id === 'configurable' && product.hasOwnProperty('configurable_children')) {
    if (!config.products.gallery.mergeConfigurableChildren && product.is_configured) {
      // When `mergeConfigurableChildren === false` we must show the images for each variant.
      // Also to avoid duplicated images by different color child, we are making
      // the configurable_children uniq for each color.
      const configurableChildren = uniqBy(product.configurable_children, 'color');
      const newProduct = {
        ...product,
        configurable_children: configurableChildren
      }
      return uniqBy(configurableChildrenImages(newProduct), 'src').filter(
        f => f.src && f.src !== config.images.productPlaceholder
      )
    }
  }

  // In any other case we will use the core product gallery function to get the images.
  return getCoreProductGallery(product);
}
