<template>
  <div class="a-product-select-size">
    <SfButton
      :class="classes"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <!--@slot Use this slot to place content inside the button.-->
      <slot />
    </SfButton>
  </div>
</template>
<script>
import { SfButton } from '@storefront-ui/vue'

export default {
  name: 'AProductSelectSize',
  components: {
    SfButton
  },
  props: {
    hasStock: {
      type: Boolean,
      default: true
    },
    sizeErrorCode: {
      type: String,
      default: null
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasError () {
      let flag = false;
      if (this.sizeErrorCode === 'restock') {
        flag = true;
      } else if (this.sizeErrorCode === 'addtocart' && this.hasStock) {
        flag = true;
      }
      return flag;
    },
    classes () {
      return [
        'sf-size-button',
        `sf-size-button--primary-${this.selected ? 'contained' : 'outlined'}`,
        { 'error': this.hasError },
        { 'sf-button--oos': !this.hasStock },
        `sf-button--oos-${this.selected ? 'selected' : ''}`
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "~@storefront-ui/shared/styles/helpers/breakpoints";

  .sf-size-button {
    --button-background: var(--white);
    --button-border-width: 1px;
    --button-border-color: var(--bold-brown-base);
    --button-border-radius: 0;
    --button-box-shadow: none;
    --button-color: var(--bold-brown-base);
    --button-padding: calc(var(--spacer-sm) - 2px) var(--spacer-xl);
    opacity: var(--button-opacity, 1);
    cursor: var(--button-cursor, pointer);
    letter-spacing: 1px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    user-select: none;
    font-size: var(--font_size_75);
    font-weight: 400;
    line-height: var(--fix_line_height_100);

    &--primary {
      &-outlined {
        --button-border-color: var(--neutral-100);
      }
    }

    &:disabled {
      --button-cursor: not-allowed;
      --button-opacity: 0.6;
    }

    @include for-desktop {
      &:hover:enabled {
        --button-opacity: 0.6;
      }
    }
  }

  ::v-deep .sf-button {
    --button-border-radius: 0;
    --button-padding: 12px 24px;
    margin-right: var(--spacer-xs);
    margin-bottom: var(--spacer-xs);
    line-height: 0.8;
    position: relative;
    width: 64px;
    height: 44px;
    &.error {
      border: 1px solid var(--danger-500);
    }
    &--oos {
      position: relative;
      --button-border-color: var(--neutral-100);
      --button-color: var(--neutral-100);

      &:after {
        content: '';
        position: absolute;
        border-top: 1px solid var(--neutral-500);
        transform: rotate(147deg);
        left: -5px;
        top: 50%;
        width: calc(100% + 10px);
        transform-origin: center;
      }

      &-selected{
        &:hover{
          opacity: unset;
        }
        &:after {
          content: '';
          display: none;
        }
      }
    }
  }
</style>
