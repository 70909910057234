import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isServer } from '@vue-storefront/core/helpers'
import { RECENTLY_VIEWED_KEY, SEARCH_HISTORY } from 'common/constants';

export * from './price'
export * from './category'
export * from './checkout'
export * from './cookie'
export * from './impact'
export * from './readableStream'

export const formatProductSlug = (slug: string) => {
  if (!slug) return
  let slugSplit: any = slug.split('-')
  // Removing numbers from the product slug
  if (!isNaN(slugSplit[slugSplit.length - 1])) {
    slugSplit = slugSplit.slice(0, slugSplit.length - 1)
    slug = slugSplit.join('-')
  }
  return slug
}

export const encodeBase64 = (key: string) => window.btoa(unescape(encodeURIComponent(key)))

export function convertPrice (price: string) {
  const currency = currentStoreView().i18n.currencySign;
  return parseFloat(price?.split(currency)?.join('')?.split(',')?.join(''))
}

export function getCurrentCategoryBannerPath (path: string, isMobile = true) {
  if (!path) return ''
  let { cfImageResizeBaseUrl, baseS3ImageUrl } = config.server
  let { quality, format, bannerBaseUrl } = config.images;
  const categoryBannerWidth = isMobile ? config.categoryBanner?.mobile?.width : config.categoryBanner?.width;

  return `${cfImageResizeBaseUrl}/width=${categoryBannerWidth},quality=${quality},format=${format}/${baseS3ImageUrl}${path}`;
}

export function getPathForStaticPage (path: string) {
  const { storeCode } = currentStoreView()
  const isStoreCodeEquals = storeCode === config.defaultStoreCode
  return isStoreCodeEquals ? `/i${path}` : path
}

export function createSmoothscroll (from = 0, to = 0, speed = 15) {
  let currentDelta = from > to ? from - to : to - from
  let smoothscroll = () => {
    if (Math.abs(currentDelta) < speed) return
    currentDelta = currentDelta - currentDelta / speed
    window.requestAnimationFrame(smoothscroll);
    window.scrollTo(0, from > to ? to + currentDelta : to - currentDelta);
  }
  return smoothscroll()
}

export function checkWebpSupport (bannersToTransform, isWebpSupported) {
  // In SSR it is not easily known whether webp image is supported by web browser or not.
  // Empty string also cannot be used here, because empty string evaluates to url('')
  // and it is resolved as the base URL (the same as our Homepage), so as a consequence
  // Homepage was requested again.
  // To fix that case, dummy empty data URI is provided just to prevent any unnecessary
  // requests.
  // --- see https://github.com/DivanteLtd/vsf-capybara/issues/168
  const theSmallestDummyImage = 'data:,'
  return bannersToTransform.map(banner => Object.assign(
    {},
    banner,
    { image: isServer ? theSmallestDummyImage : isWebpSupported ? banner.image.webp : banner.image.fallback }
  ))
}

export function getTopLevelCategories (categoryList) {
  // Display only the root level (level = 1 => Default Category), categoriesDynamicPrefetchLevel = 2 by default
  const categoryLevel = config.entities.category.categoriesDynamicPrefetchLevel >= 0
    ? config.entities.category.categoriesDynamicPrefetchLevel
    : 2

  return categoryList.filter(
    category => category.level === categoryLevel && category.is_active && category.include_in_menu
  )
}

export function getRecentlyViewedSku () {
  if (isServer) {
    return []
  }
  const { storeCode } = currentStoreView()
  return JSON.parse(localStorage?.getItem(`${storeCode}/${RECENTLY_VIEWED_KEY}`)) || []
}

export function setRecentlyViewedSku (sku) {
  if (!isServer) {
    const { storeCode } = currentStoreView()
    const currentList = getRecentlyViewedSku()
    if (currentList.indexOf(sku) >= 0) {
      currentList.splice(currentList.indexOf(sku), 1);
    }
    currentList.push(sku)
    if (currentList.length > 10) {
      currentList.shift()
    }
    localStorage?.setItem(`${storeCode}/${RECENTLY_VIEWED_KEY}`, JSON.stringify(currentList))
  }
}

export function getSearchHistory () {
  if (isServer) {
    return []
  }
  const { storeCode } = currentStoreView()
  return JSON.parse(localStorage?.getItem(`${storeCode}/${SEARCH_HISTORY}`)) || []
}

export function setSearchHistory (list) {
  if (!isServer) {
    const { storeCode } = currentStoreView()
    localStorage?.setItem(`${storeCode}/${SEARCH_HISTORY}`, JSON.stringify(list))
  }
}

export function setSearchHistoryText (text) {
  if (!isServer) {
    const searchText = text.toLowerCase()
    const currentList = getSearchHistory()
    if (currentList.includes(searchText)) {
      const idx = currentList.indexOf(searchText)
      currentList.splice(idx, 1);
    }
    currentList.unshift(searchText);
    if (currentList.length > 5) {
      currentList.pop()
    }
    setSearchHistory(currentList)
  }
}

export function isValidDate (dateObject) {
  return new Date(dateObject).toString() !== 'Invalid Date';
}

export function genericPaymentCode (methodName) {
  return {
    'paymentMethod': {
      'method': methodName
    }
  }
}
