import i18n from '@vue-storefront/i18n';
import { isServer } from '@vue-storefront/core/helpers';
import * as types from '@vue-storefront/core/modules/catalog/store/product/mutation-types';
import getProductGallery from '../../helpers/getProductGallery';
import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import ProductState from '@vue-storefront/core/modules/catalog/types/ProductState'
import { Logger } from '@vue-storefront/core/lib/logger';
import config from 'config'
import { ProductService } from '../../data-resolver/ProductService'
import {
  registerProductsMapping
} from '@vue-storefront/core/modules/catalog/helpers'
import createAttributesListQuery from '@vue-storefront/core/modules/catalog/helpers/createAttributesListQuery';
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import * as nextTypes from './types'
import { fetchProductStockFromIms } from './service';
import { getRecentlyViewedSku } from 'common/helpers';
import { SearchQuery } from 'storefront-query-builder';
import rootStore from '@vue-storefront/core/store'

const showErrorMessage = (message = '') => {
  return {
    type: 'error',
    message: i18n.t(message),
    action1: { label: i18n.t('OK') }
  };
};
const actions: ActionTree<ProductState, RootState> = {
  async loadProductStockFromIms ({ commit, dispatch }, childSkus: string) {
    commit(nextTypes.LOADING_PRODUCT_STOCK_FROM_IMS, true)
    try {
      const resp = await fetchProductStockFromIms(childSkus)
      const payload = resp?.result?.checkStockByChildSkus
      commit(nextTypes.IMS_STOCKS, payload)
      commit(nextTypes.LOADING_PRODUCT_STOCK_FROM_IMS, false)
      return true
    } catch (error) {
      commit(nextTypes.LOADING_PRODUCT_STOCK_FROM_IMS, false)
      dispatch('notification/spawnNotification', showErrorMessage(error.message), { root: true })
      return false
    }
  },
  async findProducts (context, {
    query,
    start = 0,
    size = 50,
    sort = '',
    excludeFields = null,
    includeFields = null,
    configuration = null,
    populateRequestCacheTags = false,
    options: {
      populateRequestCacheTags: populateRequestCacheTagsNew = false,
      prefetchGroupProducts = !isServer,
      setProductErrors = false,
      fallbackToDefaultWhenNoAvailable = true,
      assignProductConfiguration = false,
      separateSelectedVariant = false,
      setConfigurableProductOptions = config.cart.setConfigurableProductOptions,
      filterUnavailableVariants = config.products.filterUnavailableVariants
    } = {}
  } = {}) {
    const { items, ...restResponseData } = await ProductService.getProducts({
      query,
      start,
      size,
      sort,
      excludeFields,
      includeFields,
      configuration,
      options: {
        prefetchGroupProducts,
        fallbackToDefaultWhenNoAvailable,
        setProductErrors,
        setConfigurableProductOptions,
        filterUnavailableVariants,
        assignProductConfiguration,
        separateSelectedVariant
      }
    })

    registerProductsMapping(context, items)

    if (populateRequestCacheTags) {
      Logger.warn('deprecated from 1.13, use "options.populateRequestCacheTags" instead')()
    }

    if (populateRequestCacheTags || populateRequestCacheTagsNew) {
      await rootStore.dispatch('cache-tags/addTags', { tags: items.map((product) => `P${product.id}`) })
    }

    await context.dispatch('tax/calculateTaxes', { products: items }, { root: true })

    return { ...restResponseData, items }
  },
  /**
   * Search products by specific field
   * @param {Object} options
   */
  async single (context, {
    options = {},
    setCurrentProduct = false,
    key = 'sku',
    skipCache = false
  } = {}) {
    if (setCurrentProduct) {
      Logger.warn('option `setCurrentProduct` is deprecated, will be not used from 1.13')()
    }
    if (!options[key]) {
      throw new Error('Please provide the search key ' + key + ' for product/single action!')
    }
    const product = await ProductService.getProductByKey({
      options: { ...options, availabilityCheck: true },
      key,
      skipCache
    })

    await context.dispatch('tax/calculateTaxes', { products: [product] }, { root: true })

    if (setCurrentProduct) await context.dispatch('setCurrent', product)
    EventBus.$emitFilter('product-after-single', { key, options, product })

    return product
  },
  /**
   * Set product gallery depending on product type
   */
  setProductGallery (context, { product }) {
    context.commit(types.PRODUCT_SET_GALLERY, getProductGallery(product))
  },
  async loadSizeOptions (context) {
    const filterField = 'attribute_code';
    const attributeCode = 'size';
    const only_user_defined = false;
    const only_visible = false;
    const filterValues = [attributeCode];

    const optionsReducer = (previous, current) => [...previous, current.swatch.value];

    const { items } = await context.dispatch('attribute/list', { filterValues }, { root: true });

    let { options } = items.find(({ attribute_code }) => attribute_code === attributeCode) || {};

    if (!options || !options.length) {
      const query = createAttributesListQuery({
        filterValues,
        filterField,
        onlyDefinedByUser: only_user_defined,
        onlyVisible: only_visible
      });

      const { items: [size] } = await quickSearchByQuery({ entityType: 'attribute', query, size: 200 });
      options = size.options;
    }

    return options && options.reduce(optionsReducer, []);
  },
  async loadRecentlyViewedProducts (context) {
    const skuList = getRecentlyViewedSku()
    if (skuList.length) {
      let searchQuery = new SearchQuery();
      searchQuery = searchQuery.applyFilter({
        key: 'sku',
        value: { eq: [...skuList].sort() }
      });

      const response = await context.dispatch(
        'product/findProducts',
        {
          query: searchQuery,
          size: 10,
          options: {
            fallbackToDefaultWhenNoAvailable: false,
            setProductErrors: true,
            assignProductConfiguration: true,
            separateSelectedVariant: true
          }
        },
        { root: true }
      );
      if (response?.items?.length) {
        const recentlyViewedList = response.items.map(item => {
          return {
            ...item,
            recentlyViewedPos: skuList.indexOf(item.sku)
          }
        }).sort((a, b) => {
          return b.recentlyViewedPos - a.recentlyViewedPos;
        })
        context.commit(nextTypes.RECENTLY_VIEWED, recentlyViewedList)
      }
    }
  }
}

export default actions
