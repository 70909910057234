<template>
  <div>
    <SfSidebar
      :visible="isVisible"
      @close="close"
      :persistent="false"
    >
      <template #bar>
        <div class="header-container ppp">
          <AAccountIcon class="my-account" @toggleModal="toggleNewAuthModal()" />
        </div>
      </template>

      <template>
        <div class="content">
          <OMegamenuMobileCategoriesV2
            :available-categories="proData"
            :loading="loading"
            :parent-id="activeCategoryParentId"
            :child-slug="activeCategorySlug"
            :going-back="goingBack"
            @set-active-category="getActiveCategory"
            @handleClose="close"
          />
        </div>
        <div v-show="!activeChildCategories.length">
          <!-- <OMegaMenuMobileBanners /> -->
          <OMegaMenuMobileFooter />
        </div>
      </template>
    </SfSidebar>
  </div>
</template>
<script>
import OMegamenuMobileCategoriesV2 from './o-mega-menu-mobile-categoriesV2.vue'
import OMegaMenuMobileFooter from './o-mega-menu-mobile-footer.vue';
import AAccountIcon from 'common/components/atoms/a-account-icon';
import windowSize from 'common/mixins/windowSize.js';
import { mapGetters, mapActions } from 'vuex';

import { SfSidebar } from '@storefront-ui/vue';
import _flatMapDeep from 'lodash-es/flatMapDeep';
import _cloneDeep from 'lodash-es/cloneDeep';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import config from 'config'

export default {
  name: 'OMegaMenuMobileModal',
  components: {
    SfSidebar,
    OMegaMenuMobileFooter,
    AAccountIcon,
    OMegamenuMobileCategoriesV2
  },
  mixins: [windowSize],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  created () {
    this.proData = this.processedCategories();
  },
  data: () => ({
    activeCategory: {},
    loading: false,
    goingBack: false,
    topLevelSlugs: config?.megaMenu?.mobile?.topLevelSlugs || [],
    topLevelInnerSlugs: config?.megaMenu?.mobile?.topLevelInnerSlugs || [],
    topLevelInnerPaths: config?.megaMenu?.mobile?.topLevelInnerPaths || [],
    proData: []
  }),
  computed: {
    ...mapGetters(['getCurrentStoreView']),
    ...mapGetters('user', ['isLoggedIn']),
    availableCategories () {
      let availableCategories = []
      const categories = this.modalData?.payload?.availableCategories || [];

      categories.forEach(c => {
        if (this.topLevelSlugs.includes(c.slug)) {
          const children = this.getChildren(c?.id)
          children?.forEach(sc => {
            if (sc.include_in_menu > 0) {
              if (this.topLevelInnerPaths.includes(sc.url_path)) {
                availableCategories.push(...sc?.children_data?.filter(cd => cd.include_in_menu > 0)?.sort((a, b) => a?.position - b?.position))
              } else availableCategories?.push(sc)
            }
          })
        } else availableCategories?.push(c)
      })
      return availableCategories;
    },
    activeChildCategories () {
      if (this.activeCategory?.level === 2) {
        if (this.topLevelInnerSlugs.includes(this.activeCategorySlug)) {
          const visibleCategories = []
          const children = this.getChildren(this.activeCategoryId)
          children?.forEach(sc => {
            if (sc.include_in_menu > 0) {
              if (this.topLevelInnerPaths.includes(sc.url_path)) visibleCategories.push(...sc?.children_data?.filter(cd => cd.include_in_menu > 0)?.sort((a, b) => a?.position - b?.position))
              else visibleCategories?.push(sc)
            }
          })

          return visibleCategories
        }
        return this.getChildren(this.activeCategory?.id) || []
      } else {
        return this.getActiveChildCategories(this.activeCategory?.children_data?.filter(cd => cd.include_in_menu > 0)) || []
      }
    },
    activeCategoryParentId () {
      return this.activeCategory?.parent_id || this.activeCategory?.parentId
    },
    activeCategorySlug () {
      return this.activeCategory?.slug
    },
    activeCategoryId () {
      return this.activeCategory?.id
    },
    storeCode () {
      return this.getCurrentStoreView?.storeCode;
    }
  },
  watch: {
    '$route': {
      handler: function (val) {
        if (val) this.close()
      }
    },
    isMobileDevice: {
      handler: function (val) {
        if (!val) this.close()
      }
    }
  },
  methods: {
    ...mapActions('user', {
      toggleAuthModal: 'toggleAuthModal'
    }),
    processedCategories () {
      const categories = _cloneDeep(this.modalData?.payload?.availableCategories || []);
      let availableCategories = [];

      const processJSON = (json) => {
        if (!Array.isArray(json) || json.length === 0) {
          return;
        }
        json.forEach((item, idx) => {
          if (item.lb_megamenu_type === 'full-width-tabed') {
            availableCategories = [...availableCategories, { ..._cloneDeep(item), expanded: false }];
            json.splice(idx, 1);
          }
          if (item.children_data) {
            processJSON(item.children_data);
          }
        });
      };

      processJSON(categories);
      categories.push(...availableCategories)
      return categories?.sort((a, b) => a?.position - b?.position);
    },
    close () {
      this.proData = this.processedCategories();
      this.setActiveCategory('');
      this.$emit('close-slide-bar')
    },
    toggleNewAuthModal () {
      this.proData = this.processedCategories();
      EventBus.$emit('close-megamenu')
      this.toggleAuthModal();
    },
    getChildren (categoryId) {
      const menuCategories = this.$store.getters['catalog-es/getSubCategories']
      const filteredMenuCategories = menuCategories.filter(
        c => c.parent_id === categoryId && c.include_in_menu > 0
      );
      return filteredMenuCategories?.sort((a, b) => a?.position - b?.position)
    },
    getActiveChildCategories (categories) {
      return categories?.sort((a, b) => a?.position - b?.position) || []
    },
    getActiveCategory (category) {
      if (!category) return;
      this.goingBack = false;
      this.setActiveCategory(category)
    },
    back () {
      this.goingBack = true;
      if (this.activeCategory?.level === 2 || this.activeCategory?.slug === 'shop') {
        this.setActiveCategory('')
      } else {
        const getMembers = (categoryArray) => {
          if (!categoryArray.children_data || !categoryArray.children_data.length) {
            return categoryArray;
          }
          return [categoryArray, _flatMapDeep(categoryArray.children_data, getMembers)];
        }
        this.setActiveCategory(_flatMapDeep(this.availableCategories, getMembers).find(c => c.id === this.activeCategory.parent_id));
      }
    },
    setActiveCategory (payload) {
      this.loading = true;
      setTimeout(() => {
        this.activeCategory = payload
        this.loading = false;
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .sf-sidebar__content {
  padding: 0;
  height: 100%;
}
::v-deep {
  .sf-overlay {
    top: 120px;
  }
  .sf-sidebar__aside {
    top: 100px;
    margin-right: 25px;
    height: auto;
  }
  .account-tier-points-info-container {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .account-info-container {
    width: 100%;
    .user-name {
      font-family: var(--font-secondary);
      font-size: var(--font_size_125);
      font-weight: 600;
      line-height: var(--fix_line_height_300);
    }
    .login-register {
      font-family: var(--font-secondary);
      font-size: var(--font_size_75);
      font-weight: 600;
      line-height: var(--fix_line_height_300);
    }
  }
  .a-account-icon {
    width: 100%;
  }
}
.header-container {
  background: var(--white);
  border-bottom: 1px solid var(--_c-gray-accent);
  padding: 16px;
  .header {
    height: 70px;
    display: flex;
    margin: 0 30px;
    align-items: center;
    justify-content: start;
    display: none;;
    .logo {
      flex: 0.97 1;
      margin: 0;
      margin-left: -3px;
    }
  }

  .cross-icon {
    cursor: pointer;
  }

  .category-header {
    height: 70px;
    display: flex;
    margin: 0 30px;
    margin-right: 40px;
    align-items: center;
    justify-content: start;

    .title {
      flex: 1;
      font-weight: 400;
      text-align: center;
      margin-left: -17px;
      color: var(--neutral-900);
      font-size: var(--font_size_75);
      text-transform: capitalize;
      font-family: var(--font-secondary);
    }
    .back-icon {
      cursor: pointer;
    }
  }
}
</style>
