export const SET_CART_ID = 'SET_CART_ID';
export const SET_CART_ITEMS = 'SET_CART_ITEMS';
export const SET_CART_TOTALS = 'SET_CART_TOTALS';
export const RESET_CART_MODULE = 'RESET_CART_MODULE';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const SET_SHIPPING_METHODS = 'SET_SHIPPING_METHODS';
export const SET_IS_ADDING_TO_CART = 'SET_IS_ADDING_TO_CART';
export const SET_IS_LOADING_CART = 'SET_IS_LOADING_CART';
export const SET_IS_LOADED_CART = 'SET_IS_LOADED_CART';
export const SET_ITEMS_TO_REMOVE = 'SET_ITEMS_TO_REMOVE';
export const RESET_ITEMS_TO_REMOVE = 'RESET_ITEMS_TO_REMOVE';
export const SET_CART_ITEMS_STOCK = 'SET_CART_ITEMS_STOCK';
