function getProps (props) {
  const {
    benefit_1_text,
    benefit_2_text,
    benefit_3_text,
    benefit_1_image,
    benefit_2_image,
    benefit_3_image
  } = props;

  return {
    benefit1Text: benefit_1_text,
    benefit2Text: benefit_2_text,
    benefit3Text: benefit_3_text,
    benefit1Image: benefit_1_image,
    benefit2Image: benefit_2_image,
    benefit3Image: benefit_3_image,
    editable: props
  }
}

const LbBenefits = () => ({
  type: 'lb_benefits',
  props: getProps
});

export default LbBenefits
