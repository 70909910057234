import { sha3_224 } from 'js-sha3'
import i18n from '@vue-storefront/i18n'
import { Logger } from '@vue-storefront/core/lib/logger'
import { isOnline } from '@vue-storefront/core/lib/search'
import { extendStore } from '@vue-storefront/core/helpers';
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { Order } from '@vue-storefront/core/modules/order/types/Order'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { orderHooksExecutors } from '@vue-storefront/core/modules/order/hooks'
import * as types from '@vue-storefront/core/modules/order/store/mutation-types';
import { prepareOrder, optimizeOrder, notifications } from '@vue-storefront/core/modules/order/helpers'
import { OrderService } from '@vue-storefront/core/data-resolver'
import config from 'config';
import { PAYMENT_REFUSAL_REASONS, GENERIC_PAYMENT_ERROR_MESSAGE } from 'common/constants'
import { trackGtmEvents } from 'common/helpers/gtm';

const KEY = 'order-next';

const orderNextStore = {
  namespaced: true
};

const orderNextModule = {
  state: {
    isOrderSummaryFetched: false,
    isPromoBlokFetched: false,
    orderSummaryData: {
      components: []
    },
    promoData: {
      components: []
    },
    orderApiFailed: false,
    orderFailureMessage: ''
  },
  mutations: {
    setOrderSummaryData (state, orderSummaryData) {
      state.orderSummaryData = orderSummaryData
    },
    setPromoData (state, promoData) {
      state.promoData = promoData
    },
    setIsOrderSummaryFetched (state, isOrderSummaryFetched) {
      state.isOrderSummaryFetched = isOrderSummaryFetched
    },
    setIsPromoBlokFetched (state, isPromoBlokFetched) {
      state.isPromoBlokFetched = isPromoBlokFetched
    },
    setOrderApiFailed (state, orderApiFailed) {
      state.orderApiFailed = orderApiFailed
    },
    setOrderFailureMessage (state, message) {
      state.orderFailureMessage = message
    }
  },
  getters: {
    getOrderSummaryData: (state) => state.orderSummaryData?.components || [],
    getPromoData: (state) => state.promoData?.components || [],
    getIsOrderSummaryFetched: (state) => state.isOrderSummaryFetched,
    getIsPromoBlokFetched: (state) => state.isPromoBlokFetched,
    isGiftcardOnly: (state, getters, rootState, rootGetters) => !!(rootGetters['cart/getCartItems']?.every(item => item.type_id === 'giftcard') || rootState['order/last_order_confirmation']?.order?.products?.every(product => product.type_id === 'giftcard')),
    getOrderApiFailed: state => state.orderApiFailed,
    getOrderApiFailedMessage: state => state.orderFailureMessage
  },
  actions: {
    handlePlacingOrderFailed ({ commit, dispatch }, { newOrder, currentOrderHash }) {
      const order = { ...newOrder, transmited: false }
      commit(types.ORDER_REMOVE_SESSION_ORDER_HASH, currentOrderHash)
      dispatch('notification/spawnNotification', notifications.orderCannotTransfered(), { root: true })
      dispatch('enqueueOrder', { newOrder: order })

      EventBus.$emit('notification-progress-stop')
    },
    async placeOrder ({ commit, getters, dispatch, rootGetters }, newOrder: Order) {
      // Check if order is already processed/processing
      const optimizedOrder = optimizeOrder(newOrder)
      const currentOrderHash = sha3_224(JSON.stringify(optimizedOrder))
      const isAlreadyProcessed = getters.getSessionOrderHashes.includes(currentOrderHash)
      if (isAlreadyProcessed) return
      commit(types.ORDER_ADD_SESSION_STAMPS, newOrder)
      commit(types.ORDER_ADD_SESSION_ORDER_HASH, currentOrderHash)
      const preparedOrder = prepareOrder(optimizedOrder)

      EventBus.$emit('order-before-placed', { order: preparedOrder })
      const order = orderHooksExecutors.beforePlaceOrder(preparedOrder)
      const extensionAttributes = rootGetters['checkout/getExtensionAttributes']
      if (extensionAttributes) {
        order.addressInformation.shippingAddress.extension_attributes = { ...extensionAttributes }
      }
      const district_code = order?.addressInformation?.billingAddress?.custom_attributes?.find(attr => attr.attribute_code === 'district_code')?.value;
      if (district_code) {
        order.addressInformation.billingAddress.extension_attributes = { district_code };
      }

      if (!isOnline()) {
        // We are not using queue to store or process failed orders
        EventBus.$emit('order-after-placed', { order })
        orderHooksExecutors.beforePlaceOrder({ order, task: { resultCode: 200 } })
        return { resultCode: 200 }
      }

      EventBus.$emit('notification-progress-start', i18n.t('Processing order...'))

      try {
        return await dispatch('processOrder', { newOrder: order, currentOrderHash })
      } catch (error) {
        // We are not using queue to store or process failed orders
        throw error
      }
    },
    async processOrder ({ commit, dispatch }, { newOrder, currentOrderHash }) {
      const order = { ...newOrder, transmited: true }
      commit('setOrderApiFailed', false)
      commit('setOrderFailureMessage', '')

      // Silent toast notifications for adyen credit card and saved card payments
      const isAdyenCardOrSavedCard = !!['adyen_oneclick', 'adyen_cc']?.includes(order?.addressInformation?.payment_method_code)
      const task = await OrderService.placeOrder(order, isAdyenCardOrSavedCard)

      if (task.resultCode === 200) {
        dispatch('enqueueOrder', { newOrder: order })
        commit(types.ORDER_LAST_ORDER_WITH_CONFIRMATION, { order, confirmation: task.result })
        orderHooksExecutors.afterPlaceOrder({ order, task })
        EventBus.$emit('order-after-placed', { order, confirmation: task.result })
        EventBus.$emit('notification-progress-stop')
        return task
      }

      if (task.resultCode === 400) {
        commit(types.ORDER_REMOVE_SESSION_ORDER_HASH, currentOrderHash)
        commit('setOrderApiFailed', true)
        // Show custom error messages in case of adyen saved cards or cc payments only
        if (isAdyenCardOrSavedCard) {
          const message = PAYMENT_REFUSAL_REASONS[task.result] || GENERIC_PAYMENT_ERROR_MESSAGE
          commit('setOrderFailureMessage', message)
          trackGtmEvents({
            event: 'card_payment_error',
            error_message: message,
            refusal_reason: task.result
          });
        }

        Logger.error('Internal validation error; Order entity is not compliant with the schema: ' + JSON.stringify(task.result), 'orders')()
        dispatch('enqueueOrder', { newOrder: order })
        EventBus.$emit('notification-progress-stop')
        return task
      }
      EventBus.$emit('notification-progress-stop')
      throw new Error('Unhandled place order request error')
    },
    fetchBlockBySlug ({ dispatch, rootGetters }, { slug = '', transpileRequired = true }) {
      const storeCode = rootGetters['getCurrentStoreView']?.storeCode
      return dispatch('storyblok/fetchCmsBlock', {
        slug: `${storeCode}/${slug}`,
        version: 'published',
        transpileRequired
      }, {
        root: true
      });
    },

    async fetchOrderSummaryBlock ({ commit, getters, dispatch }) {
      // Do not fetch blocks if already fetched
      if (getters.getIsOrderSummaryFetched) {
        return
      }
      try {
        const blockPostfix = config.server.isProduction ? '' : '-sit'
        const blockData = await dispatch('fetchBlockBySlug', { slug: `blocks/order-summary${blockPostfix}` });
        commit('setIsOrderSummaryFetched', true)
        commit('setOrderSummaryData', blockData)
      } catch {
        Logger.error('Error loading Order summary storyblok', 'orders')()
      }
    },
    async fetchPromoCodeBlock ({ commit, getters, dispatch }) {
      // Do not fetch blocks if already fetched
      if (getters.getIsPromoBlokFetched) {
        return
      }
      const blockData = await dispatch('fetchBlockBySlug', { slug: 'blocks/promo-code' });
      commit('setIsPromoBlokFetched', true)
      commit('setPromoData', blockData)
    }
  }
};

export const OrderNextModule: StorefrontModule = function ({
  app,
  store,
  appConfig,
  router,
  moduleConfig
}) {
  store.registerModule(KEY, orderNextStore);

  extendStore('order', orderNextModule);
};
