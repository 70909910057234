import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { currencyRates } from './state';
import { roundUp, formatPrice } from './helper';

const KEY = 'currencyconversion';

const currencyStore = {
  namespaced: true,
  state: {
    currencyRates,
    localValue: {
      country: 'USA'
    }
  },
  getters: {
    currencyRate: state => (val) => {
      const selectedCurrency = state?.localValue?.country;
      const conversionRate = state.currencyRates[selectedCurrency];
      return formatPrice(roundUp(val * conversionRate?.val), conversionRate?.symbol, conversionRate?.fractionDigits, conversionRate?.locale);
    },
    conversionEnabled (state) {
      return state?.localValue?.country !== 'USA';
    }
  },
  mutations: {
    setCurrency (state, payload) {
      state.localValue.country = payload;
    }
  }
};
export const CurrencyRateModule: StorefrontModule = function ({
  app,
  store,
  router,
  moduleConfig,
  appConfig
}) {
  store.registerModule(KEY, currencyStore);
};
