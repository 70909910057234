export async function parseReadableStream (responseBody) {
  const reader = responseBody.getReader()
  const readableStream = new ReadableStream({
    start (controller) {
      function push () {
        reader.read().then(({ done, value }) => {
          if (done) {
            controller.close();
            return;
          }
          controller.enqueue(value);
          push();
        })
      }

      push();
    }
  })

  const readableResponse = new Response(readableStream, { headers: { 'Content-Type': 'text/html' } }).text()

  return JSON.parse(await readableResponse)
}
