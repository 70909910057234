import { FetchContext, FetchResponse } from 'ohmyfetch';
import config from 'config';
import isUndefined from 'lodash-es/isUndefined';
import i18n from '@vue-storefront/i18n';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import rootStore from '@vue-storefront/core/store';
import { Logger } from '@vue-storefront/core/lib/logger';
import { UNAUTHORIZED_CODE } from 'common/constants';

export function handleUnAuthorizedResponse <T> ({ response }: FetchContext & {
  response: FetchResponse<T>
}) {
  const { storeCode } = currentStoreView();
  const USER_TOKEN_KEY = `${storeCode}-shop/user/current-token`;
  const currentToken = localStorage.getItem(USER_TOKEN_KEY);
  if (response.status === UNAUTHORIZED_CODE /** unauthorized */ && currentToken) { // the token is no longer valid, try to invalidate it
    Logger.error('Invalid token - need to be revalidated' + currentToken + response.url + rootStore.state.userTokenInvalidateLock, 'sync')();
    if (isNaN(rootStore.state.userTokenInvalidateAttemptsCount) || isUndefined(rootStore.state.userTokenInvalidateAttemptsCount)) rootStore.state.userTokenInvalidateAttemptsCount = 0;
    if (isNaN(rootStore.state.userTokenInvalidateLock) || isUndefined(rootStore.state.userTokenInvalidateLock)) rootStore.state.userTokenInvalidateLock = 0;

    if (config.users.autoRefreshTokens) {
      if (!rootStore.state.userTokenInvalidateLock) {
        rootStore.state.userTokenInvalidateLock++;
        if (rootStore.state.userTokenInvalidateAttemptsCount >= config.queues.maxNetworkTaskAttempts) {
          Logger.error('Internal Application error while refreshing the tokens. Please clear the storage and refresh page.', 'sync')();
          rootStore.state.userTokenInvalidateLock = -1;
          rootStore.dispatch('user/logout', { silent: true });
          rootStore.dispatch('notification/spawnNotification', {
            type: 'error',
            message: i18n.t('Internal Application error while refreshing the tokens. Please clear the storage and refresh page.'),
            action1: { label: i18n.t('OK') }
          });
          rootStore.state.userTokenInvalidateAttemptsCount = 0;
          window?.location?.reload();
        } else {
          Logger.info('Invalidation process in progress (autoRefreshTokens is set to true)' + rootStore.state.userTokenInvalidateAttemptsCount + rootStore.state.userTokenInvalidateLock, 'sync')();
          rootStore.state.userTokenInvalidateAttemptsCount++;
          rootStore.dispatch('user/refresh').then((token) => {
            if (token) {
              rootStore.state.userTokenInvalidateLock = 0;
              rootStore.state.userTokenInvalidated = token;
              Logger.info('User token refreshed successfully' + token, 'sync')();
            } else {
              rootStore.state.userTokenInvalidateLock = -1;
              rootStore.dispatch('user/logout', { silent: true });
              Logger.error('Error refreshing user token' + token, 'sync')();
            }
          }).catch((excp) => {
            rootStore.state.userTokenInvalidateLock = -1;
            rootStore.dispatch('user/logout', { silent: true });
            Logger.error('Error refreshing user token' + excp, 'sync')();
          })
        }
      }
    } else {
      Logger.info('Invalidation process is disabled (autoRefreshTokens is set to false)', 'sync')();
      rootStore.dispatch('user/logout', { silent: true });
    }
  }
}
