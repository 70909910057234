export const getDefaultState = () => ({
  cartId: '',
  cartItems: [],
  cartTotals: {},
  paymentMethods: [],
  shippingMethods: [],
  isAddingToCart: false,
  cartIsLoaded: false,
  loadingCart: true,
  itemsToRemove: []
});
