<template>
  <div class="sf-product-card">
    <div class="sf-product-card__image-wrapper">
      <router-link
        :to="link"
        class="sf-product-card__link"
        @click="productEventHandler(link)"
        :target="openNewTab ? '_blank': ''"
      >
        <slot name="image" v-bind="{ image, title }">
          <template v-if="showCoverImage && !isHovering">
            <MImage
              ref="sfGalleryBigImage"
              :src="coverImagePath"
              :alt="title"
              :backdrop="true"
              @mouseenter="onMouseEnter"
              @mouseleave="onMouseLeave"
            />
          </template>
          <template v-else-if="Array.isArray(image)">
            <MImage
              v-for="(picture, key) in image.slice(0, 2)"
              :key="key"
              ref="sfGalleryBigImage"
              :src="picture"
              :alt="title"
              :backdrop="true"
            />
          </template>
          <template v-else>
            <MImage
              ref="sfGalleryBigImage"
              :src="activeImage"
              :alt="title"
              :lazy="!loadOnPriority"
              backdrop
              :class="{ 'no-opacity': loadOnPriority }"
              :setpriority="loadOnPriority"
              @mouseenter="onMouseEnter"
              @mouseleave="onMouseLeave"
            />
            <AProductLabel :categories="categoryIds" />
            <link
              v-if="loadOnPriority"
              fetchpriority="high"
              rel="preload"
              :href="activeImage"
              as="image"
            >
            <link
              v-if="!isMobileDevice && hoverImage"
              rel="preload"
              :href="hoverImage"
              as="image"
            >
          </template>
          <div class="featured-badge" v-if="isFeatured">
            <span>Featured</span>
          </div>
        </slot>
      </router-link>
      <slot name="badge" v-bind="{ badgeLabel, badgeColor }">
        <SfBadge
          v-if="badgeLabel"
          class="sf-product-card__badge"
          :class="badgeColorClass"
        >
          {{ badgeLabel }}
        </SfBadge>
        <SfBadge
          v-if="isPreOrder"
          class="sf-product-card__badge pre-order-badge"
        >
          {{ $t("pre-order") }}
        </SfBadge>
        <SfBadge
          v-if="!stock && !isPreOrder && !saleVariantFilterApplied"
          class="sf-product-card__badge oos-badge"
        >
          <span class="label-2">{{ $t("Out of Stock") }}</span>
        </SfBadge>
      </slot>
    </div>
    <AProductLabel2 :label="product.product_label_2" />
    <router-link
      class="sf-product-card__link prd__link"
      :to="link"
      @click="titleEventHandler(link)"
    >
      <slot name="title" v-bind="{ title }">
        <p class="sf-product-card__title paragraph-2">
          {{ title }}
        </p>
      </slot>
    </router-link>
    <SfButton
      v-if="wishlistIcon !== false"
      ref="SfButtonRef"
      :aria-label="`${ariaLabel} ${title}`"
      :class="wishlistIconClasses"
      @click="toggleIsOnWishlist"
    >
      <slot name="wishlist-icon" v-bind="{ currentWishlistIcon }">
        <SfIcon
          :icon="currentWishlistIcon"
          size="22px"
          data-test="sf-wishlist-icon"
        />
      </slot>
    </SfButton>
    <div class="price_with_discount" @click.stop="">
      <slot name="price" v-bind="{ specialPrice, regularPrice }">
        <template v-if="!childProductPrice">
          <template v-if="!isAllChildProductOnSpecialPrice">
            <template>
              <!-- if some child items are still at full price, then show full price -->
              <template v-if="!isSomeChildProductOnSpecialPrice || !isOnSalePage">
                <div class="sf-product-card__price">
                  {{ regularPrice }}
                </div>
              </template>
              <template v-else>
                <SfPrice
                  class="sf-product-card__price"
                  :regular="regularPrice"
                  :special="specialPrice"
                />
              </template>
              <div v-if="specialPrice && isOnSalePage" class="sf-price__discount tiny">
                {{ discount() }}
              </div>
            </template>
          </template>
          <template v-else>
            <!-- if all items are marked down but there is a range of prices, show the lower in-stock product price -->
            <template v-if="productPriceRange">
              <SfPrice
                class="sf-product-card__price"
                :regular="regularPrice"
                :special="inStockMinSpecialPrice"
              />
              <div v-if="specialPrice" class="sf-price__discount tiny">
                {{ discount(inStockMinSpecialPrice) }}
              </div>
            </template>
            <template v-else>
              <SfPrice
                class="sf-product-card__price"
                :regular="regularPrice"
                :special="specialPrice"
              />
              <div v-if="specialPrice" class="sf-price__discount tiny">
                {{ discount() }}
              </div>
            </template>
          </template>
        </template>

        <template v-else>
          <SfPrice
            v-if="childProductPrice.special"
            class="sf-product-card__price"
            :regular="regularPrice"
            :special="childProductPrice.special"
          />
          <div class="sf-product-card__price" v-else>
            {{ childProductPrice.regular }}
          </div>
          <div v-if="childProductPrice.special" class="sf-price__discount tiny">
            {{ childProductDiscount }}
          </div>
        </template>
      </slot>
    </div>
    <AApproxPrice
      :price-value="priceValue"
      :price-type="(specialPrice && isOnSalePage) ? 'special' : 'regular'"
    />
    <slot name="reviews" v-bind="{ maxRating, scoreRating }">
      <div
        v-if="typeof scoreRating === 'number'"
        class="sf-product-card__reviews"
      >
        <SfRating
          class="sf-product-card__rating"
          :max="maxRating"
          :score="scoreRating"
        />
        <a
          v-if="reviewsCount"
          v-focus
          :aria-label="`Read ${reviewsCount} reviews about ${title}`"
          class="sf-product-card__reviews-count"
          href="#"
          @click="$emit('click:reviews')"
        >
          ({{ reviewsCount }})
        </a>
      </div>
    </slot>
    <!-- default slot content -->
    <slot />
  </div>
</template>

<script>
import {
  SfProductCard,
  SfButton,
  SfPrice,
  SfIcon
} from '@storefront-ui/vue';
import { getThumbnailPath } from 'src/modules/catalog-es/galleryhelper';
import { convertPrice } from 'common/helpers';
import AApproxPrice from '../atoms/a-approx-price.vue';
import AProductLabel from '../atoms/a-product-label.vue';
import AProductLabel2 from '../atoms/a-product-label-2.vue';
import MImage from './m-image.vue';
import windowSize from 'common/mixins/windowSize';
import config from 'config';

export default {
  name: 'MProductCard',
  components: {
    AApproxPrice,
    AProductLabel,
    AProductLabel2,
    MImage,
    SfIcon,
    SfButton,
    SfPrice
  },
  mixins: [SfProductCard, windowSize],
  props: {
    categoryIds: {
      type: Array,
      default: () => []
    },
    blockId: {
      type: String,
      default: ''
    },
    hoverImage: {
      type: String,
      default: ''
    },
    gtmLinkEvent: {
      type: String,
      default: ''
    },
    gtmProductEvent: {
      type: String,
      default: ''
    },
    stock: {
      type: Boolean,
      default: true
    },
    isPreOrder: {
      type: Boolean,
      default: false
    },
    link: {
      type: [String, Object],
      default: ''
    },
    mobileImage: {
      type: String,
      default: ''
    },
    loadOnPriority: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      required: false,
      default: () => {}
    },
    openNewTab: {
      type: Boolean,
      default: false
    },
    childProductPrice: {
      type: Object,
      required: false,
      default: null
    },
    saleVariantFilterApplied: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    isHovering: false
  }),
  computed: {
    childProductDiscount () {
      const convertedRegularPrice = convertPrice(this.childProductPrice.regular);
      const convertedSpecialPrice = convertPrice(this.childProductPrice.special);
      const diff = convertedRegularPrice - convertedSpecialPrice;
      return ((diff / convertedRegularPrice) * 100).toFixed() + '%';
    },
    priceValue () {
      const effectivePrice = this.childProductPrice
        ? (this.childProductPrice.special || this.childProductPrice.regular)
        : (this.specialPrice || this.regularPrice);
      return convertPrice(effectivePrice);
    },
    activeImage () {
      const activeCurImage = this?.isMobileDevice
        ? this.mobileImage
        : this.image;
      return !this.isHovering ? activeCurImage : this.hoverImage;
    },
    coverImages () {
      const links = this.product?.cover_image?.split(',')
      if (!links) return null
      let images = {}
      links.forEach(link => {
        const linkSplitted = link.split(':')
        if (linkSplitted.length > 0) {
          const newImageLink = {
            [linkSplitted[0].trim()]: linkSplitted[1].trim()
          }
          Object.assign(images, newImageLink)
        }
      })
      return images
    },
    currentCategory () {
      return '/' + this.$route.fullPath
        .split('?')[0]
        .split('/')
        .slice(2)
        .join('/');
    },
    showCoverImage () {
      return this.coverImages ? !!this?.coverImages[this?.currentCategory] : false
    },
    coverImagePath () {
      if (!this.coverImages) return ''
      const { width, height } = config?.products?.thumbnails
      const url = this?.coverImages[this?.currentCategory]
      if (!url) return ''
      return getThumbnailPath(this.coverImages[this?.currentCategory], width, height)
    },
    isFeatured () {
      const queries = this.$route?.query
      const isFeatured = queries.featuredSkus
      return (isFeatured && isFeatured.includes(this.product.parentSku) && (queries.page === '1' || !queries.page))
    },
    productPriceRange () {
      return this.product?.price?.range || null
    },
    isAllChildProductOnSpecialPrice () {
      return this.product.configurable_children?.every(
        (childSku) => childSku?.price?.special
      );
    },
    isSomeChildProductOnSpecialPrice () {
      return this.product.configurable_children?.some(
        (childSku) => childSku?.price?.special
      );
    },
    isOnSalePage () {
      const currentPath = this.$route.path;
      return currentPath.includes('sale');
    },
    inStockMinSpecialPrice () {
      if (!this.isOnSalePage) {
        return this.productPriceRange.min;
      }
      const filteredProducts = this.product.configurable_children.filter(product => product.stock.qty > 0 && convertPrice(product.price.special) < convertPrice(product.price.regular));
      // Find the product with the minimum special price among the filtered products
      const minSpecialPriceProduct = filteredProducts.reduce((minProduct, currentProduct) => {
        const minSpecialPrice = convertPrice(minProduct.price.special);
        const currentSpecialPrice = convertPrice(currentProduct.price.special);
        return currentSpecialPrice < minSpecialPrice ? currentProduct : minProduct;
      }, filteredProducts[0]);
      return minSpecialPriceProduct?.price?.special || this.productPriceRange.min;
    }
  },
  methods: {
    discount (specialPrice) {
      const convertedRegularPrice = convertPrice(this.regularPrice);
      const convertedSpecialPrice = convertPrice(specialPrice || this.specialPrice);
      const diff = convertedRegularPrice - convertedSpecialPrice;
      return ((diff / convertedRegularPrice) * 100).toFixed() + '%';
    },
    onMouseEnter () {
      if (!this.hoverImage || this.isMobileDevice || this.isHovering) return;
      this.isHovering = true;
    },
    onMouseLeave () {
      if (!this.hoverImage || !this.isHovering) return;
      this.isHovering = false;
    },
    titleEventHandler (link) {
      if (this.gtmLinkEvent) {
        this?.$gtm?.trackEvent({ event: this.gtmLinkEvent });
      }
    },
    productEventHandler (link) {
      if (this.gtmProductEvent) {
        this?.$gtm?.trackEvent({ event: this.gtmProductEvent });
      }
    },
    toggleIsOnWishlist () {
      // TODO : To pass Unit test error
    }
  }
};
</script>
<style lang="scss" scoped>
@import "src/common/assets/css/pricediscount.scss";

.sf-price {
  ::v-deep &__value--special {
    font-weight: bold;
    margin-left: var(--spacing-xl);
    color: var(--heart-red-base);
    text-decoration: none;
  }
}

.featured-badge {
  position: absolute;
  top: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  right: 0;
  color: var(--white);
  font-size: var(--font_size_50);
  height: 22px;
  width: 66px;
  background: var(--soft-beige-base);
}
.oos-badge {
  top: calc(100% - 40px);
  height: 40px;
  width: 100%;
  background-color: var(--heart-red-50);
  text-transform: uppercase;
  color: var(--neutral-900);
  opacity: 0.9;
}
.pre-order-badge {
  @extend .oos-badge;
  background-color: var(--cool-pink-base);
}
.add-to-cart-btn {
  position: absolute;
  bottom: 12px;
  right: 12px;
  height: 40px;
  width: 40px;
  .lb-icon {
    stroke: var(--white);
  }
}
::v-deep .no-opacity {
  img {
    opacity: 1 !important;
  }
}
.price_with_discount {
  @include price-discount;
  display: inline-flex;
  gap: var(--spacer-xs);
  .sf-price__discount {
    color: var(--white);
    height: fit-content;
    background: var(--danger-400);
  }
}

.sf-product-card {
  .regular-price-item {
    margin-top: 0;
  }
  .product-price {
    margin: 0;
    flex-basis: auto !important;
    font-size: var(--font_size_75);
    font-weight: 500;
  }
  ::v-deep .strikethrough-price .sf-price__value {
    color: var(--neutral-500);
    text-decoration: line-through;
    font-style: italic;
  }
}
</style>
