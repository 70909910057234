import { SearchQuery } from 'storefront-query-builder'
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { STOCK, CC_IMS_STOCK } from 'common/constants';

export function prepareQuickSearchQuery (queryText) {
  let searchQuery = new SearchQuery()

  searchQuery = searchQuery
    .setSearchText(queryText)
    .applyFilter({ key: 'visibility', value: { 'in': [3, 4] } })
    .applyFilter({ key: 'status', value: { 'in': [0, 1] } })/* 2 = disabled, 3 = out of stock */

  if (config.products.listOutOfStockProducts === false) {
    searchQuery = searchQuery.applyFilter({ key: currentStoreView()?.ims ? CC_IMS_STOCK : STOCK, value: { 'eq': true } })
  }

  return searchQuery
}
