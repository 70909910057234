import config from 'config';
import { TaskQueue } from '@vue-storefront/core/lib/sync';
import Task from '@vue-storefront/core/lib/sync/types/Task';
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import rootStore from '@vue-storefront/core/store'
import { Logger } from '@vue-storefront/core/lib/logger'
import { parseReadableStream } from '../../common/helpers/'

export const fetchLoyaltyChallenges = async (): Promise<Task> => {
  const url = getApiEndpointUrl(config.loyalty.endpoint, 'challenges')
  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
}
export const fetchCustomerLoyaltyStatus = async (): Promise<Task> => {
  const url = getApiEndpointUrl(config.loyalty.endpoint, 'customerLoyaltyStatus')
  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
}
export const fetchCustomerLoyaltyPointsHistory = async (pageNum: number): Promise<Task> => {
  const url = getApiEndpointUrl(config.loyalty.endpoint, 'pointsHistory').replace('{{pageNum}}', pageNum.toString());
  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
}
export const callClaimReward = async (rewardID: string): Promise<Task> => {
  const url = getApiEndpointUrl(config.loyalty.endpoint, 'claimReward')
    .replace('{{rewardID}}', rewardID)
  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
}
export const optInToLoyaltyProgram = async (payload): Promise<Task> => {
  const url = getApiEndpointUrl(config.loyalty.endpoint, 'optIn')
  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({ ...payload })
    },
    silent: true
  });
}
export const fetchAvailableWallets = async (): Promise<Task> => {
  const url = getApiEndpointUrl(config.loyalty.endpoint, 'availableWallets')
  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
}
export const fetchDownlodWallet = async (walletType: string): Promise<Task> => {
  const url = getApiEndpointUrl(
    config.loyalty.endpoint,
    'downloadWallet'
  )
    .replace('{{walletType}}', walletType)
  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
}
export const fetchClaimedRewards = async (): Promise<Task> => {
  const url = getApiEndpointUrl(config.loyalty.endpoint, 'claimedRewards')
  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
};
export const leaveLoyaltyProgram = async (): Promise<Task> => {
  const url = getApiEndpointUrl(config.loyalty.endpoint, 'leaveLoyaltyProgram')
  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
};
export const fetchReferralsData = async (): Promise<Task> => {
  const url = getApiEndpointUrl(config.loyalty.endpoint, 'referrals')
  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
};
export const fetchAvailableRewards = async (pointsFilter: string = '', token: string) => {
  let queryParams: string[] = []
  let queryString = ''
  if (pointsFilter !== '') queryParams.push(`pointsFilter=${pointsFilter}`)
  const currentStoreCode = rootStore.getters['getCurrentStoreView']?.storeCode
  if (currentStoreCode) queryParams.push(`storeCode=${currentStoreCode}`)
  queryString = queryParams.join('&')
  let url = `${getApiEndpointUrl(config.api, 'url')}${getApiEndpointUrl(config.loyalty.endpoint, 'availableRewards')}`
  if (queryString.length) {
    // eslint-disable-next-line eqeqeq
    url = url.indexOf('?') != -1
      ? `${url}&${queryString}`
      : `${url}?${queryString}`
  }
  try {
    const { body: responseBody } = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`
      }
    })
    return await parseReadableStream(responseBody)
  } catch (error) {
    Logger.error(error)
    return []
  }
};
