import config from 'config';
import { isServer } from 'common/utils';
import sumBy from 'lodash-es/sumBy';
import { COUNTRIES } from 'common/helpers/countries';
import {
  storesShortCode,
  freeShippingThreshold,
  STAPLES_CATEGORY_ID,
  GIFT_CARD_TYPE,
  SIMPLE_TYPE,
  includedTaxLabels
} from 'common/constants';
import { getThumbnailPath, productThumbnailPath } from '@vue-storefront/core/helpers';
import { formatProductSlug } from 'common/helpers';

const getThumbnailForProduct = (product) => {
  const thumbnail = productThumbnailPath(product)
  const { width, height } = config?.cart?.thumbnails

  return getThumbnailPath(thumbnail, width, height)
}

const getProductUrl = (isGiftCard, product) => {
  if (!isGiftCard) {
    return `/${formatProductSlug(product.slug)}.html`
  }
  const sku = product?.parentSku || product.sku
  return `p/${sku}/${product?.id}`;
}

const getters = {
  isGuestCart: (state, getters) => isNaN(getters.getCartId),
  getIsCartLoaded: state => state.cartIsLoaded,
  getCartItems: state => (state.cartItems || [])
    ?.sort((a, b) => Number(a.isInStock) - Number(b.isInStock)),
  getIsAddingToCart: state => state.isAddingToCart,
  getCartTotals: state => state.cartTotals || {},
  getIsLoadingCart: state => state.loadingCart,
  getCartTotalsSegments: state => state.cartTotals?.total_segments || [],
  getShippingMethods: state => state.shippingMethods || [],
  getShippingInfo: state => state.shippingInfo || [],
  hasPreorderItemInCart: state => state.cartItems?.some(item => item.is_pre_order),
  hasMixedItemsInCart: (state, getters) => !state?.cartItems?.every(item => item.is_pre_order) && getters.hasPreorderItemInCart,
  isGiftcardOnly: ({ cartItems }) => cartItems?.length ? cartItems.every(item => item.type_id === 'giftcard') : false,
  isCartEmpty: state => !state.cartItems.length,
  getItemsToRemove: state => state.itemsToRemove,
  haveItemsToRemove: state => !!state.itemsToRemove.length,
  getItemsTotalQuantity: ({ cartItems }) => config.cart.minicartCountType === 'items' ? cartItems.length : sumBy(cartItems, p => p.qty),
  getAppliedStoreCredit: (state, getters) => Math.abs(getters.getCartTotalsSegments?.find((total) => total?.code === 'customerbalance')?.value || 0),
  anyCartItemIsOos: (state, getters) => getters.getCartItems?.some(cartItem => cartItem.hasOwnProperty?.('isInStock') && !cartItem.isInStock),
  oosItemsCount: (state, getters) => getters.getCartItems?.filter(({ isInStock }) => isInStock === false)?.reduce((sum, record) => (sum + 1), 0),
  getCartGrandTotal: (state, getters) => ({ title: 'Total', value: getters.getCartTotalsSegments?.find((total) => total?.code === 'grand_total')?.value || 0 }),
  getCartId: (state, getters, rootState, rootGetters) => {
    if (!isServer && !state.cartId) {
      const { storeCode } = rootGetters['getCurrentStoreView']
      return JSON.parse(localStorage.getItem(`${storeCode}-shop/cart/current-cart-token`) || '""')
    }
    return state.cartId || ''
  },
  getAppliedCouponFromTotals: (state, getters) => {
    const totals = getters.getCartTotals
    // this discount amount check is necessary to make sure we're not ignoring negative values
    if (!totals || isNaN(totals.discount_amount) || totals.discount_amount === 0) {
      if (!totals.coupon_code) {
        return false
      }

      return {
        code: totals.coupon_code || undefined,
        discount: undefined
      };
    }

    return {
      code: totals.coupon_code || '',
      discount: totals.discount_amount,
      title: getters.getCartDiscount?.title || ''
    }
  },
  getCartSubtotal: (state, getters, rootState, rootGetters) => {
    let subtotal = getters.getCartTotalsSegments?.find?.(total => total.code === 'subtotal')?.value;
    if (rootGetters.getCurrentStoreView.tax.usePriceExclusiveTax) {
      subtotal = getters.getCartTotals?.subtotal || subtotal;
    }
    return { title: 'Subtotal', value: subtotal || 0, code: 'subtotal' };
  },
  getCartDiscount: (state, getters) => {
    let discount = getters.getCartTotalsSegments?.find?.(total => total.code === 'discount');
    return { title: discount?.title || 'Discount', value: Math.abs(discount?.value || 0), code: 'discount' };
  },
  getCartSubtotalAfterDiscount: (state, getters, rootState, rootGetters) => {
    const subtotal = getters.getCartSubtotal?.value || 0;
    let subtotalAfterDiscount = { title: 'Subtotal after discount', value: subtotal, code: 'subtotalAfterDiscount' };
    const appliedPromoCode = getters.getAppliedCouponFromTotals;
    if (!appliedPromoCode) return subtotalAfterDiscount;
    subtotalAfterDiscount.value = subtotal - getters.getCartDiscount.value;
    return subtotalAfterDiscount;
  },
  getFreeShippingCountry: (state, getters, rootState, rootGetters) => {
    const storeCode = rootGetters.getCurrentStoreView?.storeCode;
    if (!storeCode) return null;
    const intlCountryCode = rootGetters['checkout/getShippingAddressCountry']?.alpha3 || rootState?.currencyconversion?.localValue?.country;
    return storeCode === storesShortCode.intl ? intlCountryCode : rootGetters?.getCurrentStoreView?.countryCode
  },
  getFreeShippingThreshold: (state, getters, rootState, rootGetters) => {
    return freeShippingThreshold[getters.getFreeShippingCountry];
  },
  getFreeShippingSubtotal: (state, getters) => {
    return getters.getCartSubtotal.value - getters.getCartDiscount.value;
  },
  getFreeShippingAmountLeft: (state, getters) => {
    return getters.getFreeShippingThreshold - getters.getFreeShippingSubtotal;
  },
  getIsFreeShippingApplicable: (state, getters, rootState, rootGetters) => {
    const storeCode = rootGetters.getCurrentStoreView?.storeCode;
    if (!storeCode) return false;
    const flag = `${storeCode}_min_spent_bar`.toUpperCase();
    return config?.featureFlags?.[flag]?.isActive && (getters.getFreeShippingCountry in freeShippingThreshold)
  },
  isShippingFree: (state, getters) => {
    return getters.getIsFreeShippingApplicable && getters.getFreeShippingAmountLeft <= 0;
  },
  getCartShippingTotal: (state, getters, rootState, rootGetters) => {
    let title = 'Shipping';
    let value = getters.getCartTotalsSegments?.find((total) => total?.code === 'shipping')?.value || 0;

    if (!rootGetters['checkout/isShippableAddress'] && !getters.isShippingFree) {
      title = 'Shipping (Calculated at checkout)';
      value = '-';
    }
    if (!value || (getters.isShippingFree)) {
      value = 'Free';
    }
    return { title, value, code: 'shipping' };
  },
  selectedCountry: (state, getters, rootState, rootGetters) => {
    if (!rootGetters['checkout/isShippableAddress'] && rootGetters['address/selectedCountry']) {
      return rootGetters['address/selectedCountry']
    }
    return rootGetters['checkout/getShippingDetails']?.country_id || rootGetters['address/selectedCountry']
  },
  getCartTaxes: (state, getters, rootState, rootGetters) => {
    const gstCountries = ['AU', 'NZ', 'AUS', 'NZL']; // AUS and NZL are set by country switcher so adding them here too
    let title = 'Tax';
    let value = getters.getCartTotalsSegments?.find((total) => total?.code === 'tax')?.value || 0;

    // For US and INTL store when invalid address is selected
    if (
      (
        rootGetters?.getCurrentStoreView?.storeCode === storesShortCode.us ||
        rootGetters?.getCurrentStoreView?.storeCode === storesShortCode.intl
      ) &&
      !rootGetters['checkout/isShippableAddress']
    ) {
      title = 'Tax (Calculated at checkout)'
      value = '-'
    }

    // For No Tax Countries
    if (
      (rootGetters?.getCurrentStoreView?.storeCode === storesShortCode.intl && !gstCountries.includes(getters.selectedCountry)) ||
      rootGetters?.getCurrentStoreView?.storeCode === storesShortCode.hk ||
      rootGetters?.getCurrentStoreView?.storeCode === storesShortCode.id
    ) { value = 'No Tax'; }

    // Tax label
    if (rootGetters?.getCurrentStoreView?.storeCode === storesShortCode.sg) {
      title = includedTaxLabels['SG']
    } else if (rootGetters?.getCurrentStoreView?.storeCode === storesShortCode.my) {
      title = includedTaxLabels['MY']
    } else if (rootGetters?.getCurrentStoreView?.storeCode !== storesShortCode.us && rootGetters['checkout/isShippableAddress']) {
      title = includedTaxLabels[getters.selectedCountry] || 'Tax';
    }
    return { title, value, code: 'tax' }
  },
  getCartTaxesAndDuties: (state, getters, rootState, rootGetters) => ({ title: 'Taxes & Duties', value: getters.getCartTotalsSegments?.find((total) => total?.code === 'ddp_tax_duty_amount')?.value || 0, code: 'ddp_tax_duty_amount' }),
  getCartTotalsSummary: (_, getters) => ([
    getters.getCartSubtotal,
    getters.getCartSubtotalAfterDiscount
  ]),
  getPaymentMethods: state => state.paymentMethods.filter(method => method.code !== 'cashondelivery').map(method => {
    method.is_server_method = true
    return method
  }),
  unShippableProduct (state, getters, rootState, rootGetters) {
    const countryId = rootState?.checkout?.shippingDetails?.country_id ||
      (rootGetters.getCurrentStoreView?.storeCode === storesShortCode.intl ? rootGetters['address/selectedCountry'] : rootGetters.getCurrentStoreView?.storeCode?.toUpperCase());
    const country = COUNTRIES.find(({ alpha2, alpha3 }) => countryId === alpha2 || countryId === alpha3);
    const cartItem = getters.getCartItems?.find((item) => config.unshippableProducts[country.alpha2]?.includes(item.sku));
    const countryName = country.name.indexOf('(the)') !== -1 ? `The ${country.name.replace('(the)', '').trim()}` : country.name.trim();
    return cartItem ? { countryName, productName: cartItem.name } : null;
  },
  getMappedCartItems: (state, getters, rootState, rootGetters) => {
    const isOnIntl = rootGetters['getCurrentStoreView'].storeCode === storesShortCode.intl
    return getters.getCartItems.map(cartItem => ({
      ...cartItem,
      isGiftCard: cartItem.type_id === GIFT_CARD_TYPE,
      isLowInStock: cartItem.type_id === GIFT_CARD_TYPE ? false : cartItem.stock?.qty <= 15,
      is_in_stock: cartItem.isInStock,
      isPreOrder: cartItem.is_pre_order,
      isReturnable: cartItem.is_returnable,
      productImage: getThumbnailForProduct(cartItem),
      isGWPItem: cartItem.type_id === SIMPLE_TYPE && cartItem.price === 0,
      isStapleItem: !isOnIntl && cartItem?.category_ids?.includes(STAPLES_CATEGORY_ID),
      url: getProductUrl(cartItem.type_id === GIFT_CARD_TYPE, cartItem)
    }))
  },
  isSalesActive: (state, getters, rootState, rootGetters) => {
    const storeCode = rootGetters['getCurrentStoreView']?.storeCode;
    const currentStoreConfig = config.storeViews[storeCode]
    if (!currentStoreConfig?.salesPeriod?.startDate || !currentStoreConfig?.salesPeriod?.endDate) return false
    const startDate = new Date(currentStoreConfig.salesPeriod.startDate)
    const endDate = new Date(currentStoreConfig.salesPeriod.endDate)
    const currentDate = new Date().getTime();
    return currentDate >= startDate?.getTime() && currentDate <= endDate?.getTime()
  }
}

export default getters;
