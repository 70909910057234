import HeroBanner from './HeroBanner'
import withComponent from './withComponent';

function getProps (props) {
  const {
    banners,
    change_time
  } = props;

  const bannersGroup = banners.map((current) => withComponent(HeroBanner)(current))

  return {
    bannersGroup,
    changeTime: parseInt(change_time)
  }
}

const HomepageHeroCarouselBannersGroup = () => ({
  type: 'homepage_hero_carousel',
  props: getProps
});

export default HomepageHeroCarouselBannersGroup
