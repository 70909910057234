<template>
  <div>
    <div
      class="overlay"
      @click="closeSearchpanel"
    />
    <div
      class="search-panel-container"
      :class="{'full-height' : products && products.length}"
    >
      <div class="header">
        <ALinkLogo
          :open="true"
          class="logo"
          @click.native="closeSearchpanel"
        />
        <button
          class="close-icon"
          @click="closeSearchpanel"
        >
          <svg class="lb-icon account-icon no-fill stroke">
            <use xlink:href="#icon_cross" />
          </svg>
        </button>
      </div>
      <div class="search-box">
        <div class="search-wrapper">
          <div class="input-wrapper">
            <svg class="lb-icon account-icon no-fill stroke">
              <use xlink:href="#Search" />
            </svg>
            <input
              id="search"
              ref="search"
              v-model.trim="search"
              type="text"
              :placeholder="$t('Search')"
              class="search-input-box paragraph-1"
              maxlength="255"
              role="combobox"
              aria-haspopup="false"
              aria-autocomplete="both"
              autofocus="true"
              autocomplete="off"
              @input="startSearch"
            >
            <div
              v-if="search && search.length"
              class="clear-text"
              @click="clearText"
            >
              <svg class="lb-icon account-icon no-fill stroke">
                <use xlink:href="#icon_cross" />
              </svg>
            </div>
          </div>

          <button
            class="cancel-btn"
            @click="closeSearchpanel"
          >
            {{ $t('Cancel') }}
          </button>
        </div>
        <div
          v-if="!search"
          class="search-suggestion"
        >
          {{ $t(staticStrings.searchSuggestion) }}
        </div>
        <MSearchHistory
          v-show="showHistoryList && !emptyResults"
          @searchTextClicked="searchHistoryTextClicked"
        />
        <MSearchTrending
          v-show="!isLoading && search.length <= 0"
          @searchTextClicked="searchTrendingTextClicked"
        />
      </div>

      <SkeletonTheme
        v-if="isLoading"
        color="#e1e1e1"
        highlight="#eee"
        class="skeleton-loader"
      >
        <div class="left-pane">
          <skeleton class="point-skeletion" />
          <skeleton class="point-skeletion" />
          <skeleton class="point-skeletion" />
          <skeleton class="point-skeletion" />
          <skeleton class="point-skeletion" />
        </div>
        <div class="right-pane">
          <skeleton
            v-for="item in 6"
            :key="item"
            class="point-skeletion"
          />
        </div>
      </SkeletonTheme>
      <div
        v-if="!isLoading && search.length > 0"
        class="search-results"
      >
        <MSearchCategories
          v-model="selectedCategoriesId"
          :categories="categories"
          :search-text="search"
        />
        <MSearchProducts
          :visible-products="visibleProducts"
          :search-text="search"
          :has-products="readMore"
          :result-count="resultCount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SearchPanel from 'src/compatibility/components/blocks/SearchPanel/SearchPanel'
import VueOfflineMixin from 'vue-offline/mixin'
import { prepareCategoryProduct, setSearchHistoryText } from 'common/helpers';
import ALinkLogo from 'common/components/atoms/a-link-logo';
import MSearchProducts from 'common/components/molecules/search-panel/m-search-products';
import MSearchCategories from 'common/components/molecules/search-panel/m-search-categories';
import { SkeletonTheme } from 'vue-loading-skeleton';
import debounce from 'lodash-es/debounce';
import staticStrings from '../../../config/staticStrings';
import MSearchHistory from 'common/components/molecules/search-panel/m-search-history'
import MSearchTrending from 'common/components/molecules/search-panel/m-search-trending'

export default {
  name: 'MSearch',
  components: {
    ALinkLogo,
    MSearchProducts,
    MSearchCategories,
    SkeletonTheme,
    MSearchHistory,
    MSearchTrending
  },
  mixins: [SearchPanel, VueOfflineMixin],
  props: {
    staticStrings: {
      type: Object,
      default: () => staticStrings
    }
  },
  data () {
    return {
      selectedCategoriesId: [],
      isLoading: false,
      showHistoryList: true
    }
  },
  computed: {
    categories () {
      const categories = this.products
        .filter(p => p.category)
        .map(p => p.category)
        .flat()

      const distinctCategories = Array.from(
        new Set(categories.map(c => c.category_id))
      ).map(catId => categories.find(c => c.category_id === catId)).slice(0, 5)

      return distinctCategories
    },
    visibleProducts () {
      let productList = this.products || []
      if (this.selectedCategoriesId.length) {
        productList = productList.filter(product => {
          const categoryIds = product.category_ids || [];
          return categoryIds.some(categoryId => {
            return this.selectedCategoriesId.includes(categoryId)
          })
        }
        )
      }
      return productList.map(prepareCategoryProduct)
    }
  },
  watch: {
    $route (to, from) {
      this.closeSearchpanel()
    },
    search () {
      this.resetLoading();
    }
  },
  beforeMount () {
    this.$bus.$on('load-more-products', this.seeMore);
    this.$bus.$on('search-history-text-clicked', this.searchHistoryTextClicked);
    this.startSearch = debounce(this.startSearch, 500);
  },
  mounted () {
    const classes = document.body.classList;
    classes.add('modal-open');
    this.$refs.search.focus()
    this.showHistoryList = true
  },
  beforeDestroy () {
    const classes = document.body.classList;
    classes.remove('modal-open');
    this.$bus.$off('load-more-products', this.seeMore)
    this.$bus.$off('search-history-text-clicked', this.searchHistoryTextClicked)
  },
  methods: {
    async startSearch ({ fromSuggestion }) {
      if (!this.search) {
        this.isLoading = false;
        return;
      }
      this.isLoading = true;
      if (!fromSuggestion) {
        this.$gtm?.trackEvent({ event: 'search_start', search_term: this.search })
      }

      const { items, total } = await this.$store.dispatch('catalog-es/getProductsByKeyword', {
        keyword: this.search,
        pageSize: 8
      });
      this.products = items
      this.resultCount = total?.value || 0;

      if (this.products?.length) {
        this.showHistoryList = false
        if (this.search.length >= 3) {
          setSearchHistoryText(this.search)
        }
      }

      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    resetLoading () {
      if (this.search?.length) {
        this.isLoading = true;
      } else {
        this.showHistoryList = true
      }
    },
    clearText () {
      this.search = ''
      this.showHistoryList = true
      this.products = []
      this.isLoading = false
      this.emptyResults = false
    },
    searchHistoryTextClicked (text) {
      this.search = text
      this.showHistoryList = false
      this.$gtm?.trackEvent({ event: 'click_recent_search', search_term: this.search })
      this.startSearch({ fromSuggestion: true })
    },
    searchTrendingTextClicked ({ label, link }) {
      this.$gtm?.trackEvent({ event: 'click_trending_search', search_term: label })
      if (link) {
        this.$router.push(this.localizedRoute(link));
        this.closeSearchpanel();
        return;
      }
      this.search = label;
      this.startSearch({ fromSuggestion: true })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.skeleton-loader {
  flex: 1;
  .left-pane {
    flex: 1 1;
    display: flex;
    flex-flow: column;
    width: 200px;
    margin-bottom: 40px;

    .point-skeletion {
      width: 100%;
      height: 15px;
      margin-bottom: 8px;
    }
  }
  .right-pane {
    flex: 3;
    display: flex;
    flex-wrap: wrap;
    .point-skeletion {
      height: 200px;
      padding-left: 10px;
      margin-bottom: 10px;
      box-sizing: border-box;
    }
  }
}
.overlay {
  background: var(--black-opacity);
  height: 100%;
  z-index: 1;
  position: fixed;
  width: 100%;
}
.search-panel-container {
  z-index: 1;
  position: fixed;
  display: flex;
  flex-flow: column;
  left: 0;
  top: 0;
  width: 100%;
  background: var(--white);
  &.full-height {
    height: 100%;
    padding-bottom: 0;
  }
  @include for-desktop {
    padding-bottom: var(--spacer-lg);
  }
  @include for-mobile {
    top: 106px !important;
    height: 100%;
  }
  .header {
    background: var(--white);
    padding: var(--spacer-lg);
    height: 115px;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    justify-content: space-between;
    .close-icon {
      cursor: pointer;
      background: transparent;
      border: none;
      &:focus {
        outline: none;
      }
    }
    @include for-mobile {
      display: none;
    }
  }
  .search-box {
    @include for-mobile {
      padding: 20px;
    }
    @include for-desktop {
      width: 100%;
      max-width: 1270px;
      margin: 0 auto;
      padding-top: 63px;
      padding-bottom: var(--spacer-lg);
    }

    @media (max-width: 1290px) {
      padding: var(--spacer-lg);
      padding-top: 63px;
      max-width: 900px;
    }
    .search-input-box {
      width: 100%;
      box-sizing: border-box;
      background: 0 0;
      border: none;
      padding: 0;
      padding-left: 12px;
      height: var(--spacer-xl);
      min-height: var(--spacer-xl);
      color: var(--theme-text);
      &::placeholder {
        color: var(--bold-brown-500);
        opacity: 0.5;
      }
      &:focus {
        outline: none;
      }
    }
    .search-suggestion {
      color: var(--charcoal-grey);
      margin-top: 10px;
      font-size: var(--text-sm);
      margin-bottom: 32px;
      @include for-mobile {
        font-size: 10px;
      }
    }
    .search-wrapper {
      display: flex;
      .cancel-btn {
        @include for-desktop {
          display: none;
        }
        cursor: pointer;
        background: transparent;
        box-sizing: border-box;
        border: 1px solid var(--neutral-900);
        border-radius: 5px;
        height: var(--spacer-xl);
        margin-left: 12px;
        width: 83px;
        word-break: keep-all;
      }
      .input-wrapper {
        border: 1px solid var(--neutral-200);
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 12px;
        height: var(--spacer-xl);
        min-height: var(--spacer-xl);
        box-sizing: border-box;
        .clear-text {
          background: var(--white);
          height: 38px;
          width: var(--spacer-xl);
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid var(--neutral-900);
          border-right: none;
          border-radius: 0 5px 5px 0;
          .lb-icon {
            width: 12px;
          }
        }
      }
    }
    @include for-mobile {
      .search-input-box {
        font-size: var(--text-lg);
      }
    }
  }
  .search-results {
    flex: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin-top: 5px;
    padding: 0 var(--spacer-2xl);
    @include for-desktop {
      width: 100%;
      max-width: 1270px;
      margin: 0 auto;
    }
    @include for-mobile {
      margin-bottom: var(--spacer-2xl);
    }
    @media (min-width: 1024px) and (max-width: 1290px) {
      width: 100%;
      max-width: 900px;
    }
  }
}
@media screen and (min-width: $desktop-min) {
  .skeleton-loader {
    @include for-desktop {
      width: 1270px;
      max-width: 1270px;
      margin: 0 auto;
    }
    padding: 0 var(--spacer-2xl);
    .right-pane {
      .point-skeletion {
        flex: 25% 0 0;
      }
    }
  }
}
@media only screen and (max-width: $tablet-min) {
  .skeleton-loader {
    flex-flow: column;
    margin-top: 10px;
    padding: 0 40px;
    .right-pane {
      .point-skeletion {
        flex: 50% 0 0;
      }
    }
  }
  .logo {
    display: none;
  }
  .search-box {
    padding: 28px 15px 0 15px !important;
  }
  .search-results {
    padding: 0 15px !important;
    display: block !important;
  }
}
</style>
