<template>
  <div class="account-container">
    <SfButton
      class="sf-button--pure a-account-icon"
      :class="{ 'icon-active': active && isActive, 'wishlist-active': activeWishlist }"
      @click.stop="goToAccount"
      data-testid="accountIcon"
    >
      <div class="account-info-container">
        <template v-if="!isLoggedIn">
          <svg class="lb-icon account-icon">
            <use xlink:href="#Account" />
          </svg>
          <p class="tiny login-register desktop-only-ext">
            Login/Register
          </p>
        </template>
        <template v-else>
          <svg class="lb-icon account-icon">
            <use xlink:href="#Account_LoggedIn" />
          </svg>
          <AAccountTierPointsInfo class="desktop-only-ext" />
        </template>
      </div>
    </SfButton>
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import { ModalList } from 'theme/store/ui/modals';
import windowSize from 'common/mixins/windowSize';
import AAccountTierPointsInfo from 'common/components/atoms/a-account-tier-points-info.vue';

export default {
  components: { SfButton, AAccountTierPointsInfo },
  mixins: [windowSize],
  props: {
    open: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn', 'getCurrentUser']),
    ...mapGetters('ui', ['activeModals']),
    ...mapState({
      isMicrocartOpen: state => state.ui.microcart,
      isMenubarOpen: state => state.ui.sidebar
    }),
    ...mapGetters('loyalty', ['customerPoints', 'userLoyaltyTier'])
  },
  data () {
    return {
      active: false,
      activeWishlist: false
    };
  },
  watch: {
    activeModals: function (newVal, oldVal) {
      if (newVal.length) {
        newVal.find(m => {
          if (m.name === 'm-modal-authentication') {
            this.active = true;
          } else if (m.name === ModalList.MModalWishlistNotification) {
            this.activeWishlist = true;
          } else {
            this.active = false;
          }
        });
      } else {
        this.active = false;
        this.activeWishlist = false;
      }
    }
  },
  methods: {
    ...mapActions('ui', {
      openModal: 'openModal'
    }),
    ...mapActions('loyalty', [
      'getCustomerLoyaltyStatus'
    ]),
    goToAccount () {
      this.$gtm?.trackEvent({ event: 'click_profile' })
      if (!this.isMicrocartOpen && !this.isMenubarOpen) {
        if (this.isLoggedIn) {
          this.$emit('toggleModal');
        } else {
          this.$emit('headerActionClicked');
          const body = document.body;
          let classes = body.classList;
          classes.add('modal-open');
          this.$emit('toggleModal')
        }
      } else {
        this.$store.dispatch('ui/toggleMicrocart');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.light {
  fill: var(--lace);
}
.dark {
  fill: var(--neutral-900);
}
.a-account-icon {
  position: relative;
  outline: none;
  background: transparent;
  border-radius: 50px;
  height: 35px;
  @include for-mobile {
    height: 36px;
  }
}

.icon-active {
  background: var(--soft-peach);
  &::after {
    position: absolute;
    content: "";
    height: 4px;
    background: var(--soft-peach);
    width: 30px;
    border-radius: 10px;
    bottom: -28px;

    @include for-mobile {
      bottom: -6px;
    }
  }
}
.wishlist-active {
  &:after {
    @include for-desktop {
      display: none;
    }
    content: "";
    position: absolute;
    background: var(--lace);
    width: 35px;
    height: 50px;
    z-index: -1;
  }
}
.account-container {
  display: flex;
  .account-info-container {
    display: flex;
    align-items: center;
    .login-register {
      margin-left: 8px;
    }
    @include for-mobile {
      .account-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
