<template>
  <div class="account-tier-points-info-container">
    <p class="tiny user-name">
      Hi, {{ getCurrentUser.firstname }}
    </p>
    <div class="user-tier" v-if="userLoyaltyTier" :class="userLoyaltyTier" @click="goToRewardsPage">
      <p class="tiny tier-info">
        {{ userLoyaltyTier }}: {{ customerPoints }} Points
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AAccountTierPointsInfo',
  computed: {
    ...mapGetters('user', ['isLoggedIn', 'getCurrentUser']),
    ...mapGetters('loyalty', ['customerPoints', 'userLoyaltyTier'])
  },
  methods: {
    goToRewardsPage (event) {
      event.stopPropagation();
      this.$router.push(this.localizedRoute('/my-account?page=my-rewards'))
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.account-tier-points-info-container {
  display: flex;
   .user-name {
      margin: 3px 8px;
      white-space: nowrap;
    }
    .user-tier {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &.bronze {
        width: 128px;
        height: 22px;
        background: linear-gradient(92.5215deg, #8E4827 0%, #934B2A 10.41%, #A05531 21.98%, #B6643D 34.1%, #D57A4F 46.54%, #E48457 51.66%, #DF8154 52.76%, #C26C44 60.42%, #AB5C37 68.57%, #9B512E 77.31%, #914A29 87.09%, #8E4827 100%);
      }
      &.silver {
        width: 128px;
        height: 22px;
        background: linear-gradient(95.8521deg, #7E7B79 0%, #827F7E 11.56%, #8F8D8B 24.4%, #A4A3A1 37.86%, #C2C1C0 51.66%, #AFAEAD 57.36%, #9A9796 66.1%, #8A8786 75.53%, #817E7C 86.08%, #7E7B79 100%);
      }
      &.gold {
        width: 128px;
        height: 22px;
        background: linear-gradient(95.4359deg, #996A00 0%, #9C6D05 10.62%, #A37712 22.41%, #AF8728 34.77%, #C09C47 47.45%, #C7A553 51.66%, #C6A451 52.12%, #B68F34 59.89%, #A97F1D 68.12%, #A0730D 76.99%, #9B6C03 86.91%, #996A00 100%);
      }
      .tier-info {
        color: var(--white);
        text-transform: capitalize;
      }
    }
}
</style>
