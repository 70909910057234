import config from 'config'

const phStoreRegionData = {
  countryCodes: [
    {
      name: 'Philippines',
      alpha3: 'PHL',
      alpha2: 'PH'
    }
  ]
}

const usStoreRegionData = {
  countryCodes: [
    {
      name: 'United States',
      alpha3: 'USA',
      alpha2: 'US'
    },
    {
      name: 'Canada',
      alpha3: 'CAN',
      alpha2: 'CA'
    }
  ],
  states: {
    USA: [
      { id: 1, name: 'Alabama', value: 'Alabama', disabled: false },
      { id: 2, name: 'Alaska', value: 'Alaska', disabled: false },
      {
        id: 3,
        name: 'American Samoa',
        value: 'American Samoa',
        disabled: false
      },
      { id: 4, name: 'Arizona', value: 'Arizona', disabled: false },
      { id: 5, name: 'Arkansas', value: 'Arkansas', disabled: false },
      { id: 12, name: 'California', value: 'California', disabled: false },
      { id: 13, name: 'Colorado', value: 'Colorado', disabled: false },
      { id: 14, name: 'Connecticut', value: 'Connecticut', disabled: false },
      { id: 15, name: 'Delaware', value: 'Delaware', disabled: false },
      {
        id: 16,
        name: 'District of Columbia',
        value: 'District of Columbia',
        disabled: false
      },
      {
        id: 17,
        name: 'Federated States of Micronesia',
        value: 'Federated States of Micronesia',
        disabled: false
      },
      { id: 18, name: 'Florida', value: 'Florida', disabled: false },
      { id: 19, name: 'Georgia', value: 'Georgia', disabled: false },
      { id: 20, name: 'Guam', value: 'Guam', disabled: false },
      { id: 21, name: 'Hawaii', value: 'Hawaii', disabled: false },
      { id: 22, name: 'Idaho', value: 'Idaho', disabled: false },
      { id: 23, name: 'Illinois', value: 'Illinois', disabled: false },
      { id: 24, name: 'Indiana', value: 'Indiana', disabled: false },
      { id: 25, name: 'Iowa', value: 'Iowa', disabled: false },
      { id: 26, name: 'Kansas', value: 'Kansas', disabled: false },
      { id: 27, name: 'Kentucky', value: 'Kentucky', disabled: false },
      { id: 28, name: 'Louisiana', value: 'Louisiana', disabled: false },
      { id: 29, name: 'Maine', value: 'Maine', disabled: false },
      {
        id: 30,
        name: 'Marshall Islands',
        value: 'Marshall Islands',
        disabled: false
      },
      { id: 31, name: 'Maryland', value: 'Maryland', disabled: false },
      {
        id: 32,
        name: 'Massachusetts',
        value: 'Massachusetts',
        disabled: false
      },
      { id: 33, name: 'Michigan', value: 'Michigan', disabled: false },
      { id: 34, name: 'Minnesota', value: 'Minnesota', disabled: false },
      { id: 35, name: 'Mississippi', value: 'Mississippi', disabled: false },
      { id: 36, name: 'Missouri', value: 'Missouri', disabled: false },
      { id: 37, name: 'Montana', value: 'Montana', disabled: false },
      { id: 38, name: 'Nebraska', value: 'Nebraska', disabled: false },
      { id: 39, name: 'Nevada', value: 'Nevada', disabled: false },
      {
        id: 40,
        name: 'New Hampshire',
        value: 'New Hampshire',
        disabled: false
      },
      { id: 41, name: 'New Jersey', value: 'New Jersey', disabled: false },
      { id: 42, name: 'New Mexico', value: 'New Mexico', disabled: false },
      { id: 43, name: 'New York', value: 'New York', disabled: false },
      {
        id: 44,
        name: 'North Carolina',
        value: 'North Carolina',
        disabled: false
      },
      { id: 45, name: 'North Dakota', value: 'North Dakota', disabled: false },
      {
        id: 46,
        name: 'Northern Mariana Islands',
        value: 'Northern Mariana Islands',
        disabled: false
      },
      { id: 47, name: 'Ohio', value: 'Ohio', disabled: false },
      { id: 48, name: 'Oklahoma', value: 'Oklahoma', disabled: false },
      { id: 49, name: 'Oregon', value: 'Oregon', disabled: false },
      { id: 51, name: 'Pennsylvania', value: 'Pennsylvania', disabled: false },
      { id: 52, name: 'Puerto Rico', value: 'Puerto Rico', disabled: false },
      { id: 53, name: 'Rhode Island', value: 'Rhode Island', disabled: false },
      {
        id: 54,
        name: 'South Carolina',
        value: 'South Carolina',
        disabled: false
      },
      { id: 55, name: 'South Dakota', value: 'South Dakota', disabled: false },
      { id: 56, name: 'Tennessee', value: 'Tennessee', disabled: false },
      { id: 57, name: 'Texas', value: 'Texas', disabled: false },
      // TODO: Enable this state with id from Magento
      // { name: 'United States Minor Outlying Islands', value: 'United States Minor Outlying Islands', disabled: false },
      { id: 58, name: 'Utah', value: 'Utah', disabled: false },
      { id: 59, name: 'Vermont', value: 'Vermont', disabled: false },
      {
        id: 60,
        name: 'Virgin Islands',
        value: 'Virgin Islands',
        disabled: false
      },
      { id: 61, name: 'Virginia', value: 'Virginia', disabled: false },
      { id: 62, name: 'Washington', value: 'Washington', disabled: false },
      {
        id: 63,
        name: 'West Virginia',
        value: 'West Virginia',
        disabled: false
      },
      { id: 64, name: 'Wisconsin', value: 'Wisconsin', disabled: false },
      { id: 65, name: 'Wyoming', value: 'Wyoming', disabled: false }
    ],
    CAN: [
      {
        id: 66,
        name: 'Alberta',
        value: 'Alberta',
        disabled: false
      },
      {
        id: 67,
        name: 'British Columbia',
        value: 'British Columbia',
        disabled: false
      },
      {
        id: 68,
        name: 'Manitoba',
        value: 'Manitoba',
        disabled: false
      },
      {
        id: 70,
        name: 'New Brunswick',
        value: 'New Brunswick',
        disabled: false
      },
      {
        id: 69,
        name: 'Newfoundland and Labrador',
        value: 'Newfoundland and Labrador',
        disabled: false
      },
      {
        id: 72,
        name: 'Northwest Territories',
        value: 'Northwest Territories',
        disabled: false
      },
      {
        id: 71,
        name: 'Nova Scotia',
        value: 'Nova Scotia',
        disabled: false
      },
      {
        id: 73,
        name: 'Nunavut',
        value: 'Nunavut',
        disabled: false
      },
      {
        id: 74,
        name: 'Ontario',
        value: 'Ontario',
        disabled: false
      },
      {
        id: 75,
        name: 'Prince Edward Island',
        value: 'Prince Edward Island',
        disabled: false
      },
      {
        id: 76,
        name: 'Quebec',
        value: 'Quebec',
        disabled: false
      },
      {
        id: 77,
        name: 'Saskatchewan',
        value: 'Saskatchewan',
        disabled: false
      },
      {
        id: 78,
        name: 'Yukon Territory',
        value: 'Yukon Territory',
        disabled: false
      }
    ]
  }
};

export const regionData = {
  sg: {
    countryCodes: [
      {
        name: 'Singapore',
        alpha3: 'SGP',
        alpha2: 'SG'
      }
    ]
  },
  ph: phStoreRegionData,
  my: {
    countryCodes: [
      {
        name: 'Malaysia',
        alpha3: 'MYS',
        alpha2: 'MY'
      }
    ],
    states: {
      label: 'State',
      list: [
        {
          name: 'Johor',
          value: 'Johor',
          disabled: false
        },
        {
          name: 'Kedah',
          value: 'Kedah',
          disabled: false
        },
        {
          name: 'Kelantan',
          value: 'Kelantan',
          disabled: false
        },
        {
          name: 'Melaka',
          value: 'Melaka',
          disabled: false
        },
        {
          name: 'Negeri Sembilan',
          value: 'Negeri Sembilan',
          disabled: false
        },
        {
          name: 'Pahang',
          value: 'Pahang',
          disabled: false
        },
        {
          name: 'Penang',
          value: 'Penang',
          disabled: false
        },
        {
          name: 'Perak',
          value: 'Perak',
          disabled: false
        },
        {
          name: 'Perlis',
          value: 'Perlis',
          disabled: false
        },
        {
          name: 'Sabah',
          value: 'Sabah',
          disabled: false
        },
        {
          name: 'Sarawak',
          value: 'Sarawak',
          disabled: false
        },
        {
          name: 'Selangor',
          value: 'Selangor',
          disabled: false
        },
        {
          name: 'Terengganu  ',
          value: 'Terengganu',
          disabled: false
        },
        {
          name: 'Wilayah Persekutuan  ',
          value: 'Wilayah Persekutuan',
          disabled: false
        }
      ]
    }
  },
  id: {
    countryCodes: [
      {
        name: 'Indonesia',
        alpha3: 'IDN',
        alpha2: 'ID'
      }
    ],
    states: {
      label: 'State',
      list: [
        {
          name: 'Bangka BelitungBali',
          value: 'Bangka BelitungBali',
          disabled: false,
          code: 'ID-BA'
        },
        {
          name: 'Bangka Belitung',
          value: 'Bangka Belitung',
          disabled: false,
          code: 'ID-BB'
        },
        {
          name: 'Banten',
          value: 'Banten',
          disabled: false,
          code: 'ID-BT'
        },
        {
          name: 'Bengkulu',
          value: 'Bengkulu',
          disabled: false,
          code: 'ID-BE'
        },
        {
          name: 'DI Yogyakarta',
          value: 'DI Yogyakarta',
          disabled: false,
          code: 'ID-YO'
        },
        {
          name: 'DKI Jakarta',
          value: 'DKI Jakarta',
          disabled: false,
          code: 'ID-JK'
        },
        {
          name: 'Gorontalo',
          value: 'Gorontalo',
          disabled: false,
          code: 'ID-GO'
        },
        {
          name: 'Jambi',
          value: 'Jambi',
          disabled: false,
          code: 'ID-JA'
        },
        {
          name: 'Jawa Barat',
          value: 'Jawa Barat',
          disabled: false,
          code: 'ID-JB'
        },
        {
          name: 'Jawa Tengah',
          value: 'Jawa Tengah',
          disabled: false,
          code: 'ID-JT'
        },
        {
          name: 'Jawa Timur',
          value: 'Jawa Timur',
          disabled: false,
          code: 'ID-JI'
        },
        {
          name: 'Kalimantan Barat',
          value: 'Kalimantan Barat',
          disabled: false,
          code: 'ID-KB'
        },
        {
          name: 'Kalimantan Selatan',
          value: 'Kalimantan Selatan',
          disabled: false,
          code: 'ID-KS'
        },
        {
          name: 'Kalimantan Tengah',
          value: 'Kalimantan Tengah',
          disabled: false,
          code: 'ID-KT'
        },
        {
          name: 'Kalimantan Timur',
          value: 'Kalimantan Timur',
          disabled: false,
          code: 'ID-KI'
        },
        {
          name: 'Kalimantan Utara',
          value: 'Kalimantan Utara',
          disabled: false,
          code: 'ID-KU'
        },
        {
          name: 'Kepulauan Riau',
          value: 'Kepulauan Riau',
          disabled: false,
          code: 'ID-KR'
        },
        {
          name: 'Lampung',
          value: 'Lampung',
          disabled: false,
          code: 'ID-LA'
        },
        {
          name: 'Maluku',
          value: 'Maluku',
          disabled: false,
          code: 'ID-MA'
        },
        {
          name: 'Maluku Utara',
          value: 'Maluku Utara',
          disabled: false,
          code: 'ID-MU'
        },
        {
          name: 'Nanggroe Aceh Darussalam (NAD)',
          value: 'Nanggroe Aceh Darussalam (NAD)',
          disabled: false,
          code: 'ID-AC'
        },
        {
          name: 'Nusa Tenggara Barat (NTB)',
          value: 'Nusa Tenggara Barat (NTB)',
          disabled: false,
          code: 'ID-NB'
        },
        {
          name: 'Nusa Tenggara Timur (NTT)',
          value: 'Nusa Tenggara Timur (NTT)',
          disabled: false,
          code: 'ID-NT'
        },
        {
          name: 'Papua',
          value: 'Papua',
          disabled: false,
          code: 'ID-PA'
        },
        {
          name: 'Papua Barat',
          value: 'Papua Barat',
          disabled: false,
          code: 'ID-PB'
        },
        {
          name: 'Riau',
          value: 'Riau',
          disabled: false,
          code: 'ID-RI'
        },
        {
          name: 'Sulawesi Barat',
          value: 'Sulawesi Barat',
          disabled: false,
          code: 'ID-SR'
        },
        {
          name: 'Sulawesi Selatan',
          value: 'Sulawesi Selatan',
          disabled: false,
          code: 'ID-SN'
        },
        {
          name: 'Sulawesi Tengah',
          value: 'Sulawesi Tengah',
          disabled: false,
          code: 'ID-ST'
        },
        {
          name: 'Sulawesi Tenggara',
          value: 'Sulawesi Tenggara',
          disabled: false,
          code: 'ID-SG'
        },
        {
          name: 'Sulawesi Utara',
          value: 'Sulawesi Utara',
          disabled: false,
          code: 'ID-SA'
        },
        {
          name: 'Sumatera Barat',
          value: 'Sumatera Barat',
          disabled: false,
          code: 'ID-SB'
        },
        {
          name: 'Sumatera Selatan',
          value: 'Sumatera Selatan',
          disabled: false,
          code: 'ID-SS'
        },
        {
          name: 'Sumatera Utara',
          value: 'Sumatera Utara',
          disabled: false,
          code: 'ID-SU'
        }
      ]
    }
  },
  hk: {
    countryCodes: [
      {
        name: 'Hong Kong',
        alpha3: 'HKG',
        alpha2: 'HK'
      },
      {
        name: 'MAC',
        alpha3: 'MAC',
        alpha2: 'MO'
      }
    ],
    states: {
      label: 'District',
      list: [
        {
          name: 'Hong Kong Island',
          value: 'Hong Kong Island',
          disabled: true
        },
        {
          name: 'Central and Western',
          value: 'Central and Western',
          disabled: false
        },
        {
          name: 'Eastern',
          value: 'Eastern',
          disabled: false
        },
        {
          name: 'Southern',
          value: 'Southern',
          disabled: false
        },
        {
          name: 'Wan Chai',
          value: 'Wan Chai',
          disabled: false
        },
        {
          name: '──────────',
          value: '──────────',
          disabled: true
        },
        {
          name: 'Kowloon',
          value: 'disabled',
          disabled: true
        },
        {
          name: 'Kowloon City',
          value: 'Kowloon City',
          disabled: false
        },
        {
          name: 'Kwun Tong',
          value: 'Kwun Tong',
          disabled: false
        },
        {
          name: 'Sham Shui Po',
          value: 'Sham Shui Po',
          disabled: false
        },
        {
          name: 'Wong Tai Sin',
          value: 'Wong Tai Sin',
          disabled: false
        },
        {
          name: 'Yau Tsim Mong',
          value: 'Yau Tsim Mong',
          disabled: false
        },
        {
          name: '──────────',
          value: '──────────',
          disabled: true
        },
        {
          name: 'New Territories',
          value: 'New Territories',
          disabled: true
        },
        {
          name: 'Islands',
          value: 'Islands',
          disabled: false
        },
        {
          name: 'Kwai Tsing',
          value: 'Kwai Tsing',
          disabled: false
        },
        {
          name: 'North',
          value: 'North',
          disabled: false
        },
        {
          name: 'Sai Kung',
          value: 'Sai Kung',
          disabled: false
        },
        {
          name: 'Sha Tin',
          value: 'Sha Tin',
          disabled: false
        },
        {
          name: 'Tai Po',
          value: 'Tai Po',
          disabled: false
        },
        {
          name: 'Tsuen Wan',
          value: 'Tsuen Wan',
          disabled: false
        },
        {
          name: 'Tuen Mun',
          value: 'Tuen Mun',
          disabled: false
        },
        {
          name: 'Yuen Long',
          value: 'Yuen Long',
          disabled: false
        }
      ]
    }
  },
  jp: {
    countryCodes: [
      {
        name: 'Japan',
        alpha3: 'JPN',
        alpha2: 'JP'
      }
    ],
    states: {
      label: 'Prefecture',
      list: [
        {
          name: '愛知県',
          value: '愛知県',
          disabled: false
        },
        {
          name: '秋田県',
          value: '秋田県',
          disabled: false
        },
        {
          name: '青森県',
          value: '青森県',
          disabled: false
        },
        {
          name: '千葉県',
          value: '千葉県',
          disabled: false
        },
        {
          name: '愛媛県',
          value: '愛媛県',
          disabled: false
        },
        {
          name: '福井県',
          value: '福井県',
          disabled: false
        },
        {
          name: '福岡県',
          value: '福岡県',
          disabled: false
        },
        {
          name: '福島県',
          value: '福島県',
          disabled: false
        },
        {
          name: '岐阜県',
          value: '岐阜県',
          disabled: false
        },
        {
          name: '群馬県',
          value: '群馬県',
          disabled: false
        },
        {
          name: '広島県',
          value: '広島県',
          disabled: false
        },
        {
          name: '北海道',
          value: '北海道',
          disabled: false
        },
        {
          name: '兵庫県',
          value: '兵庫県',
          disabled: false
        },
        {
          name: '茨城県',
          value: '茨城県',
          disabled: false
        },
        {
          name: '石川県',
          value: '石川県',
          disabled: false
        },
        {
          name: '岩手県',
          value: '岩手県',
          disabled: false
        },
        {
          name: '香川県',
          value: '香川県',
          disabled: false
        },
        {
          name: '鹿児島県',
          value: '鹿児島県',
          disabled: false
        },
        {
          name: '神奈川県',
          value: '神奈川県',
          disabled: false
        },
        {
          name: '高知県',
          value: '高知県',
          disabled: false
        },
        {
          name: '熊本県',
          value: '熊本県',
          disabled: false
        },
        {
          name: '京都府',
          value: '京都府',
          disabled: false
        },
        {
          name: '三重県',
          value: '三重県',
          disabled: false
        },
        {
          name: '宮城県',
          value: '宮城県',
          disabled: false
        },
        {
          name: '宮崎県',
          value: '宮崎県',
          disabled: false
        },
        {
          name: '長野県',
          value: '長野県',
          disabled: false
        },
        {
          name: '長崎県',
          value: '長崎県',
          disabled: false
        },
        {
          name: '奈良県',
          value: '奈良県',
          disabled: false
        },
        {
          name: '新潟県',
          value: '新潟県',
          disabled: false
        },
        {
          name: '大分県',
          value: '大分県',
          disabled: false
        },
        {
          name: '岡山県',
          value: '岡山県',
          disabled: false
        },
        {
          name: '沖縄県',
          value: '沖縄県',
          disabled: false
        },
        {
          name: '大阪府',
          value: '大阪府',
          disabled: false
        },
        {
          name: '佐賀県',
          value: '佐賀県',
          disabled: false
        },
        {
          name: '埼玉県',
          value: '埼玉県',
          disabled: false
        },
        {
          name: '滋賀県',
          value: '滋賀県',
          disabled: false
        },
        {
          name: '島根県',
          value: '島根県',
          disabled: false
        },
        {
          name: '静岡県',
          value: '静岡県',
          disabled: false
        },
        {
          name: '栃木県',
          value: '栃木県',
          disabled: false
        },
        {
          name: '徳島県',
          value: '徳島県',
          disabled: false
        },
        {
          name: '東京都',
          value: '東京都',
          disabled: false
        },
        {
          name: '鳥取県',
          value: '鳥取県',
          disabled: false
        },
        {
          name: '富山県',
          value: '富山県',
          disabled: false
        },
        {
          name: '和歌山県',
          value: '和歌山県',
          disabled: false
        },
        {
          name: '山形県',
          value: '山形県',
          disabled: false
        },
        {
          name: '山口県',
          value: '山口県',
          disabled: false
        },
        {
          name: '山梨県',
          value: '山梨県',
          disabled: false
        }
      ]
    }
  },
  us: usStoreRegionData,
  intl: {
    countryCodes: [
      {
        name: 'Australia',
        alpha3: 'AUS',
        alpha2: 'AU'
      },
      {
        name: 'Brunei',
        alpha3: 'BRN',
        alpha2: 'BN'
      },
      {
        name: 'Cambodia',
        alpha3: 'KHM',
        alpha2: 'KH'
      },
      {
        name: 'Japan',
        alpha3: 'JPN',
        alpha2: 'JP'
      },
      {
        name: 'China',
        alpha3: 'CHN',
        alpha2: 'CN'
      },
      {
        name: 'Guam',
        alpha3: 'GUM',
        alpha2: 'GU'
      },
      {
        name: 'New Zealand',
        alpha3: 'NZL',
        alpha2: 'NZ'
      },
      {
        name: 'Saudi Arabia',
        alpha3: 'SAU',
        alpha2: 'SA'
      },
      {
        name: 'South Korea',
        alpha3: 'KOR',
        alpha2: 'KR'
      },
      {
        name: 'Taiwan, Republic of China',
        alpha3: 'TWN',
        alpha2: 'TW'
      },
      {
        name: 'United Arab Emirates',
        alpha3: 'ARE',
        alpha2: 'AE'
      },
      {
        name: 'Vietnam',
        alpha3: 'VNM',
        alpha2: 'VN'
      }
    ],
    states: {
      AUS: [
        {
          id: 645,
          name: 'Australian Capital Territory',
          value: 'Australian Capital Territory',
          disabled: false
        },
        {
          id: 648,
          name: 'New South Wales',
          value: 'New South Wales',
          disabled: false
        },
        {
          id: 666,
          name: 'Northern Territory',
          value: 'Northern Territory',
          disabled: false
        },
        {
          id: 654,
          name: 'Queensland',
          value: 'Queensland',
          disabled: false
        },
        {
          id: 657,
          name: 'South Australia',
          value: 'South Australia',
          disabled: false
        },
        {
          id: 660,
          name: 'Tasmania',
          value: 'Tasmania',
          disabled: false
        },
        {
          id: 651,
          name: 'Victoria',
          value: 'Victoria',
          disabled: false
        },
        {
          id: 663,
          name: 'Western Australia',
          value: 'Western Australia',
          disabled: false
        }
      ],
      CHN: [
        {
          id: 799,
          name: 'Anhui Sheng',
          value: 'Anhui Sheng',
          disabled: false
        },
        {
          id: 802,
          name: 'Beijing Shi',
          value: 'Beijing Shi',
          disabled: false
        },
        {
          id: 805,
          name: 'Chongqing Shi',
          value: 'Chongqing Shi',
          disabled: false
        },
        {
          id: 808,
          name: 'Fuijan Sheng',
          value: 'Fuijan Sheng',
          disabled: false
        },
        {
          id: 811,
          name: 'Gansu Sheng',
          value: 'Gansu Sheng',
          disabled: false
        },
        {
          id: 814,
          name: 'Guangdong Sheng',
          value: 'Guangdong Sheng',
          disabled: false
        },
        {
          id: 817,
          name: 'Guangxi Zhuangzu Zizhiqu',
          value: 'Guangxi Zhuangzu Zizhiqu',
          disabled: false
        },
        {
          id: 820,
          name: 'Guizhou Sheng',
          value: 'Guizhou Sheng',
          disabled: false
        },
        {
          id: 823,
          name: 'Hainan Sheng',
          value: 'Hainan Sheng',
          disabled: false
        },
        {
          id: 826,
          name: 'Hebei Sheng',
          value: 'Hebei Sheng',
          disabled: false
        },
        {
          id: 829,
          name: 'Heilongjiang Sheng',
          value: 'Heilongjiang Sheng',
          disabled: false
        },
        {
          id: 832,
          name: 'Henan Sheng',
          value: 'Henan Sheng',
          disabled: false
        },
        {
          id: 835,
          name: 'Hong Kong SAR',
          value: 'Hong Kong SAR',
          disabled: false
        },
        {
          id: 838,
          name: 'Hubei Sheng',
          value: 'Hubei Sheng',
          disabled: false
        },
        {
          id: 841,
          name: 'Hunan Sheng',
          value: 'Hunan Sheng',
          disabled: false
        },
        {
          id: 844,
          name: 'Jiangsu Sheng',
          value: 'Jiangsu Sheng',
          disabled: false
        },
        {
          id: 847,
          name: 'Jiangxi Sheng',
          value: 'Jiangxi Sheng',
          disabled: false
        },
        {
          id: 850,
          name: 'Jilin Sheng',
          value: 'Jilin Sheng',
          disabled: false
        },
        {
          id: 853,
          name: 'Liaoning Sheng',
          value: 'Liaoning Sheng',
          disabled: false
        },
        {
          id: 856,
          name: 'Macao SAR',
          value: 'Macao SAR',
          disabled: false
        },
        {
          id: 859,
          name: 'Nei Mongol Zizhiqu',
          value: 'Nei Mongol Zizhiqu',
          disabled: false
        },
        {
          id: 862,
          name: 'Ningxia Huizi Zizhiqu',
          value: 'Ningxia Huizi Zizhiqu',
          disabled: false
        },
        {
          id: 865,
          name: 'Qinghai Sheng',
          value: 'Qinghai Sheng',
          disabled: false
        },
        {
          id: 868,
          name: 'Shaanxi Sheng',
          value: 'Shaanxi Sheng',
          disabled: false
        },
        {
          id: 871,
          name: 'Shandong Sheng',
          value: 'Shandong Sheng',
          disabled: false
        },
        {
          id: 874,
          name: 'Shanghai Sheng',
          value: 'Shanghai Sheng',
          disabled: false
        },
        {
          id: 865,
          name: 'Qinghai Sheng',
          value: 'Qinghai Sheng',
          disabled: false
        },
        {
          id: 880,
          name: 'Sichuan Sheng',
          value: 'Sichuan Sheng',
          disabled: false
        },
        {
          id: 883,
          name: 'Taiwan Sheng',
          value: 'Taiwan Sheng',
          disabled: false
        },
        {
          id: 8886,
          name: 'Tianjin Shi',
          value: 'Tianjin Shi',
          disabled: false
        },
        {
          id: 889,
          name: 'Xinjiang Uygur Zizhiqu',
          value: 'Xinjiang Uygur Zizhiqu',
          disabled: false
        },
        {
          id: 892,
          name: 'Xizang Zizhiqu',
          value: 'Xizang Zizhiqu',
          disabled: false
        },
        {
          id: 895,
          name: 'Yunnan Sheng',
          value: 'Yunnan Sheng',
          disabled: false
        },
        {
          id: 898,
          name: 'Zhejiang Sheng',
          value: 'Zhejiang Sheng',
          disabled: false
        }
      ]
    }
  }
};
