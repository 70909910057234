import { observeElement } from 'src/common/helpers/mutationobserver';

export default {
  mounted () {
    observeElement('#campaign-button').then((elm: any) => {
      const dataLayer = {
        campaignName: elm.getAttribute('data-track-promo-name'),
        campaignId: elm.getAttribute('data-track-promo-id'),
        couponCode: elm.getAttribute('data-track-promo-code')
      };
      this.$gtm?.trackEvent({
        event: 'view_popup',
        campaign: dataLayer
      });
      elm.addEventListener('click', () => {
        this.$gtm?.trackEvent({
          event: 'click_cta_popup',
          campaign: dataLayer
        });
      });
    });
  }
};
