export const SN_USER = 'user'

export const UPDATE_STORE_CREDITS = SN_USER + '/UPDATE_STORE_CREDITS';
export const CUSTOMER_ATTRIBUTE = SN_USER + '/CUSTOMER_ATTRIBUTE';
export const LOADING_ORDERS_HISTORY = SN_USER + '/LOADING_ORDERS_HISTORY';
export const TOGGLE_STORE_CREDIT = SN_USER + '/TOGGLE_STORE_CREDIT';
export const TOGGLE_AUTH_MODAL = SN_USER + '/TOGGLE_AUTH_MODAL';
export const CLOSE_AUTH_MODAL = SN_USER + '/CLOSE_AUTH_MODAL';
export const TOGGLE_PASSWORD_CHANGE_SUCCESS = SN_USER + '/TOGGLE_PASSWORD_CHANGE_SUCCESS';
export const RESET_USER_TOKEN = SN_USER + '/RESET_USER_TOKEN';
export const RESET_USER_ORDERS_HISTORY = SN_USER + '/RESET_USER_ORDERS_HISTORY';
export const SET_CURRENT_ORDER_DETAILS = SN_USER + '/SET_CURRENT_ORDER_DETAILS';
export const USER_CURRENT_LOCATION = SN_USER + '/USER_CURRENT_LOCATION';
export const USER_CURRENT_LOCATION_LOADING = SN_USER + '/USER_CURRENT_LOCATION_LOADING';
