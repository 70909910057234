import { MutationTree } from 'vuex'
import { StoryblokState } from '../types/State'
import * as next from './types'

const mutations: MutationTree<StoryblokState> = {
  setStoreCode (state: StoryblokState, storeCode) {
    state.storeCode = storeCode
  },
  loadingStory (state: StoryblokState, { key }) {
    state.currentStoryKey = key;
    state.stories = {
      ...state.stories,
      [key]: {
        ...state.stories[key],
        loading: true
      }
    }
  },
  setStory (state: StoryblokState, { key, story }) {
    state.stories = {
      ...state.stories,
      [key]: {
        ...state.stories[key],
        loading: false,
        story
      }
    }
  },
  [next.IN_PROGRESS] (state: StoryblokState, { blockKey, isProcessing }) {
    state.isProcessing = { ...state.isProcessing, [blockKey]: isProcessing };
  },
  setPreviewToken (state: StoryblokState, { previewToken }) {
    state.previewToken = previewToken
  },
  updateStory (state: StoryblokState, { key, story }) {
    state.stories = {
      ...state.stories,
      [key]: {
        ...state.stories[key],
        story
      }
    }
  },
  currentStory (state: StoryblokState, { key }) {
    state.currentStoryKey = key
  },
  supportsWebp (state: StoryblokState, supportsWebp) {
    state.supportsWebp = supportsWebp
  },
  categoryBannerMapping (state, { bannerMapping }) {
    state.categoryBannerMap = bannerMapping
  }
}

export default mutations;
