<template>
  <svg
    viewBox="0 0 259 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg replaced-svg logo"
    :class="open ? 'dark' : 'light'"
  >
    <g clip-path="url(#clip0_318_14283)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 27.8698V0.391602H4.66193V23.1593H16.7829V27.8698H0Z"
        fill="#C10016"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.2441 14.6418C41.2441 8.04753 36.3958 4.71054 32.2934 4.71054C28.5266 4.71054 24.6105 7.4975 24.6105 13.6214C24.6105 20.0982 29.0114 23.5527 33.3003 23.5527C37.1792 23.5527 41.2441 20.6874 41.2441 14.6418ZM19.9482 15.1912C19.9482 11.109 21.7013 7.73284 24.6105 4.55354L27.855 0.981546C28.7876 0.471021 30.5778 0 32.8529 0C39.6406 0 45.9064 5.26023 45.9064 13.1504C45.9064 17.0756 44.3768 20.3734 41.1695 23.8276L37.9247 27.3604C36.6197 27.9887 34.6056 28.2633 32.7408 28.2633C26.2515 28.2633 19.9482 22.9642 19.9482 15.1912Z"
        fill="#C10016"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M57.6398 27.8698L48.9126 0.391602H54.0222L61.0339 23.3946L68.008 0.391602H73.0427L64.3158 27.8698H57.6398Z"
        fill="#C10016"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M123.33 23.2376C125.643 23.2376 126.911 21.5102 126.911 19.6265C126.911 17.742 125.643 16.2111 122.957 16.2111H116.916V23.2376H123.33ZM116.916 5.02381V11.6971H122.622C124.934 11.6971 126.165 10.0484 126.165 8.36046C126.165 6.47604 124.934 5.02381 122.622 5.02381H116.916ZM127.731 12.914C129.969 13.8165 131.647 15.9758 131.647 18.3309C131.647 20.7252 130.678 22.1386 128.403 24.5727L125.27 27.8698H112.254V0.391602H123.032C128.514 0.391602 130.901 3.84575 130.901 6.75088C130.901 9.06682 129.745 10.7156 128.589 11.9716L127.731 12.914Z"
        fill="#C10016"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M158.381 14.6418C158.381 8.04753 153.533 4.71054 149.43 4.71054C145.663 4.71054 141.747 7.4975 141.747 13.6214C141.747 20.0982 146.148 23.5527 150.437 23.5527C154.316 23.5527 158.381 20.6874 158.381 14.6418ZM137.085 15.1912C137.085 11.109 138.838 7.73284 141.747 4.55354L144.992 0.981546C145.924 0.471021 147.715 0 149.99 0C156.777 0 163.043 5.26023 163.043 13.1504C163.043 17.0756 161.514 20.3734 158.306 23.8276L155.062 27.3604C153.756 27.9887 151.742 28.2633 149.877 28.2633C143.388 28.2633 137.085 22.9642 137.085 15.1912Z"
        fill="#C10016"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M186.464 0.391602H191.127V27.8698H184.749L173.597 5.57316L173.896 27.8698H169.234V0.391602H175.985L186.726 21.8637L186.464 0.391602Z"
        fill="#C10016"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M199.553 27.8698H204.215V0.391602H199.553V27.8698Z"
        fill="#C10016"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M217.405 27.8698V5.10214H209.274V0.391602H230.16V5.10214H222.067V27.8698H217.405Z"
        fill="#C10016"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M253.76 14.6418C253.76 8.04753 248.912 4.71054 244.809 4.71054C241.042 4.71054 237.126 7.4975 237.126 13.6214C237.126 20.0982 241.527 23.5527 245.816 23.5527C249.695 23.5527 253.76 20.6874 253.76 14.6418ZM232.464 15.1912C232.464 11.109 234.217 7.73284 237.126 4.55354L240.371 0.981546C241.304 0.471021 243.094 0 245.368 0C252.156 0 258.422 5.26023 258.422 13.1504C258.422 17.0756 256.893 20.3734 253.685 23.8276L250.441 27.3604C249.135 27.9887 247.122 28.2633 245.257 28.2633C238.767 28.2633 232.464 22.9642 232.464 15.1912Z"
        fill="#C10016"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M98.0934 22.767H80.6609V15.8979H91.8483V11.3838H80.6609V4.71055H93.1536V0H76V27.4782L87.1435 27.4786C89.197 27.4786 90.8056 26.602 91.2996 26.0956L89.3865 31.6073H92.5313L98.0934 22.767Z"
        fill="#C10016"
      />
    </g>
    <defs>
      <clipPath id="clip0_318_14283">
        <rect width="258.422" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    open: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

path {
  fill-rule: evenodd;
  clip-rule: evenodd;
}
.light {
  fill: var(--lace);
}
.dark {
  fill: var(--neutral-900);
}
.logo {
  enable-background: new 0 0 99 50;
  width: 211px;
  height: 56px;
  margin: 0;
  @include for-mobile {
    height: 30px;
    width: 162px;
  }
}
</style>
