function getProps (props) {
  const {
    components: tabComponents,
    theme
  } = props;

  const components = (tabComponents || []).map(({ title, content, tablink }) => ({
    title,
    content,
    tablink: tablink || 'default'
  }))

  return {
    components,
    fullWidth: props?.full_width,
    theme
  }
}

const Tabs = () => ({
  type: 'tabs',
  props: getProps
});

export default Tabs
