function getLeadgenSectionProps (props) {
  const {
    title,
    subtitle,
    title_custom_field_1,
    title_custom_field_2,
    cta,
    checkbox,
    text,
    form_id: formId,
    show_country,
    show_lastname,
    show_custom_field_1,
    show_custom_field_2,
    is_required_custom_field_1,
    is_required_custom_field_2,
    success_page_url,
    cta_button
  } = props;

  return {
    formId,
    title: title,
    subtitle: subtitle,
    cta: cta,
    titleCustomField1: title_custom_field_1,
    titleCustomField2: title_custom_field_2,
    terms: checkbox || {},
    headingText: text || {},
    showCountry: show_country,
    showLastName: show_lastname,
    showCustomField1: show_custom_field_1,
    showCustomField2: show_custom_field_2,
    isRequiredCustomField1: is_required_custom_field_1,
    isRequiredCustomField2: is_required_custom_field_2,
    successPageUrl: success_page_url,
    ctaButton: cta_button,
    editable: props
  }
}

const LeadGen = () => ({
  type: 'leadgen',
  props: getLeadgenSectionProps
});

export default LeadGen
