import Card from './Card'
import withComponent from './withComponent';

function getProps (props) {
  const {
    columns: propColumns,
    columns_length: columnsLength,
    title,
    grid_bg_color: gridBgColor
  } = props;

  const columns = propColumns.map((current) => withComponent(Card)(current))

  return {
    columns,
    columnsLength,
    title,
    gridBgColor
  }
}

const GridSlider = () => ({
  type: 'grid_slider',
  props: getProps
});

export default GridSlider
