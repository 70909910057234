export const SN_CHECKOUT = 'checkout';
export const CHECKOUT_SELECT_PAYMENT_METHOD = SN_CHECKOUT + '/SELECT_PAYMENT_METHOD';
export const CHECKOUT_SELECT_SHIPPING_METHOD = SN_CHECKOUT + '/SELECT_SHIPPING_METHOD';
export const CHECKOUT_SELECT_SHIPPING_ADDRESS = SN_CHECKOUT + '/SELECT_SHIPPING_ADDRESS';
export const CHECKOUT_SELECT_BILLING_ADDRESS = SN_CHECKOUT + '/SELECT_BILLING_ADDRESS';
export const SET_EXTENSION_ATTRIBUTES = SN_CHECKOUT + '/SET_EXTENSION_ATTRIBUTES';
export const SET_POP_STATION = SN_CHECKOUT + '/SET_POP_STATION';
export const SET_COLLECTION_STORE = SN_CHECKOUT + '/SET_COLLECTION_STORE';
export const SET_POS_CLIENT = SN_CHECKOUT + '/SET_POS_CLIENT';
export const SET_GUEST_USER = SN_CHECKOUT + '/SET_GUEST_USER';
export const RESET_SHIPPING_METHOD = SN_CHECKOUT + '/RESET_SHIPPING_METHOD';
