import { loyaltyPointValues } from 'common/constants';
import { GCPCTab } from 'src/modules/loyalty/types';

const state = {
  loadingCustomerLoyaltyStatus: false,
  customerLoyaltyStatus: null,
  claimedRewards: null,
  loadingClaimedRewards: false,
  availableRewards: null,
  loadingAvailableRewards: false,
  pointsFilterApplied: false,
  loadingPartnerPerks: false,
  availableWallets: [],
  loadingAvailableWallets: false,
  claimingReward: false,
  loadingPointsHistory: false,
  loyaltyPointValues,
  pointsHistory: [],
  referralData: {
    referralCode: '',
    name: ''
  },
  isStatusActive: false,
  isJoining: false,
  availableGCRewards: null,
  loadingAvailableGCRewards: false,
  checkoutCouponRewards: null,
  checkoutCouponRewardsTotal: 0,
  loadingCheckoutCouponRewards: false,
  isAllGCLoaded: false,
  openGCPCModal: false,
  gcpcActiveTab: GCPCTab.PromoCode
};
export default state
