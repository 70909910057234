import { render, staticRenderFns } from "./o-product-card.vue?vue&type=template&id=62a6b920&scoped=true&"
import script from "./o-product-card.vue?vue&type=script&lang=js&"
export * from "./o-product-card.vue?vue&type=script&lang=js&"
import style0 from "./o-product-card.vue?vue&type=style&index=0&id=62a6b920&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62a6b920",
  null
  
)

export default component.exports