<template>
  <div v-if="trendingList.length > 0" class="trending-searches">
    <div class="static-heading label-1">
      <p>{{ $t("TRENDING SEARCHES") }}</p>
    </div>
    <div class="text-list paragraph-2">
      <div v-for="(item, idx) in trendingList" :key="idx" class="text">
        <p @click="searchTextClicked(item)">
          {{ item.label }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { Logger } from '@vue-storefront/core/lib/logger';
export default {
  name: 'MSearchTrending',
  props: {},
  data () {
    return {
      trendingList: []
    };
  },
  computed: {
    ...mapGetters(['getCurrentStoreView'])
  },
  async created () {
    try {
      if (this.trendingList?.length <= 0) {
        const storeCode = this.getCurrentStoreView?.storeCode
        let trendingData = await this.fetchDataSource('trending-search');
        const storeTrendingList = trendingData.find(item => item?.name === storeCode)?.value.split('|')
        this.trendingList = storeTrendingList.map(item => {
          const [label, link] = item.split(':');
          return {
            label: label.trim(),
            link: link ? link.trim() : null
          };
        });
      }
    } catch (err) {
      Logger.error(err, 'Error fetching and parsing trending searches')();
    }
  },
  methods: {
    ...mapActions('storyblok', ['fetchDataSource']),
    searchTextClicked (text) {
      this.$emit('searchTextClicked', text);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.trending-searches {
  margin-top: 40px;
  .static-heading {
    margin-bottom: 12px;
  }
  .text-list {
    display: inline-flex;
    flex-wrap: wrap;
    .text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      p {
        margin: 0;
        cursor: pointer;
      }
      background: var(--danger-50);
      padding: 5px 8px;
      box-sizing: border-box;
      margin-right: 10px;
    }
  }
}
</style>
