import * as types from './mutation-types';
import { getDefaultState } from './state';

const mutations = {
  [types.RESET_CART_MODULE]: (state) => {
    Object.assign(state, getDefaultState());
    state.loadingCart = false; // When user logs out cart keeps loading
  },
  [types.SET_CART_ID] (state, cartId) {
    state.cartId = cartId
  },
  [types.SET_IS_ADDING_TO_CART] (state, isAddingToCart) {
    state.isAddingToCart = isAddingToCart
  },
  [types.SET_CART_ITEMS] (state, cartItems) {
    state.cartItems = cartItems
  },
  [types.SET_CART_TOTALS] (state, cartTotals) {
    state.cartTotals = cartTotals
  },
  [types.SET_PAYMENT_METHODS] (state, paymentMethods) {
    state.paymentMethods = paymentMethods
  },
  [types.SET_SHIPPING_METHODS] (state, shippingMethods) {
    state.shippingMethods = shippingMethods
  },
  [types.SET_IS_LOADING_CART] (state, loadingCart) {
    state.loadingCart = loadingCart
  },
  [types.SET_IS_LOADED_CART] (state, cartIsLoaded) {
    state.cartIsLoaded = cartIsLoaded;
  },
  [types.SET_ITEMS_TO_REMOVE] (state, itemToRemove) {
    if (!Array.isArray(itemToRemove)) {
      state.itemsToRemove.push(itemToRemove)
    } else {
      state.itemsToRemove = itemToRemove
    }
  },
  [types.RESET_ITEMS_TO_REMOVE] (state) {
    state.itemsToRemove = []
  }
}

export default mutations;
