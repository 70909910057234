import { MutationPayload, Store } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState';
import { isServer } from 'common/utils';
import { BroadcastChannelStrategy } from './broadcastChannelStrategy';

type predicateType = string[] | ((mutation: MutationPayload, state: RootState) => boolean);

export const sharedMutations = (store: Store<RootState>, { storeKey, predicate, sharingKey }: { storeKey?: string, predicate: predicateType, sharingKey: string }) => {
  if (isServer) return;
  try {
    if (!Array.isArray(predicate) && typeof predicate !== 'function') {
      throw new Error(
        'Either array of accepted mutations or predicate function must be supplied'
      );
    }

    const shouldShare: predicateType =
    typeof predicate === 'function'
      ? predicate
      : ({ type }: { type: string }, _state) => {
        let mutationNames = predicate;
        if (storeKey) {
          mutationNames = mutationNames.map(mutationName => `${storeKey}/${mutationName}`)
        }
        return mutationNames.includes(type);
      };

    let sharingInProgress = false;
    const selectedStrategy = new BroadcastChannelStrategy({ sharingKey });

    store.subscribe((mutation, state) => {
      if (sharingInProgress) {
        return;
      }

      if (!shouldShare(mutation, state)) {
        return;
      }
      selectedStrategy.share(mutation);
    });

    selectedStrategy.addEventListener(mutation => {
      try {
        sharingInProgress = true;
        store.commit(mutation.type, mutation.payload);
      } finally {
        sharingInProgress = false;
      }
      return 'done';
    });
  } catch (e) {
    console.log(e)
  }
};
