<template>
  <div v-if="categories.length > 0" class="categories-container">
    <p class="section-header">
      {{ $t('Categories') }}
    </p>
    <ul class="categories-list">
      <li
        v-for="category in categories"
        :key="category.category_id"
        class="category-item"
        :class="!value.includes(category.category_id) && 'non-selected-category'"
        @click="redirectCategory(category)"
      >
        {{ $t(category.name) }}
      </li>
    </ul>
  </div>
</template>

<script>
import { trackGtmEvents } from 'common/helpers/gtm'
import { mapGetters } from 'vuex'
let storeCode;
export default {
  name: 'MSearchCategories',
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    searchText: {
      type: String,
      default: ''
    }
  },
  mounted () {
    storeCode = this.getCurrentStoreView().storeCode;
  },
  methods: {
    ...mapGetters(['getCurrentStoreView']),
    redirectCategory ({ category_id, name }) {
      this.$store.dispatch('category/list', { key: 'id', value: category_id })
        .then(({ items = [{ url_path: '' }] }) => {
          const redirectPath = `/${storeCode}/${items[0].url_path}`;
          this.$gtm?.trackEvent({ event: 'search_category_click', search_term: this.searchText, value: name });
          const { commit } = this.$store;
          this.$router.push(redirectPath);
          commit('ui/setSidebar', false);
          commit('ui/setMicrocart', false);
          commit('ui/setSearchpanel', false);
        });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.categories-container {
  margin-right: 12%;
  .section-header {
    color: var(--neutral-900);
    display: inline-block;
    padding: 0;
    font-size: var(--font_size_75);
    margin: 0 10px 5px 0;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.4px;
    display: flex;
  }
  .categories-list {
    list-style: none;
    padding: 0;
    .non-selected-category {
      opacity: 0.7;
    }
    .category-item {
      cursor: pointer;
      color: var(--c-text);
      padding: 7px 10px 7px 0px;
      letter-spacing: 0;
      display: block;
      line-height: 14px;
      text-decoration: none;
      font-size: var(--font_size_75);
    }
  }
}

@media only screen and (max-width: $tablet-min) {
  .categories-container {
    margin-bottom: 20px;
    .section-header {
      margin-top: 10px !important;
    }
  }
}
</style>
