import Vue from 'vue'
import VueGtm from 'vue-gtm'

import { once, isServer } from '@vue-storefront/core/helpers'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { Logger } from '@vue-storefront/core/lib/logger'

import { googleTagManagerModule } from './store'
import { afterRegistration, isEnabled } from './hooks/afterRegistration'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
export const KEY = 'google-tag-manager'

export const GoogleTagManagerModule: StorefrontModule = function ({ store, router, appConfig }) {
  const currentStore = Object.assign(currentStoreView());
  if (!isServer && isEnabled(currentStore?.googleTagManager?.id)) {
    const $window = (window as any);

    $window.dataLayer = $window?.dataLayer || [];
    $window?.dataLayer?.push({
      originalLocation: document?.location?.protocol + '//' +
                        document?.location?.hostname +
                        document?.location?.pathname +
                        document?.location?.search
    });
    $window.pageViewer = () => document?.location?.pathname + document?.location?.search;
    once('__VUE_EXTEND_GTM__', () => {
      Vue.use(VueGtm, {
        enabled: true,
        id: currentStore.googleTagManager.id,
        debug: currentStore.googleTagManager.debug,
        vueRouter: router
      })
    })
  } else {
    Logger.warn('Google Tag Manager extensions is not working. Ensure Google Tag Manager container ID is defined in config', 'GTM')()
  }

  store.registerModule(KEY, googleTagManagerModule)

  afterRegistration(appConfig, store, router)
}
