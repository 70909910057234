import { urlStore } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { beforeEachGuard } from './router/beforeEach'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { isServer } from '@vue-storefront/core/helpers';

export const cacheStorage = StorageManager.init('url')

export const UrlModule: StorefrontModule = function ({ store, router, cacheTags }) {
  store.registerModule('url', urlStore)
  router.beforeEach(async function (to, from, next) {
    if (!isServer) {
      // remove any scroll locks set by popup on navigation
      document.body.style.overflow = '';
    }
    await beforeEachGuard(to, from, next, cacheTags)
  })
}
