import omit from 'lodash-es/omit'
import config, { products, entities } from 'config'
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { extendStore, buildFilterProductsQuery } from '@vue-storefront/core/helpers';
import { preConfigureProduct } from '@vue-storefront/core/modules/catalog/helpers/search'
import * as types from '@vue-storefront/core/modules/catalog-next/store/category/mutation-types'
import { composeSelectedSort, updatePriceAggregation } from './helpers';
import * as nextTypes from './types';
import FilterVariant from '@vue-storefront/core/modules/catalog-next/types/FilterVariant';
import { router } from '@vue-storefront/core/app';
import { changeFilterQuery } from '@vue-storefront/core/modules/catalog-next/helpers/filterHelpers';
import { STOCK } from 'src/common/constants'
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
const { configureProductAsync } = require('@vue-storefront/core/modules/catalog/helpers')

const KEY = 'vs-category-next'

const categoryNextStore = {
  namespaced: true
}

const categoryNextModule = {
  state: {
    currentList: ''
  },
  getters: {
    getCurrentList: (state) => state.currentList,
    getCurrentFilters: (state, getters) => getters.getCurrentSearchQuery
  },
  actions: {
    async switchSearchFilters ({ dispatch }, filterVariants: FilterVariant[] = []) {
      let currentQuery = router.currentRoute[products.routerFiltersSource]
      filterVariants.forEach(filterVariant => {
        if (filterVariant?.type === 'stock.is_in_stock' && filterVariant?.label === '1') filterVariant.id = 'true'
        currentQuery = changeFilterQuery({ currentQuery, filterVariant })
      })
      delete currentQuery?.page;
      await dispatch('changeRouterFilterParameters', currentQuery)
    },
    async configureProducts ({ rootState }, { products = [], filters = {}, populateRequestCacheTags = config.server.useOutputCacheTagging } = {}) {
      return products.map(product => {
        product = Object.assign({}, preConfigureProduct({ product, populateRequestCacheTags }))
        const configuredProductVariant = configureProductAsync({ rootState, state: { current_configuration: {} } }, { product, configuration: filters, selectDefaultVariant: false, fallbackToDefaultWhenNoAvailable: true, setProductErorrs: false })
        return Object.assign(product, { selectedVariant: omit(configuredProductVariant, ['visibility']) })
      })
    },
    async loadCategoryProducts ({ commit, getters, dispatch, rootState }, { route, category, pageSize = 50, start: startPosition = 0 }) {
      const searchCategory = category || getters.getCategoryFrom(route.path) || {}
      let categoryMappedFilters = getters.getFiltersMap[searchCategory.id]
      if (categoryMappedFilters && categoryMappedFilters[STOCK]) {
        categoryMappedFilters[STOCK]?.forEach(e => {
          e.label === '1' ? e.id = 'true' : e.id = 'false'
        })
      }
      const areFiltersInQuery = !!Object.keys(route[products.routerFiltersSource]).length
      if (!categoryMappedFilters && areFiltersInQuery) { // loading all filters only when some filters are currently chosen and category has no available filters yet
        await dispatch('loadCategoryFilters', searchCategory)
      }
      const searchQuery = getters.getCurrentFiltersFrom(route[products.routerFiltersSource], categoryMappedFilters)
      let filterQr = buildFilterProductsQuery(searchCategory, searchQuery.filters)
      try {
        let { default_sort_by, url_key } = category;
        if (url_key !== 'new-in') {
          default_sort_by = 'recommended';
        }
        const { items, perPage, start, total, aggregations, attributeMetadata } = await dispatch('product/findProducts', {
          query: filterQr,
          sort: composeSelectedSort({ searchQuery, route, categoryDefaultSort: default_sort_by }),
          includeFields: entities.productList.includeFields,
          excludeFields: entities.productList.excludeFields,
          size: pageSize,
          start: startPosition,
          configuration: searchQuery.filters,
          options: {
            populateRequestCacheTags: true,
            prefetchGroupProducts: false,
            setProductErrors: false,
            fallbackToDefaultWhenNoAvailable: true,
            assignProductConfiguration: false,
            separateSelectedVariant: true
          }
        }, { root: true })
        await dispatch('loadAvailableFiltersFrom', {
          aggregations: updatePriceAggregation(aggregations),
          attributeMetadata,
          category: searchCategory,
          filters: searchQuery.filters
        })
        commit(types.CATEGORY_SET_SEARCH_PRODUCTS_STATS, { perPage, start, total })
        commit(types.CATEGORY_SET_PRODUCTS, items)
        return items
      } catch (e) {
        return []
      }
    },
    async loadSearchProducts ({ commit, getters, dispatch, rootState }, { route, pageSize = 50, start: startPosition = 0 }) {
      // TODO: move code here from searchresult.vue
    }
  },
  mutations: {
    [nextTypes.ADD_CURRENT_LIST] (state, payload: string) {
      state.currentList = payload;
    }
  }
}

export const CategoryNextModule: StorefrontModule = function ({ app, store, appConfig, router, moduleConfig }) {
  store.registerModule(KEY, categoryNextStore)

  extendStore('category-next', categoryNextModule)
}
