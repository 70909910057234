import config from 'config';
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import Task from '@vue-storefront/core/lib/sync/types/Task'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl'

export const midtransMakePayment = async (data: any): Promise<Task> => {
  const url = processLocalizedURLAddress(
    getApiEndpointUrl(config?.midtrans?.endpoint, 'charge')
  );

  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify(data)
    },
    silent: true
  });
};

export const midtransFetchPaymentStatus = async (orderId: string): Promise<Task> => {
  if (!orderId) throw new Error('Order ID not found');
  const url =
    getApiEndpointUrl(config?.midtrans?.endpoint, 'paymentStatus') + `?orderId=${orderId}`;
  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors'
    },
    silent: true
  });
};
