import { Logger } from '@vue-storefront/core/lib/logger';
import { extendStore } from '@vue-storefront/core/helpers';
import { CartService } from '@vue-storefront/core/data-resolver';
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import * as types from '@vue-storefront/core/modules/cart/store/mutation-types';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import config from 'config'
import { COUNTRIES } from 'common/helpers/countries';
import { storesShortCode } from 'common/constants';
import sumBy from 'lodash-es/sumBy'

const KEY = 'cart-next';

const cartNextStore = {
  namespaced: true
};

const cartNextModule = {
  state: {
    haveOosItems: false,
    isWishlist: false,
    updatedItems: []
  },
  getters: {
    getIsWishlist: state => state.isWishlist,
    getCartToken: (state, getters, rootState, rootGetters) => rootGetters['cart-v1/getCartId'],
    getIsAdding: (state, getters, rootState, rootGetters) => rootGetters['cart-v1/getIsAddingToCart'],
    getCartItems: (state, getters, rootState, rootGetters) => {
      const products = rootGetters['cart-v1/getCartItems']
      return products?.map(cartItem => {
        cartItem.is_in_stock = true;
        return cartItem
      })
    },
    getItemsTotalQuantity: (state, getters, rootState, rootGetters) => config.cart.minicartCountType === 'items' ? getters.getCartItems.length : sumBy(getters.getCartItems, p => p.qty),
    anyCartItemIsOos: (state, getters, rootState, rootGetters) => rootGetters['cart-v1/anyCartItemIsOos'],
    isCartEmpty: (state, getters) => !getters.getCartItems?.length,
    getPlatformTotals: (state, getters, rootState, rootGetters) => rootGetters['cart-v1/getCartTotals'],
    getTotals: (state, getters, rootState, rootGetters) => rootGetters['cart-v1/getCartTotalsSegments'],
    getAppliedGiftcards: (state, getters): {coupon_code: string, cost: number}[] => {
      const giftCards = getters.getTotals?.find(total => total.code === 'giftcardaccount')?.extension_attributes?.gift_cards || [];
      if (giftCards?.length) {
        const list = JSON.parse(giftCards);
        return list.map(gc => ({
          coupon_code: gc.c,
          cost: gc.a
        }))
      }
      return [];
    },
    getCartSubtotal: (state, getters, rootState, rootGetters): number => {
      let isCartEmpty = getters.isCartEmpty
      let total = getters.getTotals?.find?.(total => total.code === 'subtotal');
      if (rootGetters.getCurrentStoreView.tax.usePriceExclusiveTax) {
        total.value = getters.getPlatformTotals?.subtotal || total?.value;
      }
      return (total && !isCartEmpty) ? total : { value: 0 }
    },
    getGrandTotal: (state, getters): number => {
      let isCartEmpty = getters.isCartEmpty
      let total = getters.getTotals.find(total => total.code === 'grand_total')
      return (total && !isCartEmpty) ? total : { value: 0 }
    },
    getCoupon: (state, getters, rootState, rootGetters) => rootGetters['cart-v1/getAppliedCouponFromTotals'],
    unShippableProduct (state, getters, rootState, rootGetters) {
      const countryId = rootState?.checkout?.shippingDetails?.country_id ||
        (rootGetters.getCurrentStoreView?.storeCode === storesShortCode.intl ? rootGetters['address/selectedCountry'] : rootGetters.getCurrentStoreView?.storeCode?.toUpperCase());
      const country = COUNTRIES.find(({ alpha2, alpha3 }) => countryId === alpha2 || countryId === alpha3);
      const cartItem = rootGetters['cart/getCartItems'].find((item) => config.unshippableProducts[country.alpha2]?.includes(item.sku));
      const countryName = country.name.indexOf('(the)') !== -1 ? `The ${country.name.replace('(the)', '').trim()}` : country.name.trim();
      return cartItem ? { countryName, productName: cartItem.name } : null;
    }
  },
  mutations: {
    setIsWishlist (state, isWishlist) {
      state.isWishlist = isWishlist
    },
    resetUpdatedItems (state, item) {
      state.updatedItems = []
    },
    setUpdatedItems (state, item) {
      state.updatedItems.push(item)
    }
  },
  actions: {
    resetCartModule ({ state, dispatch, rootGetters }) {
      if (rootGetters['featurehub/isCartModuleActive']) {
        dispatch('cart-v1/resetCartModule', null, { root: true })
      }
      state.isMicrocartOpen = false;
      state.itemsAfterPlatformTotals = {};
      state.platformTotals = null;
      state.platformTotalSegments = null;
      state.cartIsLoaded = false;
      state.cartServerToken = '';
      state.shipping = [];
      state.payment = [];
      state.cartItemsHash = '';
      state.cartServerLastSyncDate = 0;
      state.cartServerLastTotalsSyncDate = 0;
      state.cartItems = [];
      state.connectBypassCount = 0;
      state.isAddingToCart = false;
      state.isWishlist = false;
      state.updatedItems = [];
    },
    async syncTotals ({ dispatch, getters, rootGetters }, payload: { forceServerSync: boolean, methodsData?: any } = { forceServerSync: false, methodsData: null }) {
      try {
        EventBus.$emit('notification-progress-start', 'Please wait ...')
        await dispatch('cart-v1/fetchShippingInfo', null, { root: true })
      } catch (e) {
        Logger.debug('Problem fetching shipping info', 'new-cart', e)()
      } finally {
        EventBus.$emit('notification-progress-stop', '')
      }
    },

    async syncShippingMethods ({ getters, rootGetters, dispatch }, { forceServerSync = false }) {
      await dispatch('cart-v1/fetchShippingMethods', null, { root: true })
    },
    async removeCoupon ({ getters, dispatch, commit, rootGetters }, { sync = true } = {}) {
      try {
        return dispatch('cart-v1/removeCoupon', null, {
          root: true
        });
      } catch (error) {
        Logger.error(`Remove coupon debug error - ${error}`, 'cart')()
      } finally {
        EventBus.$emit('hide-calculating-text-in-totals')
      }
    },

    async applyCoupon ({ getters, dispatch, commit, rootGetters }, couponCode) {
      try {
        return dispatch('cart-v1/applyCoupon', couponCode, {
          root: true
        });
      } catch (error) {
        Logger.error(`Apply coupon debug error - ${error}`, 'cart')()
        throw error
      } finally {
        EventBus.$emit('hide-calculating-text-in-totals')
      }
    },
    async applyGiftCard ({ getters, dispatch, commit, rootGetters }, giftCards) {
      try {
        if (!giftCards?.length) { return }
        return dispatch('cart-v1/applyGiftcard', giftCards, {
          root: true
        });
      } catch (error) {
        Logger.error(`Apply giftcard debug error - ${error}`, 'cart')()
        throw error
      }
    },
    async removeGiftCard ({ getters, dispatch, commit, rootGetters }, giftCard) {
      return dispatch('cart-v1/removeGiftcard', giftCard, {
        root: true
      });
    },
    async checkGiftCardAmount ({ getters, rootGetters, dispatch }, giftCard) {
      try {
        return dispatch('cart-v1/checkGiftcardamount', giftCard, {
          root: true
        });
      } catch (error) {
        Logger.error(`Check giftcard amount debug error - ${error}`, 'cart')()
        throw error
      }
    },
    async redeemGcAsSc ({ getters, dispatch, commit, rootGetters }, giftCard) {
      try {
        return dispatch('cart-v1/redeemGiftcardAsStoreCredit', giftCard, {
          root: true
        });
      } catch (error) {
        Logger.error(`Redeem as giftcard debug error - ${error}`, 'cart')()
        throw error
      }
    },
    async applyStoreCredits ({ getters, dispatch, commit, rootGetters }) {
      return dispatch('cart-v1/applyStoreCredits', null, {
        root: true
      });
    },
    async removeStoreCredits ({ getters, dispatch, commit, rootGetters }) {
      return dispatch('cart-v1/removeStoreCredits', null, {
        root: true
      });
    },

    async removeItem ({ commit, dispatch, getters, rootGetters }, payload) {
      const product = payload.product || payload
      return dispatch('cart-v1/deleteItems', [product], {
        root: true
      })
    },
    async updateQuantity ({ commit, dispatch, getters, rootGetters }, { product, qty, forceServerSilence = false }) {
      try {
        EventBus.$emit('show-calculating-text-in-totals')
        return dispatch('cart-v1/updateItem', {
          ...product,
          qty: qty
        }, {
          root: true
        })
      } catch (err) {
        console.error(err)
      } finally {
        EventBus.$emit('hide-calculating-text-in-totals')
      }
    },
    async deleteItems ({ dispatch, getters, commit, rootGetters }, cartItems) {
      if (!cartItems?.length) { return }
      if (rootGetters['featurehub/isCartModuleActive']) {
        dispatch('cart-v1/setItemsToRemove', cartItems, {
          root: true
        })
        return dispatch('cart-v1/deleteItems', cartItems, {
          root: true
        })
      }
    },

    async fetchCartToken ({ getters, dispatch, commit }, { guestCart = false, forceClientState = false }) {
      const { result, resultCode } = await CartService.getCartToken(guestCart, forceClientState)

      if (resultCode === 200) {
        commit(types.CART_LOAD_CART_SERVER_TOKEN, result)
      }
    }

  }
};

export const CartNextModule: StorefrontModule = function ({
  app,
  store,
  appConfig,
  router,
  moduleConfig
}) {
  store.registerModule(KEY, cartNextStore);

  extendStore('cart', cartNextModule);
};
