const getCuratorIOProps = props => {
  return {
    height: props.height,
    editable: props
  };
};
const CuratorIO = () => ({
  type: 'curator_io',
  props: getCuratorIOProps
});

export default CuratorIO;
