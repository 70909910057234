import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';

export const MidtransStore: Module<any, any> = {
  namespaced: true,
  actions,
  getters,
  mutations: {},
  state: {}
}
