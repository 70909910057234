import config from 'config';
import { TaskQueue } from '@vue-storefront/core/lib/sync';
import Task from '@vue-storefront/core/lib/sync/types/Task';
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';

export const fetchCmsContent = async (slug: string): Promise<Task> => {
  if (!slug) throw new Error('Slug is missing');
  const url = getApiEndpointUrl(config.storyblok.endpoint, 'fetch_endpoint')
    .replace(':slug', slug)

  return TaskQueue.execute({
    url: url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
}

export const fetchDataSourceEntries = async (label: string): Promise<Task> => {
  if (!label) throw new Error('Data source label is missing');
  const url = getApiEndpointUrl(config.storyblok.endpoint, 'data_source_endpoint')
    .replace(':label', label)

  return TaskQueue.execute({
    url: url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
}

export const CmsContentService = {
  fetchCmsContent
};
