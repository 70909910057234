const getProps = props => {
  return {
    title: props.title,
    description: props.description,
    contents: props.contents
  };
};
const UgcCarousel = () => ({
  type: 'ugc_carousel',
  props: getProps
});

export default UgcCarousel;
