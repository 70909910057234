import { VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

const isVisibleObserver = Symbol('is-visible-observer');

function cacheObserver (el: HTMLElement, observer: IntersectionObserver) {
  el[isVisibleObserver] = observer;
}
function removeCachedObserver (el) {
  el[isVisibleObserver] = undefined;
}

export default ({
  inserted (el: HTMLElement, { expression, value }: DirectiveBinding, vnode: VNode) {
    let observer = new IntersectionObserver(([entry], _) => {
      if (expression || value) vnode.context[expression || value] = entry.isIntersecting;
    });
    observer.observe(el);
    cacheObserver(el, observer);
  },

  unbind (el: HTMLElement) {
    let cached = el[isVisibleObserver];
    if (cached instanceof IntersectionObserver) {
      cached.unobserve(el);
      cached.disconnect();
    }
    removeCachedObserver(el);
  }
});
