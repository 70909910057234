<template>
  <MSearch v-if="isSearchPanelVisible" />
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import SearchPanelMixin from '@vue-storefront/core/compatibility/components/blocks/SearchPanel/SearchPanel';
import ALoadingSpinner from 'common/components/atoms/a-loading-spinner';
import ALoadingError from 'common/components/atoms/a-loading-error';
import MSearch from 'common/components/molecules/search-panel/m-search';

const SearchPanel = () =>
  import(/* webpackChunkName: "vsf-search-panel" */ 'common/components/organisms/o-search-panel');

export default {
  name: 'OSearch',
  components: {
    MSearch
  },
  props: {
    floatingIcon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      size: 16,
      searchPanelAsyncComponent: () => ({
        component: SearchPanel(),
        loading: ALoadingSpinner,
        error: ALoadingError,
        timeout: 3000
      })
    }
  },
  mixins: [SearchPanelMixin],
  computed: {
    ...mapGetters('category', ['getCategories']),
    ...mapGetters('user', ['isLoggedIn']),
    ...mapState({
      isSearchPanelVisible: state => state.ui.searchpanel
    })
  },
  methods: {
    reloadComponent () {
      this.searchPanelAsyncComponent = () => ({
        component: SearchPanel(),
        loading: ALoadingSpinner,
        error: ALoadingError,
        timeout: 3000
      });
    }
  }
};
</script>
