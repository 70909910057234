import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const KEY = 'loyalty';

const featurehubStore = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
export const LoyaltyModule: StorefrontModule = function ({ store }) {
  store.registerModule(KEY, featurehubStore);
};
