import { getColor } from '../helpers'

function getProps (props) {
  const {
    image,
    title,
    text_color,
    no_gap,
    component,
    CTA: cta
  } = props;

  return {
    image: image || {},
    title,
    textColor: getColor(text_color),
    noGap: no_gap,
    editable: props,
    cta,
    component
  }
}

const OverlayCard = () => ({
  type: 'overlay_card',
  props: getProps
});

export default OverlayCard
