import config from 'config'

import { getThumbnailPath } from '@vue-storefront/core/helpers';
import { attributeImages, childHasImage } from '@vue-storefront/core/modules/catalog/helpers';

/**
 * Get media Gallery images from product
 */
export function getMediaGallery (product) {
  let mediaGallery = []
  if (product.media_gallery) {
    for (let mediaItem of product.media_gallery) {
      if (mediaItem.image) {
        let video = mediaItem.vid

        if (video && video.video_id) {
          video.id = video.video_id
          delete video.video_id
        }

        mediaGallery.push({
          'src': getThumbnailPath(mediaItem.image, config.products.gallery.width, config.products.gallery.height),
          'loading': getThumbnailPath(mediaItem.image, config.products.thumbnails.width, config.products.thumbnails.height),
          'video': video
        })
      }
    }
  }
  return mediaGallery
}

/**
 * Get configurable_children images from product if any
 * otherwise get attribute images
 */
export function configurableChildrenImages (product) {
  let configurableChildrenImages = []
  if (childHasImage(product.configurable_children)) {
    let configurableAttributes = product.configurable_options.map(option => option.attribute_code)
    configurableChildrenImages = product.configurable_children.map(child =>
      getMediaGallery(child).map(media => ({
        ...media,
        'id': configurableAttributes.reduce((result, attribute) => {
          result[attribute] = child[attribute]
          return result
        }, {})
      }))
    ).reduce((medias, media) => [...medias, ...media])
  } else {
    configurableChildrenImages = attributeImages(product)
  }
  return configurableChildrenImages
}
