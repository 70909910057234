
const getBodyProps = (props) => {
  return { PreOrderText: props.text };
};

const PreOrderText = () => ({
  type: 'pre_order_text',
  props: getBodyProps
});

export default PreOrderText;
