export default {
  data: () => ({
    window: {
      width: 0,
      height: 0
    },
    desktopWidth: 1024
  }),
  computed: {
    isMobileDevice () {
      return this?.window?.width < this?.desktopWidth || false
    },
    isTabletDevice () {
      return this?.window?.width >= 768 && this?.window?.width <= 1023
    }
  },
  mounted () {
    window?.addEventListener('resize', this?.handleResize);
    this.handleResize();
  },
  destroyed () {
    window?.removeEventListener('resize', this?.handleResize);
  },
  methods: {
    handleResize () {
      this.window.width = window?.innerWidth;
      this.window.height = window?.innerHeight;
    }
  }
}
