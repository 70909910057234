














































































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import MegaMenuTracking from 'src/common/mixins/MegaMenuTracking';
import isOnPage from 'src/common/mixins/isOnPage';

import OSearch from 'common/components/organisms/o-search';
import LBLogo from 'common/components/atoms/a-link-logo';
import ASearchIcon from 'common/components/atoms/a-search-icon';
import OSitewideBanner from 'common/components/organisms/o-sitewide-banner';

import OMegaMenuCtas from './o-mega-menu-ctas.vue';
import OMegaMenuCategories from './o-mega-menu-categories.vue';
import OMegaMenuSubCategory from './o-mega-menu-sub-category.vue';
import OMegaMenuMobileModal from './mobile/o-mega-menu-mobile-modal.vue';

import windowSize from 'common/mixins/windowSize';
import WindowScrollPositionMixin from 'common/mixins/windowScrollPositionMixin';
import MCountrySelection from 'common/components/molecules/m-country-selection';
import _cloneDeep from 'lodash-es/cloneDeep';

export default Vue.extend({
  name: 'OMegaMenu',
  components: {
    LBLogo,
    OSearch,
    ASearchIcon,
    OSitewideBanner,
    OMegaMenuSubCategory,
    OMegaMenuMobileModal,
    OMegaMenuCategories,
    OMegaMenuCtas,
    MCountrySelection
  },
  mixins: [
    MegaMenuTracking,
    isOnPage,
    windowSize,
    WindowScrollPositionMixin('position', 'direction')
  ],
  data: () => ({
    isSlideBarVisible: false,
    isHomeRoute: false,
    currentCategory: '',
    pauseEvent: false,
    hide: false,
    modalKey: 0
  }),
  computed: {
    ...mapState({
      isSearchPanelVisible: (state: any) => state?.ui?.searchpanel,
      isMenubarOpen: (state: any) => state?.ui?.sidebar
    }),
    ...mapGetters('catalog-es', [
      'getTopLevelCategories',
      'getAvailableCategories'
    ]),
    ...mapGetters('loyalty', ['userLoyaltyTier']),
    ...mapGetters('user', ['isLoggedIn', 'getCurrentUser']),
    availableCategories () {
      return this.getAvailableCategories
    },
    getCurrentCategory () {
      const topLevelCategories = this.getTierProcessedCategories(this.getTopLevelCategories)
      return topLevelCategories?.find(
        (c) => c?.slug === this.currentCategory
      );
    },
    modalData () {
      const availableCategories = this.getTierProcessedCategories(this.availableCategories, true)
      return {
        payload: {
          availableCategories
        }
      };
    }
  },
  watch: {
    $route: {
      handler: function (val) {
        if (val) this.handleFocusOut();
      }
    },
    '$route.name': {
      handler (newVal) {
        this.hide = null;
        this.pauseEvent = true;
        setTimeout(() => {
          this.pauseEvent = false;
        }, 100);
        const { storeCode } = this;
        this.removeClass('hide-header');
        if (newVal === 'checkout' || newVal === `${storeCode}-checkout`) {
          this.hide = true;
        } else {
          this.isHomeRoute =
            newVal === 'home' ||
            newVal === `${storeCode}-home` ||
            newVal === `${storeCode}-${storeCode}-headless-home`;
        }
      }
    },
    position (value) {
      if (value[1] > 0 && !this.pauseEvent) {
        if (this.direction === 'DOWN') {
          this.addClass('hide-header');
        } else {
          this.removeClass('hide-header');
        }
      }
    }
  },
  beforeMount () {
    this.$bus.$on('toggle-megamenu', this.toggleMegaMenuVisibility);
    this.$bus.$on('close-megamenu', this.closeMenu);
  },
  beforeDestroy () {
    this.$bus.$off('toggle-megamenu', this.toggleMegaMenuVisibility);
    this.$bus.$off('close-megamenu', this.closeMenu);
  },
  methods: {
    setCurrentCategory (category: string) {
      if (!category) return;
      this.currentCategory = category;
    },
    handleFocusOut () {
      this.currentCategory = null;
    },
    toggleMenu () {
      if (this.isSlideBarVisible) {
        this.isSlideBarVisible = false;
        return;
      }
      this.trackMenuHamburgerClick();
      this.isSlideBarVisible = true;
    },
    closeMenu () {
      this.isSlideBarVisible = false;
      this.modalData?.payload?.availableCategories?.forEach((item) => {
        item.expanded = false;
      });
    },
    addClass (className) {
      const header = this.$refs['o-mega-menu-container'];
      if (header) {
        header.classList.add(className);
      }
    },
    removeClass (className) {
      const header = this.$refs['o-mega-menu-container'];
      if (header) {
        header.classList.remove(className);
      }
    },
    toggleMegaMenuVisibility ({ isVisible }) {
      if (isVisible) {
        this.removeClass('hide-header');
      } else {
        this.addClass('hide-header');
      }
    },
    getTierProcessedCategories (categories, forceRerender = false) {
      let tier = 'bronze'
      if (this.isLoggedIn && this.userLoyaltyTier) {
        tier = this.userLoyaltyTier
      }
      const availableCategories = _cloneDeep(categories)
      availableCategories?.forEach(category => {
        if (category?.tierRestrictions) {
          const isChildrenVisible = category?.tierRestrictions[tier]
          if (!isChildrenVisible) {
            category.children_count = 0
            category.children_data = []
          }
        }
      });
      // using key to force rerender if tier update
      if (forceRerender) {
        this.modalKey++
      }
      return availableCategories
    }
  }
});
