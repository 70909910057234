import { prepareQuery } from '@vue-storefront/core/modules/catalog/queries/common'
import { StockService } from '@vue-storefront/core/data-resolver';

export const productpageStore = {
  namespaced: true,
  state: {
    new_collection: [],
    additionalInfoActiveTab: {},
    productAdded: null,
    sizeManuallySelected: false,
    quickAddSizeManuallySelected: false,
    sizeError: null,
    productStock: [],
    preselectedProduct: null,
    qtyLimitError: false
  },
  getters: {
    getEverythingNewCollection (state) {
      return state.new_collection
    },
    getSizeManuallySelected (state) {
      return state.sizeManuallySelected;
    },
    getQuickAddSizeManuallySelected (state) {
      return state.quickAddSizeManuallySelected;
    },
    getProductStock (state) {
      return (product) => state.productStock.find(stock => stock.sku === product.sku)
    },
    getPreselectedProduct (state) {
      return state.preselectedProduct;
    },
    getPreselectedProductSku (state) {
      return state.preselectedProduct && state.preselectedProduct.sku
    },
    getSizeError (state) {
      return state.sizeError;
    },
    getQtyLimitError (state) {
      return state.qtyLimitError
    }
  },
  mutations: {
    SET_NEW_COLLECTION (state, products) {
      state.new_collection = products || []
    },
    setAdditionalInfoActiveTab (state, tab) {
      state.additionalInfoActiveTab = { uid: tab };
    },
    setProductAdded (state, product) {
      state.productAdded = product;
    },
    setSizeManuallySelected (state, size) {
      state.sizeManuallySelected = size;
    },
    setQuickAddSizeManuallySelected (state, size) {
      state.quickAddSizeManuallySelected = size;
    },
    setProductStock (state, stock) {
      state.productStock = [...state.productStock, stock];
    },
    setPreselectedProduct (state, product) {
      state.preselectedProduct = product;
    },
    SET_SIZE_ERROR (state, errorCode) {
      state.sizeError = errorCode;
    },
    SET_QTY_LIMIT_ERROR (state, isError) {
      state.qtyLimitError = isError
    }
  },
  actions: {
    async fetchNewCollection ({ commit, dispatch }) {
      const newProductsQuery = prepareQuery({ queryConfig: 'newProducts' })

      const newProductsResult = await dispatch('product/list', {
        query: newProductsQuery,
        size: 8,
        sort: 'created_at:desc'
      }, { root: true })
      const configuredProducts = await dispatch(
        'category-next/configureProducts',
        { products: newProductsResult.items
        }, { root: true })
      commit('SET_NEW_COLLECTION', configuredProducts)
    },
    async getProductMaxSaleQty ({ commit, getters }, { product }) {
      let stock = getters.getProductStock(product);

      if (stock) return stock;

      const { result } = await StockService.check(product.sku);
      stock = {
        sku: product.sku,
        maxSaleQty: result && (result.max_sale_qty || result.qty)
      };

      commit('setProductStock', stock);

      return stock;
    },
    setAdditionalInfoActiveTab ({ commit }, tab) {
      commit('setAdditionalInfoActiveTab', tab)
    },
    setProductAdded ({ commit }, product) {
      commit('setProductAdded', product)
    },
    setSizeError ({ commit }, errorCode) {
      commit('SET_SIZE_ERROR', errorCode)
    },
    setQtyLimitError ({ commit }, isError) {
      commit('SET_QTY_LIMIT_ERROR', isError)
    }
  }
}
