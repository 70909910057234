import config from 'config';
import { GCPCTab } from '../types';

const getters = {
  customerLoyaltyStatus: state => state.customerLoyaltyStatus,
  getLoadingPartnerPerks: state => state.loadingPartnerPerks,
  customerReferralCode: state => state.customerLoyaltyStatus?.referral_code,
  customerLoyaltyQrCode: state => state.customerLoyaltyStatus?.qr_code,
  loadingCustomerLoyaltyStatus: state => state.loadingCustomerLoyaltyStatus,
  customerPoints: state => state.customerLoyaltyStatus?.points || 0,
  pointsExpiringThisMonth: state => state.customerLoyaltyStatus?.expiring_points?.this_month || 0,
  loadingClaimedRewards: state => state.loadingClaimedRewards,
  loadingAvailableRewards: state => state.loadingAvailableRewards,
  claimedRewards: state => state.claimedRewards || [],
  availableRewards: state =>
    state.availableRewards?.filter(r => r.points >= 1) || [],
  partnerPerksRewards: state =>
    state.availableRewards?.filter(r => r.points === 0 || !r.points) || [],
  availableWallets: state => state.availableWallets || [],
  loadingAvailableWallets: state => state.loadingAvailableWallets,
  claimingReward: state => state.claimingReward,
  isLoadingPointsHistory: state => state.loadingPointsHistory,
  getPointsHistory: state => state.pointsHistory || {
    currentPoints: 0,
    pointsHistory: [],
    pointsExpiringThisMonth: 0,
    pointsTotalCount: 0
  },
  getReferralData: state => state.referralData,
  getReferralCode: state => state.referralData?.referralCode || '',
  getReferralFirstName: state => state.referralData?.name,
  getPointValue (state, getters, rootState, rootGetters) {
    const { storeCode } = rootGetters?.getCurrentStoreView;
    return state?.loyaltyPointValues[storeCode]
  },
  isLoyaltyStatusActive: (state, getters, rootState, rootGetters) => state.isStatusActive,
  userLoyaltyTier: (state, getters, rootState, rootGetters) =>
    state.customerLoyaltyStatus?.tier,
  userLoyaltyTierNameAtLogin: (state, getters, rootState, rootGetters) =>
    state.customerLoyaltyStatus?.tier || rootGetters['user/getCurrentUser']?.custom_attributes?.find(
      attribute => attribute?.attribute_code === 'loyalty_tier'
    )?.value || '',
  isJoiningLoyaltyProgram: state => state.isJoining,
  isInhouseLoyaltyEnabled: state => (state.customerLoyaltyStatus?.is_in_house || config?.featureFlags?.INHOUSE_LOYALTY?.isActive),
  loadingAvailableGCRewards: state => state.loadingAvailableGCRewards,
  availableGCRewards: state => state.availableGCRewards || [],
  loadingCheckoutCouponRewards: state => state.loadingCheckoutCouponRewards,
  checkoutCouponRewards: state => state.checkoutCouponRewards || [],
  checkoutCouponRewardsTotal: state => state.checkoutCouponRewardsTotal || 0,
  isAllGCLoaded: state => state.isAllGCLoaded || false,
  openGCPCModal: state => state.openGCPCModal || false,
  gcpcActiveTab: state => state.gcpcActiveTab || GCPCTab.PromoCode
};

export default getters;
