<template>
  <div
    class="default-layout"
    :class="[addPageMargin, isHome && 'cms-page']"
  >
    <MLoader />
    <div id="viewport">
      <div>
        <OMegaMenu />
      </div>
      <OMicrocart />
      <keep-alive exclude="Product,Checkout,Cart,OrderConfirmed,AdyenProcess,CmsContent,HomeContainer,MyAccount,StyleMachine,ReviewCollection,ReviewsListing">
        <slot />
      </keep-alive>
      <template v-if="!isCheckout">
        <ONewFooter />
        <ClientOnly>
          <ONewSubFooter />
        </ClientOnly>
      </template>
      <OModal />
      <MProductAddedPanel />
      <ONotification />
      <MOfflineBadge />
    </div>
    <vue-progress-bar />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OMicrocart from 'common/components/organisms/o-microcart';
import ClientOnly from 'vue-client-only';
import ONewFooter from 'common/components/organisms/o-new-footer';
import ONewSubFooter from 'common/components/organisms/o-new-sub-footer';
import OModal from 'common/components/organisms/o-modal';
import MLoader from 'common/components/molecules/m-loader';
import ONotification from 'common/components/organisms/o-notification';
import MOfflineBadge from 'common/components/molecules/m-offline-badge';
import OMegaMenu from 'common/components/organisms/mega-menu/o-mega-menu';
import { isServer } from '@vue-storefront/core/helpers';
import Head from 'theme/head';
import config from 'config';
import MProductAddedPanel from 'common/components/molecules/m-product-added-panel'

export default {
  components: {
    OMicrocart,
    ClientOnly,
    ONewFooter,
    ONewSubFooter,
    MLoader,
    ONotification,
    MOfflineBadge,
    OModal,
    OMegaMenu,
    MProductAddedPanel
  },
  data () {
    let name = this.$route.name;
    return {
      quicklink: null,
      isHome: this.isHomeRoute(name),
      isCheckout: this.isCheckoutRoute(name)
    };
  },
  computed: {
    ...mapGetters({
      // userLoyaltyTier: 'loyalty/userLoyaltyTier',
      user: 'user/getCurrentUser',
      isSalesActive: 'cart-v1/isSalesActive'
    }),
    route () {
      return this.$route.name;
    },
    quicklinkEnabled () {
      return (
        typeof config.quicklink !== 'undefined' && config.quicklink.enabled
      );
    },
    addPageMargin () {
      return this.isHome || this.isCheckout ? '' : 'page-t-margin';
    }
  },
  watch: {
    $route ({ name }) {
      this.isHome = this.isHomeRoute(name);
      this.isCheckout = this.isCheckoutRoute(name);
    }
  },
  beforeMount () {
    // Progress bar on top of the page
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start();
      this.$Progress.increase(40);
      next();
    });
    this.$router.afterEach(() => {
      if (!isServer && this.quicklinkEnabled) {
        this.quicklink.listen();
      }
      this.$Progress.finish();
      // eslint-disable-next-line no-undef
      window?.ScarabUtil?.loadedModules?.emarsys_webpersonalization?.instance?.reload();
    });
    this.$bus.$on('offline-order-confirmation', this.onOrderConfirmation);
  },
  created () {
    if (!isServer) {
      this.$store.dispatch('catalog-es/getMegaMenuCategories')
    }
  },
  mounted () {
    if (!isServer && this.quicklinkEnabled) {
      this.quicklink = require('quicklink');
      this.quicklink.listen();
    }
    this.$store.dispatch('ui/checkWebpSupport');
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      window?.ScarabUtil?.loadedModules?.emarsys_webpersonalization?.instance?.reload();
    }, 3000);
    this.$bus.$on('social-login', this.trackSocialLoginEvents);
  },
  beforeDestroy () {
    this.$bus.$off('offline-order-confirmation', this.onOrderConfirmation);
    this.$bus.$off('social-login', this.trackSocialLoginEvents);
  },
  methods: {
    trackSocialLoginEvents ({ provider, isLogin, error }) {
      let prefix = 'success';
      if (error) {
        prefix = 'error'
      }
      let socialLoginProvider = 'google'
      let type = 'login';
      if (provider !== 'google') {
        socialLoginProvider = 'facebook';
      }
      if (!isLogin) {
        type = 'register';
      }
      // it'll become like success_google_login_panel OR error_google_login_panel
      this.$gtm?.trackEvent({
        event: `${prefix}_${socialLoginProvider}_${type}_${!this.isCheckout ? 'panel' : 'checkout'}`,
        // tier_name: this.userLoyaltyTier,
        customer_id: this?.user?.id
      });
    },
    isHomeRoute (routeName) {
      return (
        routeName === 'home' ||
        routeName === 'id-home' ||
        routeName === 'id-id-headless-home'
      );
    },
    isCheckoutRoute (routeName) {
      return routeName === 'checkout' || routeName === 'id-checkout';
    },
    onOrderConfirmation (payload) {
      // This method was used to show OrderConfirmation Modal when user is offline
      // Currently we don't need this to show
      // this.openModal({ name: ModalList.OrderConfirmation, payload });
    },
    async fetchMenuData () {
      await this.$store.dispatch('catalog-es/initMegaMenuTopLevel')
    },
    async fetchFooterData () {
      await this.$store.dispatch('storyblok/fetchSBFooter');
    }
  },
  serverPrefetch () {
    return Promise.all([
      this.$store.dispatch('promoted/updatePromotedOffers'),
      this.fetchMenuData(),
      this.fetchFooterData()
    ]);
  },
  metaInfo: Head
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.page-t-margin {
  margin-top: 171px;
  @include for-mobile {
    margin-top: 85px;
  }
}
.cms-page {
  background-color: var(--sauvignon);
}
</style>

<style>
#ss_widget_frame {
  max-width: 95%;
}
iframe#launcher {
  z-index: 3 !important;
}
</style>
