export const SN_CATEGORY = 'category'
export const CATEGORY_SET_PRODUCTS = `${SN_CATEGORY}/SET_PRODUCTS`
export const CATEGORY_ADD_PRODUCTS = `${SN_CATEGORY}/ADD_PRODUCTS`
export const CATEGORY_SET_SEARCH_PRODUCTS_STATS = `${SN_CATEGORY}/SET_SEARCH_PRODUCTS_STATS`
export const CATEGORY_ADD_CATEGORIES = `${SN_CATEGORY}/ADD_CATEGORIES`
export const CATEGORY_ADD_CATEGORY = `${SN_CATEGORY}/ADD_CATEGORY`
export const CATEGORY_SET_CATEGORY_FILTERS = `${SN_CATEGORY}/SET_CATEGORY_FILTERS`
export const CATEGORY_ADD_NOT_FOUND_CATEGORY_IDS = `${SN_CATEGORY}/ADD_NOT_FOUND_CATEGORY_IDS`
export const CATEGORY_UPD_MENU_CATEGORIES = `${SN_CATEGORY}/UPD_MENU_CATEGORIES`
export const SEARCH_SET_FILTERS = `SET_SEARCH_FILTER`;

