import rootStore from '@vue-storefront/core/store';
const getters = {
  wishlistId: state => {
    const isWishlistV2Enabled = rootStore.getters['featurehub/isWishlistV2Enabled'];
    if (isWishlistV2Enabled) {
      return state?.wishlist?.wishlistId;
    }
    return state?.wishlist?.wishlists?.[0]?.id || 0
  },
  pageInfo: state => {
    const isWishlistV2Enabled = rootStore.getters['featurehub/isWishlistV2Enabled'];
    if (isWishlistV2Enabled) {
      return state?.wishlist?.pageInfo || null
    }
    return state?.wishlist?.wishlists?.[0]?.items_v2?.page_info || null
  },
  isWishlistEmpty: (state) => {
    const isWishlistV2Enabled = rootStore.getters['featurehub/isWishlistV2Enabled'];
    if (isWishlistV2Enabled) {
      return !state?.loadingWishlist && !state?.wishlist?.items?.length;
    }
    return !state?.loadingWishlist && !(state?.wishlist?.wishlists?.[0]?.items_v2?.items?.length || state?.wishlist?.wishlists?.[0]?.items_count)
  },
  loadingWishlist: state => state?.loadingWishlist,
  loadingUserWishlist: state => state?.loadingUserWishlist,
  isUpdatingWishlistItem: state => state?.updatingWishlistItem,
  removingProductFromWishlist: state => state?.removingProductFromWishlist,
  wishlistItems (state) {
    const isWishlistV2Enabled = rootStore.getters['featurehub/isWishlistV2Enabled'];
    if (isWishlistV2Enabled) {
      let items = state?.wishlist?.items || [];
      items = items.map((item) => {
        const mappedItem = {
          added_at: item?.addedAt,
          id: item?.wishlistItemId,
          child_sku: item?.sku,
          child_stock_status: item?.product?.stock?.is_in_stock ? 'IN_STOCK' : 'OUT_OF_STOCK',
          quantity: item?.qty,
          child_swatch_image: item?.product?.configurable_children?.swatch_image,
          product: {
            type_id: item?.product?.type_id,
            url_key: item?.product?.url_key,
            sku: item?.product?.sku,
            name: item?.product?.name,
            stock_status: item?.product?.stock?.status ? 'IN_STOCK' : 'OUT_OF_STOCK',
            swatch_image: item?.product?.image,
            image: item?.product?.configurable_children?.swatch_image || item?.product?.image,
            configurable_children: item?.product?.configurable_children,
            price_range: {
              minimum_price: {
                regular_price: {
                  value: item?.product?.configurable_children?.original_price
                },
                special_price: {
                  value: item?.product?.configurable_children?.special_price
                }
              }
            },
            final_price: item?.product.final_price,
            price: item?.product.price,
            product_option: { extension_attributes: { giftcard_item_option: {} } }
          },
          configurable_options: item?.configurable_options
        };
        if (item?.product?.type_id === 'giftcard') {
          const giftcard_item_option = {
            giftcard_sender_name: item?.senderName,
            giftcard_sender_email: item?.senderEmail,
            giftcard_recipient_name: item?.receiverName,
            giftcard_recipient_email: item?.receiverEmail,
            giftcard_message: item?.message
          }
          mappedItem['gift_card_options'] = {
            sender_name: item?.senderName,
            sender_email: item?.senderEmail,
            recipient_name: item?.receiverName,
            recipient_email: item?.receiverEmail,
            message: item?.message
          }
          mappedItem.product.product_option.extension_attributes.giftcard_item_option = giftcard_item_option;
        }
        return mappedItem;
      });
      return items;
    } else {
      let items = state?.wishlist?.wishlists?.[0]?.items_v2?.items || [];
      items = items.map((item) => {
        item.product.product_option = { extension_attributes: {} };
        if (item?.product?.type_id === 'giftcard') {
          const giftcard_item_option = {
            giftcard_sender_name: item?.gift_card_options?.sender_name,
            giftcard_sender_email: item?.gift_card_options?.sender_email,
            giftcard_recipient_name: item?.gift_card_options?.recipient_name,
            giftcard_recipient_email: item?.gift_card_options?.recipient_email,
            giftcard_message: item?.gift_card_options?.message
          }
          item.product.product_option.extension_attributes.giftcard_item_option = giftcard_item_option;
        }
        return item;
      });
      return items;
    }
  }
};

export default getters;
