
export const roundUp = (converted) => {
  const hundredthsPlace = 100;
  const decimalVal = Math.round((converted - parseInt(converted, 10)) * hundredthsPlace);
  const decimalPoint = 49;
  const divideMultiply = 2;
  return (decimalVal < decimalPoint) ? (Math.round(converted * divideMultiply) / divideMultiply) : Math.round(converted);
}
export const formatPrice = (inPrice, symbol, fractionDigits = 2, locale = null) => {
  if (!locale) {
    return (`${symbol} ${inPrice.toFixed(fractionDigits)}`);
  }
  const options = { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits };
  const localePrice = Math.abs(inPrice).toLocaleString(locale, options);
  return (`${symbol} ${localePrice}`);
}
