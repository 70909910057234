const getProps = props => {
  return {
    title: props.title,
    description: props.description,
    imageUrl: props.image?.filename,
    skuList: props.skuList?.split(','),
    isVideo: props.is_video,
    videoId: props.video_id
  };
};
const UgcItem = () => ({
  type: 'ugc_item',
  props: getProps
});

export default UgcItem;
