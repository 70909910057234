function getProps (props) {
  const {
    title,
    subtitle,
    background_color: backgroundColor,
    desktop_image: desktopImage,
    mobile_image: mobileImage,
    text_color: textColor
  } = props;

  return {
    title,
    subtitle,
    backgroundColor,
    desktopImage,
    mobileImage,
    textColor
  }
}

const CategoryBannerTextOnlyLayout = () => ({
  type: 'category_banner_text_with_image',
  props: getProps
});

export default CategoryBannerTextOnlyLayout
