import * as types from './mutation-types';

const mutations = {
  [types.INIT_CACHE_TAG] (state, { set }: { set: Set<unknown> }) {
    state.tags = set;
  },
  [types.ADD_CACHE_TAGS] (state, { tags }: { tags: string[] }) {
    tags.forEach((tag) => {
      state.tags?.add(tag)
    })
  }
}

export default mutations
