



















import ASearchIcon from 'common/components/atoms/a-search-icon';
import AAccountIcon from 'common/components/atoms/a-account-icon';
import AMicrocartIcon from 'common/components/atoms/a-microcart-icon';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import isOnPage from 'common/mixins/isOnPage';
import windowSize from 'common/mixins/windowSize';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

import { mapGetters, mapActions } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'OMegaMenuCtasComponent',
  mixins: [isOnPage, windowSize],
  components: {
    ASearchIcon,
    AAccountIcon,
    AMicrocartIcon,
    MAuthenticationSidebar: () => import('common/components/molecules/auth-v1/m-authentication-sidebar.vue')
  },
  watch: {
    activeModals: function (newVal, oldVal) {
      if (newVal[0]?.payload?.hideSelection && !this.isLoggedIn) {
        this.wishlistAuth = false
      } else {
        this.wishlistAuth = true
      }
    }
  },
  data: () => ({
    wishlistAuth: true
  }),
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    ...mapGetters('user', ['getAuthModalVisibility']),
    ...mapGetters('ui', ['activeModals'])
  },
  methods: {
    ...mapActions('ui', {
      openModal: 'openModal'
    }),
    ...mapActions('user', {
      toggleAuthModal: 'toggleAuthModal'
    }),
    myWishlistLink () {
      if (!this.isLoggedIn) {
        this.toggleAuthModal()
      } else this.$router.push(localizedRoute('/my-account?page=my-wishlist'))
    },
    toggleNewAuthModal () {
      EventBus.$emit('close-megamenu')
      this.toggleAuthModal();
    }
  }
});
