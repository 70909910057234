import { StoryblokComponent } from 'storyblok-js-client'
export const IN_PROGRESS = 'inProgress';

export enum FooterItemType {
  Text = 'footer_text',
  Link = 'footer_link'
}

export interface FooterItem extends StoryblokComponent<FooterItemType> {
  id: string,
  _uid: string,
  link?: {
    id: string,
    url: string,
    linktype: string,
    fieldtype: string,
    cached_url: string
  },
  title: string,
  isTitle?: boolean,
  component: FooterItemType,
  href?: string,
  openNewTab?: boolean,
  external?: boolean
}

export interface FooterGroup extends StoryblokComponent<'footer_column_block'> {
  id: string,
  links: FooterItem[],
  title: string,
  component: 'footer_column_block'
}
