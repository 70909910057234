import { mapActions, mapGetters } from 'vuex';
import config from 'config'
import { loadScript } from '../helpers';

export default {
  name: 'CmsContent',
  components: {
    'hero_banner': () => import('src/modules/storyblok/components/HeroBanner.vue'),
    'image_with_text': () => import('src/modules/storyblok/components/ImageWithText.vue'),
    'divider_line': () => import('src/modules/storyblok/components/DividerLine.vue'),
    'full_width_section': () => import('src/modules/storyblok/components/FullWidthSection.vue'),
    'grid': () => import('src/modules/storyblok/components/Grid.vue'),
    'grid_slider': () => import('src/modules/storyblok/components/GridSlider.vue'),
    'card': () => import('src/modules/storyblok/components/Card.vue'),
    'leadgen': () => import('src/modules/storyblok/components/LeadGen.vue'),
    'promo_code': () => import('src/modules/storyblok/components/PromoCode.vue'),
    'tabs': () => import('src/modules/storyblok/components/Tabs.vue'),
    'cta': () => import('src/modules/storyblok/components/CTA.vue'),
    'video_clp': () => import('src/modules/storyblok/components/VideoClp.vue'),
    'sitewide_texts': () => import('src/modules/storyblok/components/SitewideText.vue'),
    'sitewide_banner': () => import('src/modules/storyblok/components/SitewideBanner.vue'),
    'pre_order_message_block': () => import('src/modules/storyblok/components/PreorderMessageBlock.vue'),
    'pre_order_text': () => import('src/modules/storyblok/components/PreorderText.vue'),
    'homepage_hero_carousel': () => import('src/modules/storyblok/components/HomepageHeroCarousel.vue'),
    'homeblock': () => import('src/modules/storyblok/components/Homeblock.vue'),
    'homepage_product_carousel': () => import('src/modules/storyblok/components/HomepageProductCarousel.vue'),
    'order_summary_block': () => import('src/modules/storyblok/components/OrderSummaryBlock.vue'),
    'promo_code_block': () => import('src/modules/storyblok/components/PromoCodeBlock.vue'),
    'delivery_delay_block': () => import('src/modules/storyblok/components/DeliveryDelayBlock.vue'),
    'mega_menu_banner_block': () => import('src/modules/storyblok/components/MegaMenuBannerBlock.vue'),
    'mega_menu_mobile_banner_block': () => import('src/modules/storyblok/components/MegaMenuMobileBannerBlock.vue'),
    'mega_menu_banner': () => import('src/modules/storyblok/components/MegaMenuBanner.vue'),
    'mega_menu_mobile_banner': () => import('src/modules/storyblok/components/MegaMenuMobileBanner.vue'),
    'EmptySearchResultBlock': () => import('src/modules/storyblok/components/EmptySearchResultBlock.vue'),
    'search_error_message': () => import('src/modules/storyblok/components/SearchErrorMessage.vue'),
    'popular_categories_block': () => import('src/modules/storyblok/components/PopularCategoriesBlock.vue'),
    'popular_category': () => import('src/modules/storyblok/components/PopularCategory.vue'),
    'typeform': () => import('src/modules/storyblok/components/Typeform.vue'),
    'curator_io': () => import('src/modules/storyblok/components/CuratorIO.vue'),
    'rich_text_block': () => import('src/modules/storyblok/components/RichTextBlock.vue'),
    'category_banner_carousel': () => import('src/modules/storyblok/components/CategoryBannerCarousel.vue'),
    'category_banner_cards': () => import('src/modules/storyblok/components/CategoryBannerCards.vue'),
    'sb_table': () => import('src/modules/storyblok/components/Table.vue'),
    'product_model': () => import('src/modules/storyblok/components/ProductModel.vue'),
    'product_uvp': () => import('src/modules/storyblok/components/ProductUVP.vue'),
    'global_uvp': () => import('src/modules/storyblok/components/GlobalUvp.vue'),
    'child_product_carousel': () => import('src/modules/storyblok/components/ChildProductCarousel.vue'),
    'banner_with_product_grid': () => import('src/modules/storyblok/components/BannerWithProductGrid.vue'),
    'ugc_carousel': () => import('src/modules/storyblok/components/UGC/UgcCarousel.vue'),
    'ugc_item': () => import('src/modules/storyblok/components/UGC/UgcItem.vue'),
    'homepage_about_brand': () => import('src/modules/storyblok/components/HomepageAboutBrand.vue'),
    'overlay_card': () => import('src/modules/storyblok/components/OverlayCard.vue'),
    'amplify_signature_card': () => import('src/modules/storyblok/components/AmplifySignatureCard.vue'),
    'common_carousel': () => import('src/modules/storyblok/components/CommonCarousel.vue'),
    'lb_benefits': () => import('src/modules/storyblok/components/LbBenefits.vue'),
    'iframe_block': () => import('src/modules/storyblok/components/IframeBlock.vue')
  },
  computed: {
    ...mapGetters({
      stories: 'storyblok/getStories',
      currentStoryKey: 'storyblok/getCurrentStoryKey'
    }),
    currentStory () {
      return this.stories?.[this.currentStoryKey]?.story;
    },
    pageTitle () {
      return this.currentStory?.title || null
    },
    pageDescription () {
      return this.currentStory?.description || null
    },
    pageKeywords () {
      return this.currentStory?.keywords || null
    },
    currentStoryComponents () {
      return this?.currentStory?.components
    },
    isFullWidthPage () {
      return this.currentStory?.isFullWidth || false
    }
  },
  async mounted () {
    const url = `https://app.storyblok.com/f/storyblok-latest.js?t=${config?.storyblok?.previewToken}`
    await loadScript(url, 'storyblok-javascript-bridge')
    window?.storyblok?.on(['input', 'published', 'change'], (event: any) => {
      if (event?.action === 'input') {
        let data = { eventStory: event?.story, key: this.currentStoryKey }
        this.updateCmsData(data)
      } else if (!(event).slugChanged) {
        window?.location?.reload()
      }
    })
  },
  methods: {
    ...mapActions('storyblok', ['fetchCmsData', 'updateCmsData', 'fetchSeoFooterData']),
    getStoryComponentsByKey (key) {
      const selectedStory = this.stories?.[key]?.story;
      return selectedStory?.components;
    }
  }
}
