<template>
  <div class="sitewide-container">
    <component
      :is="item.type"
      v-for="(item, index) in currentBlock"
      :key="index"
      v-bind="item.props"
    />
  </div>
</template>
<script>
import CmsRender from 'src/modules/storyblok/mixins/CmsRender';
import { mapGetters } from 'vuex';
import config from 'config';

const blockPostfix = config.server.isProduction ? '' : '-sit'

export default {
  name: 'SBanner',
  mixins: [CmsRender],
  data () {
    return {
      blockKey: `blocks/sitewide-banner${blockPostfix}`,
      blockData: {}
    }
  },
  computed: {
    ...mapGetters(['getCurrentStoreView']),
    currentBlock () {
      return this.blockData?.components;
    }
  },
  async created () {
    const storeCode = this.getCurrentStoreView?.storeCode;
    const version = this.$route.query?._storyblok ? 'draft' : 'published'
    this.blockData = await this.$store.dispatch('storyblok/fetchCmsBlock', { slug: `${storeCode}/${this.blockKey}`, version: version });
  }
}
</script>
<style lang="scss" scoped>
.sitewide-container {
  height: 50px;
  overflow: hidden;
}
</style>
