import config from 'config';
import * as next from './types';
import { Logger } from '@vue-storefront/core/lib/logger';
import { extendStore } from '@vue-storefront/core/helpers';
import { changeAddress, freshAddress } from 'common/helpers/address';
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import * as types from '@vue-storefront/core/modules/checkout/store/checkout/mutation-types';
import { postalCodeLength } from 'common/constants';
import { dialCodes } from 'common/config/dialCodes';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { getCountryByCode } from 'common/config/countries-data';
import { getAddressFieldsConfigByCountry } from 'common/components/molecules/address/address-form-config';

const KEY = 'checkout-next';

const checkoutNextStore = {
  namespaced: true
};

const checkoutNextModule = {
  state: {
    extensionAttributes: {},
    selectedPopStation: {},
    posClient: null,
    guestUser: null
  },
  getters: {
    getGuestUser: state => state.guestUser,
    getCustomerEmail: (state, getters, rootState, rootGetters) => rootState.user?.current?.email || getters.getGuestUser?.email,
    getIsGuestCheckout: (state, getters, rootState, rootGetters) => rootGetters['featurehub/isGuestCheckoutEnabled'] && !!getters.getGuestUser?.email,
    getShippingMethods: (state, getters, rootState, rootGetters) => {
      if (rootGetters['featurehub/isCartModuleActive']) {
        return rootGetters['cart-v1/getShippingMethods']
      }
      return state.shippingMethods
    },
    getPaymentMethods: (state, getters, rootState, rootGetters) => {
      if (rootGetters['featurehub/isCartModuleActive']) {
        return rootGetters['cart-v1/getPaymentMethods']
      }

      const isVirtualCart = rootGetters['cart/isVirtualCart']

      return state.paymentMethods.filter(method => !isVirtualCart || method.code !== 'cashondelivery')
    },
    getShippingAddressCountry: (state, getters) => {
      const countryCode = getters.getShippingDetails?.country_id;
      return getCountryByCode(countryCode)
    },
    isPostalCodeCorrect: (state) => {
      const countryId = state.shippingDetails?.country_id
      const postalCode = state.shippingDetails?.postcode
      const requiredLengthForPostalCode = postalCodeLength[countryId]
      // if country doesn't exist
      if (!requiredLengthForPostalCode) {
        return true
      }

      // if postal code is empty for a selected address
      if (!postalCode) {
        return false
      }

      if (countryId === 'US') {
        return !!(postalCode?.match(new RegExp('(^\\d{5}$)|(^\\d{5}-\\d{4}$)', 'g')))
      }

      if (countryId === 'TW') {
        return !!(postalCode?.match(new RegExp('(^\\d{5}$)|(^\\d{6}$)', 'g')))
      }

      // create a dynamic regex for validating postcode
      return !!(postalCode?.match(new RegExp(`^\\d{${requiredLengthForPostalCode}}$`)))
    },
    isPhoneNumberCorrect: (state) => {
      const countryId = state.shippingDetails?.country_id
      const telephone = state.shippingDetails?.telephone
      const countryDialCode = dialCodes.find(dialCode => dialCode.code === countryId)?.dial_code || ''
      // if country doesn't exist
      if (!countryDialCode) {
        return false
      }
      let dialCode = `+${countryDialCode}`;

      // get user selected dial code
      const customAttributes = state.shippingDetails?.custom_attributes;
      if (customAttributes) {
        dialCode = customAttributes.find(attr => attr.attribute_code === 'country_code')?.value || `+${countryDialCode}`;
      }
      const phoneNumber = parsePhoneNumber(`${dialCode}${telephone}`);
      return phoneNumber.isValid();
    },
    getAddressFieldsConfigByCountry: (state, getters) => {
      const countryCodeAlpha3 = getters.getShippingAddressCountry?.alpha3;
      return getAddressFieldsConfigByCountry(countryCodeAlpha3)
    },
    isAddressStateValid: (state, getters) => {
      if (!getters.getAddressFieldsConfigByCountry.state.required) {
        return true;
      }
      const regionData = getters.getShippingDetails.region;
      if (!regionData.region || !regionData.region_code || (!regionData.region_id && regionData.region_id !== 0)) {
        return false;
      }
      return true;
    },
    isShippingAddressValid (state, getters) {
      return getters.isShippableAddress && getters.isPostalCodeCorrect && getters.isPhoneNumberCorrect && getters.isAddressStateValid;
    },
    isShippableAddress (state, getters, rootState) {
      const countryCodes = rootState.address.countryCodes
      const countryId = state.shippingDetails.country_id
      const allowed = countryCodes.find(({ alpha2 }) => alpha2 === countryId)

      return !!allowed
    },
    getPosClient: (state) => state.posClient,
    getExtensionAttributes: (state) => state.extensionAttributes,
    getSelectedPopStation: (state) => state.selectedPopStation,
    getSelectedCollectionStore: (state) => state.selectedCollectionStore,
    readyToShip: (state, getters, rootState, rootGetters) => {
      if (rootGetters['cart-v1/unShippableProduct']) return false;
      let { shippingMethod, shippingCarrier, firstname } = getters.getShippingDetails;
      if (rootGetters['cart-v1/isGiftcardOnly']) {
        return firstname && getters.isShippingAddressValid;
      }
      return (
        firstname &&
        shippingMethod &&
        shippingCarrier &&
        getters.isShippingAddressValid
      );
    },
    readyToPay: (state, getters, rootState, rootGetters) => {
      // let productsInStock = this.stockCheckCompleted && this.stockCheckOK;
      return !rootGetters['cart-v1/unShippableProduct'] && (getters.readyToShip || rootGetters['cart-v1/isGiftcardOnly']) && !rootGetters['cart-v1/anyCartItemIsOos'];
    }
  },
  mutations: {
    [next.RESET_SHIPPING_METHOD] (state) {
      state.shippingDetails = freshAddress({ shippingMethod: '', shippingCarrier: '' })
    },
    [next.SET_GUEST_USER] (state, guestUser) {
      state.guestUser = guestUser
    },
    [next.CHECKOUT_SELECT_SHIPPING_METHOD] (state, payload) {
      state.shippingDetails = {
        ...state.shippingDetails,
        shippingMethod: payload.method_code,
        shippingCarrier: payload.carrier_code
      }
    },
    [next.CHECKOUT_SELECT_SHIPPING_ADDRESS] (state, payload) {
      state.shippingDetails = changeAddress(state.shippingDetails, payload)
    },
    [next.CHECKOUT_SELECT_PAYMENT_METHOD] (state, payload) {
      state.paymentDetails = {
        ...state.paymentDetails,
        paymentMethod: payload.code,
        paymentMethodAdditional: payload.paymentMethodAdditional
      }
    },
    [next.CHECKOUT_SELECT_BILLING_ADDRESS] (state, payload) {
      state.paymentDetails = changeAddress(state.paymentDetails, payload)
    },
    [next.SET_EXTENSION_ATTRIBUTES] (state, payload) {
      state.extensionAttributes = payload
    },
    [next.SET_POP_STATION] (state, payload) {
      state.selectedPopStation = payload
    },
    [next.SET_COLLECTION_STORE] (state, payload) {
      state.selectedCollectionStore = payload
    },
    [next.SET_POS_CLIENT] (state, payload) {
      state.posClient = payload
    }
  },
  actions: {
    resetGuestCheckout ({ commit, dispatch }) {
      commit(next.SET_GUEST_USER, null);
      commit(next.RESET_SHIPPING_METHOD)
    },
    setGuestUser ({ commit }, guestUser) {
      commit(next.SET_GUEST_USER, {
        email: guestUser.email,
        agreement: !!guestUser.agreement,
        subscribeToNewsletter: !!guestUser.subscribeToNewsletter
      })
    },
    async setPopStation ({ commit }, payload) {
      await commit(next.SET_POP_STATION, payload)
    },
    async setPosClient ({ commit }, payload) {
      await commit(next.SET_POS_CLIENT, payload)
    },
    async setCollectionStore ({ commit }, payload) {
      await commit(next.SET_COLLECTION_STORE, payload)
    },
    async setExtensionAttributes ({ commit }, payload) {
      await commit(next.SET_EXTENSION_ATTRIBUTES, payload)
    },
    async selectShippingMethod ({ commit }, payload) {
      await commit(next.CHECKOUT_SELECT_SHIPPING_METHOD, payload)
    },
    async selectShippingAddress ({ commit, dispatch }, payload) {
      await commit(next.CHECKOUT_SELECT_SHIPPING_ADDRESS, payload)
      const countryCodes = config.checkout.countryCodes
      const countryId = payload.country_id
      const allowed = Object.values(countryCodes).find(code => code === countryId)

      if (!allowed) {
        await commit(next.CHECKOUT_SELECT_SHIPPING_METHOD, {
          method_code: '',
          carrier_code: ''
        })
      }
    },
    async selectPaymentMethod ({ commit }, payload) {
      await commit(next.CHECKOUT_SELECT_PAYMENT_METHOD, payload)
    },
    async selectBillingAddress ({ commit }, payload) {
      await commit(next.CHECKOUT_SELECT_BILLING_ADDRESS, payload)
    },
    async placeOrder ({ dispatch, rootState, rootGetters }, { order }) {
      try {
        const result = await dispatch('order/placeOrder', order, { root: true })
        if (!result?.resultCode || result?.resultCode === 200) {
          const lastOrder =
            rootState?.order?.last_order_confirmation?.order?.products;
          const lastOrderId = rootState?.order?.last_order_confirmation?.confirmation?.magentoOrderId
          const lastOrders = lastOrder?.map(item => {
            return {
              item: item?.parentSku || item?.sku,
              price: item?.totals?.row_total_incl_tax,
              quantity: item.qty
            };
          });
          const payload = {
            orderId: lastOrderId,
            items: lastOrders
          };

          await dispatch('updateOrderTimestamp')
          // clear cart without sync, because after order cart will be already cleared on backend
          await dispatch('cart/clear', { sync: false }, { root: true });

          dispatch(
            'emarsys/scarabQueuePush',
            {
              key: 'purchase',
              value: payload
            },
            { root: true }
          );
          await dispatch('dropPassword')
        }
      } catch (e) {
        Logger.error('Error while placing order', 'bottom-funnel - checkout', e)()
      }
    },
    async load ({ commit, state }) {
      const checkoutStorage = StorageManager.get('checkout')
      const [
        personalDetails
      ] = await Promise.all([
        checkoutStorage.getItem('personal-details')
      ])

      if (personalDetails) {
        await commit(types.CHECKOUT_LOAD_PERSONAL_DETAILS, personalDetails)
      }

      if (!state.paymentDetails.firstname) {
        await commit(types.CHECKOUT_LOAD_PAYMENT_DETAILS, freshAddress({ paymentMethod: '', paymentMethodAdditional: {} }))
      }
      if (!state.shippingDetails.firstname) {
        await commit(types.CHECKOUT_LOAD_SHIPPING_DETAILS, freshAddress({ shippingMethod: '', shippingCarrier: '' }))
      }
    }
  }
};

export const CheckoutNextModule: StorefrontModule = function ({
  app,
  store,
  appConfig,
  router,
  moduleConfig
}) {
  store.registerModule(KEY, checkoutNextStore);

  store.subscribe(({ type }, state) => {
    if (
      type.endsWith(next.CHECKOUT_SELECT_SHIPPING_ADDRESS) ||
      type.endsWith(next.CHECKOUT_SELECT_SHIPPING_METHOD)
    ) {
      StorageManager.get('checkout').setItem('shipping-details', state.checkout.shippingDetails)
        .catch((reason) => { Logger.error(reason)() })
    }

    if (
      type.endsWith(next.CHECKOUT_SELECT_BILLING_ADDRESS) ||
      type.endsWith(next.CHECKOUT_SELECT_PAYMENT_METHOD)
    ) {
      StorageManager.get('checkout').setItem('payment-details', state.checkout.paymentDetails)
        .catch((reason) => { Logger.error(reason)() })
    }

    if (type.endsWith(next.RESET_SHIPPING_METHOD)) {
      StorageManager.get('checkout').removeItem('shipping-details')
        .catch((reason) => { Logger.error(reason)() })
      StorageManager.get('checkout').removeItem('payment-details')
        .catch((reason) => { Logger.error(reason)() })
    }
  })

  extendStore('checkout', checkoutNextModule);
};
