import i18n from '@vue-storefront/i18n';
import * as types from './types';
import config from 'config';
import { checkProductAvailability } from '../services'

const showErrorMessage = message => {
  if (!message) return;
  return {
    type: 'error',
    message: i18n.t(message),
    action1: { label: i18n.t('OK') }
  };
};

const actions = {
  async getProductAvailability ({ commit, dispatch }, productSKU: string) {
    if (!productSKU) dispatch('notification/spawnNotification', showErrorMessage('No product SKU found in getProductAvailability!'), { root: true })
    commit(types?.CHECKING_PRODUCT_AVAILABILITY, true)
    let success = true
    const resp = await checkProductAvailability(productSKU)
    if (resp.code !== 200 && resp.code !== 404) {
      success = false
      dispatch('notification/spawnNotification', showErrorMessage('Something went wrong in checkProductAvailability!'), { root: true })
      console.error(resp)
      commit(types?.CHECKING_PRODUCT_AVAILABILITY, false);
    }
    commit(types?.SET_PRODUCT, productSKU);
    commit(types?.PRODUCT_AVAILABILITY, resp?.result?.checkStock)
    commit(types?.CHECKING_PRODUCT_AVAILABILITY, false);
    return success
  }
}

export default actions;
