const getProps = props => {
  const {
    title,
    subtitle,
    description,
    banner_bg_color,
    banner_position,
    background_image,
    cta,
    sku_1,
    sku_2,
    sku_3,
    sku_4,
    text_color,
    open_in_new_tab
  } = props;

  return {
    title,
    subtitle,
    description,
    bannerBackgroundColor: banner_bg_color?.color,
    bannerPosition: banner_position,
    backgroundImage: background_image?.filename || '',
    cta,
    textColor: text_color?.color,
    skuList: [sku_1, sku_2, sku_3, sku_4],
    openInNewTab: open_in_new_tab
  }
};
const BannerWithProductGrid = () => ({
  type: 'banner_with_product_grid',
  props: getProps
});

export default BannerWithProductGrid;
