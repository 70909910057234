
const getBodyProps = (props) => {
  return { message: props.message };
};

const SearchErrorMessage = () => ({
  type: 'search_error_message',
  props: getBodyProps
});

export default SearchErrorMessage;
