import { getColor, getLinkUrl } from '../helpers'

function getProps (props) {
  const {
    image,
    title,
    subtitle,
    text,
    background_color,
    color,
    cta_label: ctaLabel,
    cta_link: ctaLink,
    layout_option,
    no_gap
  } = props;

  return {
    image: image || {},
    title,
    subtitle,
    ctaLabel,
    text: text || {},
    ctaLink: getLinkUrl(ctaLink),
    ctaLinkType: ctaLink?.linktype || '',
    backgroundColor: getColor(background_color),
    color: getColor(color),
    layoutOption: layout_option,
    noGap: no_gap,
    editable: props
  }
}

const ImageWithText = () => ({
  type: 'image_with_text',
  props: getProps
});

export default ImageWithText
