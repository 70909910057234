var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SfButton',_vm._g(_vm._b({staticClass:"a-button",class:[
    _vm.buttonClass,
    {
      primary: _vm.primary,
      secondary: _vm.secondary,
      tertiary: _vm.tertiary,
      block: _vm.block
    }
  ],attrs:{"disabled":_vm.disabled}},'SfButton',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }