<template>
  <SfButton
    class="sf-button--pure a-microcart-icon"
    :class="isMicrocartOpen ? 'icon-active' : null"
    @click="handleRedirect"
    @mouseenter="toggleMicrocart(true)"
    @mouseleave="toggleMicrocart(false)"
    data-testid="microcartIcon"
  >
    <svg class="lb-icon">
      <use xlink:href="#Cart" />
    </svg>
    <SfBadge
      data-testid="microcartQuantity"
      v-show="totalQuantity"
      class="sf-icon__badge sf-badge--number badge"
    >
      {{ totalQuantity }}
    </SfBadge>
  </SfButton>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { SfButton, SfBadge } from '@storefront-ui/vue';
import debounce from 'lodash-es/debounce';
import windowSize from 'common/mixins/windowSize';
import syncCartWhenLocalStorageChange from 'common/helpers/syncCartWhenLocalStorageChange';

export default {
  mixins: [windowSize],
  components: { SfButton, SfBadge },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    window: {
      width: 0,
      height: 0
    }
  }),
  mounted () {
    syncCartWhenLocalStorageChange.addEventListener()
    this.$once('hook:beforeDestroy', () => {
      syncCartWhenLocalStorageChange.removeEventListener()
    })
  },
  beforeMount () {
    this.sendHoverEvent = debounce(() => {
      this.$gtm?.trackEvent({ event: 'hover_minicart' });
    }, 500);
  },
  computed: {
    ...mapGetters({
      totalQuantity: 'cart/getItemsTotalQuantity'
    }),
    ...mapGetters('ui', ['activeModals', 'isMiniCartOpen']),
    ...mapState({
      isMicrocartOpen: state => state.ui.microcart
    })
  },
  methods: {
    ...mapActions({
      switchMicrocart: 'ui/switchMicrocart'
    }),
    toggleMicrocart (status) {
      if (!this.activeModals?.length === 0 && this.window?.width > this.desktopWidth) return
      if (status) {
        this.sendHoverEvent();
      }
      this.switchMicrocart(status);
    },
    handleRedirect () {
      this.$gtm?.trackEvent({ event: 'click_bag_icon' })
      this.$router.push(this.localizedRoute('/checkout/cart'))
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.a-microcart-icon {
  cursor: pointer;
  height: fit-content;
  margin-top: 4px;
  position: relative;
  @include for-mobile {
    margin-left: 5px;
    .lb-icon {
      width: 22px;
      height: 22px;
      margin-left: 12px;
      margin-top: 2px;
    }
  }
}

.sf-badge--number {
  position: absolute;
  top: -8px;
  right: -8px;
  color: var(--white);
  background: var(--bold-brown-500);
  font-weight: 400;
  font-size: var(--font_size_50);
  font-family: var(--font-secondary);
  line-height: var(--fix_line_height_75);
  text-align: center;
  width: 1rem;
  height: 1rem;
}
.cart-icon {
  @include for-mobile {
    width: 23px;
    height: auto;
  }
}
</style>
