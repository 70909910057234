
const getTextProps = (props) => {
  return { bannerText: props.banner_text, countdownEndTime: props.countdown_end_time };
};

const SitewideTextsWithCountDown = () => ({
  type: 'sitewide_texts_with_countdown',
  props: getTextProps
});

export default SitewideTextsWithCountDown;
