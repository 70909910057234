import * as types from './types';

const mutations = {
  [types.VOTING_PRODUCT] (state, payload: boolean) {
    state.votingProduct = payload;
  },
  [types.FETCHING_VOTES] (state, payload: boolean) {
    state.fetchingVotes = payload;
  },
  [types.DELETING_VOTE] (state, payload: boolean) {
    state.deletingVote = payload;
  },
  [types.ADD_SUBSCRIPTIONS] (state, payload: any) {
    state.subscriptions = payload;
  },
  [types.ADD_PRODUCTS_LIST] (state, payload: any) {
    state.productsList = payload;
  }
}

export default mutations;
