import rootStore from '@vue-storefront/core/store'
import {
  fetchProductsByCategoryIds,
  fetchProductsByKeyword,
  fetchProductsByUrlKey,
  fetchSimilarProducts,
  fetchProductDetails,
  fetchMegaMenu,
  fetchProductBreadCrumbs,
  fetchCategoryBreadCrumbs, fetchProductsBySkus, fetchServerTime
} from './service';
import { updatePriceAggregation } from 'src/modules/category-next/helpers';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import {
  handleResult,
  prepareProducts,
  createConfiguredProducts,
  getFilters
} from './helper';
import { getProductGallery } from './galleryhelper';
import config from 'config';
import * as types from './mutation-types';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { isServer } from 'common/utils'
import { setSearchHistoryText, getRecentlyViewedSku, encodeBase64 } from 'common/helpers';
import { transpileCmsContent } from 'src/modules/storyblok/transpilers/';

const getStoryData = (story) => {
  let components = transpileCmsContent(story);

  let { title, description, keywords } = story.content;
  return {
    components: components,
    title: title,
    description: description,
    keywords: keywords
  };
};

const isRestrictedCategory = (urlKey) => {
  const restrictedCategory = ['sale', 'sale-sg', 'sale-hk', 'sale-my', 'sale-intl', 'sale-us', 'sale-ph'];
  let res = '';
  restrictedCategory.forEach((key) => {
    const regex = new RegExp(`\\/${key}(?:\\/.*)?(?:\\?.*)?$`);
    if (regex.test('/' + urlKey)) {
      res = key;
    }
  });
  return !!res;
}

const setPlpStoryData = (storyBlokContent, commit) => {
  const { banner, seo_footer } = storyBlokContent || {};

  if (banner && Object.keys(banner).length) {
    const storyData = getStoryData(banner)
    commit(types.SET_PLP_BANNER, { storyBlokContent: storyData });
    const inlineBannerWrapper = banner?.content?.body.find(item => item.component === 'category_inline_banners')
    if (inlineBannerWrapper) {
      commit(types.SET_PLP_INLINE_BANNER, { inlineBanners: inlineBannerWrapper.inline_banners });
    } else {
      commit(types.SET_PLP_INLINE_BANNER, { inlineBanners: [] });
    }
  } else {
    commit(types.SET_PLP_BANNER, { storyBlokContent: {} });
  }
  if (seo_footer && Object.keys(seo_footer).length) {
    const storyData = getStoryData(seo_footer)
    commit(types.SET_PLP_FOOTER, { storyBlokContent: storyData });
  } else {
    commit(types.SET_PLP_FOOTER, { storyBlokContent: {} });
  }
}

const entityType = 'product';
const actions = {

  async getRelatedProductsBySku ({ commit, getters, rootState, rootGetters }, {
    skuMapping, inStockOnly = false
  }) {
    try {
      if (!Object.keys(skuMapping).length) return
      const entityType = 'getRelatedProductsBySku'
      const { storeCode } = currentStoreView();

      const skus = Object.keys(skuMapping);
      const key = (skus.join(',') + storeCode).replace(
        / /g,
        '_'
      );
      const cache = getters.getRelatedProductsCache;
      const cachedResults = cache.find(item => item[key]);
      // Client Side Cache
      if (cachedResults) {
        const { items } = cachedResults[key];
        commit(types.ADD_RELATED_PRODUCTS, { products: items || [] })
        commit(types.LOADING_RELATED_PRODUCTS, { isLoading: false })
        return { items };
      }

      commit(types.LOADING_RELATED_PRODUCTS, { isLoading: true })
      const resp = await fetchProductsBySkus({ skus, storeCode, inStockOnly })
      if (resp.status !== 200) {
        commit(types.LOADING_RELATED_PRODUCTS, { isLoading: false })
        throw new Error('Error! fetching getProductsBySku');
      }
      const data = await resp.json();
      const { result } = data
      const results = handleResult(result, entityType);
      const { items } = results

      items.sort((a, b) => {
        const posA = skuMapping[a.sku] || 0;
        const posB = skuMapping[b.sku] || 0;
        return posA - posB;
      });

      commit(types.ADD_RELATED_PRODUCTS, { products: items || [] })
      commit(types.LOADING_RELATED_PRODUCTS, { isLoading: false })
      commit(types.ADD_RELATED_PRODUCTS_CACHE, { results, key });

      return items
    } catch (error) {
      console.error('getProductsBySku', error);
    }
  },
  async getProductsByUrlKey (
    { commit, getters, rootState, rootGetters, dispatch },
    { urlKey, route, category, pageSize = 40, agg = true, queryParams }
  ) {
    try {
      const shouldPopulateCacheTags = config.server.useOutputCacheTagging && rootGetters['cache-tags/getTags'];
      if (isRestrictedCategory(urlKey)) {
        const isLoggedIn = rootGetters['user/isLoggedIn']
        if (isLoggedIn) {
          let tier = ''
          const userLoyaltyStatus = rootGetters['loyalty/customerLoyaltyStatus']
          if (!userLoyaltyStatus) {
            await rootStore.dispatch('loyalty/getCustomerLoyaltyStatus')
            const userLoyaltyStatus = rootGetters['loyalty/customerLoyaltyStatus']
            tier = userLoyaltyStatus?.tier
          } else {
            tier = userLoyaltyStatus?.tier
          }
          if (tier) {
            queryParams = queryParams + `&tiertype=${encodeBase64(tier)}`
          }
        }
      }
      commit(types.SET_PLP_PRODUCTS_LOADING, { isLoading: true });
      const { storeCode } = currentStoreView();
      const key = (urlKey + storeCode + pageSize + queryParams).replace(
        / /g,
        '_'
      );
      const cache = getters.getPlpProductsCache;
      const cachedResults = cache.find(item => item[key]);
      // Client Side Cache
      if (cachedResults) {
        const { items, total, aggregations, isRestricted = false, storyBlokContent } = cachedResults[key];
        commit(types.SET_RESTRICTED_CATEGORY, { isRestricted });
        const aggregationFilters = getFilters({
          aggregations: aggregations,
          getAvailableFiltersFrom: getters['getAvailableFiltersFrom'],
          updatePriceAggregation
        });
        commit(
          'category-next/SET_SEARCH_FILTER',
          { id: '-1', filters: aggregationFilters },
          { root: true }
        );
        commit(types.ADD_PLP_PRODUCTS_TOTAL_COUNT, {
          totalResults: total.value
        });

        if (queryParams.includes('price=')) {
          const priceRangeValue = getters['priceFilterRangeValues'];
          if (!priceRangeValue?.minPrice && !priceRangeValue?.maxPrice) {
            commit(types.PRICE_FILTER_RANGE_VALUES, {
              minPrice: aggregations['agg_min_price']?.value,
              maxPrice: aggregations['agg_max_price']?.value
            });
          }
        } else {
          commit(types.PRICE_FILTER_RANGE_VALUES, {
            minPrice: aggregations['agg_min_price']?.value,
            maxPrice: aggregations['agg_max_price']?.value
          });
        }
        commit(types.ADD_PLP_PRODUCTS, { products: isServer ? items.slice(0, 8) : items });
        setPlpStoryData(storyBlokContent, commit)
        commit(types.SET_PLP_PRODUCTS_LOADING, { isLoading: false });
        return { items, total, aggregations };
      }

      const resp = await fetchProductsByUrlKey(
        urlKey,
        storeCode,
        pageSize,
        agg,
        queryParams
      );
      if (resp.code !== 200) {
        throw new Error(resp.result);
      } else {
        const isRestricted = resp?.result?.isRestricted || false;
        commit(types.SET_RESTRICTED_CATEGORY, { isRestricted });
        const results = handleResult(resp?.result, entityType, 0, 40);
        const { items, total, aggregations, storyBlokContent } = results;

        const aggregationFilters = getFilters({
          aggregations: aggregations,
          getAvailableFiltersFrom: getters['getAvailableFiltersFrom'],
          updatePriceAggregation
        });
        commit(
          'category-next/SET_SEARCH_FILTER',
          { id: '-1', filters: aggregationFilters },
          { root: true }
        );
        commit(types.ADD_PLP_PRODUCTS_TOTAL_COUNT, {
          totalResults: total.value
        });
        if (queryParams.includes('price=')) {
          const priceRangeValue = getters['priceFilterRangeValues'];
          if (!priceRangeValue?.minPrice && !priceRangeValue?.maxPrice) {
            commit(types.PRICE_FILTER_RANGE_VALUES, {
              minPrice: aggregations['agg_min_price']?.value,
              maxPrice: aggregations['agg_max_price']?.value
            });
          }
        } else {
          commit(types.PRICE_FILTER_RANGE_VALUES, {
            minPrice: aggregations['agg_min_price']?.value,
            maxPrice: aggregations['agg_max_price']?.value
          });
        }
        const products = isServer ? items.slice(0, 8) : items
        commit(types.ADD_PLP_PRODUCTS, { products });
        results['isRestricted'] = isRestricted;

        commit(types.ADD_PLP_CACHE_PRODUCTS, { results, key });
        setPlpStoryData(storyBlokContent, commit)
        if (shouldPopulateCacheTags) {
          await rootStore.dispatch('cache-tags/addTags', { tags: items.map((product) => `P${product.id}`) })
        }
        commit(types.SET_PLP_PRODUCTS_LOADING, { isLoading: false });
        return results;
      }
    } catch (error) {
      console.error('getProductsByUrlKey', error);
    }
  },
  async getProductsByKeyword (
    { commit, getters },
    { keyword, pageSize, agg = false, queryParams = '' }
  ) {
    try {
      commit(types.SET_SEARCH_EMPTY_RESULT, { isEmpty: false })
      commit(types.SET_SEARCH_LOADING, { loading: true });
      const { storeCode } = currentStoreView();
      const key = (keyword + storeCode + pageSize + queryParams).replace(
        / /g,
        '_'
      );
      const resultsList = getters.getSearchCacheResults;
      if (resultsList.length) {
        const cachedResult = resultsList.find(item => {
          return item[key];
        });
        if (cachedResult) {
          const { items, total, aggregations, allOutOfStock } = cachedResult[key]
          const aggregationFilters = getFilters({
            aggregations: aggregations,
            getAvailableFiltersFrom: getters['getAvailableFiltersFrom'],
            updatePriceAggregation
          });

          commit(types.SET_IS_ALL_OOS, { isAllOOS: allOutOfStock });
          commit(types.ADD_SEARCH_PRODUCTS, { products: items });
          commit(types.SET_SEARCH_KEYWORD, { keyword });
          commit(types.SET_SEARCH_PRODUCT_COUNT, { count: total.value });
          if (total.value === 0) {
            commit(types.SET_SEARCH_EMPTY_RESULT, { isEmpty: true })
          }
          commit(
            'category-next/SET_SEARCH_FILTER',
            { id: '-1', filters: aggregationFilters },
            { root: true }
          );
          if (queryParams.includes('price=')) {
            const priceRangeValue = getters['priceFilterRangeValues'];
            if (!priceRangeValue?.minPrice && !priceRangeValue?.maxPrice) {
              commit(types.PRICE_FILTER_RANGE_VALUES, {
                minPrice: aggregations['agg_min_price']?.value,
                maxPrice: aggregations['agg_max_price']?.value
              });
            }
          } else {
            commit(types.PRICE_FILTER_RANGE_VALUES, {
              minPrice: aggregations['agg_min_price']?.value,
              maxPrice: aggregations['agg_max_price']?.value
            });
          }
          commit(types.SET_SEARCH_LOADING, { loading: false });

          return cachedResult[key];
        }
      }
      const resp = await fetchProductsByKeyword(
        keyword,
        storeCode,
        pageSize,
        agg,
        queryParams
      );
      if (resp.resultCode !== 200) {
        commit(types.SET_SEARCH_LOADING, { loading: false });
        commit(types.SET_SEARCH_EMPTY_RESULT, { isEmpty: false })
        throw new Error(resp.result);
      } else {
        if (resp?.result?.search_redirect_url) {
          commit(types.SEARCH_KEYWORD_REDIRECT_URL, { redirectUrl: resp.result.search_redirect_url });
          commit(types.SET_SEARCH_LOADING, { loading: false });
          commit(types.SET_SEARCH_KEYWORD, { keyword });
          commit(types.SET_SEARCH_EMPTY_RESULT, { isEmpty: false });
          setSearchHistoryText(keyword);
          return;
        }

        commit(types.SEARCH_KEYWORD_REDIRECT_URL, { redirectUrl: '' });

        const results = handleResult(resp?.result, entityType, 0, 40);

        const aggregationFilters = getFilters({
          aggregations: results.aggregations,
          getAvailableFiltersFrom: getters['getAvailableFiltersFrom'],
          updatePriceAggregation
        });

        commit(types.SET_IS_ALL_OOS, { isAllOOS: results.allOutOfStock });
        commit(types.ADD_SEARCH_PRODUCTS, { products: results.items });
        // commit(types.ADD_SEARCH_CACHE_PRODUCTS, { results, key });
        commit(types.SET_SEARCH_KEYWORD, { keyword });
        commit(types.SET_SEARCH_PRODUCT_COUNT, {
          count: results.total.value || 0
        });
        if (results.total.value === 0) {
          commit(types.SET_SEARCH_EMPTY_RESULT, { isEmpty: true })
        }
        commit(
          'category-next/SET_SEARCH_FILTER',
          { id: '-1', filters: aggregationFilters },
          { root: true }
        );
        if (queryParams.includes('price=')) {
          const priceRangeValue = getters['priceFilterRangeValues'];
          if (!priceRangeValue?.minPrice && !priceRangeValue?.maxPrice) {
            commit(types.PRICE_FILTER_RANGE_VALUES, {
              minPrice: results?.aggregations['agg_min_price']?.value,
              maxPrice: results?.aggregations['agg_max_price']?.value
            });
          }
        } else {
          commit(types.PRICE_FILTER_RANGE_VALUES, {
            minPrice: results?.aggregations['agg_min_price']?.value,
            maxPrice: results?.aggregations['agg_max_price']?.value
          });
        }
        commit(types.SET_SEARCH_LOADING, { loading: false });
        setSearchHistoryText(keyword);
        return results;
      }
    } catch (error) {}
  },
  async getProductsByCategoryIds (
    { commit, getters, dispatch, rootState },
    { categoryIds, pageSize = 10 }
  ) {
    try {
      const { storeCode } = currentStoreView();
      const resp = await fetchProductsByCategoryIds(
        categoryIds,
        storeCode,
        pageSize
      );
      if (resp.resultCode !== 200) throw new Error(resp.result);
      return handleResult(resp?.result, entityType, 0, 40);
    } catch (error) {}
  },
  async loadSimilarProducts (
    { commit, getters, dispatch, rootState },
    { colorSku = '' }
  ) {
    const { storeCode } = currentStoreView();
    commit(types.SET_SIMILAR_PRODUCTS_LOADING, { isLoading: true });
    const resp = await fetchSimilarProducts(colorSku, storeCode);
    if (resp.status !== 200) {
      throw new Error('Error! fetching similar products');
    }
    const data = await resp.json();
    const { items = [] } = handleResult(data?.result, entityType);
    commit(types.SET_SIMILAR_PRODUCTS_LOADING, { isLoading: false });
    commit(types.ADD_SIMILAR_PRODUCTS, { items });
    return items;
  },
  async getProductAttributes ({ dispatch }, { product }) {
    const productFields = Object.keys(product).filter(fieldName => {
      return !config.entities.product.standardSystemFields.includes(fieldName);
    });
    const {
      product: { useDynamicAttributeLoader },
      optimize,
      attribute
    } = config.entities;
    return dispatch(
      'attribute/list',
      {
        filterValues: useDynamicAttributeLoader ? productFields : null,
        only_visible: !!useDynamicAttributeLoader,
        only_user_defined: true,
        includeFields: optimize ? attribute.includeFields : null
      },
      { root: true }
    );
  },
  async getProductDetails ({ commit, dispatch }, {
    sku = '',
    urlKey = '',
    colorSelected = '',
    sizeSelected = '',
    fetchAttributes = true
  }) {
    try {
      const { storeCode } = currentStoreView();
      commit(types.SET_PRODUCT_DETAILS_LOADING, { isLoading: true });
      const resp = await fetchProductDetails(urlKey, sku, storeCode);
      if (resp.status !== 200) {
        throw new Error('Error! fetching products details');
      }
      const data = await resp.json();
      const { items } = handleResult(data?.result, entityType);
      const products = prepareProducts(items);
      let preConfigureProduct = {
        ...(colorSelected && {
          color: {
            id: colorSelected
          }
        }),
        ...(sizeSelected && {
          size: {
            id: sizeSelected
          }
        })
      };

      const configuredProducts = await createConfiguredProducts(products, preConfigureProduct);

      const product = configuredProducts[0];
      // GWP product
      if (product?.final_price === 0 || product?.price === 0) {
        throw new Error('Error loading GWP product');
      }
      const childTags = product?.configurable_children?.map(({ id }) => `P${id}`);
      await rootStore.dispatch('cache-tags/addTags', { tags: [`P${product.id}`, `P${product.parentId}`, ...childTags] })
      const { configuration, ...restProduct } = product;
      const productUpdated = Object.assign({}, restProduct);
      commit(types.SET_PRODUCT_DETAILS, { result: productUpdated });

      if (fetchAttributes) {
        await dispatch('getProductAttributes', { product });
      }

      commit(types.SET_CURRENT_CONFIGURATION, configuration || {});
      commit(types.SET_PRODUCT_GALLERY, {
        gallery: getProductGallery(product)
      });
      commit(types.SET_PRODUCT_DETAILS_LOADING, { isLoading: false });
      await EventBus.$emitFilter('product-after-load', {})

      return product;
    } catch (e) {
      throw new Error(e);
    }
  },
  async updateCurrentConfiguration ({ commit, getters, dispatch, rootState }, filterOption) {
    const currentConfig = getters.getProductConfiguration;
    const currentProduct = getters.getProductDetails;
    let changedConfig = Object.assign({}, currentConfig, {
      [filterOption.attribute_code]: filterOption
    });
    let configuredProducts = await createConfiguredProducts(
      [currentProduct],
      changedConfig
    );
    if (!configuredProducts) {
      const selectedProduct = currentProduct.configurable_children.find(
        item => item.color === Number(changedConfig.color.id)
      );
      changedConfig.size = {
        attribute_code: 'size',
        id: selectedProduct.size.toString(),
        label: selectedProduct.size
      };
      configuredProducts = await createConfiguredProducts(
        [currentProduct],
        changedConfig
      );
    }
    const product = configuredProducts[0];
    const { configuration, ...restProduct } = product;
    const productUpdated = Object.assign({}, restProduct);
    commit(types.SET_PRODUCT_DETAILS, { result: productUpdated });
    commit(types.SET_CURRENT_CONFIGURATION, configuration || {});
  },
  async getCategories ({ commit }, { categoryIds, commitType }) {
    const entityType = 'category'
    const { storeCode } = currentStoreView();
    if (!storeCode) {
      throw new Error('Megamenu: Storecode missing')
    }
    const categoryData = await fetchMegaMenu({ categoryIds, storeCode });
    if (categoryData) {
      const { items } = handleResult(categoryData?.result, entityType);
      commit(commitType, { categories: items });
    }
  },
  async initMegaMenuTopLevel ({ getters, dispatch }) {
    try {
      const topLevelId = config.entities.category.categoriesRootCategorylId
      await dispatch('getCategories', {
        categoryIds: [topLevelId],
        commitType: types.SET_MEGAMENU_CATEGORIES
      })
    } catch (e) {
      throw new Error(e);
    }
  },
  async getMegaMenuCategories ({ getters, dispatch }) {
    try {
      const availableIds = getters.getAvailableCategories.map((item) => item.id);
      await dispatch('getCategories', {
        categoryIds: availableIds,
        commitType: types.SET_MEGAMENU_SUBCATEGORIES
      })
    } catch (e) {
      throw new Error(e);
    }
  },
  async loadProductBreadcrumbs ({ commit }, { product }) {
    const parentSku = product?.type_id === 'configurable' ? product?.sku?.split('-')?.[0] : product.sku
    if (!parentSku) {
      return
    }
    const { storeCode } = currentStoreView()
    const { result: productBreadCrumbs } = await fetchProductBreadCrumbs({ productSku: parentSku, storeCode })
    commit(types.SET_PRODUCT_BREADCRUMBS, { productBreadCrumbs })
  },
  async loadCategoryBreadcrumbs ({ commit, getters }, { category }) {
    const isSameCategory = category.id === getters.getCurrentCategory?.id
    if (isSameCategory) {
      return;
    }

    const { storeCode } = currentStoreView()
    const { result: categoryBreadCrumbs } = await fetchCategoryBreadCrumbs({ urlPath: category.url_path, storeCode });
    commit(types.SET_CATEGORY_BREADCRUMBS, { categoryBreadCrumbs })
    commit(types.SET_CURRENT_CATEGORY, { category })
  },
  async getProductsBySku ({ getters }, { skus, inStockOnly = false }) {
    try {
      if (!skus.length) return
      const entityType = 'getRelatedProductsBySku'
      const { storeCode } = currentStoreView();
      const resp = await fetchProductsBySkus({ skus, storeCode, inStockOnly })
      if (resp.status !== 200) {
        throw new Error('Error! fetching getProductsBySku');
      }
      const data = await resp.json();
      const { result } = data
      const results = handleResult(result, entityType);
      const { items } = results
      return items
    } catch (error) {
      console.error('getProductsBySku', error);
    }
  },
  async loadRecentlyViewedProducts ({ commit, dispatch }) {
    try {
      const skuList = getRecentlyViewedSku();
      if (!skuList?.length) return
      const items = await dispatch('getProductsBySku', { skus: skuList, inStockOnly: true });
      const recentlyViewedProductList = items?.map(item => {
        return {
          ...item,
          recentlyViewedPos: skuList.indexOf(item.sku)
        }
      })?.sort((a, b) => {
        return b.recentlyViewedPos - a.recentlyViewedPos;
      }) || [];
      commit(types.SET_RECENTLY_VIEWED_PRODUCTS, recentlyViewedProductList)
    } catch (error) {
      console.error(error, 'loadRecentlyViewedProducts');
    }
  },
  async fetchServerTime ({ commit }) {
    try {
      const date = await fetchServerTime();
      commit(types.SET_SERVER_TIME, { serverTime: new Date(date), timeWhenServerTimeFetched: new Date() })
    } catch (error) {
      console.error(error, 'getServerTime');
    }
  }
};

export default actions;
