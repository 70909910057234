export const COUNTRY_CODE_STORAGE_KEY = 'currentShoppingCountryCode'
export const COLOR_FAMILY = 'configurable_children.color_family'
export const STOCK = 'stock.is_in_stock'
export const CC_IMS_STOCK = 'configurable_children.imsStock.is_in_stock';
export const IMS_STOCK = 'imsStock.is_in_stock';
export const COLORS = 'colors'
export const SELECTED = 'selected'
export const FILTER_EVENT = 'filter'
export const SORT = 'sort'
export const PURCHASE_EVENT = 'purchase'
export const DISPLAY_CURRENCY = 'displayCurrency'
export const CART = 'cart'
export const shopTheLookLabel = 'Shop The Look'
export const similarProductsLabel = 'You May Also Like'
export const internationalCourier = 'International Courier'
export const MEMBER = 'member'
export const SILVER = 'silver'
export const GOLD = 'gold'
export const DATE_FORMAT = 'DD/MM/YYYY'
export const ADYEN_HPP = 'adyen_hpp'
export const ALIPAY_HK = 'alipay_hk'
export const GRABPAY_MY = 'grabpay_MY'
export const STRIPE_CARDS = 'stripe-cards'
export const ADYEN_ONECLICK = 'adyen_oneclick'
export const FREE = 'free'
export const HOME_DELIVERY = 'HOME DELIVERY'
export const SELF_COLLECTION = 'SELF COLLECTION'
export const POP_STATION = 'popstation'
export const SHIP_TO_STORE = 'shiptostore'
export const EXPRESS = 'express'
export const ADYEN_POS_CLOUD = 'adyen_pos_cloud'
export const MIDTRANS_SNAP = 'snap'
export const STICKY_CHECKOUT_80 = 'ff_sticky_checkout_80'
export const STICKY_CHECKOUT_20 = 'ff_sticky_checkout_20'
export const ATC_CHECKOUT_80 = 'ff_sticky_atc_80'
export const ATC_CHECKOUT_20 = 'ff_sticky_atc_control_20'
export const BESTSELLER_URL_PATH = 'shop/highlights/bestsellers';
export const MAX_SELECTABLE_QUANTITY = 5;
export const STRIPE = 'stripe_payments'
export const HOOLAH = 'hoolah'
export const APPLE_PAY = 'applepay'
export const GOOGLE_PAY = 'googlepay'
export const PAY_NOW = 'paynow'
export const GCASH = 'gcash'
export const RECENTLY_VIEWED = 'Recently Viewed Items'
export const RECENTLY_VIEWED_KEY = 'recently-viewed'
export const SEARCH_HISTORY = 'search-history'
export const SIMILAR_PRODUCT = 'Product Similarity'
export const BAG_LIMIT_EXCEEDED_MSG = 'You may add up to 5 of this item to bag. Please check the quantity in your bag and try again.'
export const STAPLES_CATEGORY_ID = 2075
export const GIFT_CARD_TYPE = 'giftcard'
export const CONFIG_TYPE = 'configurable'
export const SIMPLE_TYPE = 'simple'
export const UNAUTHORIZED_CODE = 401
export const GTM_EVENT = {
  recentlyViewedImpression: 'impression_product_rv_pdp',
  recentlyViewedClick: 'click_product_rv_pdp',
  recentlyViewedImpressionCart: 'impression_product_rv_cart',
  cartPageProductCarousel: 'impression_product_reco_cart',
  miniCartPageProductCarousel: 'impression_product_reco_minicart',
  ymalClick: 'ymal_aws_pdp_product',
  productClick: 'productClick',
  stlClick: 'click_product_stl_pdp',
  stlImpression: 'impression_product_stl_pdp',
  similarClick: 'click_product_similarity',
  similarImpression: 'impression_product_similarity',
  recentlyViewedClickCart: 'click_product_rv_cart',
  carouselProductClickCart: 'click_product_reco_cart',
  carouselProductClickMiniCart: 'click_product_reco_minicart',
  carouselProductClickExpandCart: 'click_expand_reco_cart',
  carouselProductClickCollapseCart: 'click_collapse_reco_cart'
}
export const REVIEW_STRING = {
  uploadImageText: 'Upload Image(s)',
  requiredInfo: 'Required fields are marked with (*)',
  required: 'Required',
  reviewTitle: 'Review title',
  review: 'Review',
  submitReview: 'Submit Review',
  submitReviewNext: 'Submit & Review Next Item',
  uploadSuccess: 'Review added successfully',
  uploadError: 'Something went wrong. Try again in a few seconds.',
  imageSizeLimit: 'Select image less than 2MB',
  imageCountLimit: 'Please select maximum of 5 images',
  reviewTnc: 'By pressing submit, you acknowledge and consent to the collection and use of the personal data you have provided in accordance with LB’s',
  reviewPointTnc: '* For each valid review only. All submissions will be reviewed by our Customer Care team within 2 to 5 business days.',
  fitTitle: 'fit',
  bust: 'your bust measurements',
  waist: 'your waist measurements',
  hip: 'your hip measurements',
  height: 'your height',
  ratingError: 'Please select a rating',
  ratingTitle: 'overall rating',
  bannerTitle: 'my review for:',
  viewAllReviews: 'VIEW ALL REVIEWS',
  backText: 'Back To My Order',
  titleLength: 'Please enter a max of 100 characters',
  reviewMinLength: 'Please enter 50 characters or more',
  reviewTitlePlaceholder: 'Give your review a summary',
  reviewDetailsPlaceholder: 'Share with us your thoughts on the size and fit, material, styling, and more!'
}

export const CHECKOUT_TAB = {
  PersonalDetails: 'personalDetails',
  Shipping: 'shipping',
  Payment: 'payment',
  OrderReview: 'orderReview',
  LoginSignup: 'login-signup'
};

export const emarsysCurrencyEvents = {
  purchase: true,
  cart: true
}

export const bankIssuers = [
  {
    icon: 'payment-bankislam',
    issuerCode: 'fpx_bimb'
  },
  {
    icon: 'payment-uobbank',
    issuerCode: 'fpx_uob'
  },
  {
    icon: 'payment-cimbclicks',
    issuerCode: 'fpx_cimbclicks'
  },
  {
    icon: 'payment-kuwaitfinancehouse',
    issuerCode: 'fpx_kfh'
  },
  {
    icon: 'payment-rhbbank',
    issuerCode: 'fpx_rhb'
  },
  {
    icon: 'payment-alliancebank',
    issuerCode: 'fpx_abmb'
  },
  {
    icon: 'payment-amonline',
    issuerCode: 'fpx_amb'
  },
  {
    icon: 'payment-hsbc',
    issuerCode: 'fpx_hsbc'
  },
  {
    icon: 'payment-affinbank',
    issuerCode: 'fpx_abb'
  },
  {
    icon: 'payment-ocbcbank',
    issuerCode: 'fpx_ocbc'
  },
  {
    icon: 'payment-bankpublic',
    issuerCode: 'fpx_pbb'
  },
  {
    icon: 'payment-standardchartered',
    issuerCode: 'fpx_scb'
  },
  {
    icon: 'payment-simpanannasional',
    issuerCode: 'fpx_bsn'
  },
  {
    icon: 'payment-maybank',
    issuerCode: 'fpx_mb2u'
  },
  {
    icon: 'payment-hongleongconnect',
    issuerCode: 'fpx_hlb'
  },
  {
    icon: 'payment-bankmuamalat',
    issuerCode: 'fpx_bmmb'
  },
  {
    icon: 'payment-agrobank',
    issuerCode: 'fpx_agrobank'
  },
  {
    icon: 'payment-bankrakyat',
    issuerCode: 'fpx_bkrm'
  }
];

export const freeShippingThreshold = {
  'AUS': 75,
  'CAN': 75,
  'CHN': 100,
  'TWN': 60,
  'NZL': 100,
  'SAU': 100,
  'PHL': 3800,
  'KOR': 100,
  'ARE': 100,
  'VNM': 100,
  'BRN': 100,
  'MMR': 100,
  'USA': 75,
  'JPN': 75,
  'MAC': 350,
  'HKG': 350,
  'SGP': 60,
  'MYS': 200
}

export const routeIdentifiers = {
  'home': 'home',
  'sg-headless-home': 'sg-headless-home',
  'my-headless-home': 'my-headless-home',
  'id-headless-home': 'id-headless-home',
  'general-size-charts': 'general-size-charts',
  'promotions': 'promotions',
  'privacy-policy': 'privacy-policy',
  'lb-anniversary': 'lb-anniversary',
  'terms-and-conditions': 'terms-and-conditions',
  'lbcommunity-plus': 'promotions/lbcommunity-plus',
  'welcome-home-lbcommunity': 'welcome-home-lbcommunity',
  'ongoing-offers': 'promotions/ongoing-offers',
  'fall-winter-styles': 'fall-winter-styles',
  'new-user-sign-up-japan': 'new-user-sign-up-japan',
  'new-user-sign-up-special-promotion-worldwide': 'new-user-sign-up-special-promotion-worldwide'
}

export const loyaltyPointValues = {
  'hk': 5,
  'sg': 1,
  'my': 3,
  'intl': 1,
  'us': 1,
  'jp': 100,
  'id': 10000,
  'ph': 40
}

export const minimumSpendRequireForGoldTier = {
  'sg': 1200,
  'my': 3600,
  'us': 850,
  'hk': 6000,
  'intl': 850,
  'id': 11000000,
  'ph': 50000
}

export const minimumSpendRequireForSilverTier = {
  'sg': 500,
  'my': 1500,
  'us': 400,
  'hk': 2500,
  'intl': 400,
  'id': 4500000,
  'ph': 20000
}

export const paymentMethods = {
  free: {
    component: 'MFree',
    gtmEvent: 'click_payment_storecredits'
  },
  adyen_cc: {
    component: 'MAdyenCard',
    gtmEvent: 'payment_creditcard'
  },
  paypal_express: {
    component: 'MPaypal',
    gtmEvent: 'payment_paypal'
  },
  adyen_oneclick: {
    component: 'MAdyenOneClick',
    gtmEvent: 'click_payment_savedcc'
  },
  adyen_hpp_sg: {
    component: 'MPaynow',
    gtmEvent: 'payment_paynow'
  },
  adyen_hpp: {
    component: 'MKlarna',
    gtmEvent: 'click_payment_klarna'
  },
  adyen_hpp_hk: {
    component: 'MAlipayHk',
    gtmEvent: 'click_payment_alipayhk'
  },
  adyen_hpp_my: {
    component: 'MEBanking',
    gtmEvent: 'click_payment_ebanking'
  },
  alipay_hk: {
    component: 'MAlipayHk',
    gtmEvent: 'click_payment_alipayhk'
  },
  grabpay_MY: {
    component: 'MGrabpayMy',
    gtmEvent: 'click_payment_grabpay'
  },
  ebanking: {
    component: 'MEBanking',
    gtmEvent: 'click_payment_ebanking'
  },
  adyen_pos_cloud: {
    component: 'MPOS',
    gtmEvent: null
  },
  snap_cc: {
    component: 'MMidtransCard',
    gtmEvent: null
  },
  snap_va: {
    component: 'MMidtransVA',
    gtmEvent: null
  },
  hoolah: {
    component: 'MHoolah',
    gtmEvent: 'click_payment_shopback'
  },
  applepay: {
    component: 'MApplePay',
    gtmEvent: 'click_apple_pay'
  }
}

export const paymentMethodsExceptIdV1 = {
  free: {
    component: 'MFree',
    gtmEvent: 'payment_storecredits'
  },
  adyen_cc: {
    component: 'MAdyenCard',
    gtmEvent: 'payment_creditcard'
  },
  adyen_hpp_sg: {
    component: 'MPaynow',
    gtmEvent: 'payment_paynow'
  },
  paypal_express: {
    component: 'MPaypal',
    gtmEvent: 'payment_paypal'
  },
  adyen_oneclick: {
    component: 'MAdyenOneClick',
    gtmEvent: 'payment_savedcc'
  },
  adyen_hpp: {
    component: 'MKlarna',
    gtmEvent: 'payment_klarna'
  },
  adyen_hpp_hk: {
    component: 'MAlipayHk',
    gtmEvent: 'payment_alipayhk'
  },
  adyen_hpp_my: {
    component: 'MEBanking',
    gtmEvent: 'payment_ebanking'
  },
  adyen_hpp_ph: {
    component: 'MGcash',
    gtmEvent: 'payment_gcash'
  },
  alipay_hk: {
    component: 'MAlipayHk',
    gtmEvent: 'payment_alipayhk'
  },
  grabpay_MY: {
    component: 'MGrabpayMy',
    gtmEvent: 'payment_grabpay'
  },
  ebanking: {
    component: 'MEBanking',
    gtmEvent: 'payment_ebanking'
  },
  adyen_pos_cloud: {
    component: 'MPOS',
    gtmEvent: null
  },
  hoolah: {
    component: 'MHoolah',
    gtmEvent: 'payment_shopback'
  },
  applepay: {
    component: 'MApplePay',
    gtmEvent: 'payment_applepay'
  },
  googlepay: {
    component: 'MGooglePay',
    gtmEvent: 'payment_googlepay'
  },
  gcash: {
    component: 'MGcash',
    gtmEvent: 'payment_gcash'
  }
}

export const paymentMethodsIDV1 = {
  free: {
    component: 'MFree',
    gtmEvent: 'payment_storecredits'
  },
  snap_cc: {
    component: 'MMidtransCard',
    gtmEvent: 'payment_midtranscc'
  },
  snap_va: {
    component: 'MMidtransVA',
    gtmEvent: 'payment_virtualbank'
  }
}

export const includedTaxLabels = {
  AU: 'Australian Tax (10%) (Included)',
  NZ: 'New Zealand Tax (15%) (Included)',
  MY: 'Tax (10%) (Included)',
  SG: 'GST (9%) (Included)'
}

export const includedTax = {
  AU: true,
  NZ: true,
  SG: true,
  MY: true
}

export const storeSelector = [
  {
    name: 'Singapore',
    image: 'store_thumbnail_sg.jpg',
    path: `/stores?tab=sg`
  },
  {
    name: 'Malaysia',
    image: 'store_thumbnail_my.jpg',
    path: `/stores?tab=my`
  },
  {
    name: 'Indonesia',
    image: 'store_thumbnail_indo.jpg',
    path: `/stores?tab=id`
  },
  {
    name: 'Cambodia',
    image: 'store_thumbnail_cam.jpg',
    path: `/stores?tab=cd`
  },
  {
    name: 'Hong Kong',
    image: 'store_thumbnail_hk.jpg',
    path: `/stores?tab=hk`
  }
]

export const tabIndexMapping = {
  sg: 0,
  my: 1,
  id: 2,
  cd: 3,
  hk: 4
}

export const surveyLinkMapping = {
  sg: 'https://forms.gle/7Gyf7hGT1XVwCKGp8',
  my: 'https://forms.gle/bEu1qBin81AAqKMCA',
  intl: 'https://forms.gle/6yd1UfZ88B9Yoqrn8',
  us: 'https://forms.gle/6yd1UfZ88B9Yoqrn8',
  jp: 'https://docs.google.com/forms/d/1wgVuXmeUBeWNkUV6ItuCjum5CpQZzEtJK_fZLCtqsvQ/viewform',
  hk: 'https://forms.gle/wugfom9iokvEFWuF7',
  id: 'https://docs.google.com/forms/d/e/1FAIpQLSeufl_H8P4oS1hQig1wft1dYfp5Fk7R-Y8EfutsS5XfQknC9A/viewform'
}

export const storesShortCode = {
  intl: 'intl',
  us: 'us',
  hk: 'hk',
  jp: 'jp',
  sg: 'sg',
  my: 'my',
  id: 'id',
  ph: 'ph'
}

export const midtransVirtualPaymentMethods = [
  {
    icon: 'payment-bca',
    name: 'bca',
    code: 'bca',
    paymentType: 'bank_transfer'
  },
  {
    icon: 'payment-bni',
    name: 'bni',
    code: 'bni',
    paymentType: 'bank_transfer'
  },
  {
    icon: 'payment-mandiri',
    name: 'mandiri',
    code: 'echannel',
    paymentType: 'echannel'
  },
  {
    icon: 'payment-permata',
    name: 'permata',
    code: 'permata',
    paymentType: 'bank_transfer'
  },
  {
    icon: 'payment-other',
    name: 'other',
    code: 'bni',
    paymentType: 'bank_transfer'
  }
];

export const loyaltyButtonsGtmEvent = {
  joinSection: { join: 'click_popup_joinnow_header', login: 'click_popup_login_header' },
  rewardSection: { join: 'click_popup_joinnow_body', login: 'click_popup_login_body' },
  completeProfileSection: { join: 'click_popup_joinnow_footer', login: 'click_popup_login_footer' },
  checkoutHeaderJoinNow: { join: 'join_now_header_gcpc_section' },
  checkoutJoinNow: { join: 'join_now_gcpc_section' }
}

export const contactUsProblemCategories = [
  { name: 'What can we help you with?', value: '', disabled: true, selected: true }, // Placeholder

  { name: 'General Enquiries', value: 'general_enquiry', disabled: false },
  { name: 'Partnerships & Collaborations', value: 'partnership_enquiries', disabled: false },
  { name: 'Careers', value: 'careers', disabled: false },
  { name: 'Feedback', value: 'feedback', disabled: false },
  { name: 'Sustainability', value: 'sustainability_', disabled: false },
  { name: 'Account Deletion/Unsub from Mailing List', value: 'account_deletion/unsub_from_mailing_list', disabled: false }
];

export const storeEmails = {
  'intl': 'hello@lovebonito.com',
  'us': 'hello@lovebonito.com',
  'sg': 'hellosg@lovebonito.com',
  'my': 'hellomy@lovebonito.com',
  'hk': 'hellohk@lovebonito.com',
  'jp': 'hellojp@lovebonito.com',
  'id': 'customercare.id@lovebonito.com'
}

export const rewardPointsRangeFilter = [
  '0', '500', '1000', '1500', '2000', '2500', '3000+'
];

export const REWARD_EXPIRY_DATE_FORMAT = 'DD MMM YYYY';

export const groupedButtons = {
  DELIVERY: 'delivery',
  RETURNS: 'returns'
};

export const faqPdpLinks = {
  sg: {
    orderAndDelivery: 'https://faq-sg.lovebonito.com/hc/en-us/categories/4647762545945-Order-Delivery',
    returns: 'https://faq-sg.lovebonito.com/hc/en-us/categories/4647757548825-Return-Exchanges'
  },
  hk: {
    orderAndDelivery: 'https://faq-hk.lovebonito.com/hc/en-us/categories/4709459017881-Order-Delivery',
    returns: 'https://faq-hk.lovebonito.com/hc/en-us/categories/4709562715545-Return-Exchanges'
  },
  intl: {
    orderAndDelivery: 'https://faq-intl.lovebonito.com/hc/en-us/categories/4690229094553-Order-Delivery',
    returns: 'https://faq-intl.lovebonito.com/hc/en-us/categories/4690271339673-Return-Exchanges'
  },
  us: {
    orderAndDelivery: 'https://faq-usa.lovebonito.com/hc/en-us/categories/14935319547161-Order-Delivery',
    returns: 'https://faq-usa.lovebonito.com/hc/en-us/categories/14935344163993-Return-Exchanges'
  },
  jp: {
    orderAndDelivery: 'https://faq-jp.lovebonito.com/hc/ja/categories/7734031842201-%E6%B3%A8%E6%96%87%E3%81%A8%E9%85%8D%E9%80%81',
    returns: 'https://faq-jp.lovebonito.com/hc/ja/categories/7741576745625-%E8%BF%94%E5%93%81%E3%81%A8%E4%BA%A4%E6%8F%9B'
  },
  my: {
    orderAndDelivery: 'https://faq-my.lovebonito.com/hc/en-us/categories/5086969609881-Order-Delivery',
    returns: 'https://faq-my.lovebonito.com/hc/en-us/categories/5086949652889-Return-Exchanges'
  },
  id: {
    orderAndDelivery: 'https://www.lovebonito.com/id/faq/delivery-process',
    returns: 'https://www.lovebonito.com/id/faq/return-policy'
  }
}

export const faqViewAllLinkMapping = {
  sg: 'https://faq-sg.lovebonito.com/hc/en-us/categories/4647276558489-LBCommunity-',
  my: 'https://faq-my.lovebonito.com/hc/en-us/categories/5086934062489-LB-Community-',
  intl: 'https://faq-intl.lovebonito.com/hc/en-us/categories/4690271965721-LBCommunity-',
  us: 'https://faq-intl.lovebonito.com/hc/en-us/categories/4690271965721-LBCommunity-',
  jp: 'https://faq-jp.lovebonito.com/hc/ja',
  hk: 'https://faq-hk.lovebonito.com/hc/en-us/categories/4709554720409-LBCommunity-',
  id: 'https://faq-sg.lovebonito.com/hc/en-us/categories/4647276558489-LBCommunity-'
}

export const returnsAndPolicyLinks = {
  sg: 'https://faq-sg.lovebonito.com/hc/en-us/articles/4649103858713-How-do-I-return-my-order',
  my: 'https://faq-my.lovebonito.com/hc/en-us/articles/5088656199961-How-do-I-return-my-order',
  intl: 'https://faq-intl.lovebonito.com/hc/en-us/articles/4694281127193-What-is-your-Return-Policy',
  us: 'https://faq-usa.lovebonito.com/hc/en-us/articles/14936074317593-USA-Return-Process',
  jp: 'https://faq-jp.lovebonito.com/hc/ja/articles/7741655064217-%E6%B3%A8%E6%96%87%E5%93%81%E3%82%92%E8%BF%94%E5%93%81%E3%81%99%E3%82%8B%E3%81%93%E3%81%A8%E3%81%AF%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B-',
  hk: 'https://faq-hk.lovebonito.com/hc/en-us/articles/4711889496601-How-do-I-return-my-online-order',
  id: 'https://faq-id.lovebonito.com/hc/id-id/articles/15748692016153-Dapatkah-saya-mengembalikan-produk-yang-sudah-saya-beli',
  ph: 'https://faq-ph.lovebonito.com/hc/en-us/articles/25859912033561-Return-Policy'
}

export const referAFriendRoutes = {
  sg: '/faq?categoryId=4647276558489&sectionId=24604303074201&articleId=24342295885593',
  us: '/faq?categoryId=14935398359065&sectionId=24606169496729&articleId=24342811470873',
  my: '/faq?categoryId=5086934062489&sectionId=24605078703897&articleId=24342425424665',
  intl: '/faq?categoryId=4690271965721&sectionId=24605874664345&articleId=24342675244697',
  hk: '/faq?categoryId=4709554720409&sectionId=24605591873049&articleId=24342491941145',
  id: '/faq?categoryId=23126837561369&sectionId=24781685881881&articleId=24781907784857',
  ph: '/faq?categoryId=25742460653721&sectionId=25861525571609&articleId=30500504967193'
};

export const referAFriendForRefereesRoutes = {
  sg: '/faq?categoryId=4647276558489&sectionId=24604303074201&articleId=30735136143001',
  us: '/faq?categoryId=14935398359065&sectionId=24606169496729&articleId=30736486007065',
  my: '/faq?categoryId=5086934062489&sectionId=24605078703897&articleId=30735845571481',
  intl: '/faq?categoryId=4690271965721&sectionId=24605874664345&articleId=24342675244697',
  hk: '/faq?categoryId=4709554720409&sectionId=24605591873049&articleId=30736241363481',
  id: '/faq?categoryId=23126837561369&sectionId=24781685881881&articleId=30737086118297',
  ph: '/faq?categoryId=25742460653721&sectionId=25861525571609&articleId=30736719038105'
};

export const postalCodeLength = {
  SG: 6,
  MY: 5,
  US: 5,
  JP: 7,
  AU: 4,
  PH: 4,
  TW: 6
}

export const suggestedCategories = [
  {
    name: 'New In',
    image: 'https://media.lovebonito.com/media/cta/emptybag/suggested_1.jpg',
    link: '/new-in'
  }, {
    name: 'Back In Stock',
    image: 'https://media.lovebonito.com/media/cta/emptybag/suggested_2.jpg',
    link: '/highlights/back-in-stock'
  }, {
    name: 'Dresses',
    image: 'https://media.lovebonito.com/media/cta/error/suggested_4.jpg',
    link: '/women/category/dresses'
  }
];

export const TEMP_CHECKOUT_REWARDS = [ {
  'id': 3,
  'label': 'Rewards',
  'title': 'DRINKUP',
  'points': 350,
  'description': 'Use your points to get a $5 Gift Card reward, redeemable online or offline with no minimum spend!',
  'image': 'https://www.lovebonito.com/cdn-cgi/image/width=792,quality=75,format=webp/https://public-images-for-media.s3.ap-southeast-1.amazonaws.com/media/catalog/product/t/h/th2684-031_gfjikdtesgn4ocpx.jpg',
  'coupon_code': 'GOTYOUCOVERED',
  'status': 1,
  'expires_at': null,
  'auto_apply': 0
},
{
  'id': 4,
  'label': 'Rewards',
  'title': 'FUN10-TEST',
  'points': 650,
  'description': 'Use your points to get a $10 Gift Card reward, redeemable online or offline with no minimum spend!',
  'image': 'https://www.lovebonito.com/cdn-cgi/image/width=572,quality=75,format=webp/https://public-images-for-media.s3.ap-southeast-1.amazonaws.com/media/catalog/product/y/j/yj0017-1_vstirppf0ikgxngg.jpg',
  'coupon_code': 'FUN10-TEST',
  'status': 1,
  'expires_at': '10/12/2022',
  'auto_apply': 0
},
{
  'id': 5,
  'label': 'Rewards',
  'title': '$20 LBCommunity Reward',
  'points': 1250,
  'description': 'Use your points to get a $20 Gift Card reward, redeemable online or offline with no minimum spend!',
  'image': 'https://www.lovebonito.com/assets/inhouse-loyalty/SG_Welcome.jpeg',
  'coupon_code': null,
  'status': 2,
  'expires_at': null,
  'auto_apply': 0
},
{
  'id': 7,
  'label': 'Rewards',
  'title': '$40 LBCommunity Reward',
  'points': 2400,
  'description': 'Use your points to get a $40 Gift Card reward, redeemable online or offline with no minimum spend!',
  'image': 'https://www.lovebonito.com/cdn-cgi/image/width=572,quality=75,format=webp/https://public-images-for-media.s3.ap-southeast-1.amazonaws.com/media/catalog/product/y/j/yj0017-1_vstirppf0ikgxngg.jpg',
  'coupon_code': null,
  'status': 3,
  'expires_at': null,
  'auto_apply': 0
},
{
  'id': 3,
  'name': '$5 LBCommunity Reward',
  'points': 350,
  'description': 'Use your points to get a $5 Gift Card reward, redeemable online or offline with no minimum spend!',
  'image': 'https://www.lovebonito.com/assets/inhouse-loyalty/SG_Welcome.jpeg',
  'coupon_code': null,
  'status': 2,
  'expires_at': null,
  'auto_apply': 0
},
{
  'id': 4,
  'name': '$10 LBCommunity Reward',
  'points': 650,
  'description': 'Use your points to get a $10 Gift Card reward, redeemable online or offline with no minimum spend!',
  'image': 'https://www.lovebonito.com/assets/inhouse-loyalty/SG_Welcome.jpeg',
  'coupon_code': null,
  'status': 2,
  'expires_at': null,
  'auto_apply': 0
},
{
  'id': 5,
  'name': '$20 LBCommunity Reward',
  'points': 1250,
  'description': 'Use your points to get a $20 Gift Card reward, redeemable online or offline with no minimum spend!',
  'image': 'https://www.lovebonito.com/cdn-cgi/image/width=572,quality=75,format=webp/https://public-images-for-media.s3.ap-southeast-1.amazonaws.com/media/catalog/product/y/j/yj0017-1_vstirppf0ikgxngg.jpg',
  'coupon_code': null,
  'status': 2,
  'expires_at': null,
  'auto_apply': 0
},
{
  'id': 7,
  'name': '$40 LBCommunity Reward',
  'points': 2400,
  'description': 'Use your points to get a $40 Gift Card reward, redeemable online or offline with no minimum spend!',
  'image': 'https://www.lovebonito.com/assets/inhouse-loyalty/SG_Welcome.jpeg',
  'coupon_code': null,
  'status': 2,
  'expires_at': null,
  'auto_apply': 0
},
{
  'id': 3,
  'name': '$5 LBCommunity Reward',
  'points': 350,
  'description': 'Use your points to get a $5 Gift Card reward, redeemable online or offline with no minimum spend!',
  'image': 'https://www.lovebonito.com/assets/inhouse-loyalty/SG_Welcome.jpeg',
  'coupon_code': null,
  'status': 2,
  'expires_at': null,
  'auto_apply': 0
},
{
  'id': 4,
  'name': '$10 LBCommunity Reward',
  'points': 650,
  'description': 'Use your points to get a $10 Gift Card reward, redeemable online or offline with no minimum spend!',
  'image': 'https://www.lovebonito.com/assets/inhouse-loyalty/SG_Welcome.jpeg',
  'coupon_code': null,
  'status': 2,
  'expires_at': null,
  'auto_apply': 0
},
{
  'id': 5,
  'label': 'Rewards',
  'title': '$20 LBCommunity Reward',
  'points': 1250,
  'description': 'Use your points to get a $20 Gift Card reward, redeemable online or offline with no minimum spend!',
  'image': 'https://www.lovebonito.com/assets/inhouse-loyalty/SG_Welcome.jpeg',
  'coupon_code': null,
  'status': 2,
  'expires_at': null,
  'auto_apply': 0
},
{
  'id': 7,
  'name': '$40 LBCommunity Reward',
  'points': 2400,
  'description': 'Use your points to get a $40 Gift Card reward, redeemable online or offline with no minimum spend!',
  'image': 'https://www.lovebonito.com/assets/inhouse-loyalty/SG_Welcome.jpeg',
  'coupon_code': null,
  'status': 2,
  'expires_at': null,
  'auto_apply': 0
}
];
const BLOCKED_CARD_ERROR_MESSAGE = 'Card is blocked or expired. Please contact your bank or try another payment method.'
const DECLINED_PAYMENT_ERROR_MESSAGE = 'Payment was declined due to insufficient fund or limit exceeded. Please try another payment method or contact your bank.'
const INVALID_CARD_DETAILS = 'Payment was declined due to invalid card details. Please check payment details and try again.'
export const GENERIC_PAYMENT_ERROR_MESSAGE = 'Please check payment details or use another payment method and try again.'
export const PAYMENT_REFUSAL_REASONS = {
  'Expired Card': BLOCKED_CARD_ERROR_MESSAGE,
  'Blocked Card': BLOCKED_CARD_ERROR_MESSAGE,
  'Issuer Unavailable': 'Payment declined due to technical issues. Please try again later',
  '3D Not Authenticated': 'Your payment authentication failed. Please try again or try another payment method.',
  'Withdrawal count exceeded': DECLINED_PAYMENT_ERROR_MESSAGE,
  'Withdrawal amount exceeded': DECLINED_PAYMENT_ERROR_MESSAGE,
  'Not enough balance': DECLINED_PAYMENT_ERROR_MESSAGE,
  'CVC Declined': INVALID_CARD_DETAILS,
  'Invalid Card Number': INVALID_CARD_DETAILS
}
export const sizeFilterMap = {
  'XXS': 'Apparel',
  'XS': 'Apparel',
  'XS/S': 'Apparel',
  'S': 'Apparel',
  'S/M': 'Apparel',
  'M': 'Apparel',
  'M/L': 'Apparel',
  'L': 'Apparel',
  'L/XL': 'Apparel',
  'XL': 'Apparel',
  'XXL': 'Apparel',
  '380': 'Apparel',
  '381': 'Apparel',
  '394': 'Apparel',
  '382': 'Apparel',
  '1592': 'Apparel',
  '383': 'Apparel',
  '395': 'Apparel',
  '384': 'Apparel',
  '1595': 'Apparel',
  '385': 'Apparel',
  '386': 'Apparel',
  '24': 'Jeans',
  '25': 'Jeans',
  '26': 'Jeans',
  '27': 'Jeans',
  '28': 'Jeans',
  '29': 'Jeans',
  '30': 'Jeans',
  '442': 'Jeans',
  '443': 'Jeans',
  '444': 'Jeans',
  '445': 'Jeans',
  '446': 'Jeans',
  '447': 'Jeans',
  '448': 'Jeans',
  '12-18M': 'Kids',
  '18-24M': 'Kids',
  '2-3Y': 'Kids',
  '3-4Y': 'Kids',
  '4-5Y': 'Kids',
  '5-6Y': 'Kids',
  '6-7Y': 'Kids',
  '1573': 'Kids',
  '1579': 'Kids',
  '1582': 'Kids',
  '1585': 'Kids',
  '1588': 'Kids',
  '1645': 'Kids',
  '1648': 'Kids',
  '34': 'Footwear',
  '35': 'Footwear',
  '36': 'Footwear',
  '37': 'Footwear',
  '38': 'Footwear',
  '39': 'Footwear',
  '40': 'Footwear',
  '41': 'Footwear',
  '1322': 'Footwear',
  '387': 'Footwear',
  '388': 'Footwear',
  '389': 'Footwear',
  '390': 'Footwear',
  '391': 'Footwear',
  '392': 'Footwear',
  '393': 'Footwear',
  'Free': 'Accessories & Others',
  'A': 'Accessories & Others',
  'B': 'Accessories & Others',
  'C': 'Accessories & Others',
  '70A': 'Accessories & Others',
  '70B': 'Accessories & Others',
  '70C': 'Accessories & Others',
  '75A': 'Accessories & Others',
  '75B': 'Accessories & Others',
  '75C': 'Accessories & Others',
  '80A': 'Accessories & Others',
  '80B': 'Accessories & Others',
  '80C': 'Accessories & Others',
  '85A': 'Accessories & Others',
  '85B': 'Accessories & Others',
  '85C': 'Accessories & Others',
  '18400/399': 'Accessories & Others',
  '396': 'Accessories & Others',
  '397': 'Accessories & Others',
  '398': 'Accessories & Others',
  '18199': 'Accessories & Others',
  '18202': 'Accessories & Others',
  '18205': 'Accessories & Others',
  '18211': 'Accessories & Others',
  '18214': 'Accessories & Others',
  '18217': 'Accessories & Others',
  '18223': 'Accessories & Others'
}

export const apparelFilterOrder = ['380', '381', '394', '382', '1592', '383', '395', '384', '1595', '385', '386']

export const BOOK_A_STYLIST_LINK = 'https://app.acuityscheduling.com/schedule.php?owner=16828133&&PHPSESSID=j27rnb9gdgg9763jumnoeve7ul';

export const ALPHA_SIZE_MAPPING = {
  '23': 'XXS',
  '24': 'XS',
  '25': 'XS-S',
  '26': 'S',
  '27': 'S-M',
  '28': 'M',
  '29': 'M-L',
  '30': 'L',
  '32': 'L-XL',
  '34': 'XL',
  '36': 'XXL'
};

export const ALPHA_SIZING_EXCLUDED_CATEGORIES = [476, 482, 485, 488, 494, 497, 500, 503, 506, 512, 521, 533, 536, 1482, 548, 551, 560, 18316, 18318, 18399, 18458, 18459, 18460, 18461, 18467, 18468, 18469, 18470, 18471, 18473, 18474, 18475, 18476, 18477]
