import * as types from './types';

const mutations = {
  [types.GET_USER_WISHLISTS] (state, payload: any) {
    state.wishlist = payload;
  },
  [types.LOADING_USER_WISHLIST] (state, payload: boolean) {
    state.loadingUserWishlist = payload;
  },
  [types.ADDING_PRODUCT_TO_WISHLIST] (state, payload: boolean) {
    state.loadingWishlist = payload;
  },
  [types.MOVING_PRODUCT_FROM_WISHLIST] (state, payload: boolean) {
    state.loadingWishlist = payload;
  },
  [types.REMOVING_PRODUCT_FROM_WISHLIST] (state, payload: boolean) {
    state.removingProductFromWishlist = payload;
  },
  [types.UPDATING_WISHLIST_PRODUCT] (state, updatingWishlistItem: boolean) {
    state.updatingWishlistItem = updatingWishlistItem;
  }
};

export default mutations;
