import Vue from 'vue'
import store from '@vue-storefront/core/store'

import { getProductVariant } from './getProductVariant'
import { prepareCategoryProduct } from './category'

const getProductOptions = ({ options }) => {
  try {
    return options.map(({ label, value }) => {
      const attribute: any = Object.values(store.getters['attribute/getAttributeListById'])
        .find((attr: any) => attr?.frontend_label === label)
      return attribute?.options?.find((option) => parseInt(option.value) === value);
    })
  } catch (e) {
    return []
  }
}

export function trackGtmEvents (gtmObject) {
  const GTM = (Vue as any).gtm
  GTM?.trackEvent(gtmObject)
}

export function trackVWOGtmEvents (gtmObject) {
  const GTM = (Vue as any).gtm
  GTM?.trackEvent(gtmObject)
  const VWO = window.VWO || [];
  VWO.event = VWO.event || function () { VWO.push(['event'].concat([].slice.call(arguments))) };
  VWO.event(gtmObject.event)
  console.log('vwo : ', gtmObject.event)
}

export function clearDataLayer () {
  trackGtmEvents({ ecommerce: null })
}

export function prepareProduct (product, event: string, list) {
  const { subcategory } = prepareCategoryProduct(product)
  const subcategoriesFormatted = {}
  const key = 'category'

  subcategory.forEach((sub, index) => {
    if (index === 0) {
      subcategoriesFormatted[key] = sub.label
    } else {
      subcategoriesFormatted[`${key}_${index + 1}`] = sub.label
    }
  })

  let itemVariant;
  if (event === 'purchase') {
    itemVariant = getProductOptions(product);
    itemVariant = itemVariant.map(variant => variant.label);
    itemVariant[0] = `Color: ${itemVariant[0]}`;
    itemVariant[1] = `Size: ${itemVariant[1]}`;
    itemVariant = itemVariant.join(' | ');
  } else {
    itemVariant = getProductVariant({ product, forGtmEvent: true }) || '';
  }

  return {
    name: product.name,
    id: product.parentSku,
    price: String(product.price),
    list,
    variant: itemVariant,
    quantity: product.qty,
    ...subcategoriesFormatted
  }
}

export function prepareProductCategory (product, idx, list = '') {
  const subcategoriesFormatted = {}
  const key = 'category'

  product.subcategory.forEach((sub, index) => {
    if (index === 0) {
      subcategoriesFormatted[key] = sub.label
    } else {
      subcategoriesFormatted[`${key}_${index + 1}`] = sub.label
    }
  })
  return {
    name: product.title,
    id: product.parentSku,
    list: list,
    price: product?.price?.regular?.replace('HK$ ', ''),
    variant: product.default_configuration?.color?.label || '',
    ...subcategoriesFormatted,
    position: idx + 1
  }
}
