function getProps (props) {
  const {
    sku,
    title,
    per_view: perView,
    open_in_new_tab: openInNewTab,
    background_color: backgroundColor
  } = props;

  return {
    sku,
    title,
    perView: Number(perView),
    openInNewTab,
    backgroundColor: backgroundColor?.color
  }
}

const ChildProductCarousel = () => ({
  type: 'child_product_carousel',
  props: getProps
});

export default ChildProductCarousel
