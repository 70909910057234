import i18n from '@vue-storefront/i18n';
import { ItemToWishlist, WishlistItem, WishlistUpdate } from '../interface';
import { sendProductToWishlist, fetchUserWishlists, moveWishlistProductToCart, removeProductFromWishlist, updateWishlistItem } from '../services';
import * as types from './types';
import config from 'config';
import rootStore from '@vue-storefront/core/store';

const errorMessage = i18n.t('Something went wrong in wishlist module!')
const showErrorMessage = message => {
  if (!message) return;
  return {
    type: 'error',
    message: i18n.t(message),
    action1: { label: i18n.t('OK') }
  };
};
const totalItemsToShow = config?.wishlist?.pagination?.pageSize
const actions = {
  async getUserWishlists ({ commit, dispatch }, payload = { pageSize: totalItemsToShow, page: 1, noCache: false }) {
    const { pageSize = totalItemsToShow, page = 1 } = payload
    commit(types?.LOADING_USER_WISHLIST, true);
    const resp = await fetchUserWishlists({ pageSize, page, noCache: payload.noCache });
    let success = true
    if (resp?.code !== 200) {
      dispatch('notification/spawnNotification', showErrorMessage(errorMessage), { root: true })
      console.error(resp)
      success = false
    }
    commit(types?.LOADING_USER_WISHLIST, false);
    const isWishlistV2Enabled = rootStore.getters['featurehub/isWishlistV2Enabled'];
    if (isWishlistV2Enabled) {
      commit(types?.GET_USER_WISHLISTS, resp?.result);
    } else {
      commit(types?.GET_USER_WISHLISTS, resp?.result?.customer);
    }
    return success
  },

  async addProductToWishlist ({ commit, dispatch }, item: ItemToWishlist) {
    commit(types?.ADDING_PRODUCT_TO_WISHLIST, true);
    const isWishlistV2Enabled = rootStore.getters['featurehub/isWishlistV2Enabled'];
    if (isWishlistV2Enabled) {
      try {
        await sendProductToWishlist(item);
        commit(types?.ADDING_PRODUCT_TO_WISHLIST, false);
        return true;
      } catch (error) {
        commit(types?.ADDING_PRODUCT_TO_WISHLIST, false);
        if (error?.data?.result?.message === 'SKU already exists in wishlist') {
          return true;
        }
        dispatch('notification/spawnNotification', showErrorMessage(errorMessage), { root: true })
        console.error(error);
        return false;
      }
    } else {
      const resp = await sendProductToWishlist(item);
      let success = true;
      if (resp?.code !== 200) {
        dispatch('notification/spawnNotification', showErrorMessage(errorMessage), { root: true })
        console.error(resp);
        success = false;
      }
      commit(types?.ADDING_PRODUCT_TO_WISHLIST, false);
      return success;
    }
  },

  async deleteProductFromWishlist ({ commit, dispatch }, payload: WishlistItem) {
    commit(types?.REMOVING_PRODUCT_FROM_WISHLIST, true);
    const resp = await removeProductFromWishlist(payload);
    let success = true
    if (resp?.code !== 200) {
      dispatch('notification/spawnNotification', showErrorMessage(errorMessage), { root: true })
      console.error(resp)
      success = false
    }
    commit(types?.REMOVING_PRODUCT_FROM_WISHLIST, false);
    return success
  },

  async moveWishlistItemToCart ({ commit, dispatch, rootGetters }, payload: WishlistItem) {
    commit(types?.MOVING_PRODUCT_FROM_WISHLIST, true);
    let success = true
    const resp = await moveWishlistProductToCart(payload);
    if (resp?.code !== 200) {
      success = false
      commit(types?.MOVING_PRODUCT_FROM_WISHLIST, false);
      dispatch('notification/spawnNotification', showErrorMessage(resp?.result?.message?.message || errorMessage), { root: true })
      console.error(resp)
    } else {
      await dispatch('getUserWishlists')

      commit('cart/setIsWishlist', true, { root: true })
      if (rootGetters['featurehub/isCartModuleActive']) {
        await dispatch('cart-v1/fetchCart', null, { root: true })
      } else {
        await dispatch('cart/sync', { forceSync: true }, { root: true });
      }
      commit(types?.MOVING_PRODUCT_FROM_WISHLIST, false);
      commit('cart/setIsWishlist', false, { root: true })
    }
    return success
  },
  async updateWishlistItem ({ commit, dispatch }, payload: WishlistUpdate) {
    commit(types?.UPDATING_WISHLIST_PRODUCT, true);
    let success = true
    const resp = await updateWishlistItem({
      ...payload
    });
    if (resp?.code !== 200) {
      success = false
      dispatch('notification/spawnNotification', showErrorMessage(resp?.result?.message?.message || errorMessage), { root: true })
      console.error(resp)
    }
    commit(types?.UPDATING_WISHLIST_PRODUCT, false);
    return success
  }
};

export default actions;
