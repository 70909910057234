<template>
  <div class="a-product-select-color">
    <SfButton
      class="sf-button--pure sf-color"
      :class="classes"
      :style="style"
      :aria-pressed="selected.toString()"
      v-on="$listeners"
    />
    <p v-on="$listeners" class="custom-label tiny" :style="setLabelStyle" v-if="customLabel.label">
      {{ customLabel.label }}
    </p>
  </div>
</template>
<script>
import { SfColor } from '@storefront-ui/vue';
import tinycolor from 'tinycolor2';

export default {
  name: 'AProductSelectColor',
  mixins: [SfColor],
  props: {
    hasStock: {
      type: Boolean,
      default: true
    },
    customLabel: {
      type: Object,
      default: () => ({
        label: '',
        textColor: '',
        bgColor: ''
      })
    }
  },
  computed: {
    isLight () {
      return tinycolor(this.color).isLight();
    },
    classes () {
      return [
        { 'sf-color--active': this.selected },
        { 'sf-color--oos': !this.hasStock },
        { 'sf-color--outlined': this.isLight },
        `${this.selected ? 'selected' : ''}`
      ]
    },
    setLabelStyle () {
      return {
        color: this.customLabel.textColor || '#FFFFFF',
        'background-color': this.customLabel.bgColor || '#E49E80'
      };
    }
  }
}
</script>
<style lang="scss" scoped>
.sf-color {
  margin: auto;
  margin-bottom: 10px;
  --color-size: 14px;
  width: var(--color-size);
  height: var(--color-size);
  border-radius: 50%;
  &:after {
    content: '';
    position: absolute;
    width: var(--color-size);
    height: var(--color-size);
    --color-outer-ring-border-color: var(--neutral-200);
    border-radius: 50%;
  }
  &:not(&--oos) {
    &:after {
      border: 1px solid var(--color-outer-ring-border-color);
    }
  }
  &--oos {
    opacity: 0.65;
    --button-border-color: var(--neutral-200);
    --button-color: var(--neutral-100);
    border: 1px solid var(--neutral-200);
    &:after {
      --product-select-color-border-radius: 0;
      border-top: 1px solid var(--neutral-500);
      transform: rotate(140deg);
      top: -10px;
      left: -9px;
      height: 18px;
      width: 18px;
      border-radius: 0;
    }
    &:hover {
      &:after {
        height: 18px;
        width: 18px;
      }
    }
  }
  &:hover,
  &:active,
  &--active {
    transform: none;
    --color-box-shadow: none;
    &:after {
      height: 18px;
      width: 18px;
      --color-outer-ring-border-color: var(--neutral-900);
    }
  }
}
.selected {
  pointer-events: none;
  border: none;
  &:after {
    opacity: unset;
    border: 1px solid var(--color-outer-ring-border-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -1;
  }
  &:before {
    opacity: 0;
  }
}

.custom-label {
  height: auto;
  padding: 1px 4px;
  margin-top: -15px;
  position: relative;
  z-index: 2;
  text-align: center;
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}
</style>
