import { FilterInterface } from "storefront-query-builder";

export const NestedFilterType = 'nested-color-or-size';

const nested: FilterInterface = {
    check: ({ attribute }) => attribute === NestedFilterType,
    filter ({ value, attribute, operator, queryChain }) {
      const { size, color } = value;
      return queryChain
        .filter("bool", "must", [
          {nested: {
              path: "configurable_children_filter",
              "query": {
                "bool": {
                  "must": [
                    {
                      "match": {
                        "configurable_children_filter.is_in_stock": true
                      }
                    },
                    {
                      "terms": {
                        "configurable_children_filter.size": size,
                        "configurable_children_filter.color_family": color
                      }
                    }
                  ]
                }
              }
            }},
          {match: { type_id: "configurable" }}
        ])
    }
};

export default nested;
