import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { beforeRegistration } from '../hooks/beforeRegistration';
import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

export const KEY = 'storyblok'

const StoryBlokStore = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export const StoryBlokModule: StorefrontModule = function ({ store, router, appConfig }) {
  beforeRegistration(appConfig, store);
  store.registerModule(KEY, StoryBlokStore)
}
