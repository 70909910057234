import { isServer } from '@vue-storefront/core/helpers';

export default {
  data () {
    return {
      isMobile: false
    };
  },
  mounted () {
    if (isServer) {
      return
    }
    const media = window?.matchMedia(`(max-width: ${this.mobileWidth})`);
    const self = this
    const onMediaChange = (event) => {
      self.isMobile = event.matches;
    };

    self.isMobile = media.matches;
    media?.addEventListener?.('change', onMediaChange);

    self.$once('hook:beforeDestroy', () => {
      media?.removeEventListener?.('change', onMediaChange);
    });
  },
  computed: {
    mobileWidth () {
      return '720px';
    }
  }
};
