<template>
  <div
    v-if="conversionEnabled && priceValue > 0"
    class="price-converted"
    :class="{'special': priceType === 'special'}"
  >
    Approx. <span>{{ isAddingToCart ? 'Calculating...' : getCurrencyRate(priceValue) }}</span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AApproxPrice',
  props: {
    priceType: {
      type: String,
      default: 'regular'
    },
    priceValue: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      getCurrencyRate: 'currencyconversion/currencyRate',
      conversionEnabled: 'currencyconversion/conversionEnabled',
      isAddingToCart: 'cart/getIsAdding'
    })
  }
}
</script>
<style lang="scss" scoped>
.price-converted {
  font-size: var(--font_size_50);
  font-family: var(--font-secondary);
  &.special {
    color: var(--heart-red-base);
  }
  > span {
    display: inline-block;
  }
}
</style>
