function getProps (props) {
  const { tbody, thead } = props.body;
  const { body_bg, header_bg, text_bg, table_width, side_header } = props;

  return {
    tbody,
    thead,
    textBg: text_bg,
    bodyBg: body_bg,
    headerBg: header_bg,
    sideHeader: side_header,
    tableWidth: Number(table_width)
  };
}

const Table = () => ({
  type: 'sb_table',
  props: getProps
});

export default Table;
