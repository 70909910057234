import { extendStore } from '@vue-storefront/core/helpers';
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import has from 'lodash-es/has';
import actions from './actions'
import * as nextTypes from './types'

const KEY = 'product-next'

const productNextStore = {
  namespaced: true
}

const productNextModule = {
  state: {
    loadingProductStockFromIms: false,
    imsStocks: [],
    recentlyViewedProducts: []
  },
  getters: {
    loadingProductStockFromIms: state => state?.loadingProductStockFromIms,
    getImsStocks: state => state?.imsStocks,
    getProductGallery: state => {
      const { productGallery } = state;
      try {
        // In case of no color option, we should return the gallery without any modification.
        if (!has(state, 'current_configuration.color')) {
          return productGallery;
        }
        const { current_configuration: { color } } = state;
        const attributeCode = 'color'
        return productGallery.filter(image => image.id[attributeCode] === color.label);
      } catch (e) {
        return productGallery;
      }
    },
    getRecentlyViewedProducts: state => state.recentlyViewedProducts
  },
  actions,
  mutations: {
    [nextTypes.LOADING_PRODUCT_STOCK_FROM_IMS] (state, payload: boolean) {
      state.loadingProductStockFromIms = payload;
    },
    [nextTypes.IMS_STOCKS] (state, payload: any) {
      state.imsStocks = payload;
    },
    [nextTypes.RECENTLY_VIEWED] (state, payload: any) {
      state.recentlyViewedProducts = payload;
    }
  }
}

export const ProductNextModule: StorefrontModule = function ({ app, store, appConfig, router, moduleConfig }) {
  store.registerModule(KEY, productNextStore)
  extendStore('product', productNextModule)
}
