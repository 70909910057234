const megaMenuMobileBlockProps = props => {
  return {
    images: props.blocks
  };
};

const MegaMenuMobileBlock = () => ({
  type: 'mega_menu_mobile_banner_block',
  props: megaMenuMobileBlockProps
});

export default MegaMenuMobileBlock;
