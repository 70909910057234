<template>
  <component
    :is="theMicrocartAsyncComponent"
    v-if="isMicrocartOpen || isMicrocartComponentLoaded"
    @close="$store.commit('ui/setMicrocart')"
    @reload="reloadComponent()"
  />
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ALoadingSpinner from 'common/components/atoms/a-loading-spinner';
import ALoadingError from 'common/components/atoms/a-loading-error';

const OMicrocartPanel = () =>
  import(/* webpackChunkName: "o-microcart-panel" */ 'common/components/organisms/o-microcart-panel');

export default {
  data: () => ({
    isMicrocartComponentLoaded: false,
    theMicrocartAsyncComponent: null
  }),
  computed: {
    ...mapState({
      isMicrocartOpen: state => state.ui.microcart
    }),
    ...mapGetters({ getCartItems: 'cart/getCartItems' })
  },
  mounted () {
    this.reloadComponent()
    const eventBus = this.$bus;
    eventBus.$on('cart-after-updatetotals', this.onCartItemAdd)
    eventBus.$on('cart-after-delete', this.onCartEmpty)
  },
  beforeDestroy () {
    const eventBus = this.$bus;
    eventBus.$off('cart-after-updatetotals', this.onCartItemAdd)
    eventBus.$off('cart-after-delete', this.onCartEmpty)
  },
  methods: {
    onCartItemAdd () {
      const cartData = this.getCartItems?.map((cartItem) => ({
        item: cartItem?.parentSku || cartItem?.sku,
        price: cartItem?.totals?.row_total_incl_tax || (cartItem.price_incl_tax * cartItem.qty) || 0,
        quantity: cartItem.qty
      }));
      this.$store.dispatch('emarsys/scarabQueuePush', {
        key: 'cart',
        value: cartData
      });
    },
    onCartEmpty () {
      if (!this.getCartItems?.length) {
        this.$store.dispatch('emarsys/scarabQueuePush', {
          key: 'cart',
          value: []
        });
      }
    },
    reloadComponent () {
      this.theMicrocartAsyncComponent = () => ({
        component: OMicrocartPanel().then(component => {
          this.isMicrocartComponentLoaded = true;
          return component;
        }),
        loading: ALoadingSpinner,
        error: ALoadingError,
        timeout: 3000
      });
    }
  }
};
</script>
