import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { OrderShippingDetailsNext } from './types';

export const createOrderData = ({
  shippingDetails,
  paymentDetails,
  taxCountry = currentStoreView().tax.defaultCountry
}: any): OrderShippingDetailsNext => {
  const district_code = paymentDetails?.custom_attributes?.find(attr => attr.attribute_code === 'district_code')?.value;
  const country = shippingDetails?.country_id || taxCountry

  return {
    country,
    shippingAddress: {
      firstname: shippingDetails?.firstname,
      lastname: shippingDetails?.lastname,
      city: shippingDetails?.city,
      postcode: shippingDetails?.postcode,
      street: shippingDetails?.street,
      custom_attributes: shippingDetails?.custom_attributes,
      region: shippingDetails?.region?.region || ''
    },
    billingAddress: {
      firstname: paymentDetails?.firstname,
      lastname: paymentDetails?.lastname,
      city: paymentDetails?.city,
      postcode: paymentDetails?.postcode,
      street: paymentDetails?.street,
      countryId: paymentDetails?.country_id,
      ...(district_code ? { extension_attributes: { district_code } } : {}),
      custom_attributes: shippingDetails?.custom_attributes
    },
    method_code: shippingDetails?.shippingMethod,
    carrier_code: shippingDetails?.shippingCarrier,
    payment_method: paymentDetails?.paymentMethod
  }
}
