const getVideoProps = props => {
  return {
    noGap: props.no_gap,
    videoId: props.video_id,
    title: props.title,
    subTitle: props.sub_title,
    text: props.text,
    layout: props.layout,
    thumbnail: props.video_thumbnail,
    cta: props.cta[0],
    editable: props
  };
};
const VideoClp = () => ({
  type: 'video_clp',
  props: getVideoProps
});

export default VideoClp;
