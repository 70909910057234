
const getOrderSummaryProps = (props) => {
  return { orderText: props.order_text };
};

const OrderSummaryBlock = () => ({
  type: 'order_summary_block',
  props: getOrderSummaryProps
});

export default OrderSummaryBlock;
