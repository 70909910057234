
const getPreOrderProps = (props) => {
  return { body: props.body };
};

const PreOrderMessageBlock = () => ({
  type: 'pre_order_message_block',
  props: getPreOrderProps
});

export default PreOrderMessageBlock;
