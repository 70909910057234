import { Logger } from '@vue-storefront/core/lib/logger'

export function isObject (val) {
  if (val === null || val === undefined) {
    return false
  } else if (Array.isArray(val)) {
    return false
  } else if (typeof val === 'object') {
    return true
  }
  return false
}

export function pluck (data, keys = []) {
  return keys.reduce((obj, key) => ({ ...obj, [key]: data[key] }), {})
}

export function isEqual (compareValue, withValue, skipKeys = []) {
  let isSame = true
  if (isObject(compareValue) && isObject(withValue)) {
    let keys = Object.keys(compareValue)

    keys = keys.filter(key => !skipKeys.includes(key))

    let diff = keys.find(key => !isEqual(compareValue[key], withValue[key]))
    isSame = diff === undefined
  } else if (Array.isArray(compareValue) && Array.isArray(withValue)) {
    let sameLen = compareValue.length === withValue.length
    let r = compareValue.find((v, i) => !isEqual(v, withValue[i]))
    isSame = r === undefined && sameLen
  } else {
    isSame = compareValue === withValue
  }

  return isSame
}
