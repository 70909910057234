import * as types from './types';

const mutations = {
  [types.CHECKING_PRODUCT_AVAILABILITY] (state, payload: boolean) {
    state.checkingProductAvailability = payload;
  },
  [types.SET_PRODUCT] (state, payload: string) {
    state.currentProduct = payload;
  },
  [types.PRODUCT_AVAILABILITY] (state, payload: any) {
    state.productAvailability = payload;
  }
};

export default mutations;
