export function prepareShippingAddress (shippingDetails) {
  return {
    firstname: shippingDetails.firstName,
    lastname: shippingDetails.lastName,
    street: (shippingDetails.address2 && shippingDetails.address2 !== '') ? [shippingDetails.address1, shippingDetails.address2] : [shippingDetails.address1],
    city: shippingDetails.city,
    ...(shippingDetails.state ? { region: { region: shippingDetails.state } } : {}),
    country_id: shippingDetails.country,
    postcode: shippingDetails.postcode,
    custom_attributes: [
      {
        attribute_code: 'lb_address_type',
        value: shippingDetails.addressType
      },
      {
        attribute_code: 'country_code',
        value: shippingDetails.dialCode
      }
    ],
    default_billing: !!shippingDetails.defaultBillingAddress,
    default_shipping: !!shippingDetails.defaultShippingAddress,
    ...(shippingDetails.contactNumber ? { telephone: shippingDetails.contactNumber } : {})
  }
}

export const getPostcode = (shippingDetails) => {
  const { postcode = '' } = shippingDetails
  if (shippingDetails?.country_id !== 'HK') return postcode
  return postcode || '000000'
}
