
function getProps (props) {
  const {
    title,
    footer_text,
    uvp_block
  } = props;

  try {
    return {
      title,
      footerText: footer_text || '',
      uvpBlock: uvp_block

    }
  } catch (e) {
    throw new Error('Error rendering Global UVP Section');
  }
}

const GlobalUvp = () => ({
  type: 'global_uvp',
  props: getProps
});

export default GlobalUvp
