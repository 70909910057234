import { getColor } from '../helpers'

function getProps (props) {
  const {
    image,
    title,
    subtitle,
    text,
    background_color,
    text_color,
    no_gap,
    layout_option,
    component,
    CTA: cta
  } = props;

  return {
    image: image || {},
    title,
    subtitle,
    text: text || {},
    backgroundColor: getColor(background_color),
    textColor: getColor(text_color),
    noGap: no_gap,
    layoutOption: layout_option,
    editable: props,
    cta,
    component
  }
}

const CardSingle = () => ({
  type: 'card',
  props: getProps
});

export default CardSingle
