import { price } from '@vue-storefront/core/filters';
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { StoreView } from '@vue-storefront/core/lib/types'
import { getCustomOptionValues, getCustomOptionPriceDelta } from '@vue-storefront/core/modules/catalog/helpers/customOption';
import { getBundleOptionsValues, getBundleOptionPrice } from '@vue-storefront/core/modules/catalog/helpers/bundleOptions';
import get from 'lodash-es/get';

function calculateBundleOptionsPrice (product) {
  const allBundleOptions = product.bundle_options || []
  const selectedBundleOptions = Object.values(get(product, 'product_option.extension_attributes.bundle_options', {}))
  const price = getBundleOptionPrice(
    getBundleOptionsValues(selectedBundleOptions as any[], allBundleOptions)
  )

  return price.priceInclTax
}

function calculateCustomOptionsPriceDelta (product, customOptions) {
  const priceDelta = getCustomOptionPriceDelta(
    getCustomOptionValues(Object.values(customOptions), product.custom_options),
    product
  )

  return priceDelta.priceInclTax
}

function formatPrice (value) {
  return value ? price(value) : ''
}

function calculateProductPrice (product, customOptions = {}) {
  if (!product) {
    return {
      regular: '',
      special: ''
    }
  }

  const { tax } = currentStoreView() as StoreView & { tax: { usePriceExclusiveTax: boolean }};
  const productPrice = (tax.usePriceExclusiveTax ? product.price : (product.price_incl_tax || product.priceInclTax)) || 0
  const productOriginalPrice = (tax.usePriceExclusiveTax ? (product.original_price || product.originalPrice) : (product.original_price_incl_tax || product.originalPriceInclTax)) || 0
  const specialPrice = product.special_price || product.specialPrice || 0

  const isSpecialPrice = specialPrice && productPrice && productOriginalPrice
  const priceDelta = calculateCustomOptionsPriceDelta(product, customOptions)

  const special = (productPrice + priceDelta) * product.qty || productPrice
  const original = (productOriginalPrice + priceDelta) * product.qty || productOriginalPrice
  const regular = calculateBundleOptionsPrice(product) || (productPrice + priceDelta) * product.qty || productPrice

  return {
    regular: isSpecialPrice ? original : regular,
    special: isSpecialPrice ? special : ''
  }
}

function getProductPriceRange (product, format = true) {
  if (product?.type_id === 'configurable' && product?.configurable_children?.length) {
    const prices = product.configurable_children?.map(child => child?.price)
    const range = {
      min: format ? formatPrice(Math.min(...prices)) : Math.min(...prices),
      max: format ? formatPrice(Math.max(...prices)) : Math.max(...prices)
    }
    if (range.min === range.max) return null
    return range
  }
  return null
}

export function getProductPrice (product, customOptions = {}, format = true) {
  const { regular, special } = calculateProductPrice(product, customOptions);
  return {
    regular: format ? formatPrice(regular) : regular,
    special: format ? formatPrice(special) : special,
    range: getProductPriceRange(product)
  }
}

export function getProductPriceFromTotals (product) {
  if (!product.totals || !product.totals.options) {
    return {
      regular: '',
      special: ''
    };
  }

  const isSpecialPrice = product.totals.discount_amount > 0;
  const { tax } = currentStoreView() as StoreView & { tax: { usePriceExclusiveTax: boolean }};

  const productRowPrice = tax.usePriceExclusiveTax ? product.totals.row_total : product.totals.row_total_incl_tax;

  const special = productRowPrice - product.totals.discount_amount;
  const regular = productRowPrice;

  return {
    regular: formatPrice(regular),
    special: isSpecialPrice ? formatPrice(special) : ''
  };
}
