import isString from 'lodash-es/isString'
import * as types from './mutation-types';
import DataService from 'common/services/DataService';
import { isServer } from 'common/utils'
import { createOrderData } from 'src/modules/cart-next/createOrderData'

import { CartItemTotals, CartItemOption } from './types';
import { trackGtmEvents, prepareProduct } from 'common/helpers/gtm';
import { GIFT_CARD_TYPE } from 'common/constants';

import config from 'config'

const CART_URLS = {
  createCart: '/api/cart/create',
  getCart: '/api/cart/items-with-totals',
  stockCheck: '/api/stock/bulk-check',
  getTotals: '/api/cart/totals',
  recalculateTotals: '/api/cart/recalculate-totals',
  addItem: '/api/cart/update',
  addItemV1: '/api/cart/add',
  getCartProductsDetails: '/api/ext/es-catalog/catalog/cart-items-details',
  mergeCart: '/api/cart/merge',
  updateItem: '/api/cart/update',
  deleteItem: '/api/cart/delete',
  applyGiftcard: '/api/ext/giftcard/apply',
  applyCoupon: '/api/cart/apply-coupon',
  checkStorecredit: '/api/ext/store-credit/check',
  applyStorecredit: '/api/ext/store-credit/apply',
  removeCoupon: '/api/cart/delete-coupon',
  removeStorecredit: '/api/ext/store-credit/remove',
  removeGiftcard: '/api/ext/giftcard/delete',
  collectTotals: '/api/cart/collect-totals/pull',
  getPaymentMethods: '/api/cart/payment-methods',
  getShippingMethods: '/api/cart/shipping-methods',
  getShippingInfo: '/api/cart/shipping-information',
  checkGiftcardamount: '/api/ext/giftcard/check-amount',
  redeemGiftcardAsStoreCredit: '/api/ext/giftcard/redeemAsStoreCredit'
}

const actions = {
  resetCartModule ({ state, commit, rootGetters }) {
    commit(types.RESET_CART_MODULE)
    const storeCode = rootGetters['getCurrentStoreView'].storeCode
    localStorage.removeItem(`${storeCode}-shop/cart/current-cart-token`)
  },
  async handleInactiveCart ({ getters, dispatch }, error) {
    try {
      const errorMessage = error?.statusText || error.data?.result;
      // eslint-disable-next-line quotes
      const errorForInvalidCartId = "\"quoteId\" is required. Enter and try again."
      const customerNotHaveActiveCartErrorMessage = 'Current customer does not have an active cart.';
      const errorForCartNotExist = 'No such entity with';
      if (
        !getters.getCartId ||
        errorMessage.includes(errorForCartNotExist) ||
          errorForInvalidCartId === errorMessage ||
          customerNotHaveActiveCartErrorMessage === errorMessage
      ) {
        console.debug(`${error}, 'cart error having cart id as ${getters.getCartId}`);
        await dispatch('createCart', true);
        return true;
      }
      return false;
    } catch (_) {
      return false;
    }
  },
  getCartIdFromStorage ({ rootGetters }) {
    if (isServer) {
      return ''
    }
    const storeCode = rootGetters['getCurrentStoreView'].storeCode
    return JSON.parse(localStorage.getItem(`${storeCode}-shop/cart/current-cart-token`) || '""')
  },
  setCartInStorage ({ rootGetters }, cartId) {
    const storeCode = rootGetters['getCurrentStoreView'].storeCode
    localStorage.setItem(`${storeCode}-shop/cart/current-cart-token`, JSON.stringify(cartId))
  },
  async setCartIdFromStorageAndFetchCart ({ commit, dispatch, getters }) {
    try {
      await commit(types.SET_IS_LOADING_CART, true);
      const cartIdFromStorage = await dispatch('getCartIdFromStorage');
      if (cartIdFromStorage) {
        commit(types.SET_CART_ID, cartIdFromStorage);
        if (getters.isGuestCart) {
          await dispatch('fetchCart');
        }
        await commit(types.SET_IS_LOADED_CART, true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (getters.isGuestCart || !getters.getCartId) {
        await commit(types.SET_IS_LOADING_CART, false);
      }
    }
  },
  async createCart ({ commit, dispatch }, shouldCreateNewCart = false) {
    if (!shouldCreateNewCart) {
      const cartIdFromStorage = await dispatch('getCartIdFromStorage')
      if (cartIdFromStorage) {
        commit(types.SET_CART_ID, cartIdFromStorage)
        return
      }
    }
    const response = await DataService.post(CART_URLS.createCart)
    const cartId = response.result
    commit(types.SET_CART_ID, cartId)
    dispatch('setCartInStorage', cartId)
  },
  async mergeOrFetchCart ({ commit, getters, dispatch }) {
    try {
      await commit(types.SET_IS_LOADING_CART, true);
      // We need to merge cart only when it's a guest cart
      if (getters.getCartId && getters.isGuestCart) {
        const response = await DataService.get(`${CART_URLS.mergeCart}/${getters.getCartId}`);
        if (!response.result) {
          return;
        }
      }
      await dispatch('createCart', true);
      await dispatch('fetchCart');
      await commit(types.SET_IS_LOADED_CART, true);
    } catch (error) {
      console.error(error);
      const isNewCartCreated = await dispatch('handleInactiveCart', error);
      if (!isNewCartCreated) return;
      await dispatch('fetchCart');
    } finally {
      await commit(types.SET_IS_LOADING_CART, false);
    }
  },
  setItemsToRemove ({ commit }, itemsToRemove) {
    commit(types.SET_ITEMS_TO_REMOVE, itemsToRemove)
  },
  resetItemsToRemove ({ commit }) {
    commit(types.RESET_ITEMS_TO_REMOVE)
  },
  async fetchCart ({ commit, getters, dispatch, rootGetters }) {
    try {
      if (isServer || !getters.getCartId) {
        return
      }
      let URL = `${CART_URLS.getCart}`
      if (getters.isGuestCart) {
        URL += `/${getters.getCartId}`
      }
      const { result } = await DataService.get(URL)
      const cartItems = result?.items || []

      if (!cartItems.length) {
        commit(types.SET_CART_ITEMS, [])
        commit(types.SET_CART_TOTALS, {})
        return
      }

      await dispatch('mapCartItemsAndFetchDetails', result)
    } catch (error) {
      const errorMessage = error?.statusText || error.data?.result
      if (!errorMessage) {
        throw new Error(error)
      }
      const isNewCartCreated = await dispatch('handleInactiveCart', error);
      if (!isNewCartCreated) return;
      return dispatch('fetchCart')
    }
  },
  async fetchProductsByParenSku (vuexContext, items) {
    const shouldTreatGwpAsConfigProduct = config?.featureFlags?.['GWP_IS_CONFIG_PRODUCT']?.isActive

    // Create sku list to fetch products from catalog service
    const skus = items?.map(item => {
      const productType = item.product_type
      let prefix = null

      if (shouldTreatGwpAsConfigProduct) {
        // Treat all products as configurable apart from giftcards
        prefix = productType !== 'giftcard' ? 'config' : 'simple'
      } else {
        // Treat all products as simple apart from configurable products
        prefix = productType === 'configurable' ? 'config' : 'simple'
      }

      return `${prefix}-${item.sku}`
    })?.join(',')

    const resp = await DataService.get(`${CART_URLS.getCartProductsDetails}?skus=${encodeURIComponent(skus)}`)
    if (!resp.result) {
      return []
    }
    return resp.result
  },
  async mapCartItemsAndFetchDetails ({ commit, dispatch, rootGetters }: any, payload: { items: Record<string, any>[], totals: {
    items: []
  } }) {
    const cartItems = payload?.items || [];
    const totals = payload?.totals || {
      items: []
    };
    commit(types.SET_CART_TOTALS, totals);
    const resultItems = await dispatch('fetchProductsByParenSku', cartItems);
    const itemsStock = await dispatch('checkBulkStatus', cartItems);
    const attributes = rootGetters['attribute/getAttributeListById'];
    const items = cartItems?.map(originalProduct => {
      const product = resultItems?.find(item => item.sku === originalProduct.sku);
      if (!product) {
        return originalProduct;
      }

      const productTotals: CartItemTotals = totals?.items?.find((item: any) => item.item_id === originalProduct.item_id);
      if (isString(productTotals?.options)) {
        productTotals.options = JSON.parse(productTotals.options as unknown as string) as CartItemOption[];
      }
      const itemOptions = originalProduct?.product_option?.extension_attributes?.configurable_item_options || [];
      const options = itemOptions.map(({ option_id, option_value }) => {
        const attribute = attributes[option_id];
        if (!attribute) return null;
        return {
          label: attribute.frontend_label,
          value: option_value
        };
      });
      const route = rootGetters['url/getCurrentRoute'];
      const onCheckoutPage = route.name.endsWith('checkout');
      const onCartPage = route.name.endsWith('cart');
      let isInStock = false;

      // We don't maintain quantity for giftcards, mark them as in stock always.
      if (originalProduct.product_type === GIFT_CARD_TYPE) {
        isInStock = true;
      } else {
        const stockQuantity = itemsStock?.items?.[0]?.[product.sku]?.quantity || 0;
        isInStock = stockQuantity >= originalProduct.qty;
      }

      if (!isInStock && (onCheckoutPage || onCartPage)) {
        let page = onCartPage ? 'cart' : 'checkout';

        const eventName = `${page}_OOS_item`;
        trackGtmEvents({
          event: eventName,
          ...prepareProduct(product, page)
        });
      }
      return {
        ...product,
        isInStock,
        type_id: originalProduct.product_type,
        options,
        totals: productTotals,
        qty: originalProduct.qty,
        server_item_id: originalProduct.item_id,
        server_cart_id: originalProduct.quote_id,
        product_option: originalProduct.product_option
      };
    });
    commit(types.SET_CART_ITEMS, items)
  },
  async checkBulkStatus (vuexContext: any, cartItems: any[]) {
    const skus = cartItems?.map(item => item.sku)?.join(',')
    if (!skus) { return [] }
    const { result = [] } = await DataService.get(`${CART_URLS.stockCheck}?skus=${encodeURIComponent(skus)}`)
    return result
  },
  async checkProductStock ({ dispatch, getters }, product) {
    const existingProduct = getters.getCartItems?.find(prod => prod.sku === product.sku)
    const qty = existingProduct ? existingProduct.qty + 1 : (product.qty ? product.qty : 1)

    return dispatch('stock/check', { product, qty }, { root: true })
  },
  async addItem ({ commit, getters, rootGetters, dispatch }, item) {
    try {
      commit(types.SET_IS_ADDING_TO_CART, true)
      // Create cart if it doesn't exist already
      if (!getters.getCartId) {
        await dispatch('createCart')
      }
      const cartId = getters.getCartId

      //  Stock is also checked in add item api, so it's not needed to call an additional api here
      if (!rootGetters['featurehub/isCartV1ApiEnabled']) {
        // Check status of product
        const { status, onlineCheckTaskId, qty } = await dispatch('checkProductStock', item)

        if (qty <= 0 || qty < item.qty || status !== 'ok') {
          await dispatch('notification/spawnNotification', {
            type: 'danger',
            message: 'Product that you are trying to add is not available',
            action1: { label: 'OK' }
          }, { root: true });
          throw new Error('Product that you are trying to add is not available')
        }
      }

      const cartItem = {
        cartItem: {
          sku: item.parentSku,
          qty: item.qty,
          product_option: item.product_option,
          quoteId: cartId
        },
        quoteId: cartId,
        triggerSource: window?.location?.pathname || ''
      }
      let url = `${CART_URLS.addItem}?cartId=${cartId}`;
      // cart v1 api
      if (rootGetters['featurehub/isCartV1ApiEnabled']) {
        url = `${CART_URLS.addItemV1}/${cartId}/items`;
      }
      // Add item into cart
      const response = await DataService.post(url, {
        body: cartItem
      });

      // cart v1 api
      if (rootGetters['featurehub/isCartV1ApiEnabled']) {
        await dispatch('mapCartItemsAndFetchDetails', response.result);
        dispatch('onCartChanged')
        return response.code === 200;
      }

      // Fetch latest cart
      await dispatch('fetchCart')
      dispatch('onCartChanged')
      return response.code === 200
    } catch (error) {
      const errorMessage = error?.statusText || error.data?.result
      if (!errorMessage) {
        throw new Error(error)
      }
      const isNewCartCreated = await dispatch('handleInactiveCart', error);
      if (isNewCartCreated) {
        await dispatch('addItem', item)
        return
      }
      await dispatch('notification/spawnNotification', {
        type: 'danger',
        message: errorMessage,
        action1: { label: 'OK' }
      }, { root: true });
      throw new Error(error)
    } finally {
      commit(types.SET_IS_ADDING_TO_CART, false)
    }
  },
  async updateItem ({ getters, rootGetters, dispatch }, item) {
    if (isServer || !getters.getCartId) {
      return
    }
    try {
      const itemId = item.item_id || item.server_item_id
      if (!itemId) {
        return
      }
      const cartItem = {
        cartItem: {
          qty: item.qty,
          quoteId: getters.getCartId,
          product_option: item.product_option
        }
      }
      const response = await DataService.put(`${CART_URLS.updateItem}/${getters.getCartId}/items/${itemId}`, {
        body: cartItem
      })
      // cart v1 api
      if (rootGetters['featurehub/isCartV1ApiEnabled']) {
        await dispatch('mapCartItemsAndFetchDetails', response.result);
        return response.code === 200;
      }

      await dispatch('fetchCart')
      return response.code === 200;
    } catch (error) {
      const errorMessage = error?.statusText || error.data?.result
      if (!errorMessage) {
        throw new Error(error)
      }
      const isNewCartCreated = await dispatch('handleInactiveCart', error);
      if (isNewCartCreated) {
        await dispatch('addItem', item);
        return
      }
      await dispatch('notification/spawnNotification', {
        type: 'danger',
        message: errorMessage,
        action1: { label: 'OK' }
      }, { root: true });
      throw new Error(error)
    }
  },
  async deleteItems ({ commit, getters, dispatch }) {
    if (!getters.getItemsToRemove.length || isServer || !getters.getCartId) {
      return
    }
    try {
      const URL = `${CART_URLS.deleteItem}?cartId=${getters.getCartId}`
      for (const item of getters.getItemsToRemove) {
        await DataService.post(URL, {
          body: {
            cartItem: {
              sku: item.sku,
              item_id: item.item_id || item.server_item_id,
              quoteId: item.quote_id
            }
          }
        })
      }
      // Fetch latest cart
      await dispatch('fetchCart')
      dispatch('onCartChanged')
      commit(types.RESET_ITEMS_TO_REMOVE)
    } catch (error) {
      const isNewCartCreated = await dispatch('handleInactiveCart', error);
      if (!isNewCartCreated) throw new Error(error);
      await dispatch('fetchCart')
      dispatch('onCartChanged')
    }
  },
  async fetchPaymentMethods ({ getters, commit, dispatch }) {
    if (isServer || !getters.getCartId) {
      return
    }

    try {
      const { result = [], code } = await DataService.get(`${CART_URLS.getPaymentMethods}?cartId=${getters.getCartId}`);
      commit(types.SET_PAYMENT_METHODS, result)
    } catch (error) {
      const isNewCartCreated = await dispatch('handleInactiveCart', error);
      if (!isNewCartCreated) throw error;
      await dispatch('fetchCart');
      return dispatch('fetchPaymentMethods');
    }
  },
  async fetchShippingMethods ({ commit, getters, rootGetters, dispatch }) {
    if (isServer || !getters.getCartId) {
      return
    }
    const shippingDetails = rootGetters['checkout/getShippingDetails']
    const storeView = rootGetters['getCurrentStoreView']
    let address: Record<string, string> = { country_id: storeView?.tax?.defaultCountry }
    if (shippingDetails.firstname) {
      address = {
        region: shippingDetails.region.region,
        region_id: shippingDetails.region.region_id ? shippingDetails.region.region_id : 0,
        country_id: shippingDetails.country_id,
        street: shippingDetails.street,
        postcode: shippingDetails.postcode,
        city: shippingDetails.city,
        region_code: shippingDetails?.region?.region_code ? shippingDetails?.region?.region_code : '',
        custom_attributes: shippingDetails?.custom_attributes
      }
    }

    try {
      const { result, code } = await DataService.post(`${CART_URLS.getShippingMethods}?cartId=${getters.getCartId}`, {
        body: {
          address
        }
      })

      const newShippingMethods = result
        ?.map(method => ({ ...method, is_server_method: true }))
        ?.filter(method => !method.hasOwnProperty('available') || method.available) || [];

      commit(types.SET_SHIPPING_METHODS, newShippingMethods);
    } catch (error) {
      const isNewCartCreated = await dispatch('handleInactiveCart', error);
      if (!isNewCartCreated) throw error;
      await dispatch('fetchCart');
      return dispatch('fetchShippingMethods');
    }
  },
  async fetchShippingInfo ({ commit, dispatch, getters, rootGetters }) {
    const shippingDetails = rootGetters['checkout/getShippingDetails']
    const shippingMethodsData = createOrderData({
      shippingDetails: shippingDetails,
      shippingMethods: rootGetters['checkout/getShippingMethods'],
      paymentMethods: rootGetters['checkout/getPaymentMethods'],
      paymentDetails: rootGetters['checkout/getPaymentDetails']
    })

    const isShippingInformationAvailable = rootGetters['checkout/isShippableAddress'] && (shippingMethodsData.method_code || shippingMethodsData.carrier_code)

    // if shipping information is not available just fetch payment methods
    if (!isShippingInformationAvailable || !shippingMethodsData.country || !getters.getCartId) {
      return dispatch('fetchPaymentMethods')
    }

    // creating payload for request
    const shippingInfoData = {
      shippingAddress: {
        countryId: shippingMethodsData.country,
        region: shippingDetails?.region?.region || '',
        ...(shippingMethodsData.shippingAddress || {}),
        extension_attributes: {}
      },
      billingAddress: shippingMethodsData.billingAddress || {},
      shippingCarrierCode: shippingMethodsData.carrier_code || '',
      shippingMethodCode: shippingMethodsData.method_code || ''
    }

    const extensionAttributes = rootGetters['checkout/getExtensionAttributes']

    if (extensionAttributes) { shippingInfoData.shippingAddress.extension_attributes = { ...extensionAttributes } }

    try {
      const { result, code } = await DataService.post(`${CART_URLS.getShippingInfo}?cartId=${getters.getCartId}`, {
        body: {
          addressInformation: shippingInfoData
        }
      });

      // Shipping info returns totals, so we don't need to call any other api to fetch totals

      commit(types.SET_CART_TOTALS, result.totals || {});

      // Shipping info returns payment methods too
      commit(types.SET_PAYMENT_METHODS, result.payment_methods || []);
    } catch (error) {
      const isNewCartCreated = await dispatch('handleInactiveCart', error);
      if (!isNewCartCreated) throw error;
      await dispatch('fetchCart');
      return dispatch('fetchShippingInfo');
    }
  },
  async collectTotals ({ getters }) {
    const response = await DataService.get(`${CART_URLS.collectTotals}?cartId=${getters.getCartId}`)
  },
  async removeCoupon ({ getters, dispatch }) {
    try {
      const response = await DataService.post(`${CART_URLS.removeCoupon}?cartId=${getters.getCartId}`);
      // Fetch latest cart
      await dispatch('fetchCart');
      return response;
    } catch (error) {
      const isNewCartCreated = await dispatch('handleInactiveCart', error);
      if (!isNewCartCreated) throw error;
      return dispatch('fetchCart');
    }
  },
  async applyCoupon ({ getters, dispatch }, couponCode) {
    if (!couponCode || !getters.getCartId) {
      return false;
    }
    try {
      const response = await DataService.post(`${CART_URLS.applyCoupon}?cartId=${getters.getCartId}&coupon=${couponCode}`);
      // Fetch latest cart
      await dispatch('fetchCart');
      return response;
    } catch (error) {
      const isNewCartCreated = await dispatch('handleInactiveCart', error);
      if (!isNewCartCreated) throw error;
      return dispatch('fetchCart');
    }
  },
  async applyGiftcard ({ getters, dispatch }, giftCode) {
    if (!giftCode?.length || !getters.getCartId) {
      return false;
    }
    try {
      const response = await DataService.post(`${CART_URLS.applyGiftcard}`, {
        body: {
          cartId: getters.getCartId,
          giftCardAccountData: {
            gift_cards: giftCode
          }
        }
      });
      // Fetch latest cart
      await dispatch('fetchCart');
      return response;
    } catch (error) {
      const isNewCartCreated = await dispatch('handleInactiveCart', error);
      if (!isNewCartCreated) throw error;
      return dispatch('fetchCart');
    }
  },
  async removeGiftcard ({ getters, dispatch }, giftCard) {
    if (!giftCard || !getters.getCartId) return;
    try {
      const response = await DataService.delete(`${CART_URLS.removeGiftcard}`, {
        body: {
          cartId: getters.getCartId,
          code: giftCard
        }
      })
      // Fetch latest cart
      await dispatch('fetchCart');
      return response;
    } catch (error) {
      const isNewCartCreated = await dispatch('handleInactiveCart', error);
      if (!isNewCartCreated) throw error;
      return dispatch('fetchCart');
    }
  },
  async checkStorecredit ({ getters }) {
    const response = await DataService.get(CART_URLS.checkStorecredit)
  },
  async fetchTotals ({ commit, getters, dispatch }) {
    if (!getters.getCartId) return;

    try {
      const { result, code } = await DataService.get(`${CART_URLS.getTotals}?cartId=${getters.getCartId}`);
      if (code !== 200) return;
      commit(types.SET_CART_TOTALS, result);
    } catch (error) {
      const isNewCartCreated = await dispatch('handleInactiveCart', error);
      if (!isNewCartCreated) throw error;
      return dispatch('fetchCart');
    }
  },
  async applyStoreCredits ({ getters, dispatch }, fetchTotals = false) {
    const { result, code } = await DataService.post(CART_URLS.applyStorecredit)
    if (code === 200) {
      if (fetchTotals) {
        await dispatch('fetchTotals')
      } else {
        await dispatch('fetchShippingInfo')
      }
    }
    return result;
  },
  async removeStoreCredits ({ getters, dispatch }, fetchTotals = false) {
    const { result, code } = await DataService.post(CART_URLS.removeStorecredit)
    if (code === 200) {
      if (fetchTotals) {
        await dispatch('fetchTotals')
      } else {
        await dispatch('fetchShippingInfo')
      }
    }
    return result;
  },
  async checkGiftcardamount ({ getters }, giftCard: string) {
    if (!giftCard) {
      return
    }
    const response = await DataService.get(`${CART_URLS.checkGiftcardamount}?cartId=${getters.getCartId}&code=${giftCard}`)
    return response
  },
  async redeemGiftcardAsStoreCredit ({ getters }, giftCard: string) {
    const response = await DataService.post(CART_URLS.redeemGiftcardAsStoreCredit, {
      body: {
        code: giftCard
      }
    })
    return response
  },
  prepareProductForDatatracking (vuexContext, { product, event }) {
    return [prepareProduct(product, event)]
  },
  onCartChanged ({ getters, dispatch }) {
    if (!getters.getCartItems?.length) {
      dispatch('emarsys/scarabQueuePush', {
        key: 'cart',
        value: []
      }, { root: true });
      return
    }
    const cartData = getters.getCartItems?.map((cartItem) => ({
      item: cartItem?.parentSku || cartItem?.sku,
      price: cartItem?.totals?.row_total_incl_tax || (cartItem.price_incl_tax * cartItem.qty) || 0,
      quantity: cartItem.qty
    }));
    dispatch('emarsys/scarabQueuePush', {
      key: 'cart',
      value: cartData
    }, { root: true });
  },
  async recalculateTotals ({ commit, getters, dispatch }) {
    if (!getters.getCartId) return;

    try {
      const { result, code } = await DataService.post(`${CART_URLS.recalculateTotals}?cartId=${getters.getCartId}`, null);
      if (code !== 200) return;
      commit(types.SET_CART_TOTALS, result);
    } catch (error) {
      const isNewCartCreated = await dispatch('handleInactiveCart', error);
      if (!isNewCartCreated) throw error;
      return dispatch('fetchCart');
    }
  }
};

export default actions;
