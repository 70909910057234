<template>
  <SfSidebar
    :visible="visible"
    class="sf-sidebar--right sf-sidebar--icon o-microcart-panel"
    @close="clearProductAdded"
  >
    <template #circle-icon>
      {{ null }}
    </template>

    <MMicrocartContent :products="[product]">
      <template #remove-product>
        {{ null }}
      </template>
      <template #title>
        <h6 class="minicart__title title-2">
          Successfully added to your bag
          <a
            class="product-added__close"
            @click.prevent="clearProductAdded"
          >
            <svg
              class="lb-icon"
            >
              <use xlink:href="#cross" />
            </svg>
          </a>
        </h6>
      </template>
      <template #actions>
        <div class="product-added__mobile-action">
          <AButton
            @click="goToCartPage"
            class="sf-button--full-width"
            color="primary"
            variant="contained"
            type="button"
          >
            View Bag
          </AButton>
        </div>
      </template>
    </MMicrocartContent>
  </SfSidebar>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import VueOfflineMixin from 'vue-offline/mixin';
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';

import { SfSidebar } from '@storefront-ui/vue';
import LockDefault from 'common/assets/icons/icon-lock_default.svg'
import AButton from '../atoms/a-button';
import matchMedia from '../../mixins/matchMedia';
import { trackGtmEvents } from 'common/helpers/gtm'
const MMicrocartContent = () => import(/* webpackChunkName: "m-microcart-content" */'./m-microcart-content');

export default {
  name: 'MProductAddedPanel',
  components: {
    MMicrocartContent,
    AButton,
    SfSidebar
  },
  mixins: [VueOfflineMixin, onEscapePress, matchMedia],
  data () {
    return {
      LockDefault,
      visible: false,
      clearProductAddedTimeout: null
    };
  },
  computed: {
    ...mapGetters({
      isCartModuleActive: 'featurehub/isCartModuleActive',
      getCartItems: 'cart/getCartItems'
    }),
    ...mapState({
      productAdded: state => state.productpage.productAdded
    }),
    product () {
      const addedProduct = Object.assign(this.productAdded || {})
      if (!this.isCartModuleActive || !Object.keys(addedProduct).length) {
        return addedProduct
      }
      // Item id is required to update item from mini cart module
      const item = this.getCartItems.find(cartItem => cartItem.sku === addedProduct.sku)
      addedProduct['item_id'] = item.item_id || item.server_item_id
      return addedProduct
    },
    mobileWidth () {
      return '1023px';
    }
  },
  watch: {
    productAdded: function (product) {
      const visible = !!product;
      if (this.clearProductAddedTimeout) {
        clearTimeout(this.clearProductAddedTimeout);
        this.clearProductAddedTimeout = null;
      }
      if (this.isMobile) {
        this.visible = visible;
        return;
      }
      if (!visible) return;
      this.$store.commit('ui/setMicrocart', visible);
      this.clearProductAddedTimeout = setTimeout(() => {
        this.clearProductAdded();
        clearTimeout(this.clearProductAddedTimeout);
        this.clearProductAddedTimeout = null;
      }, 3000);
    },
    $route () {
      this.clearProductAdded()
    }
  },
  methods: {
    ...mapActions('productpage', {
      setProductAdded: 'setProductAdded'
    }),
    closeMiniCart () {
      this.setProductAdded(null);
      this.$store.commit('ui/setMicrocart');
    },
    clearProductAdded () {
      this.setProductAdded(null);
    },
    goToCartPage () {
      trackGtmEvents({ event: 'click_bag_minicart' })
      this.clearProductAdded();
      this.$router.push(localizedRoute('/checkout/cart'));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.product-added {
  &__close {
    position: absolute;
    top: 0;
    right: 4px;
    z-index: 999;
    svg {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }
  &__mobile-action {
    border-top: 1px solid var(--_c-gray-secondary);
  }
}

.minicart__title {
  border-bottom: none !important;
  margin-top: 0;
  padding: 0  !important;
  text-align: left !important;
  color: var(--neutral-900);
  display: flex;
  justify-content: space-between;
}

// Animation from bottom
// override existing class as new animation slide can not be added
::v-deep.sf-sidebar--right {
  .slide-right-enter-active {
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  .slide-right-leave-active {
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both reverse;
  }
  .sf-overlay {
    background: var(--black-opacity);
    z-index: 6;
  }
  @keyframes slide-top {
    0% {
        -webkit-transform: translateY(calc(100vh + 100px));
        transform: translateY(calc(100vh + 100px));
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
  }
}

.o-microcart-panel {
  ::v-deep .sf-sidebar__aside {
    z-index: 15;
    position: fixed;
    background: var(--soft-beige-50);
    border: 1px solid transparent;
    box-shadow: none;
    height: auto;
    bottom: auto;
    top: var(--sidebar-top, 90px);
    .top-divider,
    .free-shipping-bar {
      display: none;
    }
    .total-container {
      border: unset;
    }
  }

  ::v-deep .sf-overlay {
    background: var(--black-opacity);
    z-index: 6;
  }

  ::v-deep .sf-bar {
    display: none !important;
  }

  ::v-deep .sf-sidebar__aside {
    max-height: calc(100vh - var(--bottom-navigation-height));
  }
}

.micro-cart-container {
  ::v-deep .total-section {
    --microcart-total-section-position: relative;
  }
}
</style>
