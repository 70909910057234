import i18n from '@vue-storefront/i18n';
import {
  callClaimReward,
  fetchAvailableRewards,
  fetchAvailableWallets,
  fetchClaimedRewards,
  fetchCustomerLoyaltyStatus,
  fetchDownlodWallet,
  fetchCustomerLoyaltyPointsHistory,
  fetchLoyaltyChallenges,
  leaveLoyaltyProgram,
  fetchReferralsData,
  optInToLoyaltyProgram
} from 'src/modules/loyalty/services';
import * as types from './types';
import DataService from 'common/services/DataService';

const URLS = {
  checkoutAvailableRewards: '/api/ext/loyalty/reward/checkout/available-rewards?categoryId='
}

const showErrorMessage = (message = '') => {
  return {
    type: 'error',
    message: i18n.t(message),
    action1: { label: i18n.t('OK') }
  };
};
const actions = {
  async getLoyaltyChallenges ({ rootGetters }) {
    const token = rootGetters['user/getUserToken'];
    if (!token) return false;
    try {
      return fetchLoyaltyChallenges()
    } catch (error) {
      throw new Error(error)
    }
  },
  async getCustomerLoyaltyStatus ({ commit, getters, rootGetters }) {
    const token = rootGetters['user/getUserToken'];
    if (!token || getters.loadingCustomerLoyaltyStatus) return false;
    commit(types.LOADING_CUSTOMER_LOYALTY_STATUS, true)
    const resp = await fetchCustomerLoyaltyStatus();
    commit(types.LOADING_CUSTOMER_LOYALTY_STATUS, false)
    commit(types.ADD_CUSTOMER_LOYALTY_STATUS, resp?.result?.data);
    commit(types.SET_IS_STATUS_ACTIVE, resp.resultCode === 200)
    return resp.resultCode === 200;
  },
  async getCustomerLoyaltyPointsHistory ({ commit, rootGetters }, { pageNum }) {
    const token = rootGetters['user/getUserToken'];
    if (!token) return;
    if (pageNum === 1) { commit(types.LOADING_POINTS_HISTORY, true) }
    const resp = await fetchCustomerLoyaltyPointsHistory(pageNum);
    commit(types.LOADING_POINTS_HISTORY, false)
    if (resp.resultCode !== 200) { throw new Error('Something went wrong fetchCustomerLoyaltyPointsHistory'); }
    if (pageNum === 1) {
      return commit(types.POINTS_HISTORY, resp?.result);
    }
    const getPointsHistory = rootGetters['loyalty/getPointsHistory'];
    return commit(types.POINTS_HISTORY, { ...resp?.result, pointsHistory: [...getPointsHistory?.pointsHistory, ...resp?.result?.pointsHistory] });
  },
  async joinLoyaltyProgram ({ commit, rootGetters }) {
    const token = rootGetters['user/getUserToken'];
    if (!token) return false;
    const user = rootGetters['user/getCurrentUser'] || null;
    if (!user) { throw new Error('User is not logged in') }
    const website = {
      sg: 'base',
      my: 'malaysia',
      intl: 'intl',
      hk: 'hk',
      id: 'id',
      us: 'us',
      ph: 'ph'
    };

    const payload = {
      action: 'opt_in',
      customer: user.id + '',
      data: {
        email: user.email,
        handler: user.firstname,
        first_name: user.firstname,
        last_name: user.lastname,
        birth_date: user.dob,
        qr_code_data: user.extension_attributes?.customer_unicode,
        magento_website_id: user.website_id + '',
        marketing_optin: true,
        website: website[rootGetters?.getCurrentStoreView?.storeCode || 'sg']
      }
    }
    commit(types.JOINING_LOYALTY_PROGRAM, true);
    const resp = await optInToLoyaltyProgram(payload);
    commit(types.JOINING_LOYALTY_PROGRAM, false);
    return resp.resultCode === 200 || resp.resultCode === 409;
  },
  async getClaimedRewards ({ commit, rootGetters }) {
    const token = rootGetters['user/getUserToken'];
    if (!token) return;
    commit(types.LOADING_CLAIMED_REWARDS, true);
    const resp = await fetchClaimedRewards();
    commit(types.LOADING_CLAIMED_REWARDS, false);
    if (resp.resultCode !== 200) { throw new Error('Something went wrong fetchClaimedRewards'); }
    commit(types.ADD_CLAIMED_REWARDS, resp?.result?.data);
  },
  async getAvailableRewards ({ commit, rootGetters }, pointsFilter) {
    const token = rootGetters['user/getUserToken'];
    if (!token) return;
    commit(types.LOADING_AVAILABLE_REWARDS, true);
    commit(types.UPDATE_POINTS_FILTER_APPLIED, pointsFilter && pointsFilter !== '1-*')
    commit(types.LOADING_PARTNER_PERKS, !(pointsFilter))
    const resp = await fetchAvailableRewards(pointsFilter, token);
    commit(types.LOADING_AVAILABLE_REWARDS, false);
    commit(types.LOADING_PARTNER_PERKS, false)
    if (resp.code !== 200) { throw new Error('Something went wrong fetchAvailableRewards'); }
    commit(types.ADD_AVAILABLE_REWARDS, resp?.result?.data);
  },

  async getAvailableGCRewards ({ commit, getters, rootGetters }, { page = 1, limit = 10, reset = false }) {
    const token = rootGetters['user/getUserToken'];
    if (!token || getters.loadingAvailableGCRewards) return;
    commit(types.LOADING_AVAILABLE_GC_REWARDS, true);
    const url = URLS.checkoutAvailableRewards + `1&page=${page}&limit=${limit}`;
    const resp = await DataService.get(url);
    commit(types.LOADING_AVAILABLE_GC_REWARDS, false);
    if (resp.code !== 200) { throw new Error('Something went wrong fetchGCAvailableRewards'); }
    commit(types.ALL_AVAILABLE_GC_REWARDS_LOADED, resp?.result?.length !== limit);
    if (reset) {
      commit(types.ADD_AVAILABLE_GC_REWARDS, resp?.result);
    } else {
      const rewards = rootGetters['loyalty/availableGCRewards'];
      // remove duplicates from two arrays if any exists
      const items = [...new Set([...rewards, ...resp.result])]
      commit(types.ADD_AVAILABLE_GC_REWARDS, items);
    }
  },

  async getCheckoutCouponRewards ({ state, commit, rootGetters }, { page, limit }) {
    const token = rootGetters['user/getUserToken'];
    if (!token || state.loadingCheckoutCouponRewards) return;
    const rewards = rootGetters['loyalty/checkoutCouponRewards'];
    const rewardsTotal = rootGetters['loyalty/checkoutCouponRewardsTotal'];
    if (rewards?.length && rewards?.length >= rewardsTotal) return;
    commit(types.LOADING_CHECKOUT_COUPON_REWARDS, true);
    const url = URLS.checkoutAvailableRewards + `2&page=${page}&limit=${limit}`;
    const resp = await DataService.get(url);
    commit(types.LOADING_CHECKOUT_COUPON_REWARDS, false);
    if (resp.code !== 200) { throw new Error('Something went wrong getCheckoutCouponRewards'); }
    commit(types.ADD_CHECKOUT_COUPON_REWARDS, rewards.concat(resp?.result?.rewards));
    commit(types.ADD_CHECKOUT_COUPON_REWARDS_TOTAL, resp?.result?.total);
  },

  async getAvailableWallets ({ commit, rootGetters }) {
    const token = rootGetters['user/getUserToken'];
    if (!token) return;
    commit(types.LOADING_AVAILABLE_WALLETS, true);
    const resp = await fetchAvailableWallets();
    commit(types.LOADING_AVAILABLE_WALLETS, false);
    if (resp.resultCode !== 200) { throw new Error('Something went wrong fetchAvailableWallets'); }
    commit(types.ADD_AVAILABLE_WALLETS, resp?.result?.data)
  },
  async downlodWallet ({ commit, rootGetters }, walletType: string) {
    const token = rootGetters['user/getUserToken'];
    if (!token) return false;
    if (!walletType) throw new Error('walletType is missing');
    const resp = await fetchDownlodWallet(walletType);
    if (resp.resultCode !== 200) { throw new Error('Something went wrong fetchDownlodWallet'); }
    return resp?.result?.data;
  },
  async claimReward ({ commit, dispatch, rootGetters }, rewardID: string) {
    const token = rootGetters['user/getUserToken'];
    if (!token) return;
    try {
      if (!rewardID) throw new Error('Reward ID is missing');
      commit(types.CLAIMING_REWARD, true);
      const resp = await callClaimReward(rewardID);
      const upStreamError = resp.result?.upstream?.body?.error?.message
      if (upStreamError) {
        commit(types.CLAIMING_REWARD, false);
        return { err: upStreamError }
      }
      if (resp.resultCode !== 200) {
        throw new Error('Something went wrong while claiming rewards');
      }
      commit(types.CLAIMING_REWARD, false);
      return resp?.result?.data;
    } catch (error) {
      commit(types.CLAIMING_REWARD, false);
      dispatch('notification/spawnNotification', showErrorMessage(error.message), { root: true })
    }
  },
  async leaveLoyaltyProgram ({ dispatch, rootGetters }) {
    const token = rootGetters['user/getUserToken'];
    if (!token) return false;
    try {
      const resp = await leaveLoyaltyProgram();
      if (resp.resultCode !== 200) {
        throw new Error('Something went wrong while leaving the loyalty program');
      }
      const isSuccess = !!resp?.result?.message
      if (isSuccess) {
        await dispatch('user/refreshUserProfile', { resolvedFromCache: false }, { root: true })
      }
      return isSuccess;
    } catch (error) {
      console.error(error)
      dispatch('notification/spawnNotification', showErrorMessage('Something went wrong while leaving loyalty program'), { root: true })
    }
  },
  async fetchReferralsData ({ dispatch, commit, rootGetters }) {
    const token = rootGetters['user/getUserToken'];
    if (!token) return;
    const errorMessage = 'Something went wrong while fetching referrals'
    try {
      const resp = await fetchReferralsData();
      if (resp.resultCode !== 200) {
        throw new Error(errorMessage);
      }
      commit(types.LOYALTY_REFERRALS_DATA, resp?.result?.data || {})
    } catch (error) {
      console.error(error)
      dispatch('notification/spawnNotification', showErrorMessage(errorMessage), { root: true })
    }
  },
  setReferralData ({ commit }, referralData) {
    commit(types.SET_REFERRAL_DATA, referralData)
  },
  resetLoyaltyModule ({ commit }) {
    commit(types.RESET_LOYALTY_MODULE)
  }
};

export default actions;
