export const getDefaultState = () => ({
  shippingAddress: null,
  billingAddress: null,
  paymentMethods: [],
  shippingMethods: [],
  shippingMethod: null,
  paymentMethod: null,
  forterToken: null,
  cardDetails: {
    issuerBin: '',
    lastFour: null
  }
});
