function getProps (props) {
  const {
    title,
    subtitle,
    cta_title: ctaTitle,
    background_color: backgroundColor,
    text_color: textColor,
    cta_links: ctaLinks,
    background_image_mobile: backgroundImageMobile,
    background_image_desktop: backgroundImageDesktop,
    show_border: showBorder
  } = props;

  return {
    title,
    subtitle,
    ctaTitle,
    backgroundColor,
    ctaLinks,
    textColor,
    backgroundImageMobile,
    backgroundImageDesktop,
    showBorder
  }
}

const CategoryBannerTextOnlyLayout = () => ({
  type: 'category_banner_text_only_layout',
  props: getProps
});

export default CategoryBannerTextOnlyLayout
