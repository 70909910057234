import { promotionRoutes } from './promotions'
import config from 'config'
import { updateOosFilter } from 'src/common/helpers/router.helper.ts'

const Home = () =>
  import(/* webpackChunkName: "home" */ 'common/pages/HomeContainer');
const StyleQuizResult = () =>
  import(/* webpackChunkName: "style-machine" */ 'common/pages/StyleQuizResult');
const ErrorPage = () =>
  import(/* webpackChunkName: "error" */ 'common/pages/Error');
const Product = () =>
  import(/* webpackChunkName: "product" */ 'common/pages/Product');
const Category = () =>
  import(/* webpackChunkName: "category" */ 'common/pages/Category');
const Checkout = () =>
  import(/* webpackChunkName: "checkout" */ 'common/pages/Checkout');
const MyAccount = () =>
  import(/* webpackChunkName: "my-account" */ 'common/pages/MyAccount');
const Static = () =>
  import(/* webpackChunkName: "cms" */ 'common/pages/Static');
const TermsAndConditions = () =>
  import(/* webpackChunkName: "cms" */ 'common/pages/TermsAndConditionsCMS');
const Cart = () =>
  import(/* webpackChunkName: "cart" */ 'common/pages/Cart');
const OrderConfirmed = () =>
  import(/* webpackChunkName: "order" */ 'common/pages/OrderConfirmed');
const OrderInProgress = () =>
  import(/* webpackChunkName: "order" */ 'common/pages/OrderInProgress');
const LeadCreation = () =>
  import(/* webpackChunkName: "cms" */ 'common/pages/LeadCreation');
const LbAnniversary = () =>
  import(/* webpackChunkName: "cms" */ 'theme/pages/ContentLandingPages/LbAnniversary')
const LBCommunityPlus = () =>
  import(/* webpackChunkName: "cms" */ 'theme/pages/ContentLandingPages/LBCommunityPlus')
const LoyaltyProgramLbcommunityPlus = () => import('common/pages/LoyaltyProgramLbcommunityPlus')

const Content = () =>
  import(/* webpackChunkName: "content" */ 'common/pages/Content');
const Recommendations = () =>
  import(/* webpackChunkName: "recommendations" */ 'common/pages/Recommendations');
const GeneralSizeGuide = () => import(/* webpackChunkName: "cms" */'common/pages/GeneralSizeGuide/GeneralSizeGuide');
const StyleMachine = () =>
import(
  /* webpackChunkName: "style-machine" */ 'common/pages/StyleMachine'
);
const ContactUs = () =>
  import(/* webpackChunkName: "contact-us" */ 'common/pages/ContactUs');

const SearchResult = () =>
  import(/* webpackChunkName: "search-result" */ 'common/pages/SearchResult');

const ReviewCollection = () =>
  import(/* webpackChunkName: "review-collection" */ 'common/pages/ReviewCollection');

const ReviewsListing = () =>
  import(/* webpackChunkName: "review-listing" */ 'common/pages/ReviewsListing');
const SbBlockRoute = [{ name: 'cmspage-blocks', path: '/blocks/:slug', component: Content }]
const Faq = () =>
  import(/* webpackChunkName: "faq" */ 'common/pages/Faq');

let routes = [
  ...promotionRoutes,
  // { name: 'home', path: '/', component: Home, alias: '/pwa.html' },
  { name: 'faq', path: '/faq', component: Faq },
  { name: 'contact-us', path: '/contact-us', component: ContactUs },
  { name: 'id-headless-home', path: '/', component: Home, alias: '/pwa.html' },
  { name: 'style-quiz', path: '/style-quiz', component: StyleMachine },
  { name: 'style-quiz-results', path: '/style-quiz-results', component: StyleQuizResult },
  { name: 'checkout', path: '/checkout', component: Checkout },
  // { name: 'adyen-process', path: '/adyen/process/result', component: AdyenProcess }, // adyen is disabled for Indonesia
  { name: 'loyalty-program-lbcommunity', path: '/loyalty-program-lbcommunity', component: LoyaltyProgramLbcommunityPlus },
  { name: 'order-confirmed', path: '/checkout/onepage/success/:orderId', component: OrderConfirmed },
  { name: 'order-in-progress', path: '/checkout/onepage/progress/:userId', component: OrderInProgress },
  { name: 'cart', path: '/checkout/cart', component: Cart },
  { name: 'general-size-charts', path: `/general-size-charts`, component: GeneralSizeGuide },
  { name: 'lb-anniversary', path: `/lb-anniversary`, component: LbAnniversary },
  { name: 'lbcommunity-plus', path: `/promotions/lbcommunity-plus`, component: LBCommunityPlus },
  { name: 'legal', path: '/legal', component: Static },
  { name: 'lead-creation', path: '/new-user-sign-up-special-offer-hongkong', component: LeadCreation },
  { name: 'lead-creation-subscription', path: '/subscription-thank-you', component: LeadCreation },
  { name: 'my-account', path: '/my-account', component: MyAccount },
  { name: 'terms-and-conditions', path: '/terms-and-conditions', component: TermsAndConditions },
  { name: 'error', path: '/error', component: ErrorPage },
  { name: 'virtual-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'bundle-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'simple-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'downloadable-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'grouped-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'configurable-product', path: '/p/:parentSku/:slug/:childSku', component: Product },
  { name: 'product', path: '/p/:parentSku/:slug/:childSku', component: Product },
  { name: 'category', path: '/c/:slug', component: Category, beforeEnter: (to, from, next) => updateOosFilter(to, from, next) },
  { name: 'cmspage', path: '/pages/:slug', component: Content },
  { name: 'cmspage-global', path: '/global/:slug', component: Content },
  ...(!config?.server.isProduction ? SbBlockRoute : []),
  { name: 'recommendations', path: '/recommendations', component: Recommendations },
  { name: 'search-result', path: '/search', component: SearchResult },
  { name: 'reviews-listing', path: '/reviews', component: ReviewsListing },
  { name: 'review-collection', path: '/add-review', component: ReviewCollection },
  { name: 'page-not-found', path: '*', component: ErrorPage }
];

export default routes;
