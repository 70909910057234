<template>
  <div class="search-results-section">
    <p v-if="visibleProducts && visibleProducts.length > 0" class="section-header">
      {{ $t('PRODUCTS') }}
    </p>
    <div
      v-if="visibleProducts.length > 0"
      class="search-results"
    >
      <OProductCard
        v-for="(product, idx) in visibleProducts"
        :key="product.id"
        :product="product"
        :color-selected="true"
        :show-image-on-hover="true"
        class="products__product-card"
        :index="idx"
        @click.native="handleClosePanel(product, idx)"
      />
    </div>
    <div v-else class="no-results">
      <EmptySearchResultBlock
        :body="currentBlock"
        :search-text="searchText"
      />
    </div>
    <div v-if="hasProducts" class="load-more-container">
      <AButton
        v-if="!isNewSearchEnabled"
        class="load-more-btn"
        color="primary"
        variant="contained"
        @click.native="loadMoreProducts"
      >
        {{ $t('Load More') }}
      </AButton>
      <router-link v-else :to="localizedRoute(`search?q=${searchText}`)" @click.native="closeSearchpanel">
        <AButton
          class="load-more-btn"
          color="primary"
          variant="contained"
        >
          <span>
            {{ $t('View All') }}&nbsp;{{ resultCount }}&nbsp;{{ $t('Results') }}
          </span>
        </AButton>
      </router-link>
    </div>
  </div>
</template>

<script>
import EmptySearchResultBlock from 'src/modules/storyblok/components/EmptySearchResultBlock'
import AButton from 'common/components/atoms/a-button';
import SearchPanel from 'src/compatibility/components/blocks/SearchPanel/SearchPanel'
import OProductCard from 'src/common/components/organisms/o-product-card';
import { prepareProductCategory } from 'common/helpers/gtm'
import config from 'config';
import { mapGetters } from 'vuex';

export default {
  name: 'MSearchProducts',
  components: { AButton, OProductCard, EmptySearchResultBlock },
  mixins: [SearchPanel],
  props: {
    hasProducts: {
      type: Boolean,
      default: true
    },
    visibleProducts: {
      type: Array,
      default: () => []
    },
    searchText: {
      type: String,
      default: ''
    },
    resultCount: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      blockKey: 'blocks/search',
      blockData: null
    }
  },
  computed: {
    ...mapGetters({
      getCurrentStoreView: 'getCurrentStoreView'
    }),
    currentBlock () {
      return this.blockData?.components || [];
    },
    isNewSearchEnabled () {
      return config?.featureFlags?.['NEW_SEARCH']?.isActive;
    }
  },
  async created () {
    const storeCode = this.getCurrentStoreView?.storeCode;
    const version = 'published';
    this.blockData = await this.$store.dispatch('storyblok/fetchCmsBlock', {
      slug: `${storeCode}/${this.blockKey}`,
      version: version
    });
  },
  methods: {
    loadMoreProducts () {
      this.$bus.$emit('load-more-products')
    },
    handleClosePanel (product, idx) {
      if (product) {
        product = prepareProductCategory(product, idx)
        product.sku = product.id
        product.position = idx + 1
        this.$gtm?.trackEvent({
          event: 'search_result_click',
          'search_result': {
            'products': [product]
          },
          search_term: this.searchText,
          value: product.name
        })
      }
      this.closeSearchpanel();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.search-results-section {
  width: 100%;
  .section-header {
    color: var(--neutral-900);
    display: inline-block;
    padding: 0;
    font-size: var(--font_size_75);
    margin: 0 10px 5px 0;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.4px;
  }
  .search-results {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    padding: 0;
    .search-result {
      word-break: break-word;
       margin-bottom: 15px;
       padding: 0 5px;
       box-sizing: border-box;
      p {
        color: var(--text-color-primary);
        width: 80%;
        font-size: var(--font_size_75);
        margin-bottom: 0;
      }
      img {
        height: auto;
        max-width: 95%;
      }
    }
    @include for-desktop {
      margin: 0 -15px;
    }
    ::v-deep.sf-product-card {
      @media (min-width: $tablet-min) and (max-width: $tablet-max) {
        --product-card-max-width: 33%;
        flex: 1 1 33%;
      }
      @include for-desktop {
        flex: 0 0 25%;
      }
      flex: 1 1 50%;
      --product-card-max-width: 50;
       .sf-product-card__image-wrapper {
      margin-bottom: 15px;
    }
    }
  }
  .no-results {
    font-size: var(--font_size_75);
    @include for-desktop {
      height: calc(100vh - 280px);
      max-height: calc(100vh - 280px);
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
}
@media screen and (min-width: $desktop-min) {
.search-result {
    flex: 25% 0 1;
  }
}
@media only screen and (max-width: $tablet-min) {
  .search-result.search-result {
    flex: 50% 0 1;
  }
}
.load-more-container {
  padding: var(--spacer-lg) 0 var(--spacer-2xl) 0;
  .load-more-btn {
    width: 250px;
    margin: 0 auto;
  }
}
</style>
