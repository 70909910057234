import config from 'config';
import { TaskQueue } from '@vue-storefront/core/lib/sync';
import Task from '@vue-storefront/core/lib/sync/types/Task';
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';

export const fetchCurrentCountry = async (): Promise<Task> => {
  const url = getApiEndpointUrl(config.countryCode.endpoint, 'getCountryCode');

  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
}
