<template>
  <a
    class="a-link"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!--@slot Use this slot to place content inside the link.-->
    <slot />
  </a>
</template>

<script>
export default {
  name: 'ALink'
}
</script>

<style lang="scss" scoped>
.a-link {
  font-family: var(--font-secondary);
  position: relative;
  color: var(--neutral-900);
  font-size: var(--a-link-font-size, var(--font_size_75));
  font-weight: 600;
  padding-bottom: 1px;
  text-decoration: none;
  text-transform: none;
  &:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: var(--neutral-900);
    bottom: 0;
    left: 0;
    visibility: visible;
    transform: scaleX(1);
    transition: all 0.3s ease-in-out 0s;
  }
  &:hover:after {
    visibility: hidden;
    transform: scaleX(0);
  }
}
</style>
