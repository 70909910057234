import config from 'config';
import { TaskQueue } from '@vue-storefront/core/lib/sync';
import Task from '@vue-storefront/core/lib/sync/types/Task';
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';

export const checkProductAvailability = async (productSku: string): Promise<Task> => {
  if (!productSku) throw new Error('Product SKU not found!');

  const url = getApiEndpointUrl(config?.storeAvailability?.endpoint, 'checkAvailability')
    .replace('{{productSKU}}', encodeURIComponent(productSku))
  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
};
