import config from 'config';
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import Task from '@vue-storefront/core/lib/sync/types/Task'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';

export const getCities = async (searchText: string, regionCode: string): Promise<Task> => {
  const url = processLocalizedURLAddress(
    getApiEndpointUrl(config.address.endpoints, 'idCities') + `?search=${searchText}&regionCode=${regionCode}`
  );

  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
};
