import { refreshToken,
  login as userLogin,
  fetchStoreCreditsNext,
  subscribeNewsletterNext,
  updateProfile,
  getAttributeData,
  getNotifiedDifferentCountryNext,
  register,
  getSingleOrder
} from './service'
import rootStore from '@vue-storefront/core/store'
import { extendStore, onlineHelper } from '@vue-storefront/core/helpers';
import { UserService } from '@vue-storefront/core/data-resolver';
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { userHooksExecutors } from '@vue-storefront/core/modules/user/hooks';
import { UserProfile } from '@vue-storefront/core/modules/user/types/UserProfile';
import * as types from '@vue-storefront/core/modules/user/store/mutation-types';
import * as typesNext from './mutation-types';
import i18n from '@vue-storefront/i18n';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import { DataResolver } from '@vue-storefront/core/data-resolver/types/DataResolver';
import { fetchCurrentCountry } from 'src/modules/current-country/services'
import DataService from 'common/services/DataService'
const KEY = 'user-next';

const ADDRESS_URLS = {
  addAddress: '/api/ext/address/add',
  updateAddress: '/api/ext/address/update',
  deleteAddress: '/api/ext/address/remove'
};

const userNextStore = {
  namespaced: true
};

const userNextModule = {
  state: {
    isLoadingOrders: true,
    storeCredits: null,
    attributes: null,
    storeCreditsApplied: false,
    passwordChangeSuccess: false,
    currentOrder: null,
    currentLocation: '',
    authModalVisible: false,
    authModalData: null,
    isLocationLoading: false,
    guestUser: false
  },
  getters: {
    isGuestUser (state, guestUser) {
      state.guestUser = guestUser
    },
    isLoadingOrders (state) {
      return state.isLoadingOrders
    },
    getCurrentUser (state) {
      return state.current;
    },
    haveAddresses (state) {
      return !!state.current?.addresses.length;
    },
    getUserId (state) {
      return state.current?.id
    },
    // For new customres, on staging and prod group id is 4, right now we don't have anything else to check if user is new user or existing user
    getIsNewCustomer: (state, getters) => state.groupId === 4,
    getStoreCredits: state => state.storeCredits,
    isStoreCreditApplied: state => state.storeCreditsApplied,
    showPasswordChangeSuccess: state => state.passwordChangeSuccess,
    getCurrentOrder: state => state.currentOrder,
    getUserCurrentLocation (state) {
      return state.currentLocation;
    },
    getAuthModalVisibility (state) {
      return state.authModalVisible;
    },
    getAuthModalData (state) {
      return state.authModalData;
    },
    isLocationLoading (state) {
      return state.isLocationLoading
    },
    getOrdersHistory (state) {
      return state.orders_history;
    }
  },
  mutations: {
    [typesNext.TOGGLE_STORE_CREDIT] (state, storeCreditsApplied) {
      state.storeCreditsApplied = storeCreditsApplied
    },
    [typesNext.CLOSE_AUTH_MODAL] (state) {
      state.authModalVisible = false;
    },
    [typesNext.TOGGLE_AUTH_MODAL] (state, authModalData) {
      state.authModalVisible = !state.authModalVisible;
      if (!state.authModalVisible) {
        state.authModalData = null;
        return;
      }
      state.authModalData = authModalData;
    },
    [typesNext.UPDATE_STORE_CREDITS] (state, storeCredits) {
      state.storeCredits = storeCredits;
    },
    [typesNext.CUSTOMER_ATTRIBUTE] (state, attributes) {
      state.attributes = attributes;
    },
    [typesNext.LOADING_ORDERS_HISTORY] (state, payload) {
      state.isLoadingOrders = payload;
    },
    [typesNext.TOGGLE_PASSWORD_CHANGE_SUCCESS] (state, payload) {
      state.passwordChangeSuccess = payload;
    },
    [typesNext.RESET_USER_TOKEN] (state) {
      state.refreshToken = ''
      StorageManager?.get('user')?.setItem?.('current-refresh-token', '')
    },
    [typesNext.RESET_USER_ORDERS_HISTORY] (state) {
      state.orders_history = []
      StorageManager?.get('user')?.setItem?.('orders-history', [])
    },
    [typesNext.SET_CURRENT_ORDER_DETAILS] (state, payload) {
      state.currentOrder = payload
    },
    [typesNext.USER_CURRENT_LOCATION] (state, country) {
      state.currentLocation = country
    },
    [typesNext.USER_CURRENT_LOCATION_LOADING] (state, isLoading) {
      state.isLocationLoading = isLoading
    }
  },
  actions: {
    async isEmailAvailable (vuexContext, email) {
      const response = await DataService.post('/api/user/check-email', {
        body: {
          email
        }
      })
      return response.result || false
    },
    async restoreCurrentUserFromCache ({ commit, dispatch, rootGetters }) {
      const usersCollection = StorageManager.get('user')
      const currentUser = await usersCollection.getItem('current-user')

      if (!currentUser) {
        return null
      }
      commit(types.USER_INFO_LOADED, currentUser)
      await dispatch('setUserGroup', currentUser)
      EventBus.$emit('user-after-loggedin', currentUser)
      await dispatch('cart-v1/mergeOrFetchCart', null, {
        root: true
      })

      return currentUser
    },
    async refreshUserProfile ({ commit, dispatch, rootGetters }, { resolvedFromCache }) {
      const resp = await UserService.getProfile();

      if (resp.resultCode === 200) {
        commit(types.USER_INFO_LOADED, resp.result); // this also stores the current user to localForage
        await dispatch('setUserGroup', resp.result);
        dispatch('loyalty/getCustomerLoyaltyStatus', null, { root: true })
      }

      if (!resolvedFromCache && resp.resultCode === 200) {
        EventBus.$emit('user-after-loggedin', resp.result);
        await dispatch('cart-v1/mergeOrFetchCart', null, {
          root: true
        })

        return resp;
      }
    },
    async refreshOrdersHistory ({ commit }, { pageSize = 10, currentPage = 1 }) {
      commit(typesNext.LOADING_ORDERS_HISTORY, true)
      const resp = await UserService.getOrdersHistory(pageSize, currentPage)

      if (resp.code === 200) {
        commit(types.USER_ORDERS_HISTORY_LOADED, resp.result) // this also stores the current user to localForage
        commit(typesNext.LOADING_ORDERS_HISTORY, false)
        EventBus.$emit('user-after-loaded-orders', resp.result)
      }

      return resp
    },
    getNotifiedDifferentCountry (_, { email, countryId }) {
      return getNotifiedDifferentCountryNext(email, countryId)
    },
    subscribeNewsletter (_, email: string) {
      return subscribeNewsletterNext(email)
    },
    async update (_, profile: UserProfile) {
      return updateProfile(profile, 'user/handleUpdateProfile');
    },
    async handleUpdateProfile ({ dispatch }, event) {
      if (event.resultCode === 200) {
        dispatch('user/setCurrentUser', event.result, { root: true });
      }
    },
    async fetchStoreCredits (
      { commit, state: { storeCredits, token } },
      { forceUpdateStoreCredits = false }
    ) {
      if (!storeCredits || forceUpdateStoreCredits) {
        storeCredits = 0;

        if (token && token !== '') {
          try {
            const result = await fetchStoreCreditsNext();
            storeCredits = (result && typeof result.result === 'number') ? result.result : 0;
          } catch (err) {
            storeCredits = 0;
          }
        }
        commit(typesNext.UPDATE_STORE_CREDITS, storeCredits);
      }
    },
    async fetchCustomerAttributes (
      { commit, state: { attributes } }
    ) {
      if (!attributes) {
        attributes = [];
        try {
          const result = await getAttributeData();
          attributes = result ? result.result : [];
        } catch (err) {
          attributes = 0;
        }
        commit(typesNext.CUSTOMER_ATTRIBUTE, attributes);
      }
    },
    async login ({ commit, dispatch }, { username, password }) {
      const resp = await userLogin(username, password);
      userHooksExecutors.afterUserAuthorize(resp);

      if (resp.code === 200) {
        try {
          await dispatch('resetUserInvalidateLock', {}, { root: true });
          commit(types.USER_TOKEN_CHANGED, {
            newToken: resp.result,
            meta: resp.meta
          }); // TODO: handle the "Refresh-token" header
          await dispatch('sessionAfterAuthorized', {
            refresh: true,
            useCache: false
          });
          await dispatch('fetchStoreCredits', {
            forceUpdateStoreCredits: true
          });
        } catch (err) {
          await dispatch('clearCurrentUser');
          throw new Error(err);
        }
      }

      return resp;
    },
    async refresh ({ commit }) {
      const usersCollection = StorageManager.get('user');
      const currentRefreshToken = await usersCollection.getItem('current-refresh-token');
      if (!currentRefreshToken) {
        return null;
      }
      const newToken = await refreshToken(currentRefreshToken);
      if (newToken) {
        commit(types.USER_TOKEN_CHANGED, { newToken });
      } else {
        // Due to multiple requests, "userTokenInvalidateAttemptsCount" exceeds max attempts limit and
        // "userTokenInvalidateLock" get -1 value which in results blocks further requests from application
        // This will allow customers to login again and get new token and refresh token.
        setTimeout(() => {
          // Reset token invalidate lock to allow further requests
          rootStore.state.userTokenInvalidateLock = 0;
        }, 3000);
      }
      return newToken;
    },
    clearCurrentUser ({ rootState, commit, dispatch }) {
      commit(types.USER_TOKEN_CHANGED, '')
      commit(typesNext.RESET_USER_TOKEN)
      commit(typesNext.RESET_USER_ORDERS_HISTORY)
      commit(types.USER_GROUP_TOKEN_CHANGED, '')
      commit(types.USER_GROUP_CHANGED, null)
      commit(types.USER_INFO_LOADED, null)
      dispatch('checkout/savePersonalDetails', {}, { root: true })
      dispatch('checkout/saveShippingDetails', {}, { root: true })
      dispatch('checkout/savePaymentDetails', {}, { root: true })
    },
    async logout ({ commit, dispatch }, { silent = false }) {
      commit(types.USER_END_SESSION);
      commit(typesNext.UPDATE_STORE_CREDITS, 0);
      dispatch('clearCurrentUser');
      dispatch('cart/resetCartModule', null, { root: true });
      dispatch('cart/disconnect', {}, { root: true });
      EventBus.$emit('user-after-logout');
      dispatch('loyalty/resetLoyaltyModule', null, { root: true })
      // clear cart without sync, because after logout we don't want to clear cart on backend
      // user should have items when he comes back
      await dispatch('cart/clear', { sync: false }, { root: true });

      if (!silent) {
        await dispatch(
          'notification/spawnNotification',
          {
            type: 'success',
            message: i18n.t("You're logged out"),
            action1: { label: i18n.t('OK') }
          },
          { root: true }
        );
      }
      userHooksExecutors.afterUserUnauthorize();
      sessionStorage.setItem('checkoutPageView', '1');
      sessionStorage.setItem('addToCartOncePerSessionPageView', '1');
    },
    async sessionAfterAuthorized ({ dispatch }, { refresh = onlineHelper.isOnline, useCache = true }) {
      await dispatch('me', { refresh, useCache })
    },
    toggleAuthModal ({ commit }, authModalData) {
      commit(typesNext.TOGGLE_AUTH_MODAL, authModalData)
    },
    closeAuthModal ({ commit }) {
      commit(typesNext.CLOSE_AUTH_MODAL)
    },
    setStoreCreditToggle ({ commit }, storeCreditsApplied) {
      commit(typesNext.TOGGLE_STORE_CREDIT, storeCreditsApplied)
    },
    async changePassword ({ commit }, passwordData) {
      const resp = await UserService.changePassword(passwordData);
      if (resp.code === 200) {
        commit(typesNext.TOGGLE_PASSWORD_CHANGE_SUCCESS, true);

        setTimeout(() => {
          commit(typesNext.TOGGLE_PASSWORD_CHANGE_SUCCESS, false);
        }, 5000);
      }
      return resp;
    },
    async register (context, { offlineStore, password, ...customer }) {
      return register(customer as DataResolver.Customer, password, offlineStore)
    },
    async getSingleOrderDetails ({ commit }, { magentoOrderId, orderEncodedData = '' }) {
      let resp = await getSingleOrder(magentoOrderId, orderEncodedData)

      if (resp.code === 200) {
        commit(typesNext.SET_CURRENT_ORDER_DETAILS, resp.result)
      }

      return resp.result
    },
    resetSingleOrderDetails ({ commit }) {
      commit(typesNext.SET_CURRENT_ORDER_DETAILS, null)
    },
    async setUserCurrentLocation ({ commit, getters }) {
      if (getters.isLocationLoading) {
        return
      }
      commit(typesNext.USER_CURRENT_LOCATION_LOADING, true)
      const { result = {} } = await fetchCurrentCountry();
      commit(typesNext.USER_CURRENT_LOCATION_LOADING, false)
      commit(typesNext.USER_CURRENT_LOCATION, result.countryCode || '')
    },
    async fetchUserProfile ({ commit, dispatch }) {
      const resp = await UserService.getProfile();

      if (resp.resultCode === 200) {
        commit(types.USER_INFO_LOADED, resp.result); // this also stores the current user to localForage
        await dispatch('setUserGroup', resp.result);
      }
    },
    async addAddress ({ commit }, address) {
      if (address.id) throw new Error('Invalid request');

      return DataService.post(`${ADDRESS_URLS.addAddress}`, {
        body: address
      });
    },
    async updateAddress ({ commit, dispatch }, address) {
      if (!address.id) throw new Error('Invalid request');

      return DataService.put(`${ADDRESS_URLS.updateAddress}`, {
        body: address
      }).then((response) => {
        dispatch('setCurrentUser', response.result);
        commit(types.USER_INFO_LOADED, response.result);
        return response;
      }).finally(() => {
        // hide progress
      })
    },
    async deleteAddress ({ commit, dispatch }, addressId) {
      return DataService.delete(`${ADDRESS_URLS.deleteAddress}/${addressId}`).then((response) => {
        dispatch('fetchUserProfile');
        return response;
      }).finally(() => {
        // hide progress bar
      });
    }
  }
};

export const UserNextModule: StorefrontModule = function ({
  app,
  store,
  appConfig,
  router,
  moduleConfig
}) {
  store.registerModule(KEY, userNextStore);
  extendStore('user', userNextModule);
};
