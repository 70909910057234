import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { getDefaultState } from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const checkoutV1Store = {
  namespaced: true,
  state: getDefaultState(),
  getters,
  actions,
  mutations
};

const STORE_KEY = 'checkout-v1';

export const CheckoutV1Store: StorefrontModule = function ({ store }) {
  store.registerModule(STORE_KEY, checkoutV1Store);
};
