<template>
  <SfButton
    class="a-button"
    :class="[
      buttonClass,
      {
        primary: primary,
        secondary: secondary,
        tertiary: tertiary,
        block: block
      }
    ]"
    :disabled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </SfButton>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';

export default {
  components: {
    SfButton
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    tertiary: {
      type: Boolean,
      default: false
    },
    hyperLink: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    color: {
      validator: function (value) {
        // The value must match one of these strings
        return ['primary', 'secondary', 'tertiary'].indexOf(value) !== -1;
      },
      default: 'primary'
    }
  },
  computed: {
    buttonClass () {
      // Prevent layout breaking if the button is using primary or secondary prop.
      if (this.primary || this.secondary) {
        return;
      }
      return `a-button--${this.color}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.block {
  width: 100%;
}
.a-button {
  --button-background: transparent;
  --button-border-width: 1px;
  --button-border-color: transparent;
  --button-box-shadow: none;
  --button-color: var(--bold-brown-base);
  --button-padding: 18px 24px;

  opacity: 1;
  cursor: pointer;
  font-size: var(--font_size_75);
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: 600;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  text-transform: capitalize;

  &.primary,
  &--primary {
    --button-background: var(--bold-brown-base);
    --button-color: var(---white);
    &:hover:enabled {
      background-color: var(--bold-brown-400);
    }
  }

  &.secondary,
  &--secondary {
    --button-border-color: var(--neutral-100);
    --button-color: var(--neutral-900);
    --button-background: var(--white);

    @include for-desktop {
      &:hover:enabled {
        background-color: var(--neutral-50);
      }
    }
  }

  &.tertiary,
  &--tertiary {
    --button-border-color: var(--neutral-100);
    --button-color: var(--neutral-900);
    --button-background: transparent;
    --button-border-width: 0;

    @include for-desktop {
      &:hover:enabled {
        text-decoration: underline;
        background-color: transparent;
      }
    }
  }

  &:disabled {
    --button-cursor: not-allowed;
    --button-opacity: 0.6;
    background: var(--bold-brown-200);
  }
  &.disabled {
    --button-cursor: not-allowed;
    --button-opacity: 0.6;
    background: var(--bold-brown-200);
  }
}
</style>
