import { isServer } from '@vue-storefront/core/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import RootState from '@vue-storefront/core/types/RootState'
import { Store } from 'vuex'

export function beforeRegistration (config: { hasOwnProperty: (arg0: string) => any, midtrans: { hasOwnProperty: (arg0: string) => any, clientKey: string } }, store: Store<RootState>) {
  if (!isServer && config.hasOwnProperty('midtrans')) {
    const storeView = Object.assign(currentStoreView())
    const clientKey = storeView.midtrans.hasOwnProperty('clientKey') ? storeView.midtrans.clientKey : '';
    const env = storeView.midtrans?.environment === 'production' ? storeView.midtrans?.environment : 'sandbox';
    const midtrandNew3dsUrl = 'https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js';
    const script = document.createElement('script');
    if (!script) return
    script.setAttribute('src', midtrandNew3dsUrl);
    script.setAttribute('id', 'midtrans-script');
    script.setAttribute('data-client-key', clientKey);
    script.setAttribute('data-environment', env);
    document.head.appendChild(script);
  }
}
