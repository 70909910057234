import config from 'config'

export function mapUrlWithStoreCode (prefix, path) {
  const storeCode = config.storeViews.mapStoreUrlsFor[0]
  if (path.startsWith('/')) {
    return `/${prefix}-${storeCode}${path}`
  } else {
    return `/${prefix}-${storeCode}/${path}`
  }
}

export function isNewInPage (path) {
  const newInRoutes = config.products.additionalDefaultSortBy.excludePages
  const newInPattern = new RegExp(newInRoutes.join('|'));
  return !!path.match(newInPattern);
}

export function isSearchPage (path) {
  return path.includes('/search')
}
