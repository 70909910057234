const getBodyProps = (props) => {
  return {
    modelName: props.model_name,
    modelPhoto: props.model_photo?.filename,
    modelSize: props.model_size,
    modelHeight: props.model_height,
    modelWaist: props.model_waist,
    modelHip: props.model_hip,
    modelBust: props.model_bust
  };
};

const ProductModel = () => ({
  type: 'product_model',
  props: getBodyProps
});

export default ProductModel;
