const getMegaMenuMobileBannerProps = props => {
  return { image: props.image, title: props.title, link: props.link };
};

const MegaMenuMobileBanner = () => ({
  type: 'mega_menu_mobile_banner',
  props: getMegaMenuMobileBannerProps
});

export default MegaMenuMobileBanner;
