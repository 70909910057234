<template>
  <MProductCard
    ref="mProductCardRef"
    :title="product.title"
    :image="image"
    :mobile-image="mobileImage"
    :regular-price="product.price.regular"
    :special-price="product.price.special"
    :link="productLink"
    :load-on-priority="loadOnPriority"
    :wishlist-icon="false"
    :product="product"
    link-tag="router-link"
    :gtm-product-event="gtmProductEvent"
    :gtm-link-event="gtmLinkEvent"
    :hover-image="hoverImage"
    :is-pre-order="product.isPreOrder"
    :stock="isStockAvailable(product)"
    :block-id="blockId"
    :category-ids="product.category_ids"
    :open-new-tab="openNewTab"
    :child-product-price="price"
    :sale-variant-filter-applied="saleVariantFilterApplied"
  >
    <template v-slot:title="slotProps">
      <p class="paragraph-2">
        {{ slotProps.title }}
      </p>
    </template>
    <div
      class="sale-colors-sold-out"
      v-if="saleVariantFilterApplied && !isStockAvailable(product)"
    >
      SALE Colours Sold Out
    </div>
    <template v-else>
      <MProductOptionsConfigurable
        v-if="product.type_id === 'configurable'"
        ref="mProductOptionsConfigurableRef"
        :product="product"
        :configuration="configuration"
        :hide-attribute-titles="true"
        :should-validate-stock="true"
        :preselect-color="shouldPreselectColor"
        :hide-size-selector="true"
        :link="productLink"
        @click.native.stop=""
      />
    </template>
    <div
      class="product-rating-container"
      v-if="ratings.avgRating"
    >
      <MRatingStarsMinimal :rating="{avgRating: ratings.avgRating, reviewCount:ratings.totalReviews}" />
    </div>
  </MProductCard>
</template>
<script>
import MProductCard from '../molecules/m-product-card';
import MRatingStarsMinimal from 'common/components/molecules/product-review/m-rating-stars-minimal';
import MProductOptionsConfigurable from '../molecules/m-product-options-configurable';
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import windowSize from 'common/mixins/windowSize';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

export default {
  name: 'OProductCard',
  components: {
    MProductCard,
    MProductOptionsConfigurable,
    MRatingStarsMinimal
  },
  mixins: [ windowSize ],
  props: {
    blockId: {
      type: String,
      default: () => ''
    },
    observeIndividualItem: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      required: true
    },
    ratings: {
      type: Object,
      default: () => ({})
    },
    showImageOnHover: {
      type: Boolean,
      default: false
    },
    gtmLinkEvent: {
      type: String,
      default: () => ''
    },
    gtmProductEvent: {
      type: String,
      default: () => ''
    },
    loadOnPriority: {
      type: Boolean,
      default: false
    },
    openNewTab: {
      type: Boolean,
      default: false
    },
    saleVariantFilterApplied: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    currentImage: null,
    currentConfiguration: null,
    isProductVisible: false,
    isProductViewed: false,
    productUnwatch: null,
    storeCode: currentStoreView().storeCode,
    childProductPrice: null
  }),
  provide () {
    return {
      configurableOptionCallback: this.configurableOptionCallback
    }
  },
  computed: {
    image () {
      return this.currentImage || this.product.image
    },
    price () {
      return this.childProductPrice
    },
    mobileImage () {
      return this.currentImage || this.product.mobileImage
    },
    hoverImage () {
      if (!this.showImageOnHover) return ''

      return this.product?.swatchImage || ''
    },
    configuration () {
      return this.currentConfiguration || this.product.default_configuration
    },
    productLink () {
      const { type_id, parentSku, id } = this.product;
      let { link: linkString } = this.product;
      if (type_id === 'giftcard') {
        linkString = this?.localizedRoute(`/p/${parentSku}/${id}`);
      }
      if (this.currentConfiguration?.color?.id) {
        const colorCodeQuery = `colorCode=${this.currentConfiguration.color.id}`
        linkString = linkString + `?${colorCodeQuery}`
      }
      return linkString;
    },
    isImsOn () {
      return currentStoreView()?.ims || false
    },
    shouldPreselectColor () {
      return !!this.product.colorCodeToSelect
    }
  },
  watch: {
    $route (to, from) {
      this.getColorFamilyList()
    }
  },
  mounted () {
    this.getColorFamilyList()
    this.bootStrapEventObservable();
    this.preselectColor()
  },
  destroyed () {
    if (!this.observeIndividualItem) return;
    this.observer.disconnect();
  },
  methods: {
    isStockAvailable (product) {
      if (this.$route?.name?.includes('style-quiz-results') || product.type_id === 'giftcard') {
        return true
      }
      if (this.isImsOn) return product?.configurable_children?.filter(cc => cc?.imsStock?.qty > 0)?.length > 0
      else return product?.configurable_children?.filter(cc => cc?.stock?.qty > 0)?.length > 0
    },
    formatProductSlug (slug) {
      if (!slug) return
      let slugSplit = slug.split('-')
      // Removing numbers from the product slug
      if (!isNaN(slugSplit[slugSplit.length - 1])) {
        slugSplit = slugSplit.slice(0, slugSplit.length - 1)
        slug = slugSplit.join('-')
      }

      return slug
    },
    configurableOptionCallback (variant) {
      const child = this.product.configurable_children.filter(child => child.color === Number(variant.id));
      this.currentImage = this.isMobileDevice ? child[0]?.mobileImage : child[0]?.image;
      this.currentConfiguration = {
        color: {
          ...variant
        }
      };
      this.childProductPrice = child[0]?.price;
    },
    getColorFilteredProduct (colorFamilyList) {
      if (colorFamilyList?.length) {
        const child = this.product?.configurable_children?.find(child => {
          const selectedColor = colorFamilyList.includes(
            child.color_family.toString()
          );
          return selectedColor ? child : null;
        });
        this.currentImage = this.isMobileDevice ? child?.mobileImage : child?.image;
      }
    },
    getColorFamilyList () {
      const colorFamilyList = this.$route?.query['configurable_children.color_family']
      this.getColorFilteredProduct(colorFamilyList)
    },
    preselectColor () {
      if (this.product?.recommendedSku) {
        const child = this.product?.configurable_children?.find(child => {
          const selectedColor = child.sku.includes(this.product?.recommendedSku);
          return selectedColor ? child : null;
        });
        this.currentImage = this.isMobileDevice ? child?.mobileImage : child?.image;
      }
      // used for BannerProductGrid
      if (this.product?.colorCodeToSelect) {
        this.configurableOptionCallback({
          id: this.product?.colorCodeToSelect
        })
      }
    },
    bootStrapEventObservable () {
      if (!this.observeIndividualItem) return;

      this.observer = new IntersectionObserver(
        (entries) => {
          const blockEl = entries[0];
          if (blockEl.isIntersecting) {
            EventBus.$emit('home-block-promo-view', {
              id: this.blockId,
              name: this.product.title,
              creative: null,
              position: null
            });
            this.observer.disconnect();
          }
        },
        {
          threshold: 0.7
        }
      );

      setTimeout(() => {
        this.observer.observe(this.$el);
      }, 500);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/typography";

.sf-product-card {
  --product-card-background: transparent;
  --product-card-title-margin: 0;
  --product-card-title-font-size: var(--font_size_75);
  --product-card-title-font-weight: var(--font-normal);
  &::after {
    content: none;
  }
  ::v-deep .product__colors {
    margin: 0;
    .product__color-colors {
      margin-bottom: 6px;
      margin-top: 12px;
    }
  }
  .product-rating-container {
    margin: 0;
  }
}

.sale-colors-sold-out {
  width: max-content;
  padding: 3px 8px;
  border-radius: 2px;
  font-size: var(--font_size_75);
  text-align: center;
  background-color: #f2f2f2;
  color: var(--theme-primary);
  margin-top: 4px;
  margin-bottom: 20px;
}

::v-deep .sf-product-card {
  &__subcategory {
    margin: var(--product-card-subcategory-margin, 0);
    @include font(
      --product-card-subcategory-font,
      var(--font-light),
      var(--font_size_50),
      1.5,
      var(--font-secondary)
    );
    text-transform: uppercase;
    letter-spacing: 1.4px;
    color: var(--_c-gray-secondary);
  }
}
</style>
