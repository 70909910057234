import { parsePhoneNumber } from 'libphonenumber-js/max'
import { postalCodeLength } from 'common/constants';
import { getCountryByCode } from 'common/config/countries-data';

const getters = {
  getShippingMethods: (state, getters, rootState, rootGetters) => {
    return rootGetters['cart-v1/getShippingMethods'];
  },
  selectedShippingAddress: state => state.shippingAddress,
  selectedBillingAddress: state => state.billingAddress,
  selectedPaymentMethod: state => state.paymentMethod,
  selectedShippingMethod: state => state.shippingMethod,
  isPostalCodeCorrect: (state, getters) => {
    const countryId = getters.selectedShippingAddress?.country_id
    const postalCode = getters.selectedShippingAddress?.postcode
    const requiredLengthForPostalCode = postalCodeLength[countryId]
    // if country doesn't exist
    if (!requiredLengthForPostalCode) {
      return true
    }

    // if postal code is empty for a selected address
    if (!postalCode) {
      return false
    }

    if (countryId === 'US') {
      return !!(postalCode?.match(new RegExp('(^\\d{5}$)|(^\\d{5}-\\d{4}$)', 'g')))
    }

    if (countryId === 'TW') {
      return !!(postalCode?.match(new RegExp('(^\\d{5}$)|(^\\d{6}$)', 'g')))
    }

    // create a dynamic regex for validating postcode
    return !!(postalCode?.match(new RegExp(`^\\d{${requiredLengthForPostalCode}}$`)))
  },
  isPhoneNumberCorrect: (state, getters) => {
    const countryId = getters.selectedShippingAddress?.country_id
    const telephone = getters.selectedShippingAddress?.telephone
    const country = getCountryByCode(countryId);
    // if country doesn't exist
    if (!country?.dialCode) {
      return false
    }
    let dialCode = country.dialCode;

    // get user selected dial code
    const customAttributes = getters.selectedShippingAddress?.custom_attributes;
    if (customAttributes) {
      dialCode = customAttributes.find(attr => attr.attribute_code === 'country_code')?.value || country.dialCode;
    }
    const phoneNumber = parsePhoneNumber(`${dialCode}${telephone}`);
    return phoneNumber.isValid();
  },
  isShippableAddress (state, getters, rootState, rootGetters) {
    const countryCodes = rootGetters['address/countryCodes']
    const countryId = getters.selectedShippingAddress?.country_id
    if (!countryId) return false
    const allowed = countryCodes.find(({ alpha2 }) => alpha2 === countryId)

    return !!allowed
  },
  getPosClient: (state) => state.posClient,
  getSelectedPopStation: (state) => state.selectedPopStation,
  getSelectedCollectionStore: (state) => state.selectedCollectionStore,
  getForterToken: (state) => state.forterToken,
  getCardDetails: (state) => state.cardDetails
}

export default getters;
