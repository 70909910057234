import config from 'config';
import { TaskQueue } from '@vue-storefront/core/lib/sync';
import Task from '@vue-storefront/core/lib/sync/types/Task';
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import rootStore from '@vue-storefront/core/store';
import { ItemToWishlist, WishlistItem, WishlistUpdate } from './interface';
import DataService from 'common/services/DataService';

export const fetchUserWishlists = async ({ pageSize, page, noCache = false }): Promise<Task> => {
  const token = rootStore.getters['user/getToken'];
  if (!token) throw new Error('auth token is missing');

  let url;
  const isWishlistV2Enabled = rootStore.getters['featurehub/isWishlistV2Enabled'];

  if (isWishlistV2Enabled) {
    const currentStoreCode = rootStore.getters['getCurrentStoreView']?.storeCode;
    const userId = rootStore.getters['user/getUserId'];
    url = getApiEndpointUrl(config?.wishlist?.endpoint, 'getAllWishlistsV2')
      .replace('{{pageSize}}', pageSize || config?.wishlist?.pagination?.pageSize || 9)
      .replace('{{page}}', page || 1)
      .replace('{{userId}}', userId)
      .replace('{{storeCode}}', currentStoreCode) + `${noCache ? '&r=' + Date.now() : ''}`
  } else {
    url = getApiEndpointUrl(config?.wishlist?.endpoint, 'getAllWishlists')
      .replace('{{pageSize}}', pageSize || config?.wishlist?.pagination?.pageSize || 9)
      .replace('{{page}}', page || 1);
  }
  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
};

export const sendProductToWishlist = async (item: ItemToWishlist): Promise<Task> => {
  const token = rootStore.getters['user/getToken'];
  if (!token) throw new Error('auth token is missing');
  const isWishlistV2Enabled = rootStore.getters['featurehub/isWishlistV2Enabled'];
  if (isWishlistV2Enabled) {
    const currentStoreCode = rootStore.getters['getCurrentStoreView']?.storeCode;
    const { email, firstname } = rootStore.getters['user/getCurrentUser'];
    const url = getApiEndpointUrl(config?.wishlist?.endpoint, 'addToWishlistV2');
    let reqBody: any = {
      userEmail: email,
      userName: firstname,
      sku: item.sku,
      qty: item.quantity,
      parentSku: item.parent_sku,
      storeCode: currentStoreCode
    }
    if (item.wishlistType && item.wishlistType === 'giftcard') {
      reqBody = { ...reqBody,
        senderName: item.senderName,
        senderEmail: item.senderEmail,
        receiverName: item.receiverName,
        receiverEmail: item.receiverEmail,
        message: item.message,
        wishlistType: item.wishlistType
      }
    }
    return DataService.post(url, {
      body: reqBody
    });
  } else {
    const reqBody = {
      wishlistItems: []
    };
    reqBody.wishlistItems.push(item);

    const url = getApiEndpointUrl(
      config?.wishlist?.endpoint,
      'addToWishlist'
    );
    return TaskQueue.execute({
      url,
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify(reqBody)
      },
      silent: true
    });
  }
}

export const moveWishlistProductToCart = async (item: WishlistItem): Promise<Task> => {
  const token = rootStore.getters['user/getToken'];
  if (!token) throw new Error('auth token is missing');
  const url = getApiEndpointUrl(
    config?.wishlist?.endpoint,
    'moveToCart'
  );
  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify(item)
    },
    silent: true
  });
}

export const removeProductFromWishlist = async (item: WishlistItem): Promise<Task> => {
  const token = rootStore.getters['user/getToken'];
  if (!token) throw new Error('auth token is missing');
  const isWishlistV2Enabled = rootStore.getters['featurehub/isWishlistV2Enabled'];
  if (isWishlistV2Enabled) {
    const currentStoreCode = rootStore.getters['getCurrentStoreView']?.storeCode;
    const url = getApiEndpointUrl(config?.wishlist?.endpoint, 'removeFromWishlistV2')
      .replace('{{wishlistItemId}}', item.wishlistItemId)
      .replace('{{storeCode}}', currentStoreCode)
    return DataService.delete(url);
  } else {
    const url = getApiEndpointUrl(
      config?.wishlist?.endpoint,
      'removeFromWishlist'
    );
    return TaskQueue.execute({
      url,
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify(item)
      },
      silent: true
    });
  }
}

export const updateWishlistItem = async (item: WishlistUpdate): Promise<Task> => {
  const token = rootStore.getters['user/getToken'];
  if (!token) throw new Error('auth token is missing');

  const isWishlistV2Enabled = rootStore.getters['featurehub/isWishlistV2Enabled'];
  if (isWishlistV2Enabled) {
    const reqBody = {
      sku: item.sku,
      qty: item.quantity,
      senderEmail: item?.senderEmail,
      senderName: item?.senderName,
      receiverEmail: item?.receiverEmail,
      receiverName: item?.receiverName,
      message: item?.message
    }
    const currentStoreCode = rootStore.getters['getCurrentStoreView']?.storeCode;
    const url = getApiEndpointUrl(config?.wishlist?.endpoint, 'removeFromWishlistV2')
      .replace('{{wishlistItemId}}', item.wishlist_item_id)
      .replace('{{storeCode}}', currentStoreCode)
    return DataService.put(url, {
      body: reqBody
    });
  } else {
    const reqBody = {
      wishlistItems: [],
      wishlistId: rootStore.getters['wishlist-next/wishlistId']
    };
    reqBody.wishlistItems.push(item);

    const url = getApiEndpointUrl(
      config?.wishlist?.endpoint,
      'updateWishlist'
    );
    return TaskQueue.execute({
      url,
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify(reqBody)
      },
      silent: true
    });
  }
}
