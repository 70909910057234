import { StorefrontModule } from '@vue-storefront/core/lib/modules';

import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const KEY = 'store-availability';

const storeAvailability = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
export const storeAvailabilityModule: StorefrontModule = function ({ store }) {
  store.registerModule(KEY, storeAvailability);
};
