import { TaskQueue } from '@vue-storefront/core/lib/sync';
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';
import Task from '@vue-storefront/core/lib/sync/types/Task';
import config from 'config';
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';

const headers = {
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
};
export const fetchProductStockFromIms = async (
  childSkus: string
): Promise<Task> => {
  if (!childSkus) throw new Error('childSkus is required');
  const url = processLocalizedURLAddress(
    getApiEndpointUrl(config.ims.product, 'check_quantity').replace(
      '{{childSkus}}',
      encodeURIComponent(childSkus)
    )
  );
  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers
    },
    silent: true
  });
};
