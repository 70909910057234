export const addressFieldsConfig = {
  IDN: {
    state: {
      label: 'State',
      required: false
    }
  },
  SGP: {
    address1: {
      label: 'Address & Unit Number',
      required: true
    },
    state: {
      label: 'State / Province',
      required: false
    }
  },
  MAC: {
    state: {
      label: 'District',
      required: true
    },
    postcode: {
      label: 'Zip/Postal Code',
      required: false
    }
  },
  HKG: {
    state: {
      label: 'District',
      required: true
    },
    postcode: {
      label: 'Zip/Postal Code',
      required: false
    }
  },
  MYS: {
    state: {
      label: 'State',
      required: true
    }
  },
  default: {
    country: {
      label: 'Country',
      required: true
    },
    address1: {
      label: 'Address',
      required: true
    },
    address2: {
      label: 'Address Line 2 (Optional)',
      required: false
    },
    city: {
      label: 'City',
      required: true
    },
    state: {
      label: 'State / Province',
      required: true
    },
    postcode: {
      label: 'Zip/Postal Code',
      required: true
    },
    firstName: {
      label: 'First Name',
      required: true
    },
    lastName: {
      label: 'Last Name',
      required: true
    },
    phoneNumber: {
      label: 'Contact Number',
      required: true
    }
  }
};

export const getAddressFieldsConfigByCountry = (countryCode) => {
  const labels =
        addressFieldsConfig[countryCode] || addressFieldsConfig.default;
  return {
    ...addressFieldsConfig.default,
    ...labels
  };
}
