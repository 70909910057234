import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import config from 'config';

import { Logger } from '@vue-storefront/core/lib/logger'
const featureFlags = config.featureFlags

const KEY = 'featurehub';

const featurehubStore = {
  namespaced: true,
  state: {
    featureFlags
  },
  getters: {
    isFlagActive: state => flag => {
      const isActive = state.featureFlags[flag]?.isActive
      Logger.info(flag, 'FeatureHub', { isActive })()
      return isActive;
    },
    isReferAFriendEnabled (state, getters, rootState, rootGetters) {
      const flag = `${rootGetters['getCurrentStoreView']?.storeCode}_refer_a_friend`.toUpperCase()
      return state.featureFlags[flag]?.isActive
    },
    isCartModuleActive (state, getters, rootState, rootGetters) {
      const flag = `${rootGetters['getCurrentStoreView']?.storeCode}_in_house_cart`.toUpperCase()
      return state.featureFlags[flag]?.isActive
    },
    isCartV1ApiEnabled (state, getters, rootState, rootGetters) {
      return state.featureFlags['NEW_CART_V1_API_ENABLED']?.isActive
    },
    isRewardRedemptionModuleActive (state, getters, rootState, rootGetters) {
      const flag = `${rootGetters['getCurrentStoreView']?.storeCode}_reward_redemption_enabled`.toUpperCase()
      return state.featureFlags[flag]?.isActive
    },
    isRewardCarouselActiveOnCartPage (state) {
      return state.featureFlags['REWARD_REDEMPTION_CAROUSEL_ENABLED'].isActive
    },
    isWishlistV2Enabled (state, getters, rootState, rootGetters) {
      const flag = `${rootGetters['getCurrentStoreView']?.storeCode}_wishlist_v2_enabled`.toUpperCase()
      return state.featureFlags[flag]?.isActive
    },
    isGuestCheckoutEnabled (state, getters, rootState, rootGetters) {
      const flag = `${rootGetters['getCurrentStoreView']?.storeCode}_guest_checkout_enabled`.toUpperCase()
      return state.featureFlags[flag]?.isActive
    },
    isProductUVPEnabled (state) {
      return state.featureFlags['ENABLE_PRODUCT_UVP']?.isActive
    },
    isGooglepayFeatureEnabled  (state, getters, rootState, rootGetters) {
      return state.featureFlags['GOOGLE_PAY_ENABLED']?.isActive;
    }
  }
};
export const FeaturehubModule: StorefrontModule = function ({
  app,
  store,
  router,
  moduleConfig,
  appConfig
}) {
  store.registerModule(KEY, featurehubStore);
};
