export function getProductVariant ({ product, forGtmEvent = false }) {
  let variantDetails = ''
  if (!product || !product.options) return
  const configurableOptions = product.configurable_options
  if (!configurableOptions || !configurableOptions.length) return
  const COLOR_LABEL = 'color'
  product.options.forEach(option => {
    if (!option.label) return
    const optionLabel = option.label.toLowerCase()
    const optionValue = option.value
    if (optionLabel === COLOR_LABEL && product.brand_color) {
      variantDetails += (product.brand_color + '/')
      return;
    }
    // Mapping the details (IDs) with their values
    const configurableOptionValue = configurableOptions
      .find(configurableOption => configurableOption.label.toLowerCase() === optionLabel)
      ?.values?.find(value => (+value.value_index === optionValue) || (value.value_index === optionValue))
    variantDetails += (configurableOptionValue?.label + '/')
  })

  // Removing any trailing '/' character
  if (variantDetails[variantDetails.length - 1] === '/') {
    variantDetails = variantDetails.slice(0, -1)
  }

  // Formatting the variantDetails for GTM events
  if (!forGtmEvent) {
    variantDetails = variantDetails.replace('/', ' / ')
  } else {
    const splittedArray = variantDetails.split('/')
    splittedArray[0] = `Color: ${splittedArray[0] || ''}`
    splittedArray[1] = `Size: ${splittedArray[1] || ''}`
    variantDetails = splittedArray.join(' | ')
  }
  return variantDetails
}
