export default {
  name: 'CmsComponents',
  components: {
    'hero_banner': () => import('src/modules/storyblok/components/HeroBanner.vue'),
    'image_with_text': () => import('src/modules/storyblok/components/ImageWithText.vue'),
    'divider_line': () => import('src/modules/storyblok/components/DividerLine.vue'),
    'full_width_section': () => import('src/modules/storyblok/components/FullWidthSection.vue'),
    'grid': () => import('src/modules/storyblok/components/Grid.vue'),
    'grid_slider': () => import('src/modules/storyblok/components/GridSlider.vue'),
    'card': () => import('src/modules/storyblok/components/Card.vue'),
    'leadgen': () => import('src/modules/storyblok/components/LeadGen.vue'),
    'promo_code': () => import('src/modules/storyblok/components/PromoCode.vue'),
    'tabs': () => import('src/modules/storyblok/components/Tabs.vue'),
    'cta': () => import('src/modules/storyblok/components/CTA.vue'),
    'video_clp': () => import('src/modules/storyblok/components/VideoClp.vue'),
    'sitewide_texts': () => import('src/modules/storyblok/components/SitewideText.vue'),
    'sitewide_banner': () => import('src/modules/storyblok/components/SitewideBanner.vue'),
    'pre_order_message_block': () => import('src/modules/storyblok/components/PreorderMessageBlock.vue'),
    'pre_order_text': () => import('src/modules/storyblok/components/PreorderText.vue'),
    'homepage_hero_carousel': () => import('src/modules/storyblok/components/HomepageHeroCarousel.vue'),
    'homeblock': () => import('src/modules/storyblok/components/Homeblock.vue'),
    'homepage_product_carousel': () => import('src/modules/storyblok/components/HomepageProductCarousel.vue'),
    'order_summary_block': () => import('src/modules/storyblok/components/OrderSummaryBlock.vue'),
    'promo_code_block': () => import('src/modules/storyblok/components/PromoCodeBlock.vue'),
    'delivery_delay_block': () => import('src/modules/storyblok/components/DeliveryDelayBlock.vue'),
    'EmptySearchResultBlock': () => import('src/modules/storyblok/components/EmptySearchResultBlock.vue'),
    'search_error_message': () => import('src/modules/storyblok/components/SearchErrorMessage.vue'),
    'popular_categories_block': () => import('src/modules/storyblok/components/PopularCategoriesBlock.vue'),
    'popular_category': () => import('src/modules/storyblok/components/PopularCategory.vue'),
    'typeform': () => import('src/modules/storyblok/components/Typeform.vue'),
    'curator_io': () => import('src/modules/storyblok/components/CuratorIO.vue'),
    'rich_text_block': () => import('src/modules/storyblok/components/RichTextBlock.vue'),
    'sb_table': () => import('src/modules/storyblok/components/Table.vue'),
    'product_model': () => import('src/modules/storyblok/components/ProductModel.vue'),
    'ugc_carousel': () => import('src/modules/storyblok/components/UGC/UgcCarousel.vue'),
    'ugc_item': () => import('src/modules/storyblok/components/UGC/UgcItem.vue'),
    'child_product_carousel': () => import('src/modules/storyblok/components/ChildProductCarousel.vue'),
    'banner_with_product_grid': () => import('src/modules/storyblok/components/BannerWithProductGrid.vue'),
    'homepage_about_brand': () => import('src/modules/storyblok/components/HomepageAboutBrand.vue'),
    'overlay_card': () => import('src/modules/storyblok/components/OverlayCard.vue'),
    'amplify_signature_card': () => import('src/modules/storyblok/components/AmplifySignatureCard.vue'),
    'common_carousel': () => import('src/modules/storyblok/components/CommonCarousel.vue'),
    'lb_benefits': () => import('src/modules/storyblok/components/LbBenefits.vue'),
    'iframe_block': () => import('src/modules/storyblok/components/IframeBlock.vue'),
    'global_uvp': () => import('src/modules/storyblok/components/GlobalUvp.vue')
  }
}
