interface Product {
  color_options: number[],
  configurable_children: any[]
}

export default function sortConfigurableChildrenByColorOptions ({ color_options, configurable_children }: Product) {
  if (!Array.isArray(color_options) || !color_options.length) return configurable_children;

  return [...configurable_children].sort((a, b) => {
    return color_options.indexOf(a.color) - color_options.indexOf(b.color);
  })
}
