import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
const catalogEsStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export const CatalogEsModule: StorefrontModule = function ({
  store
}) {
  store.registerModule('catalog-es', catalogEsStore);
};
