<template>
  <router-link
    :to="localizedRoute('/')"
    :title="$t('Home Page')"
    class="a-logo"
    @click.native="closePopups"
  >
    <ALogo class="sf-header__logo" :open="open" />
  </router-link>
</template>

<script>
import ALogo from 'common/components/atoms/a-logo';

export default {
  components: { ALogo },
  props: {
    open: { type: Boolean, default: false }
  },
  methods: {
    closePopups () {
      this.$store.commit('ui/setSearchpanel', false)
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.a-logo {
  display: inline-flex;
  @include for-mobile {
    max-height: 48px;
  }
}
</style>
