<template>
  <div
    class="m-image"
    :class="{ 'sf-image--has-size': size && source, 'loaded': isImgLoaded }"
    :style="size"
    v-on="$listeners"
  >
    <template v-if="isPicture">
      <picture>
        <source
          :srcset="source.desktop.url"
          :media="`(min-width: ${pictureBreakpoint}px)`"
        >
        <source
          :srcset="source.mobile.url"
          :media="`(max-width: ${pictureBreakpoint - 1}px)`"
        >
        <img
          v-show="source.desktop.url"
          :src="source.desktop.url"
          v-bind="$attrs"
          width="5600"
          height="4000"
          :fetchpriority="setpriority ? 'high' : ''"
          @load="onLoadhandler"
        >
      </picture>
    </template>
    <template v-else>
      <img
        v-show="source"
        :src="source"
        v-bind="$attrs"
        width="5600"
        height="4000"
        @load="onLoadhandler"
        :fetchpriority="setpriority ? 'high' : ''"
      >
    </template>
    <noscript
      v-if="lazy && noscript"
      inline-template
    >
      <img :src="noscript" v-bind="$attrs" :width="width" :height="height" />
    </noscript>
    <div v-if="hasOverlay">
      <slot />
    </div>
  </div>
</template>
<script>
import lozad from 'lozad';

export default {
  name: 'MImage',
  inheritAttrs: false,
  props: {
    src: {
      type: [String, Object],
      default: ''
    },
    backdrop: {
      type: Boolean,
      default: false
    },
    aspectRatio: {
      type: [String, Number],
      default: 1.4
    },
    lazy: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: null
    },
    height: {
      type: [String, Number],
      default: null
    },
    pictureBreakpoint: {
      type: Number,
      default: 1024
    },
    rootMargin: {
      type: String,
      default: '0px 0px 0px 0px'
    },
    threshold: {
      type: [String, Number],
      default: 0
    },
    setpriority: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoaded: false,
      isImgLoaded: false
    };
  },
  computed: {
    isPicture () {
      return !!this.src && typeof this.src === 'object';
    },
    source () {
      const allow =
        (this.isLoaded && this.lazy) || (!this.isLoaded && !this.lazy);
      const disallow = this.isPicture
        ? { desktop: { url: null }, mobile: { url: null } }
        : null;
      return allow ? this.src : disallow;
    },
    noscript () {
      return this.isPicture ? this.src.desktop.url : this.src;
    },
    size () {
      return (
        this.width &&
        this.height && {
          '--_image-width': this.width,
          '--_image-height': this.height
        }
      );
    },
    hasOverlay () {
      return this.$slots.default;
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    onLoadhandler () {
      this.isImgLoaded = true;
    },
    init () {
      if (!this.lazy) return;
      const vm = this;
      this.$nextTick(() => {
        const observer = lozad(vm.$el, {
          load (el) {
            if (vm.backdrop) {
              el.style.paddingTop = `${vm.aspectRatio * 100}%`;
            } else {
              el.style.paddingTop = 'initial';
            }
            vm.isLoaded = true;
          },
          rootMargin: vm.rootMargin,
          threshold: vm.threshold
        });
        observer.observe();
      });
    }
  },
  watch: {
    setpriority: {
      handler () {
        if (!this.setpriority) {
          this.init()
        }
      }
    }
  }
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/atoms/SfImage.scss";
.m-image {
  width:100%;
  height:0;
  position:relative;
  background: var(--_c-light-gray);
  padding-top: 140%;
  img {
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    transition: ease-in all  0.5s;
    transition-property: opacity;
  }
}
.loaded {
  img {
    opacity: 1;
  }
}
</style>
