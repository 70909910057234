const getProps = props => {
  return {
    body: props.body,
    editable: props
  };
};

const EmptySearchResultBlock = () => ({
  type: 'search',
  props: getProps
});

export default EmptySearchResultBlock;
