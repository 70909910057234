<template>
  <div class="categoty-menu">
    <OMenuItem :menu="availableCategories" @handle-close="handleClose()" :is-level-one-item="true" />
  </div>
</template>

<script>
import OMenuItem from './o-menu-item';
import _capitalize from 'lodash-es/capitalize';
import MegaMenuTracking from 'src/common/mixins/MegaMenuTracking';
import {
  formatCategoryLink,
  getCategoryThumbnailUrl
} from 'src/modules/catalog-es/categoryHelper';

export default {
  name: 'MegamenuMobileCategoriesV2',
  mixins: [MegaMenuTracking],
  components: {
    OMenuItem
  },
  props: {
    goingBack: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    childSlug: {
      type: String,
      default: ''
    },
    parentId: {
      type: Number,
      default: 0
    },
    availableCategories: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    thumbnailImagePath (category) {
      return getCategoryThumbnailUrl(category, 30, 30);
    },
    categoryLink (category) {
      return formatCategoryLink(category);
    },
    setActiveCategory (category) {
      this.$emit('set-active-category', category);
    },
    getCategoryNameWithoutSaleText (category) {
      const splitText = category?.toLowerCase()?.split('- sale')[0] || category;
      return splitText
        .split(' ')
        .map((word) => _capitalize(word))
        .join(' ');
    },
    handleClose (category) {
      this.$emit('handleClose');
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
