
























import Vue from 'vue';
import { formatCategoryLink } from 'src/modules/catalog-es/categoryHelper';
import MegaMenuTracking from 'src/common/mixins/MegaMenuTracking';

export default Vue.extend({
  name: 'OMegaMenuCategories',
  mixins: [MegaMenuTracking],
  props: {
    currentCategory: {
      type: String,
      default: ''
    },
    availableCategories: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    categoryLink (category) {
      return formatCategoryLink(category);
    },
    setCurrentCategory (category: string) {
      this.$emit('current-category', category);
    },
    handleMenuClick (category) {
      this.triggerGtmMegaMenuClickCategoryItem(this.categoryLink(category), 1);
    }
  }
});
