

























import MegaMenuTracking from 'src/common/mixins/MegaMenuTracking';
import { formatCategoryLink } from 'src/modules/catalog-es/categoryHelper';
import { BESTSELLER_URL_PATH } from 'src/common/constants';
import _capitalize from 'lodash-es/capitalize';
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'OMegaMenuSubCategoryChild',
  mixins: [MegaMenuTracking],
  props: {
    subCategoryChild: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    categoryLink (category) {
      return formatCategoryLink(category);
    },
    filterCategories (category) {
      return category?.is_active && category?.include_in_menu > 0;
    },
    getCategoryNameWithoutSaleText (category: string) {
      const splitText = category?.toLowerCase()?.split('- sale')[0] || category;
      return splitText
        .split(' ')
        .map((word) => _capitalize(word))
        .join(' ');
    },
    handleMenuClick (category) {
      this.triggerGtmMegaMenuClickCategoryItem(this.categoryLink(category), 2);
      this.$emit('handleClose');
    }
  },
  computed: {
    ...mapGetters(['getCurrentStoreView']),
    ...mapGetters('user', ['isLoggedIn']),
    storeCode () {
      return this.getCurrentStoreView?.storeCode;
    },
    visibleSubChildCategories () {
      const categories = this.subCategoryChild
        ?.filter((category) => this.filterCategories(category))
        ?.sort((a, b) => a?.position - b?.position);

      if (this.storeCode === 'intl') {
        const bestsellersIndex = categories?.findIndex(
          (c) => c?.url_path === BESTSELLER_URL_PATH
        );
        const bestsellersChildData =
          categories[bestsellersIndex]?.children_data;
        if (bestsellersIndex >= 0 && bestsellersChildData) {
          categories?.splice(bestsellersIndex, 1, ...bestsellersChildData);
        }
      }
      return categories;
    }
  }
});
