import config from 'config'
const googleOptimize = {
  src: `https://www.googleoptimize.com/optimize.js?id=${config.optimize?.id?.clientId}`,
  async: true,
  fetchpriority: 'low'
}
const vwoPreload = { rel: 'preconnect', href: 'https://dev.visualwebsiteoptimizer.com', crossorigin: 'anonymous' }
const vwoScript = {
  vmid: 'vwoid',
  id: 'vwoCode',
  async: true,
  fetchpriority: 'low',
  type: 'text/javascript',
  innerHTML: config?.vwo?.script
}

export default {
  titleTemplate: '%s | Love, Bonito ID',
  htmlAttrs: {
    lang: 'en'
  },
  meta: [
    { charset: 'utf-8' },
    {
      vmid: 'description',
      name: 'description',
      content:
        'Shop the latest weekly arrivals at Love, Bonito. Discover new styles from dresses, tops, bottoms and one-pieces.'
    },
    { vmid: 'robots', name: 'robots', content: config?.meta?.robots },
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'theme-color', content: '#ffffff' },
    { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
  ],
  link: [
    // TODO: Review favicons sizes
    {
      rel: 'icon',
      type: 'image/png',
      href: '/assets/favicon-196x196.png?v=03',
      sizes: '196x196'
    },
    { rel: 'apple-touch-icon', href: '/assets/preview-v1/apple-touch-icon.png?v=03' },
    {
      rel: 'apple-touch-startup-image',
      href: '/assets/apple_splash_2048.png',
      sizes: '2048x2732'
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/assets/apple_splash_1668.png',
      sizes: '1668x2224'
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/assets/apple_splash_1536.png',
      sizes: '1536x2048'
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/assets/apple_splash_1125.png',
      sizes: '1125x2436'
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/assets/apple_splash_1242.png',
      sizes: '1242x2208'
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/assets/apple_splash_750.png',
      sizes: '750x1334'
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/assets/apple_splash_640.png',
      sizes: '640x1136'
    },
    { rel: 'manifest', href: '/assets/v1/id/manifest.json' },
    { rel: 'preconnect', href: 'https://www.googletagmanager.com', crossorigin: 'anonymous' },
    { rel: 'preconnect', href: 'https://app.storyblok.com', crossorigin: 'anonymous' },
    { rel: 'preconnect', href: 'https://media.lovebonito.com', crossorigin: 'anonymous' }, // TODO: remove it once images are served from another source
    { rel: 'preconnect', href: 'http://static.scarabresearch.com', crossorigin: 'anonymous' },
    config?.vwo?.enabled ? vwoPreload : {}
  ],
  script: [
    config?.storeViews.id?.enableGoogleOptimize ? googleOptimize : {},
    config?.vwo?.enabled ? vwoScript : {},
    {
      src: 'https://cdn.jsdelivr.net/npm/pwacompat@2.0.10/pwacompat.min.js',
      async: true,
      integrity:
        'sha384-I1iiXcTSM6j2xczpDckV+qhhbqiip6FyD6R5CpuqNaWXvyDUvXN5ZhIiyLQ7uuTh',
      crossorigin: 'anonymous'
    },
    {
      id: 'scarab-js-api',
      type: 'text/javascript',
      async: true,
      src: `https://recommender.scarabresearch.com/js/${config?.emarsys?.merchantId}/scarab-v2.js`
    }
  ],
  __dangerouslyDisableSanitizersByTagID: {
    vwoid: ['innerHTML']
  }
};
