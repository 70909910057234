const getIframeProps = props => {
  return {
    landingPageLink: props.landing_page_link,
    minHeight: props.min_height,
    fullWidth: props.full_width,
    editable: props
  };
};
const IframeBlock = () => ({
  type: 'iframe_block',
  props: getIframeProps
});

export default IframeBlock;
