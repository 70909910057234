
const getDeliveryDelayProps = (props) => {
  return { deliveryText: props.delivery_text };
};

const DeliveryDelayBlock = () => ({
  type: 'delivery_delay_block',
  props: getDeliveryDelayProps
});

export default DeliveryDelayBlock;
