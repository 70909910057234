import Vue from 'vue';
import { clearDataLayer, prepareProduct } from 'common/helpers/gtm';
import { Store } from 'vuex';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { isServer } from '@vue-storefront/core/helpers';
import sha1 from 'sha1';
import { sha256 } from 'js-sha256';

export const isEnabled = (gtmId: string | null) => {
  return typeof gtmId === 'string' && gtmId.length > 0
}

export function afterRegistration (config, store: Store<any>, router) {
  const storeView = Object.assign(currentStoreView())

  if (!isServer && isEnabled(storeView?.googleTagManager?.id)) {
    const GTM = (Vue as any).gtm

    const currency = storeView.i18n.currencyCode;
    const getProduct = (item) => {
      let product = {}

      const attributeMap: string[]|Record<string, any>[] = config.googleTagManager.product_attributes
      attributeMap.forEach(attribute => {
        const isObject = typeof attribute === 'object'
        let attributeField = isObject ? Object.keys(attribute)[0] : attribute
        let attributeName = isObject ? Object.values(attribute)[0] : attribute

        if (item.hasOwnProperty(attributeField) || product.hasOwnProperty(attributeName)) {
          const value = item[attributeField] || product[attributeName]
          if (value) {
            product[attributeName] = value
          }
        }
      })

      const { category } = item
      if (category && category.length > 0) {
        product['category'] = category.slice(-1)[0].name
      }

      return product
    }

    router.beforeEach((to, from, next) => {
      if (store.getters['user/getUserEmail'] && to.name !== `${currentStoreView().storeCode}-order-confirmed`) {
        const currentUser = store.getters['user/getCurrentUser'];
        const email: string = store.getters['user/getUserEmail'];
        const telephoneNumber: string = currentUser?.custom_attributes?.find(
          a => a.attribute_code === 'contact_number'
        )?.value;
        // pushing at the end of the event queue so that store variables are avaialble while firing the event
        setTimeout(() => {
          GTM.trackEvent({
            impact: {
              'customerEmailSha1': sha1(store.getters['user/getUserEmail']),
              'customerEmailSha256': sha256(email.trim().toLocaleLowerCase()),
              'customerId': store.getters['user/getUserId'],
              ...(telephoneNumber ? { phone_number_sha256: sha256(telephoneNumber.trim()) } : {})
            }
          })
        }, 0)
      }

      next()
    })

    store.subscribe(({ type, payload }, state) => {
      // Removing a Product from a Shopping Cart
      if (type === 'cart/cart/DEL') {
        const event = 'removeFromCart'

        clearDataLayer()
        GTM.trackEvent({
          event,
          ecommerce: {
            'remove': {
              products: [prepareProduct(payload.product, event)]
            }
          }
        });
      }

      // Measuring Views of Product Details
      if (type === 'product/product/SET_PRODUCT_CURRENT') {
        clearDataLayer()
        GTM.trackEvent({
          ecommerce: {
            detail: {
              'actionField': { 'list': '' }, // 'detail' actions have an optional list property.
              'products': [getProduct(payload)]
            }
          }
        });
      }
    })
  }
}
