export function prepareProducts (products) {
  return products.map(product => {
    return {
      subTotal: product.price * product.quantity,
      category: product.category,
      sku: product.id,
      quantity: product.quantity,
      name: product.name
    }
  })
}
