import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
const cacheTagsStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export const cacheTagsModule: StorefrontModule = function ({
  store
}) {
  store.registerModule('cache-tags', cacheTagsStore);
};
