
const getBannerProps = (props) => {
  return { textList: props.text_list, bannerColor: props.banner_color, textColor: props.text_color };
};

const SitewideBanner = () => ({
  type: 'sitewide_banner',
  props: getBannerProps
});

export default SitewideBanner;
