import { SearchResponse } from './types';
import config, { entities } from 'config';
import { slugify } from '@vue-storefront/core/helpers';
import Vue from 'vue'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import _isEmpty from 'lodash-es/isEmpty'
import toString from 'lodash-es/toString'
import get from 'lodash-es/get'
import { getProductPrice } from 'src/common/helpers';

export const getSystemFilterNames: string[] = config.products.systemFilterNames

// Group colors as FULL PRICE > ON SALE > OOS ITEMS
const groupColorOptions = (product) => {
  try {
    const colorInStock = [] // FULL PRICE
    const colorOnSale = [] // ON SALE
    const colorOOS = [] // OOS ITEMS

    product.color_options.forEach(color => {
      const children = product.configurable_children.filter(item => item.color === color)
      const inStockItems = children.find(item => item.stock.qty > 0 && item.stock.is_in_stock === true)
      if (!inStockItems) {
        colorOOS.push(color)
      } else {
        const allOnSale = children.every(
          child => getProductPrice(child).special
        );
        if (allOnSale) {
          colorOnSale.push(color)
        } else {
          colorInStock.push(color)
        }
      }
    })

    const updatedColorOptions = [...colorInStock, ...colorOnSale, ...colorOOS];
    product.color_options = updatedColorOptions
    const colorConfig = product.configurable_options.find(item => item.attribute_code === 'color')
    const sortedArray = colorConfig.values.slice().sort((a, b) => {
      const posA = updatedColorOptions.indexOf(Number(a.value_index)) || 0;
      const posB = updatedColorOptions.indexOf(Number(b.value_index)) || 0;
      return posA - posB;
    });
    colorConfig.values = sortedArray
  } catch {}
}

const applyColorLabel = (product) => {
  try {
    const reorderedColors = Array(product.color_options.length);
    const reorderedColorConfig = Array(product.color_options.length);
    for (const colorCode in product.color_mapping) {
      const position = product.color_mapping[colorCode].position;
      if (position && product.color_options.includes(parseInt(colorCode))) {
        const matchingObject = product.configurable_options[0].values.find(obj => obj.value_index === colorCode);
        reorderedColors[position - 1] = parseInt(colorCode);
        reorderedColorConfig[position - 1] = matchingObject
        const indexToRemove = product.color_options.indexOf(parseInt(colorCode));
        if (indexToRemove !== -1) {
          product.color_options.splice(indexToRemove, 1);
          product.configurable_options[0].values.splice(indexToRemove, 1);
        }
      }
    }
    for (let insertIndex = 0; insertIndex < reorderedColors.length; insertIndex++) {
      if (!reorderedColors[insertIndex]) {
        reorderedColors[insertIndex] = product.color_options.shift();
        reorderedColorConfig[insertIndex] = product.configurable_options[0].values.shift();
      }
    }
    product.color_options = reorderedColors
    product.configurable_options[0].values = reorderedColorConfig
  } catch (e) {}
}

export const handleResult = (resp, type, start = 0, size = 50): SearchResponse => {
  if (resp === null) {
    throw new Error('Invalid ES result - null not exepcted')
  }
  if (resp.hasOwnProperty('hits')) {
    let items = resp?.hits?.hits?.map(hit => {
      return Object.assign(hit._source, { _score: hit._score, slug: hit._source.slug ? hit._source.slug : ((hit._source.hasOwnProperty('url_key') && config.products.useMagentoUrlKeys) ? hit._source.url_key : (hit._source.hasOwnProperty('name') ? slugify(hit._source.name) + '-' + hit._source.id : '')) })
    })

    items?.forEach(product => {
      if (product?.type_id !== 'giftcard') {
        groupColorOptions(product)
        // check if custom color labeling exists
        if (product?.color_mapping) {
          applyColorLabel(product)
        }
      }
    })

    return {
      items, // TODO: add scoring information
      total: resp.hits.total,
      start: start,
      perPage: size,
      aggregations: resp.aggregations,
      attributeMetadata: resp.attribute_metadata,
      suggestions: resp.suggest,
      allOutOfStock: !!resp?.hits?.allOutOfStock,
      storyBlokContent: resp.hits?.storyBlokContent
    }
  } else {
    const isErrorObject = (resp && resp.code) >= 400 ? resp : null
    if (resp.error || isErrorObject) {
      throw new Error(JSON.stringify(resp.error || resp))
    } else {
      throw new Error('Unknown error with elasticsearch result in resultProcessor for entity type \'' + type + '\'')
    }
  }
}

export const setRequestCacheTags = ({ product }) => {
  if (Vue.prototype.$cacheTags) {
    Vue.prototype.$cacheTags.add(`P${product?.id}`);
    Vue.prototype.$cacheTags.add(`P${product?.parentId}`);
  }
}

const preConfigureProduct = (product) => {
  const setDefaultProductOptions = (product) => {
    if (product.product_option) return
    product.product_option = {
      extension_attributes: {
        custom_options: [],
        configurable_item_options: [],
        bundle_options: []
      }
    }
  }

  const setDefaultObjects = (product) => {
    product.errors = {};
    product.info = {};
    if (!product.qty) {
      product.qty = 1
    }
    if (!product.parentId) {
      product.parentId = product.id
    }
    if (!product.parentSku) {
      product.parentSku = product.sku
    }
  }

  setDefaultObjects(product)
  setDefaultProductOptions(product)

  return product;
}

export const prepareProducts = (products) => {
  const preparedProducts = products.map(preConfigureProduct)
  return preparedProducts
}

const createConfig = (product) => {
  return {
    color: {
      attribute_code: 'color',
      id: product?.color?.toString(),
      label: product?.color
    },
    size: {
      attribute_code: 'size',
      id: product?.size?.toString(),
      label: product?.size
    }
  }
}

const createOptions = (configuration) => {
  const storeView = currentStoreView()
  return [
    {
      'label': storeView.storeCode === 'jp' ? 'カラー' : 'Color',
      'value': configuration.color.label
    },
    {
      'label': storeView.storeCode === 'jp' ? 'サイズ' : 'Size',
      'value': configuration.size.label
    }
  ]
}

const createItemOptions = (configuration) => {
  return [
    {
      'option_id': '94',
      'option_value': configuration.color.id
    },
    {
      'option_id': '150',
      'option_value': configuration.size.id
    }
  ]
}

export const createConfiguredProducts = async (products, configuration) => {
  const product = products[0]
  let selectedVariant = {}
  let newConfig = {}

  if (product.configurable_children.length) {
    const hash = {}
    product?.color_options.forEach(colorOption => {
      hash[colorOption] = ''
    })
    // default selection on page load
    if (_isEmpty(configuration)) {
      for (let i = 0; i < product.color_options.length; i++) {
        const colorOption = product.color_options[i]
        selectedVariant = product.configurable_children.find(child => {
          return (child?.color === colorOption && child?.stock?.is_in_stock && child?.stock?.qty)
        })
        if (!_isEmpty(selectedVariant)) {
          newConfig = createConfig(selectedVariant)
          break
        }
      }
      if (_isEmpty(selectedVariant)) {
        // all OOS
        const firstChild = product.configurable_children[0]
        selectedVariant = firstChild
        newConfig = createConfig(firstChild)
      }
    } else {
      // color and size selected manually

      const filtered = product.configurable_children.filter(item => {
        const isColorMatched = configuration.color ? item?.color?.toString() === configuration?.color?.id?.toString() : true;
        const isSizeMatched = configuration.size ? item?.size?.toString() === configuration?.size?.id?.toString() : true;
        return isColorMatched && isSizeMatched;
      })

      if (!filtered.length) return

      newConfig = createConfig(filtered[0])
      selectedVariant = { ...filtered[0] }
    }
  } else {
    return [product]
  }

  product.options = createOptions(newConfig)
  product.product_option.extension_attributes.configurable_item_options = createItemOptions(newConfig)
  const configuredProducts = [{
    ...product,
    ...selectedVariant,
    name: product.name,
    configuration: newConfig,
    is_configured: true
  }]
  return configuredProducts
}

export const getFilters = ({ getAvailableFiltersFrom, updatePriceAggregation, aggregations }) => {
  let aggregationFilters = getAvailableFiltersFrom(updatePriceAggregation(aggregations));
  Object.keys(aggregationFilters).forEach((key) => {
    if (aggregationFilters[key]?.length <= 0 || key === 'sort') {
      delete aggregationFilters[key];
    }
  })
  return aggregationFilters
}

export function optionLabel (state, { attributeKey, searchBy = 'code', optionId }) {
  if (!state.labels) {
    state.labels = {}
  }

  // check cached attribute
  const attrCache = get(state, `labels.${attributeKey}.${optionId}`, null)
  if (attrCache) {
    return attrCache
  }

  let attr = state['list_by_' + searchBy][attributeKey]
  if (attr) {
    let opt = attr.options.find((op) => toString(op.value) === toString(optionId))

    if (opt) {
      if (!state.labels[attributeKey]) {
        state.labels[attributeKey] = {}
      }
      state.labels[attributeKey][optionId] = opt.label
      return opt ? opt.label : optionId
    } else {
      return optionId
    }
  } else {
    return optionId
  }
}

export const compareByLabel = (a, b) => {
  if (a.label < b.label) {
    return -1
  }
  if (a.label > b.label) {
    return 1
  }
  return 0
}

export const isStockAvailable = (product) => {
  if (product.type_id === 'giftcard') {
    return true
  }
  const isImsOn = currentStoreView()?.ims || false;
  if (isImsOn) return product?.configurable_children?.filter(cc => cc?.imsStock?.qty > 0)?.length > 0
  return product?.configurable_children?.filter(cc => cc?.stock?.qty > 0)?.length > 0
}

export const getSearchOptionsFromRouteParams = (params: { [key: string]: string } = {}): Record<string, string> => {
  const filterableKeys = entities.category.validSearchOptionsFromRouteParams
  let filters: { [key: string]: string } = {}

  Object.keys(params)
    .filter(key => filterableKeys.includes(key))
    .forEach(key => { filters[key] = params[key] })

  return filters
}

function createVariantForPrice (priceRange) {
  const from = priceRange.split('-')[0]
  const to = priceRange.split('-')[1]
  const variant = {
    from: parseFloat(from),
    id: priceRange,
    label: priceRange,
    single: true,
    to: parseFloat(to),
    type: 'price'
  };
  return variant;
}

export const getFiltersFromQuery = ({ filtersQuery = {}, availableFilters = {} } = {}): { filters } => {
  const searchQuery = {
    filters: {}
  }
  Object.keys(filtersQuery).forEach(filterKey => {
    const filter = availableFilters[filterKey]
    let queryValue = filtersQuery[filterKey]
    if (!filter) return
    // keep original value for system filters - for example sort
    if (getSystemFilterNames.includes(filterKey)) {
      searchQuery[filterKey] = queryValue
    } else {
      queryValue = [].concat(filtersQuery[filterKey])
      queryValue.map(singleValue => {
        let variant = filter.find(filterVariant => filterVariant.id === singleValue)
        if (!variant && filterKey === 'price') {
          variant = createVariantForPrice(filtersQuery['price'])
        }
        if (!variant) return
        if (!Array.isArray(searchQuery.filters[filterKey])) searchQuery.filters[filterKey] = []
        searchQuery.filters[filterKey].push({ ...variant, attribute_code: filterKey })
      })
    }
  })
  return searchQuery
}
