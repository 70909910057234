import { getLinkUrl } from '../helpers';

function getDividerCtaProps (props) {
  const {
    text,
    link,
    target,
    outlined,
    layout_option: layoutOption,
    hide_cta: hideCta
  } = props;

  return {
    text: text,
    link: getLinkUrl(link),
    linkType: link?.linktype,
    target: target,
    layoutOption,
    outlined,
    hideCta,
    editable: props
  }
}

const CTA = () => ({
  type: 'cta',
  props: getDividerCtaProps
});

export default CTA;
