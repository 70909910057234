import { ScarabQueuePayload } from '../store/interface';

export function afterRegistration (store) {
  let unsubscribe = store.subscribe(({ type, payload }, state) => {
    function setEmail () {
      const userEmail = store?.getters['user/getUserEmail'];
      const payload: ScarabQueuePayload = {
        key: 'setEmail',
        value: userEmail
      };
      store.dispatch('emarsys/scarabQueuePush', payload);
      unsubscribe();
    }
    if (type === 'user/user/INFO_LOADED') {
      setEmail();
    }
  });
}
