import StoryblokVue from 'storyblok-vue'
import Vue from 'vue'
import { once } from '@vue-storefront/core/helpers'
import StoryblokClient from 'storyblok-js-client'

function beforeRegistration (config, store) {
  const storyblokClientConfig = {
    cache: {
      type: 'memory'
    },
    ...config.storyblok
  }

  const storyblokClient = new StoryblokClient(storyblokClientConfig)

  Vue.prototype.$storyblokClient = storyblokClient
  store.$storyblokClient = storyblokClient

  once('__VUE_USE_STORYBLOK__', () => {
    Vue.use(StoryblokVue)
  })
}

export { beforeRegistration }
