const PopularCategoriesBlockProps = props => {
  return {
    images: props.blocks
  };
};

const PopularCategoriesBlock = () => ({
  type: 'popular_categories_block',
  props: PopularCategoriesBlockProps
});

export default PopularCategoriesBlock;
