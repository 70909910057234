export const currencyRates = {
  'AUS': { symbol: 'A$', val: '1.5175932', fractionDigits: 2 },
  'CAN': { symbol: 'CA$', val: '1.3647228', fractionDigits: 2 },
  'CHN': { symbol: 'CN¥', val: '6.9211793', fractionDigits: 2 },
  'TWN': { symbol: 'NT$', val: '30.752825', fractionDigits: 2 },
  'NZL': { symbol: 'NZ$', val: '1.6307407', fractionDigits: 2 },
  'SAU': { symbol: 'SAR', val: '3.751211632', fractionDigits: 2 },
  'PHL': { symbol: '₱', val: '55.507574', fractionDigits: 2 },
  'KOR': { symbol: '₩', val: '1339.5126', fractionDigits: 0 },
  'ARE': { symbol: 'AED', val: '3.673081993', fractionDigits: 2 },
  'VNM': { symbol: '₫', val: '23410.183', fractionDigits: 0 },
  'BRN': { symbol: 'B$', val: '1.3369156', fractionDigits: 2 },
  'KHM': { symbol: '៛', val: '4110.3784', fractionDigits: 2 },
  'MMR': { symbol: 'MMK', val: '2099.7204', fractionDigits: 0 },
  'USA': { symbol: 'US$', val: '1', fractionDigits: 2 },
  'JPN': { symbol: '¥', val: '133.0', fractionDigits: 0, locale: 'ja-JP' }
};
