function getProps (props) {
  const {
    title,
    bodytext,
    image,
    cta
  } = props;

  return {
    title,
    bodytext,
    image,
    cta
  }
}

const HomepageAboutBrand = () => ({
  type: 'homepage_about_brand',
  props: getProps
});

export default HomepageAboutBrand
