import * as types from './types';
import { loyaltyPointValues } from 'common/constants';
import { GCPCTab } from 'src/modules/loyalty/types';

const mutations = {
  [types.ADD_CUSTOMER_LOYALTY_STATUS] (state, payload: boolean) {
    state.customerLoyaltyStatus = payload;
  },
  [types.LOADING_CUSTOMER_LOYALTY_STATUS] (state, payload: boolean) {
    state.loadingCustomerLoyaltyStatus = payload;
  },
  [types.ADD_CLAIMED_REWARDS] (state, payload) {
    state.claimedRewards = payload;
  },
  [types.ADD_AVAILABLE_REWARDS] (state, payload) {
    state.availableRewards = payload;
  },
  [types.LOADING_AVAILABLE_REWARDS] (state, payload: boolean) {
    state.loadingAvailableRewards = payload;
  },
  [types.LOADING_CLAIMED_REWARDS] (state, payload: boolean) {
    state.loadingClaimedRewards = payload;
  },
  [types.UPDATE_POINTS_FILTER_APPLIED] (state, payload: boolean) {
    state.pointsFilterApplied = payload;
  },
  [types.LOADING_PARTNER_PERKS] (state, payload: boolean) {
    state.loadingPartnerPerks = payload;
  },
  [types.ADD_AVAILABLE_WALLETS] (state, payload) {
    state.availableWallets = payload;
  },
  [types.LOADING_AVAILABLE_WALLETS] (state, payload: boolean) {
    state.loadingAvailableWallets = payload;
  },
  [types.CLAIMING_REWARD] (state, payload: boolean) {
    state.claimingReward = payload;
  },
  [types.LOADING_POINTS_HISTORY] (state, loading: boolean) {
    state.loadingPointsHistory = loading;
  },
  [types.POINTS_HISTORY] (state, pointsHistory: any[]) {
    state.pointsHistory = pointsHistory;
  },
  [types.LOYALTY_REFERRALS_DATA] (state, referralData: any) {
    state.referralData = referralData;
  },
  [types.SET_REFERRAL_DATA] (state, referralData: {
    referralCode: string,
    name: string
  }) {
    state.referralData = referralData;
  },
  [types.SET_IS_STATUS_ACTIVE] (state, isStatusActive: boolean) {
    state.isStatusActive = isStatusActive;
  },
  [types.JOINING_LOYALTY_PROGRAM] (state, isJoining: boolean) {
    state.isJoining = isJoining;
  },
  [types.RESET_LOYALTY_MODULE] (state) {
    state.loadingCustomerLoyaltyStatus = false;
    state.customerLoyaltyStatus = null;
    state.claimedRewards = null;
    state.loadingClaimedRewards = false;
    state.availableRewards = null;
    state.loadingAvailableRewards = false;
    state.pointsFilterApplied = false;
    state.loadingPartnerPerks = false;
    state.availableWallets = [];
    state.loadingAvailableWallets = false;
    state.claimingReward = false;
    state.loadingPointsHistory = false;
    state.loyaltyPointValues = loyaltyPointValues;
    state.pointsHistory = [];
    state.referralData = {};
    state.decodedReferralData = {};
    state.isStatusActive = false;
    state.isJoining = false;
    state.openGCPCModal = false;
    state.gcpcActiveTab = GCPCTab.PromoCode;
    state.isLoyaltyStatusActive = false;
    state.availableGCRewards = [];
    state.checkoutCouponRewards = [];
    state.checkoutCouponRewardsTotal = 0;
    state.isAllGCLoaded = false;
  },
  [types.ADD_AVAILABLE_GC_REWARDS] (state, payload) {
    state.availableGCRewards = payload;
  },
  [types.LOADING_AVAILABLE_GC_REWARDS] (state, payload: boolean) {
    state.loadingAvailableGCRewards = payload;
  },
  [types.ADD_CHECKOUT_COUPON_REWARDS] (state, payload) {
    state.checkoutCouponRewards = payload;
  },
  [types.ADD_CHECKOUT_COUPON_REWARDS_TOTAL] (state, payload) {
    state.checkoutCouponRewardsTotal = payload;
  },
  [types.LOADING_CHECKOUT_COUPON_REWARDS] (state, payload) {
    state.loadingCheckoutCouponRewards = payload;
  },
  [types.ALL_AVAILABLE_GC_REWARDS_LOADED] (state, isAllGCLoaded: boolean) {
    state.isAllGCLoaded = isAllGCLoaded;
  },
  [types.OPEN_GC_PC_MODAL] (state, { openGCPCModal, gcpcActiveTab }) {
    state.openGCPCModal = openGCPCModal;
    state.gcpcActiveTab = gcpcActiveTab;
  }
};

export default mutations
