<template>
  <div
    :style="getStyles"
    class="EmptySearchResultBlock-container"
  >
    <SkeletonTheme
      color="#e1e1e1"
      highlight="#eee"
      class="skeleton-loader"
      v-if="isLoading"
    >
      <div>
        <skeleton class="no-result-box-skeletion" />
        <div class="grid">
          <skeleton class="line-skeletion" />
          <skeleton class="line-skeletion" />
          <skeleton class="line-skeletion" />
        </div>
      </div>
    </SkeletonTheme>
    <div v-else>
      <div
        v-for="(item, index) in toComponenets"
        :key="index + 1"
      >
        <MSearchTrending
          v-if="item.type === 'trending_search'"
          @searchTextClicked="searchTrendingTextClicked"
        />
        <MSearchHistory
          v-else-if="item.type === 'search_history'"
          @searchTextClicked="searchHistoryTextClicked"
        />
        <component
          v-else
          v-bind="item.props"
          :search-text="searchText"
          :is="item.type"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { SkeletonTheme } from 'vue-loading-skeleton';
import CmsComponents from '../mixins/CmsComponents';
import MSearchTrending from 'common/components/molecules/search-panel/m-search-trending';
import MSearchHistory from 'src/common/components/molecules/search-panel/m-search-history.vue';
export default {
  name: 'EmptySearchResultBlockComponent',
  components: {
    SkeletonTheme,
    MSearchTrending,
    MSearchHistory
  },
  props: {
    body: {
      type: Array,
      default: () => [{}]
    },
    searchText: {
      type: String,
      default: ''
    },
    editable: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isLoading: true
    };
  },
  mixins: [CmsComponents],
  mounted () {
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  },
  computed: {
    getStyles () {
      return {};
    },
    toComponenets () {
      let arr = this.body;
      const trendingSearch = {
        type: 'trending_search'
      };
      const searchHistory = {
        type: 'search_history'
      };
      if (arr[1].type !== 'search_history') arr.splice(1, 0, searchHistory);
      if (arr[2].type !== 'trending_search') arr.splice(2, 0, trendingSearch);
      return arr;
    }
  },
  methods: {
    searchHistoryTextClicked (text) {
      this.$bus.$emit('search-history-text-clicked', text);
    },
    searchTrendingTextClicked ({ label, link }) {
      this.$gtm?.trackEvent({
        event: 'click_trending_search',
        search_term: label
      });
      if (link) {
        this.$router.push(this.localizedRoute(link));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.search-history {
  padding-top: var(--spacer-lg);
}
::v-deep {
  .product-carousel.product-carousel {
    background-color: unset;
  }
}
::v-deep .product-carousel__wrapper {
  max-width: none !important;
}
::v-deep .homepage-product-carousel-container {
  background: unset !important;
  padding: 0 !important;
}
::v-deep .product-carousel__title {
  margin: 0;
  margin-top: 15px;
}

.no-result-box-skeletion {
  height: 150px;
  ::v-deep .pu-skeleton {
    margin-top: var(--spacer-sm);
  }
}
.line-skeletion {
  height: 100px;
  ::v-deep .pu-skeleton {
    margin-top: var(--spacer-sm);
  }
  @include for-desktop {
    height: 200px;
  }
}
.grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: var(--spacer-sm);
}
</style>
