import * as types from './mutation-types'
import { getDefaultState } from './state'

const mutations = {
  [types.RESET_CHECKOUT_MODULE]: (state) => {
    Object.assign(state, getDefaultState());
  },
  [types.SET_SHIPPING_ADDRESS]: (state, address) => {
    state.shippingAddress = address;
  },
  [types.SET_BILLING_ADDRESS]: (state, address) => {
    state.billingAddress = address;
  },
  [types.SELECT_PAYMENT_METHOD] (state, paymentMethod) {
    state.paymentMethods = paymentMethod;
  },
  [types.SELECT_SHIPPING_METHOD] (state, shippingMethod) {
    state.shippingMethods = shippingMethod;
  },
  [types.SET_FORTER_TOKEN] (state, forterToken: string) {
    state.forterToken = forterToken
  },
  [types.SET_CARD_DETAILS] (state, cardDetails: {
    lastFour: number,
    issuerBin: string
  }) {
    state.cardDetails = cardDetails
  }
}

export default mutations;
