<template>
  <SfButton
    class="sf-button--pure a-microcart-icon"
    @click="redirectToSearchPage"
  >
    <div class="search-box">
      <svg class="lb-icon no-fill">
        <use xlink:href="#Search" />
      </svg>
    </div>
  </SfButton>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import { trackGtmEvents } from 'common/helpers/gtm';

export default {
  components: { SfButton },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    redirectToSearchPage () {
      window?.scrollTo(0, 0)
      trackGtmEvents({
        event: 'search_icon_click'
      });
      return this.$router.push(this.localizedRoute('/search'))
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.search-box {
  p {
    display: none;
  }
  @include for-desktop {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 0.5px solid var(--theme-text);
    border-radius: 5px;
    height: 40px;
    padding: 18px 0px;
    box-sizing: border-box;
    p {
      display: block;
      margin: 0 14px;
      font-weight: 400;
      font-family: var(--font-secondary);
      font-size: var(--font_size_75);
      color: var(--text-grey);
    }
  }
}
</style>
