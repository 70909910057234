export const isServer: boolean = typeof window === 'undefined';

export const loadScript = (src: string, id: string) => {
  return new Promise<void>((resolve, reject) => {
    if (document.getElementById(id)) {
      resolve()
      return
    }
    var script = document.createElement('script')
    script.async = true
    script.src = src
    script.onerror = function onError () {
      reject(new Error('Failed to load' + src))
    }
    script.onload = function onLoad () {
      script.id = id
      resolve()
    }
    document.getElementsByTagName('head')[0].appendChild(script)
  })
}
