import config from 'config';
import { TaskQueue } from '@vue-storefront/core/lib/sync';
import Task from '@vue-storefront/core/lib/sync/types/Task';
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import fetch from 'isomorphic-fetch'

const processURLAddress = (url: string = '') => {
  if (url.startsWith('/')) return `${config.api?.url}${url}`
  return url
}

export const fetchProductsBySkus = async ({ skus, storeCode, inStockOnly = false }: { skus: string[], storeCode: string, inStockOnly?: boolean }) => {
  let url = processURLAddress(
    config?.catalog_es?.catalog?.get_products_by_sku
      ?.replace('{{skus}}', skus)
      ?.replace('{{storeCode}}', storeCode)
  );
  if (inStockOnly) {
    url = url + '&stock.is_in_stock=true'
  }
  return fetch(url);
}
export const fetchProductsByCategoryIds = async (
  categoryIds,
  storeCode,
  pageSize
): Promise<Task> => {
  let url = processLocalizedURLAddress(
    getApiEndpointUrl(config?.catalog_es?.catalog, 'get_products_by_category')
      .replace('{{categoryId}}', categoryIds)
      .replace('{{storeCode}}', storeCode)
      .replace('{{count}}', pageSize)
  );
  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
};

export const fetchServerTime = async () => {
  let url = processLocalizedURLAddress(
    getApiEndpointUrl(config?.catalog_es?.catalog, 'get_server_time')
  );
  const date = new Date();
  url = url + `&timestamp=${date.getTime()}`
  const resp = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' }
  })
  if (resp.status !== 200) {
    throw new Error('Error! fetching getServerTime');
  }
  const data = await resp.json();
  return data.result;
};

export const fetchProductsByKeyword = async (
  keyword: string,
  storeCode: string,
  pageSize: string,
  agg: boolean,
  queryParams: string
): Promise<Task> => {
  let url = processLocalizedURLAddress(
    getApiEndpointUrl(config?.catalog_es?.search, 'get_products')
      .replace('{{keyword}}', keyword)
      .replace('{{storeCode}}', storeCode)
      .replace('{{count}}', pageSize)
  );

  if (queryParams.length) {
    url = url + `&` + queryParams
  }
  if (agg) {
    url = url + `&` + 'agg=true'
  }

  url = encodeURI(url)
  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
};

export const fetchSimilarProducts = async (colorSku: string, storeCode: string) => {
  let url = processURLAddress(
    config?.catalog_es?.catalog?.get_similar_products
      ?.replace('{{colorSku}}', colorSku)
      ?.replace('{{storeCode}}', storeCode)
  );
  return fetch(url);
}

export const fetchProductsByUrlKey = async (
  urlKey: string,
  storeCode: string,
  pageSize: number,
  agg: boolean,
  queryParams: string
) => {
  try {
    let url = processLocalizedURLAddress(
      getApiEndpointUrl(config?.catalog_es?.catalog, 'get_products')
        .replace('{{urlKey}}', encodeURIComponent(urlKey))
        .replace('{{storeCode}}', storeCode)
        .replace('{{count}}', String(pageSize))
    );

    if (queryParams.length) {
      url = url + `&` + queryParams
    }
    if (agg) {
      url = url + `&` + 'agg=true'
    }

    url = encodeURI(url)
    return TaskQueue.execute({
      url,
      payload: {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      },
      silent: true
    });
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchProductDetails = async (urlKey: string, sku: string, storeCode: string) => {
  let url = processURLAddress(
    config?.catalog_es?.catalog?.get_product_details
      ?.replace('{{sku}}', sku)
      ?.replace('{{urlKey}}', urlKey)
      ?.replace('{{storeCode}}', storeCode)
  );
  return fetch(encodeURI(url))
}

export const fetchMegaMenu = async ({ categoryIds, storeCode }) => {
  try {
    if (!categoryIds.length) return;
    let url = processURLAddress(
      config?.catalog_es?.catalog?.get_mega_menu
        ?.replace('{{categoryIds}}', categoryIds)
        ?.replace('{{storeCode}}', storeCode)
    );
    const response = await fetch(encodeURI(url))
    if (response.status !== 200) {
      throw new Error('Error fetching megamenu');
    }
    const data = await response.json();
    return data
  } catch (e) {
    console.error(e.message)
  }
}

export const fetchProductBreadCrumbs = async ({ productSku, storeCode }) => {
  try {
    let url = processURLAddress(
      config?.catalog_es?.catalog?.get_product_breadcrumbs
        ?.replace('{{sku}}', productSku)
        ?.replace('{{storeCode}}', storeCode)
    );
    const response = await fetch(encodeURI(url))
    if (response.status !== 200) {
      throw new Error('Error fetching product breadcrumbs');
    }
    const data = await response.json();
    return data
  } catch (e) {
    console.error(e.message)
  }
}

export const fetchCategoryBreadCrumbs = async ({ urlPath, storeCode }) => {
  try {
    let url = processURLAddress(
      config?.catalog_es?.catalog?.get_category_breadcrumbs
        ?.replace('{{urlPath}}', urlPath)
        ?.replace('{{storeCode}}', storeCode)
    );
    const response = await fetch(encodeURI(url))
    if (response.status !== 200) {
      throw new Error('Error fetching category breadcrumbs');
    }
    const data = await response.json();
    return data
  } catch (e) {
    console.error(e.message)
  }
}
