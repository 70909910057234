import { ActionTree } from 'vuex'
import { Logger } from '@vue-storefront/core/lib/logger'
import i18n from '@vue-storefront/i18n';
import { midtransMakePayment, midtransFetchPaymentStatus } from '../service';

const showErrorMessage = (message) => {
  return ({
    type: 'error',
    message: i18n.t(message),
    action1: { label: i18n.t('OK') }
  })
}

export const actions: ActionTree<any, any> = {

  async createOrder ({ rootState }, payload) {
    const resp = await midtransMakePayment({
      ...payload,
      orderId: rootState?.order?.last_order_confirmation?.confirmation?.orderNumber,
      magentoOrderId: rootState?.order?.last_order_confirmation?.confirmation?.magentoOrderId
    });
    return resp.result
  },

  async getPaymentStatus ({ dispatch }, orderId: string) {
    try {
      const resp = await midtransFetchPaymentStatus(orderId);
      if (resp.resultCode !== 200) throw new Error(resp.result)
      return resp.result;
    } catch (error) {
      dispatch('notification/spawnNotification', showErrorMessage('Failed to fetch payment details.'), { root: true })
    }
  }
}
