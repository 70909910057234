import config from 'config';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

export function getTopLevelCategories (categoryList) {
  const categoryLevel = config.entities.category.categoriesRootCategorylId;
  return categoryList.filter(
    category =>
      category.level === categoryLevel &&
      category.is_active &&
      category.include_in_menu
  );
}

export function formatCategoryLink (category) {
  let storeCode = currentStoreView().storeCode
  storeCode ? (storeCode += '/') : (storeCode = '');
  if (!currentStoreView().appendStoreCode) {
    storeCode = '';
  }
  if (category) {
    const url = category.headless_url_key || category.url_path;
    return config.seo.useUrlDispatcher
      ? '/' + storeCode + url
      : '/' + storeCode + 'c/' + category.slug;
  }
  return '/' + storeCode;
}

export function getCategoryThumbnailUrl (category, width, height) {
  let categoryThumbnailUrl = ''

  if (category.mega_menu_image) {
    const url = category.mega_menu_image.replace('/media', '')
    let { useCloudflareImage } = config.server
    let { baseUrl, baseUrlCf } = config.images
    categoryThumbnailUrl = useCloudflareImage ? baseUrlCf.replace('{{url}}', url) : baseUrl.replace('{{url}}', url)
    categoryThumbnailUrl = categoryThumbnailUrl.replace('{{width}}', width.toString() || '')
    categoryThumbnailUrl = categoryThumbnailUrl.replace('{{height}}', height.toString() || '')
  }
  return categoryThumbnailUrl || ''
}
