<template>
  <div class="product-label" v-if="labelObject.shouldShow" v-bgcolor="labelObject.color">
    <span class="label-text tiny">{{ labelObject.text }}</span>
  </div>
</template>
<script>
import config from 'config'
export default {
  name: 'AProductLabel2',
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  directives: {
    bgcolor: {
      inserted: function (el, binding) {
        el.style.backgroundColor = binding.value;
      }
    }
  },
  computed: {
    labelObject () {
      const labelArray = this.label?.replace(/"/g, '')?.split(',');
      const isHomePage = this.$route?.name?.includes('headless-home')
      return ({
        text: labelArray?.[0],
        color: this.getMappedColor(labelArray?.[1]),
        shouldShow: !isHomePage && labelArray?.[0] && this.isActive(labelArray?.[2], labelArray?.[3])
      });
    }
  },
  methods: {
    isActive (startDate, endDate) {
      const curDate = new Date().getTime();
      const start = new Date(startDate || 'Fri, 01 Jan 1990 00:00:00 GMT');
      const end = new Date(endDate || 'Fri, 31 Dec 2100 00:00:00 GMT');
      return curDate >= start?.getTime() && curDate <= end?.getTime();
    },
    getMappedColor (colorToken) {
      const colorConfig = config?.labels_color;
      return colorConfig?.[colorToken] || colorToken || colorConfig['blue'];
    }
  }
}
</script>
<style lang="scss" scoped>
.product-label {
  display: inline-block;
  padding: 3px var(--spacing-sm);
  margin-bottom: 12px;
  .label-text {
    color: var(--neutral-900);
    text-transform: capitalize;
  }
}
</style>
