import { getColor, getImage, getLinkUrl } from '../helpers'

function getTitleSectionProps (props) {
  const {
    hero_desktop_image,
    hero_mobile_image,
    title,
    text,
    text_color,
    cta_label: ctaLabel,
    cta_link: ctaLink,
    countdown_end_time: countdownEndTime,
    align_items,
    mobile_video_id: mobileVideoId,
    desktop_video_id: desktopVideoId
  } = props;

  try {
    return {
      title,
      text: text || {},
      ctaLabel,
      ctaLink: getLinkUrl(ctaLink),
      ctaLinkType: ctaLink?.linktype || '',
      desktopImage: getImage(hero_desktop_image),
      mobileImage: getImage(hero_mobile_image),
      textColor: getColor(text_color),
      editable: props,
      countdownEndTime,
      alignItems: align_items,
      mobileVideoId,
      desktopVideoId
    }
  } catch (e) {
    throw new Error('Error rendering Hero Banner Section');
  }
}

const HeroBanner = () => ({
  type: 'hero_banner',
  props: getTitleSectionProps
});

export default HeroBanner
