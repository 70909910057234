const getTypeFormProps = props => {
  return {
    title: props.title,
    height: props.height,
    widgetID: props.widgetID,
    editable: props
  };
};
const TypeForm = () => ({
  type: 'typeform',
  props: getTypeFormProps
});

export default TypeForm;
