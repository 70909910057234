import { Module } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import AwsPersonalizeState from '../types/AwsPersonalizeState';

export const awsPersonalizeStore: Module<AwsPersonalizeState, RootState> = {
  namespaced: true,
  state: {
    itemId: '',
    userId: '',
    recommendations: [],
    lastViewedProduct: '',
    myHomePageRecommendations: [],
    loadingMyHomePageRecommendations: true
  },
  actions,
  mutations,
  getters
};
