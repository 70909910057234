<template>
  <div class="m-product-options-configurable">
    <SfAlert
      v-if="product.errors && Object.keys(product.errors).length > 0"
      class="m-product-call-to-action__alert"
      :message="product.errors | formatProductMessages"
      type="danger"
    />
    <template v-for="attribute in productAttributes">
      <div
        v-if="attribute.attribute_code === 'size' && !hideSizeSelector"
        :key="attribute.id"
        class="product__select-size"
      >
        <p
          v-if="!hideAttributeTitles"
          class="product__select-size-label"
        >
          <span>{{ attribute.label }}:</span>
          <ALink
            v-if="!hideSizeLabel"
            @click.prevent="handleSizeguideLinkClick"
          >
            {{ $t("Size Guide") }}
          </ALink>
        </p>
        <div
          id="product__select-size-sizes"
          class="product__select-size-sizes"
        >
          <AProductSelectSize
            v-for="attributeOption in availableOptions[
              attribute.attribute_code
            ]"
            :key="attributeOption.id"
            :value="attributeOption"
            :has-stock="checkSizeStock(attributeOption)"
            :size-error-code="sizeError"
            :selected="shouldPreselectSize ? isSizeSelected(attributeOption) : false"
            @click="handleChangeOption(attributeOption)"
          >
            {{ attributeOption.label }}
          </AProductSelectSize>
        </div>
        <div
          v-if="sizeError !== null"
          class="size-error-message"
        >
          {{ $t("Please select a size.") }}
        </div>
      </div>
      <div
        v-if="attribute.attribute_code === 'color'"
        :key="attribute.id"
        class="product__colors"
      >
        <p
          v-if="!hideAttributeTitles"
          class="product__color-label"
        >
          {{ attribute.label }}:
          <span class="product__color-name">
            {{ getActiveColorName(attribute) }}</span>
        </p>
        <div
          class="product__color-colors"
          :class="{ disabled: isColorSplit }"
        >
          <AProductSelectColor
            v-for="attributeOption in getAvailableColors(attribute.attribute_code).colorOptions"
            :key="attributeOption.id"
            :color="getColorHex(attributeOption)"
            class="product__color"
            :selected="shouldPreselectColor ? isColorSelected(attributeOption) : false"
            :has-stock="isColorSplit || checkColorStock(attributeOption)"
            @click="handleChangeOption(attributeOption)"
            :custom-label="getCustomLabel(attributeOption)"
          />

          <router-link v-if="getAvailableColors(attribute.attribute_code).hiddenSize > 0"
                       class="hidden-colors paragraph-2"
                       :to="link"
          >
            +{{ getAvailableColors(attribute.attribute_code).hiddenSize }}
          </router-link>
        </div>
        <div
          v-if="isColorSelectError"
          class="size-error-message"
        >
          {{ $t("Please select a color.") }}
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import get from 'lodash-es/get';
import { SfAlert } from '@storefront-ui/vue';
import { getAvailableFiltersByProduct } from '@vue-storefront/core/modules/catalog/helpers/filters';
import AProductSelectColor from 'common/components/atoms/a-product-select-color';
import AProductSelectSize from 'common/components/atoms/a-product-select-size';
import ALink from 'common/components/atoms/a-link';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { getProductPrice } from 'src/common/helpers';
import windowSize from 'common/mixins/windowSize';

export default {
  name: 'MProductOptionsConfigurable',
  inject: {
    configurableOptionCallback: { default: false }
  },
  components: {
    ALink,
    SfAlert,
    AProductSelectColor,
    AProductSelectSize
  },
  mixins: [windowSize],
  props: {
    hideSizeLabel: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      required: true
    },
    configuration: {
      type: Object,
      required: true
    },
    hideAttributeTitles: {
      type: Boolean,
      default: false
    },
    isPageLoaded: {
      type: Boolean,
      default: true
    },
    shouldValidateStock: {
      type: Boolean,
      default: false
    },
    preselectColor: {
      type: Boolean,
      default: true
    },
    preselectSize: {
      type: Boolean,
      default: false
    },
    hideSizeSelector: {
      type: Boolean,
      default: false
    },
    showColorOnSale: {
      type: Boolean,
      default: false
    },
    link: {
      type: [String, Object],
      default: ''
    }
  },
  data: () => ({
    autoSelectedColor: false,
    isColorClicked: false,
    isSizeClicked: false,
    isColorSelectError: false
  }),
  computed: {
    ...mapGetters({
      getSizeManuallySelected: 'productpage/getSizeManuallySelected',
      getAttributeListByCode: 'attribute/getAttributeListByCode',
      sizeError: 'productpage/getSizeError'
    }),
    getManuallySelectedSize () {
      return this.getSizeManuallySelected;
    },
    isImsOn () {
      return currentStoreView()?.ims || false;
    },
    haveOnlyOneSize () {
      return this.availableOptions?.size?.length === 1 || false;
    },
    getAttributeLabel () {
      return attribute => {
        const configName = attribute.attribute_code
          ? attribute.attribute_code
          : attribute.label.toLowerCase();
        return this.configuration[configName]
          ? this.configuration[configName].label
          : configName;
      };
    },
    productAttributes () {
      if (
        this.product.errors &&
        Object.keys(this.product.errors).length &&
        Object.keys(this.configuration).length
      ) {
        return [];
      }
      return this.product.configurable_options;
    },
    availableOptions () {
      return getAvailableFiltersByProduct(this.product);
    },
    getColorHex () {
      return option =>
        this.getAttributeListByCode['color']?.options?.find(
          color => option?.id === color?.value
        )?.swatch?.value;
    },
    getActiveColorName () {
      return attribute => {
        if (this.product?.brand_color) {
          return this.product.brand_color;
        }
        const colorId = get(this.configuration, `color.id`, attribute.id);
        return get(
          attribute.values.find(v => v.value_index === colorId),
          'label',
          ''
        );
      };
    },
    checkSizeStock () {
      return size =>
        this.handleValidateStock(() => {
          const { color } = this.configuration;
          const result = this.childrenStock.find(
            child =>
              child.color === parseInt(color.id) &&
              child.size === parseInt(size.id)
          );
          if (!result) return false;
          else if (this.isImsOn && result) {
            return result?.imsStock?.is_in_stock || false;
          } else {
            return Boolean(result.qty);
          }
        });
    },
    checkColorStock () {
      return color =>
        this.handleValidateStock(() => {
          let result = false;
          const productMapWthColor = this.childrenStock.filter(
            child => child.color === parseInt(color.id)
          );
          if (!productMapWthColor) result = false;
          else if (
            this.isImsOn &&
            productMapWthColor.find(e => e?.imsStock?.qty > 0)
          ) { result = true; } else if (!this.isImsOn && productMapWthColor.find(e => e?.qty > 0)) { result = true; } else {
            result = false;
          }
          return result;
        });
    },
    isColorSelected () {
      return attributeOption =>
        this.configuration.color &&
        parseInt(this.configuration.color.id) === parseInt(attributeOption.id);
    },
    isSizeSelected () {
      return attributeOption => {
        return this.configuration.size && parseInt(this.configuration.size.id) === parseInt(attributeOption.id);
      }
    },
    childrenStock () {
      return this.product?.configurable_children?.map(
        ({ sku, size, color, stock, imsStock }) => ({
          sku,
          size,
          color,
          imsStock,
          ...stock
        })
      );
    },
    isAutoSelectEnabled () {
      return this.$route.query.selection === 'auto';
    },
    getCurrentSizeId () {
      return this.configuration?.size && parseInt(this.configuration?.size?.id);
    },
    getSelectedSize () {
      const sizes = this.availableOptions?.size;
      if (!sizes) return;
      return (
        sizes.find(size => this.getCurrentSizeId === parseInt(size?.id)) || {}
      );
    },
    shouldPreselectColor () {
      return this.isColorClicked || this.preselectColor
    },
    shouldPreselectSize () {
      return this.isSizeClicked || this.preselectSize
    },
    isColorSplit () {
      return this.product?.link?.indexOf('colorCode') >= 0;
    }
  },
  watch: {
    product: function (product) {
      if (
        this.product.sku &&
        this.product.sku === this.$route.params?.childSku
      ) {
        if (product?.stock?.qty && this.isAutoSelectEnabled) {
          this.setManualSizeSelection(this.getSelectedSize);
        }
      }
    },
    $route: {
      immediate: true,
      deep: true,
      handler (val) {
        this.autoSelectedColor = false;
        this.setSizeError(null);
        this.isColorSelectError = false;
        this.setQtyLimitError(false);
        if (!val?.params?.childSku || !this.isAutoSelectEnabled) return;
        if (this.product?.sku === val.params.childSku) {
          this.setManualSizeSelection(this.getSelectedSize);
        }
      }
    },
    haveOnlyOneSize: {
      immediate: true,
      handler (val) {
        if (val && !this.isAutoSelectEnabled && this.getSelectedSize) {
          this.setManualSizeSelection(this.getSelectedSize);
        }
      }
    }
  },
  mounted () {
    this.$bus.$on('select-size-from-recommender', this.selectSizeFromRecommender);
  },
  beforeDestroy () {
    this.$bus.$off('select-size-from-recommender', this.selectSizeFromRecommender);
  },
  destroyed () {
    this.setManualSizeSelection(false);
  },
  methods: {
    ...mapActions('productpage', {
      setAdditionalInfoActiveTab: 'setAdditionalInfoActiveTab',
      setSizeError: 'setSizeError',
      setQtyLimitError: 'setQtyLimitError'
    }),
    ...mapMutations('productpage', {
      setSizeManuallySelected: 'setSizeManuallySelected'
    }),
    setManualSizeSelection (option) {
      this.setSizeManuallySelected(option);
    },
    getAvailableColors (attrCode) {
      let colorOptions = this.availableOptions[attrCode];
      const listSize = this.link ? (this.isMobileDevice ? 4 : 8) : colorOptions.length;
      colorOptions = colorOptions?.slice(0, listSize);
      return {
        colorOptions,
        hiddenSize: this.availableOptions[attrCode]?.length - colorOptions?.length
      }
    },
    handleChangeOption (option) {
      this.isColorSelectError = false;
      this.setQtyLimitError(false);
      const selectedVariant = { ...option, attribute_code: option.type };
      if (this.configurableOptionCallback) {
        this.configurableOptionCallback(selectedVariant);
        this.isColorClicked = true;
        if (option.type === 'size') {
          this.isSizeClicked = true
          this.setManualSizeSelection(option);
          this.setSizeError(null);
        }
      }
    },
    handleValidateStock (action) {
      if (typeof action !== 'function') {
        throw new Error('The param "action" must be a function.');
      }
      return this.shouldValidateStock ? action() : true;
    },
    handleSizeguideLinkClick () {
      this.setAdditionalInfoActiveTab('sizeGuide');
      this.$gtm?.trackEvent({
        event: 'click_size_guide'
      });
    },
    isColorOnSale (option) {
      const colorId = parseInt(option.id);
      const colorStock = this.childrenStock.find(
        child => child.color === colorId
      );
      const product = this.product.configurable_children.find(
        child => child.sku === colorStock.sku
      );
      const isAllProductsOnSale = this.product.configurable_children.every(
        child => getProductPrice(child).special
      );
      return !!getProductPrice(product).special && !isAllProductsOnSale;
    },
    selectSizeFromRecommender ({ recommendedSize }) {
      const ATTR_TYPE = 'size'
      const attrObject = this.product.configurable_options.find(({ attribute_code }) => {
        return attribute_code === ATTR_TYPE;
      });
      const sizeCode = attrObject?.values?.find(item => item.label === recommendedSize)?.value_index
      const variant = {
        id: sizeCode,
        label: recommendedSize,
        type: ATTR_TYPE
      }
      this.handleChangeOption(variant)
    },
    getCustomLabel (attributeOption) {
      let colorLabel = {
        label: '',
        textColor: '',
        bgColor: ''
      }
      if (this.product?.color_mapping?.[attributeOption?.id]) {
        colorLabel = this.product?.color_mapping?.[attributeOption?.id]
      }
      if (!colorLabel.label && this.showColorOnSale && this.isColorOnSale(attributeOption) && (this.isColorSplit || this.checkColorStock(attributeOption))) {
        return {
          label: 'Sale',
          textColor: '#FFFFFF',
          bgColor: '#F87070'
        }
      }
      return colorLabel
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-product-options-configurable {
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}

.attribute {
  margin-bottom: var(--spacer-xl);
}
.size-error-message {
  font-size: var(--font_size_50);
  color: var(--danger-500);
  margin-top: 10px;
}
.product {
  &__select-size {
    flex: 100%;
    margin: 0 var(--spacer-sm);
    @include for-desktop {
      margin: 0rem 0 0;
    }

    &-sizes {
      margin-top: var(--spacer-xs);
      margin-bottom: calc(var(--spacer-xs) * -1);
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__colors {
    display: flex;
    flex-direction: column;
    flex: 100%;
    @include for-mobile {
      margin: 0 var(--spacer-sm);
    }
  }

  &__color-label,
  &__select-size-label {
    margin: 0 var(--spacer-lg) 0 0;
    font-size: var(--font_size_75);
    color: var(--neutral-900);
    font-family: var(--font-secondary);
    font-weight: 400;
  }

  &__select-size-label {
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  &__color-colors {
    margin: 0.5rem 0;
    display: flex;
    flex-wrap: wrap;
  }

  &__color {
    margin: 10px;
  }

  &__color-name {
    color: var(--_c-gray-primary);
    margin-left: 5px;
  }
}
.product__color-colors.disabled {
  pointer-events: none;
}
.hidden-colors {
    display: flex;
    position: relative;
    top: -3px;
    left: -8px;
    color: var(--neutral-700);
    &:hover {
      cursor: pointer;
    }
}
</style>
