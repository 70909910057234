
const getPromoCodeProps = (props) => {
  return { promoText: props.promo_text };
};

const PromoCodeBlock = () => ({
  type: 'promo_code_block',
  props: getPromoCodeProps
});

export default PromoCodeBlock;
