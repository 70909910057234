const state = {
  homepageProductCarousel: [],
  isSearchEmpty: false,
  filtersMap: {},
  getSearchLoadingStatus: false,
  searchProducts: [],
  searchRedirectUrl: '',
  searchProductsCount: 0,
  searchProductsCache: [],
  searchKeyword: '',
  plpProductsCache: [],
  similarProducts: [],
  similarProductsLoading: false,
  products: [],
  productsLoading: true,
  totalResults: 0,
  productDetails: {},
  productDetailsLoading: false,
  productGallery: [],
  topLevelCategories: [],
  subCategories: [],
  productBreadCrumbs: [],
  categoryBreadCrumbs: [],
  currentCategory: {},
  relatedProducts: [],
  relatedProductsCache: [],
  loadingRelatedProducts: false,
  restrictedCategory: false,
  isAllSearchOOS: false,
  recentlyViewedProductList: [],
  plpFooterContent: {},
  plpBannerContent: {},
  serverTime: {},
  plpInlineBanners: [],
  priceFilterRangeValues: {}
}

export default state;
