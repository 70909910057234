<template>
  <inline-svg
    v-if="getProductLabel && getProductLabel.label"
    :src="require(`common/assets/product_labels/${getProductLabel.label}.svg`)"
    class="product-label"
  />
</template>
<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
// intentional comment to test pipe line job creation issue
export default {
  name: 'AProductLabel',
  props: {
    categories: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      label_name: 'the_staples'
    }
  },
  computed: {
    categoryMap () {
      const catMap = new Map();
      this.categories?.forEach((cat_id) => {
        catMap.set(cat_id, true)
      });
      return catMap;
    },
    getProductLabel () {
      const curDate = new Date().getTime();
      const currentStore = Object.assign(currentStoreView() || {});
      const labels = currentStore?.productLabels?.filter((labelObj) => {
        return labelObj.category === '*' ? true : this.categoryMap.has(labelObj.category)
      })?.sort((a, b) => {
        const a_date = new Date(a.date)?.getTime();
        const b_date = new Date(b.date)?.getTime();
        return b_date - a_date;
      });
      let labelToDisplay = labels?.[0] || null;
      if (labelToDisplay) {
        const startDate = new Date(labelToDisplay?.startDate || 'Fri, 01 Jan 1990 00:00:00 GMT');
        const endDate = new Date(labelToDisplay?.endDate || 'Fri, 31 Dec 2100 00:00:00 GMT');
        labelToDisplay = curDate >= startDate?.getTime() && curDate <= endDate?.getTime() ? labelToDisplay : null;
      }
      return labelToDisplay;
    }
  }
}
</script>
<style lang="scss" scoped>
.product-label {
  position: absolute;
  z-index: 1;
  top:0;
  right:0;
  margin-top: 10px;
}
</style>
