function getProps (props) {
  const {
    category_id: categoryId,
    title,
    last_item_cta: lastItemCTA,
    last_item_image: lastItemImage,
    last_item_text: lastItemText,
    items_per_view: perView,
    open_in_new_tab: openNewTab,
    background_color: backgroundColor,
    product_carousel_banner
  } = props;

  return {
    categoryId,
    title,
    lastItemCTA,
    lastItemImage,
    lastItemText,
    perView,
    openNewTab,
    backgroundColor: backgroundColor?.color,
    productCarouselBanner: product_carousel_banner?.[0] || {}
  }
}

const HomepageProductCarousel = () => ({
  type: 'homepage_product_carousel',
  props: getProps
});

export default HomepageProductCarousel
