
const getTextProps = (props) => {
  return { bannerText: props.banner_text };
};

const SitewideTexts = () => ({
  type: 'sitewide_texts',
  props: getTextProps
});

export default SitewideTexts;
