import { GetterTree } from 'vuex';
import AwsPersonalizeState from '../types/AwsPersonalizeState'
import config from 'config';

const getters: GetterTree<AwsPersonalizeState, any> = {
  getRecommendations: (state) => state.recommendations,
  getLastViewedProduct: (state) => state.lastViewedProduct,
  getMyPageRecommendations: (state) => state?.myHomePageRecommendations?.splice(0, parseInt(`${config.awsPersonalize.myHomePageRecommendationsLimit}`)),
  getLoadingMyHomePageRecommendations: (state) => state.loadingMyHomePageRecommendations
};

export default getters;
