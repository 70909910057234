const getPageNotFoundBannerItemProps = props => {
  return { image: props.image, title: props.title, link: props.link };
};

const PageNotFoundBannerItem = () => ({
  type: 'page_not_found_banner_item',
  props: getPageNotFoundBannerItemProps
});

export default PageNotFoundBannerItem;
