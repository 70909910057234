import { mapState } from 'vuex';
import i18n from '@vue-storefront/i18n';
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';
import RootState from '@vue-storefront/core/types/RootState';
import { Logger } from '@vue-storefront/core/lib/logger';
import { SearchQuery } from 'storefront-query-builder';
import { setSearchHistoryText } from 'common/helpers';

export const prepareQuickSearchQuery = queryText => {
  let searchQuery = new SearchQuery();

  searchQuery = searchQuery
    .setSearchText(queryText)
    .applyFilter({ key: 'visibility', value: { in: [3, 4] } })
    .applyFilter({
      key: 'status',
      value: { in: [0, 1] }
    }); /* 2 = disabled, 3 = out of stock */

  return searchQuery;
};

export const Search = {
  name: 'SearchPanel',
  data () {
    return {
      products: [],
      search: '',
      size: 8,
      start: 0,
      emptyResults: false,
      readMore: true,
      componentLoaded: false
    };
  },
  methods: {
    onEscapePress () {
      this.closeSearchpanel();
    },
    closeSearchpanel () {
      this.$store.commit('ui/setSidebar', false);
      this.$store.commit('ui/setMicrocart', false);
      this.$store.commit('ui/setSearchpanel', false);
    },
    buildSearchQuery (queryText) {
      let searchQuery = prepareQuickSearchQuery(queryText.toUpperCase());
      return searchQuery;
    },
    async makeSearch () {
      if (this.search !== '' && this.search !== undefined) {
        let query = this.buildSearchQuery(this.search);
        let startValue = 0;
        this.start = startValue;
        this.readMore = true;
        try {
          const { items, total } = await this.$store.dispatch('product/findProducts', {
            query,
            start: this.start,
            size: this.size,
            options: {
              populateRequestCacheTags: false,
              prefetchGroupProducts: false
            }
          });
          this.products = items;
          this.start = startValue + this.size;
          this.resultCount = total?.value || 0;
          this.emptyResults = this.products.length < 1;

          if (this.products?.length) {
            this.showHistoryList = false
            if (this.search.length >= 3) {
              setSearchHistoryText(this.search)
            }
          }

          if (this.emptyResults || this.products?.length >= total?.value) this.readMore = false;
        } catch (err) {
          Logger.error(err, 'components-search')();
        }
      } else {
        this.products = [];
        this.emptyResults = 0;
      }
    },
    async seeMore () {
      if (this.search !== '' && this.search !== undefined) {
        let query = this.buildSearchQuery(this.search);
        let startValue = this.start;
        try {
          const { items, total, start } = await this.$store.dispatch(
            'product/findProducts',
            {
              query,
              start: startValue,
              size: this.size,
              options: {
                populateRequestCacheTags: false,
                prefetchGroupProducts: false
              }
            }
          );
          this.products = this.products.concat(items);
          this.start = startValue + this.size;
          this.emptyResults = this.products.length < 1;
          if (this.emptyResults || this.products?.length >= total?.value) this.readMore = false;
        } catch (err) {
          Logger.error(err, 'components-search')();
        }
      } else {
        this.products = [];
        this.emptyResults = 0;
      }
    }
  },
  computed: {
    items () {
      return this.$store.state.search;
    },
    ...mapState({
      isOpen: (state: RootState) => state.ui.searchpanel
    })
  },
  mixins: [onEscapePress]
};
