import config from 'config';
import { isNewInPage } from './url'

function checkOosFilter () {
  const storeCode = 'SG';
  const isOosFilterEnables =
    config?.featureFlags[`${storeCode}_OOS_FILTER_ENABLE`]?.isActive || false;

  return isOosFilterEnables;
}

const isSalePage = (path = '') => {
  return path.includes('sale');
}
export async function updateOosFilter (to, from, next) {
  const oosFilter = 'stock.is_in_stock';
  if (!Object.prototype.hasOwnProperty.call(to.query, oosFilter) && !isNewInPage(to.path) && !isSalePage(to.path)) {
    next({
      ...to,
      query: {
        ...to.query,
        [oosFilter]: 'true'
      }
    });
  } else {
    next();
  }
}
