import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { getDefaultState } from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import * as types from './mutation-types';
import { isServer } from 'common/utils';
import { sharedMutations } from 'common/utils/sharedMutations';

const cartV1Store = {
  namespaced: true,
  state: getDefaultState(),
  getters,
  actions,
  mutations
};

const STORE_KEY = 'cart-v1';

export const CartV1Store: StorefrontModule = function ({ store }) {
  store.registerModule(STORE_KEY, cartV1Store);
  if (isServer) {
    return
  }
  // TODO: will remove following code once new module is fully active and available
  if (!store.getters['featurehub/isCartModuleActive']) {
    store.dispatch('cart/load')
    return
  }

  if (typeof window?.BroadcastChannel === 'function') {
    try {
      sharedMutations(store, { storeKey: STORE_KEY, predicate: [types.SET_CART_ITEMS, types.SET_CART_TOTALS, types.SET_CART_ID], sharingKey: STORE_KEY })
    } catch (error) {
      console.debug(error)
    }
  }

  store.dispatch(`${STORE_KEY}/setCartIdFromStorageAndFetchCart`);
};
