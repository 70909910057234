const getMegaMenuBannerProps = props => {
  return { image: props.image, title: props.title, link: props.link };
};

const MegaMenuBanner = () => ({
  type: 'mega_menu_banner',
  props: getMegaMenuBannerProps
});

export default MegaMenuBanner;
