import { FetchResponse } from 'ohmyfetch';

export class ApiError<T extends { result: unknown }> extends Error {
  public data: T;
  public statusText: string;
  public status: number;

  public constructor (error: FetchResponse<T>) {
    const errorMessage = (error._data?.result || error.statusText) as string
    super(errorMessage);
    this.data = error._data;
    this.status = error.status;
    this.statusText = errorMessage
  }
}
