import { StorefrontModule } from '@vue-storefront/core/lib/modules';

import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import { afterRegistration } from '../hooks/afterRegistration';

const KEY = 'emarsys';

const emarsysStore = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
export const EmarsysModule: StorefrontModule = function ({ store }) {
  store.registerModule(KEY, emarsysStore);
  afterRegistration(store);
};
