function getProps (props) {
  const {
    title,
    subtitle,
    cards
  } = props;

  return {
    title,
    subtitle,
    cards
  }
}

const CategoryBannerCards = () => ({
  type: 'category_banner_cards',
  props: getProps
});

export default CategoryBannerCards
