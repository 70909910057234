export const ADD_CUSTOMER_LOYALTY_STATUS = 'ADD_CUSTOMER_LOYALTY_STATUS';
export const LOADING_CUSTOMER_LOYALTY_STATUS = 'LOADING_CUSTOMER_LOYALTY_STATUS';
export const ADD_CLAIMED_REWARDS = 'ADD_CLAIMED_REWARDS';
export const LOADING_CLAIMED_REWARDS = 'LOADING_CLAIMED_REWARDS';
export const ADD_AVAILABLE_REWARDS = 'ADD_AVAILABLE_REWARDS';
export const LOADING_AVAILABLE_REWARDS = 'LOADING_AVAILABLE_REWARDS';
export const UPDATE_POINTS_FILTER_APPLIED = 'UPDATE_POINTS_FILTER_APPLIED'
export const LOADING_PARTNER_PERKS = 'LOADING_PARTNER_PERKS'
export const ADD_AVAILABLE_WALLETS = 'ADD_AVAILABLE_WALLETS'
export const LOADING_AVAILABLE_WALLETS = 'LOADING_AVAILABLE_WALLETS'
export const CLAIM_REWARD = 'CLAIM_REWARD'
export const CLAIMING_REWARD = 'CLAIMING_REWARD'
export const POINTS_HISTORY = 'POINTS_HISTORY'
export const LOADING_POINTS_HISTORY = 'LOADING_POINTS_HISTORY';
export const LOYALTY_REFERRALS_DATA = 'LOYALTY_REFERRALS_DATA';
export const SET_REFERRAL_DATA = 'SET_REFERRAL_DATA';
export const SET_IS_STATUS_ACTIVE = 'SET_IS_STATUS_ACTIVE';
export const JOINING_LOYALTY_PROGRAM = 'JOINING_LOYALTY_PROGRAM';
export const RESET_LOYALTY_MODULE = 'RESET_LOYALTY_MODULE'
export const ADD_AVAILABLE_GC_REWARDS = 'ADD_AVAILABLE_GC_REWARDS';
export const ALL_AVAILABLE_GC_REWARDS_LOADED = 'ALL_AVAILABLE_GC_REWARDS_LOADED';
export const LOADING_AVAILABLE_GC_REWARDS = 'LOADING_AVAILABLE_GC_REWARDS';
export const ADD_CHECKOUT_COUPON_REWARDS = 'ADD_CHECKOUT_COUPON_REWARDS';
export const ADD_CHECKOUT_COUPON_REWARDS_TOTAL = 'ADD_CHECKOUT_COUPON_REWARDS_TOTAL';
export const LOADING_CHECKOUT_COUPON_REWARDS = 'LOADING_CHECKOUT_COUPON_REWARDS';
export const OPEN_GC_PC_MODAL = 'OPEN_GC_PC_MODAL';
