import HeroBanner from './HeroBanner';
import FullWidthSection from './FullWidthSection';
import CTA from './CTA';
import DividerLine from './DividerLine';
import ImageWithText from './ImageWithText';
import Grid from './Grid';
import GridSlider from './GridSlider';
import Card from './Card';
import withComponent from './withComponent';
import PromoCode from './PromoCode';
import LeadGen from './LeadGen';
import Tabs from './Tabs';
import VideoClp from './VideoClp';
import SitewideTexts from './SitewideTexts';
import SitewideBanner from './SitewideBanner';
import HomepageHeroCarousel from './HomepageHeroCarousel';
import Homeblock from './Homeblock';
import HomepageProductCarousel from './HomepageProductCarousel'
import PreOrderMessageBlock from './PreorderMessageBlock'
import PreOrderText from './PreorderText'
import OrderSummaryBlock from './OrderSummaryBlock'
import PromoCodeBlock from './PromoCodeBlock';
import DeliveryDelayBlock from './DeliveryDelayBlock';
import MegaMenuBannerBlock from './MegaMenuBannerBlock'
import MegaMenuBanner from './MegaMenuBanner'
import MegaMenuMobileBanner from './MegaMenuMobileBanner';
import MegaMenuMobileBannerBlock from './MegaMenuMobileBannerBlock';
import EmptySearchResultBlock from './EmptySearchResultBlock';
import SearchErrorMessage from './SearchErrorMessage';
import PopularCategoriesBlock from './PopularCategoriesBlock';
import PopularCategory from './PopularCategory';
import RichTextBlock from './RichTextBlock';
import Typeform from './Typeform';
import CategoryBannerCarousel from './CategoryBannerCarousel'
import CategoryBannerTextOnlyLayout from './CategoryBannerTextOnlyLayout'
import CategoryBannerCards from './CategoryBannerCards'
import CategoryBannerTextWithImage from './CategoryBannerTextWithImage'
import Table from './Table'
import ProductModel from './ProductModel';
import CuratorIO from './CuratorIO'
import PageNotFoundBannerItem from './PageNotFoundBannerItem';
import ProductUVP from './ProductUVP';
import GlobalUvp from './GlobalUvp';
import ChildProductCarousel from './ChildProductCarousel';
import BannerWithProductGrid from './BannerWithProductGrid';
import UgcCarousel from './UgcCarousel';
import UgcItem from './UgcItem';
import IframeBlock from './IframeBlock';
import SitewideTextsWithCountDown from './SitewideTextsWithCountdown';
import HomepageAboutBrand from './HomepageAboutBrand';
import OverlayCard from './OverlayCard';
import AmplifySignatureCard from './AmplifySignatureCard';
import CommonCarousel from './CommonCarousel';
import LbBenefits from './LbBenefits';

export const compose = (...transpilers) => ({ content }) => {
  if (content.clp_header || content.body) {
    let body = content.clp_header || content.body;

    return body.reduce((current, item) => {
      const sbComponent = transpilers.find(allowedComponents => {
        return allowedComponents().type === item.component
      });

      if (sbComponent) {
        return ([
          ...current,
          withComponent(sbComponent)(item)
        ])
      }

      return current
    }, [])
  }
}

export const transpileCmsContent = compose(
  HeroBanner,
  FullWidthSection,
  CTA,
  DividerLine,
  ImageWithText,
  Grid,
  GridSlider,
  Card,
  PromoCode,
  LeadGen,
  Tabs,
  VideoClp,
  SitewideTexts,
  SitewideTextsWithCountDown,
  SitewideBanner,
  HomepageHeroCarousel,
  Homeblock,
  HomepageProductCarousel,
  PreOrderMessageBlock,
  PreOrderText,
  OrderSummaryBlock,
  PromoCodeBlock,
  DeliveryDelayBlock,
  MegaMenuBanner,
  MegaMenuBannerBlock,
  MegaMenuMobileBanner,
  MegaMenuMobileBannerBlock,
  EmptySearchResultBlock,
  SearchErrorMessage,
  PopularCategoriesBlock,
  PopularCategory,
  RichTextBlock,
  Typeform,
  CategoryBannerCarousel,
  CategoryBannerTextOnlyLayout,
  CategoryBannerCards,
  CategoryBannerTextWithImage,
  Table,
  CuratorIO,
  ProductModel,
  PageNotFoundBannerItem,
  ProductUVP,
  GlobalUvp,
  ChildProductCarousel,
  BannerWithProductGrid,
  UgcCarousel,
  UgcItem,
  HomepageAboutBrand,
  OverlayCard,
  AmplifySignatureCard,
  CommonCarousel,
  LbBenefits,
  IframeBlock
);
