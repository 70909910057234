const getProps = props => {
  return {
    body: props.body,
    blockId: props.block_id,
    fullWidth: props.full_width,
    editable: props
  };
};

const Homeblock = () => ({
  type: 'homeblock',
  props: getProps
});

export default Homeblock;
