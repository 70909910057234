import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
const { products: { additionalDefaultSortBy, defaultSortBy } } = config;

export function getAdditionalSort (route) {
  const { params: { slug } } = route;
  const {
    title,
    attribute,
    excludePages
  } = additionalDefaultSortBy;

  if (!excludePages.includes(slug)) return { id: attribute, label: title }
}

export function composeSelectedSort ({ searchQuery, route, categoryDefaultSort = '' }) {
  // this is intentional to avoid messive queries on magento and reindexing
  let defaultSort = categoryDefaultSort && `${categoryDefaultSort === 'new' ? 'recommended' : categoryDefaultSort}:desc`

  if (!defaultSort) {
    const additionalSort = getAdditionalSort(route);
    defaultSort = additionalSort ? additionalSort.id : `${defaultSortBy.attribute}:${defaultSortBy.order}`;
  }
  return searchQuery.sort || defaultSort;
}

export function updatePriceAggregation (aggregations) {
  const currentStore: any = currentStoreView()
  if (aggregations?.agg_range_price) {
    aggregations.agg_range_price.buckets = currentStore.priceFilterRange
  }

  return aggregations
}
