import { trackGtmEvents } from 'common/helpers/gtm';

export default {
  methods: {
    trackMenuHamburgerClick () {
      trackGtmEvents({
        event: 'click_megamenu_icon'
      });
    },
    triggerGtmMegaMenuViewCategoryItem (category: any) {
      const children_count = category?.children_count > 0;
      if (category && children_count) {
        const categoryName = category?.name
          ?.toLowerCase()
          ?.split(' ')
          ?.join('_');
        const GTM_VIEW_MEGA_MENU_ITEM = `view_megamenu_${categoryName}`;
        trackGtmEvents({
          event: GTM_VIEW_MEGA_MENU_ITEM
        });
      }
    },
    removeStoreCode (link) {
      const slashRemoved = link.replace(/^\//, '')
      const storeRemoved = slashRemoved.replace(/^[^/]+\//, '')
      return storeRemoved
    },
    triggerGtmMegaMenuClickCategoryItem (link, level = 0) {
      if (link) {
        const GTM_CLICK_MEGA_MENU = `click_megamenu`;
        trackGtmEvents({
          event: GTM_CLICK_MEGA_MENU,
          megamenu_link: link,
          megamenu_path: this.removeStoreCode(link)
        });
        if (level) {
          const GTM_CLICK_EVENT = `click_megamenu_lvl${level}`;
          trackGtmEvents({
            event: GTM_CLICK_EVENT,
            megamenu_link: link,
            megamenu_path: this.removeStoreCode(link)
          });
        }
      }
    },
    triggerGtmMobileMegaMenuClick (link, level = 0, isLeaf = false) {
      if (link) {
        const GTM_CLICK_MEGA_MENU = `click_megamenu`;
        if (isLeaf) {
          trackGtmEvents({
            event: GTM_CLICK_MEGA_MENU,
            megamenu_link: link,
            megamenu_path: this.removeStoreCode(link)
          });
        } else {
          const GTM_CLICK_EVENT = `click_megamenu_lvl${level}`;
          trackGtmEvents({
            event: GTM_CLICK_EVENT,
            megamenu_link: '',
            megamenu_path: link
          });
        }
      }
    }
  }
};
