import * as types from './types';
import { voteForRestock, fetchAllVotes, deleteVote } from '../services'
import { VoteForRestock } from '../interface';
import i18n from '@vue-storefront/i18n';
import DataService from 'common/services/DataService';

const CATALOG_API = {
  getProductsById: '/api/ext/es-catalog/catalog/getProductsById'
}

const showErrorMessage = message => {
  return {
    type: 'error',
    message: i18n.t(message),
    action1: { label: i18n.t('OK') }
  };
};

const actions = {
  async voteForRestockProduct ({ commit, dispatch }, payload: VoteForRestock) {
    try {
      commit(types.VOTING_PRODUCT, true)
      const resp = await voteForRestock(payload);
      if (resp.resultCode !== 200) { throw new Error('Something went wrong voteForeRestockProduct'); }
      commit(types.VOTING_PRODUCT, false)
      return resp?.result
    } catch (error) {
      commit(types.VOTING_PRODUCT, false)
      dispatch('notification/spawnNotification', showErrorMessage(error.message), { root: true })
    }
  },

  async getAllVotes ({ commit, dispatch }) {
    try {
      commit(types.FETCHING_VOTES, true)
      const resp = await fetchAllVotes()
      if (resp.resultCode !== 200) { throw new Error('Something went wrong fetchAllVotes'); }
      commit(types.ADD_SUBSCRIPTIONS, resp?.result)
      let csvProductIds = new Set()
      if (resp?.result?.length) {
        resp.result.forEach(product => {
          csvProductIds.add(product.parent_id)
        })
        const response = await DataService.get(`${CATALOG_API.getProductsById}?ids=${Array.from(csvProductIds).join(',')}`)
        commit(types.ADD_PRODUCTS_LIST, response.result?.hits?.hits)
      }
      commit(types.FETCHING_VOTES, false)
      return true
    } catch (error) {
      commit(types.FETCHING_VOTES, false)
      dispatch('notification/spawnNotification', showErrorMessage(error.message), { root: true })
    }
  },

  async deleteVote ({ commit, dispatch }, id: string) {
    try {
      commit(types.DELETING_VOTE, true)
      const resp = await deleteVote(id)
      if (resp.resultCode !== 200) { throw new Error('Something went wrong deleteVote'); }
      commit(types.DELETING_VOTE, false)
      return resp.result
    } catch (error) {
      commit(types.DELETING_VOTE, false)
      dispatch('notification/spawnNotification', showErrorMessage(error.message), { root: true })
    }
  }
}
export default actions;
