export const countries = [
  {
    name: 'Afghanistan',
    alpha2: 'AF',
    alpha3: 'AFG',
    states: [],
    value: 'AFG',
    'country-code': '004',
    dial_code: 93,
    dialCode: '+93'
  },
  {
    name: 'Albania',
    alpha2: 'AL',
    alpha3: 'ALB',
    states: [],
    value: 'ALB',
    'country-code': '008',
    dial_code: 355,
    dialCode: '+355'
  },
  {
    name: 'Algeria',
    alpha2: 'DZ',
    alpha3: 'DZA',
    states: [],
    value: 'DZA',
    'country-code': '012',
    dial_code: 213,
    dialCode: '+213'
  },
  {
    name: 'American Samoa',
    alpha2: 'AS',
    alpha3: 'ASM',
    states: [],
    value: 'ASM',
    'country-code': '016',
    dial_code: 1684,
    dialCode: '+1684'
  },
  {
    name: 'Andorra',
    alpha2: 'AD',
    alpha3: 'AND',
    states: [],
    value: 'AND',
    'country-code': '020',
    dial_code: 376,
    dialCode: '+376'
  },
  {
    name: 'Angola',
    alpha2: 'AO',
    alpha3: 'AGO',
    states: [],
    value: 'AGO',
    'country-code': '024',
    dial_code: 244,
    dialCode: '+244'
  },
  {
    name: 'Anguilla',
    alpha2: 'AI',
    alpha3: 'AIA',
    states: [],
    value: 'AIA',
    'country-code': '660',
    dial_code: 1264,
    dialCode: '+1264'
  },
  {
    name: 'Antarctica',
    alpha2: 'AQ',
    alpha3: 'ATA',
    states: [],
    value: 'ATA',
    'country-code': '010',
    dial_code: 1,
    dialCode: '+1'
  },
  {
    name: 'Antigua and Barbuda',
    alpha2: 'AG',
    alpha3: 'ATG',
    states: [],
    value: 'ATG',
    'country-code': '028',
    dial_code: 1268,
    dialCode: '+1268'
  },
  {
    name: 'Argentina',
    alpha2: 'AR',
    alpha3: 'ARG',
    states: [],
    value: 'ARG',
    'country-code': '032',
    dial_code: 54,
    dialCode: '+54'
  },
  {
    name: 'Armenia',
    alpha2: 'AM',
    alpha3: 'ARM',
    states: [],
    value: 'ARM',
    'country-code': '051',
    dial_code: 374,
    dialCode: '+374'
  },
  {
    name: 'Aruba',
    alpha2: 'AW',
    alpha3: 'ABW',
    states: [],
    value: 'ABW',
    'country-code': '533',
    dial_code: 297,
    dialCode: '+297'
  },
  {
    name: 'Australia',
    alpha2: 'AU',
    alpha3: 'AUS',
    states: [
      {
        id: 645,
        name: 'Australian Capital Territory',
        value: 'Australian Capital Territory',
        disabled: false
      },
      {
        id: 648,
        name: 'New South Wales',
        value: 'New South Wales',
        disabled: false
      },
      {
        id: 666,
        name: 'Northern Territory',
        value: 'Northern Territory',
        disabled: false
      },
      {
        id: 654,
        name: 'Queensland',
        value: 'Queensland',
        disabled: false
      },
      {
        id: 657,
        name: 'South Australia',
        value: 'South Australia',
        disabled: false
      },
      {
        id: 660,
        name: 'Tasmania',
        value: 'Tasmania',
        disabled: false
      },
      {
        id: 651,
        name: 'Victoria',
        value: 'Victoria',
        disabled: false
      },
      {
        id: 663,
        name: 'Western Australia',
        value: 'Western Australia',
        disabled: false
      }
    ],
    value: 'AUS',
    'country-code': '036',
    available: true,
    dial_code: 61,
    dialCode: '+61'
  },
  {
    name: 'Austria',
    alpha2: 'AT',
    alpha3: 'AUT',
    states: [],
    value: 'AUT',
    'country-code': '040',
    dial_code: 43,
    dialCode: '+43'
  },
  {
    name: 'Azerbaijan',
    alpha2: 'AZ',
    alpha3: 'AZE',
    states: [],
    value: 'AZE',
    'country-code': '031',
    dial_code: 994,
    dialCode: '+994'
  },
  {
    name: 'Bahamas',
    alpha2: 'BS',
    alpha3: 'BHS',
    states: [],
    value: 'BHS',
    'country-code': '044',
    dial_code: 1242,
    dialCode: '+1242'
  },
  {
    name: 'Bahrain',
    alpha2: 'BH',
    alpha3: 'BHR',
    states: [],
    value: 'BHR',
    'country-code': '048',
    dial_code: 973,
    dialCode: '+973'
  },
  {
    name: 'Bangladesh',
    alpha2: 'BD',
    alpha3: 'BGD',
    states: [],
    value: 'BGD',
    'country-code': '050',
    dial_code: 880,
    dialCode: '+880'
  },
  {
    name: 'Barbados',
    alpha2: 'BB',
    alpha3: 'BRB',
    states: [],
    value: 'BRB',
    'country-code': '052',
    dial_code: 1246,
    dialCode: '+1246'
  },
  {
    name: 'Belarus',
    alpha2: 'BY',
    alpha3: 'BLR',
    states: [],
    value: 'BLR',
    'country-code': '112',
    dial_code: 375,
    dialCode: '+375'
  },
  {
    name: 'Belgium',
    alpha2: 'BE',
    alpha3: 'BEL',
    states: [],
    value: 'BEL',
    'country-code': '056',
    dial_code: 32,
    dialCode: '+32'
  },
  {
    name: 'Belize',
    alpha2: 'BZ',
    alpha3: 'BLZ',
    states: [],
    value: 'BLZ',
    'country-code': '084',
    dial_code: 501,
    dialCode: '+501'
  },
  {
    name: 'Benin',
    alpha2: 'BJ',
    alpha3: 'BEN',
    states: [],
    value: 'BEN',
    'country-code': '204',
    dial_code: 229,
    dialCode: '+229'
  },
  {
    name: 'Bermuda',
    alpha2: 'BM',
    alpha3: 'BMU',
    states: [],
    value: 'BMU',
    'country-code': '060',
    dial_code: 1441,
    dialCode: '+1441'
  },
  {
    name: 'Bhutan',
    alpha2: 'BT',
    alpha3: 'BTN',
    states: [],
    value: 'BTN',
    'country-code': '064',
    dial_code: 975,
    dialCode: '+975'
  },
  {
    name: 'Bolivia (Plurinational State of)',
    alpha2: 'BO',
    alpha3: 'BOL',
    states: [],
    value: 'BOL',
    'country-code': '068',
    dial_code: 591,
    dialCode: '+591'
  },
  {
    name: 'Bosnia and Herzegovina',
    alpha2: 'BA',
    alpha3: 'BIH',
    states: [],
    value: 'BIH',
    'country-code': '070',
    dial_code: 387,
    dialCode: '+387'
  },
  {
    name: 'Botswana',
    alpha2: 'BW',
    alpha3: 'BWA',
    states: [],
    value: 'BWA',
    'country-code': '072',
    dial_code: 267,
    dialCode: '+267'
  },
  {
    name: 'Brazil',
    alpha2: 'BR',
    alpha3: 'BRA',
    states: [],
    value: 'BRA',
    'country-code': '076',
    dial_code: 55,
    dialCode: '+55'
  },
  {
    name: 'British Indian Ocean Territory',
    alpha2: 'IO',
    alpha3: 'IOT',
    states: [],
    value: 'IOT',
    'country-code': '086',
    dial_code: 246,
    dialCode: '+246'
  },
  {
    name: 'Brunei',
    alpha2: 'BN',
    alpha3: 'BRN',
    states: [],
    value: 'BRN',
    'country-code': '096',
    available: true,
    dial_code: 673,
    dialCode: '+673'
  },
  {
    name: 'Bulgaria',
    alpha2: 'BG',
    alpha3: 'BGR',
    states: [],
    value: 'BGR',
    'country-code': '100',
    dial_code: 359,
    dialCode: '+359'
  },
  {
    name: 'Burkina Faso',
    alpha2: 'BF',
    alpha3: 'BFA',
    states: [],
    value: 'BFA',
    'country-code': '854',
    dial_code: 226,
    dialCode: '+226'
  },
  {
    name: 'Burundi',
    alpha2: 'BI',
    alpha3: 'BDI',
    states: [],
    value: 'BDI',
    'country-code': '108',
    dial_code: 257,
    dialCode: '+257'
  },
  {
    name: 'Cabo Verde',
    alpha2: 'CV',
    alpha3: 'CPV',
    states: [],
    value: 'CPV',
    'country-code': '132',
    dial_code: 238,
    dialCode: '+238'
  },
  {
    name: 'Cambodia',
    alpha2: 'KH',
    alpha3: 'KHM',
    states: [],
    value: 'KHM',
    'country-code': '116',
    available: true,
    dial_code: 855,
    dialCode: '+855'
  },
  {
    name: 'Cameroon',
    alpha2: 'CM',
    alpha3: 'CMR',
    states: [],
    value: 'CMR',
    'country-code': '120',
    dial_code: 237,
    dialCode: '+237'
  },
  {
    name: 'Canada',
    alpha2: 'CA',
    alpha3: 'CAN',
    isGoogleAutocompleteEnabled: true,
    states: [
      {
        id: 66,
        name: 'Alberta',
        value: 'Alberta',
        disabled: false
      },
      {
        id: 67,
        name: 'British Columbia',
        value: 'British Columbia',
        disabled: false
      },
      {
        id: 68,
        name: 'Manitoba',
        value: 'Manitoba',
        disabled: false
      },
      {
        id: 70,
        name: 'New Brunswick',
        value: 'New Brunswick',
        disabled: false
      },
      {
        id: 69,
        name: 'Newfoundland and Labrador',
        value: 'Newfoundland and Labrador',
        disabled: false
      },
      {
        id: 72,
        name: 'Northwest Territories',
        value: 'Northwest Territories',
        disabled: false
      },
      {
        id: 71,
        name: 'Nova Scotia',
        value: 'Nova Scotia',
        disabled: false
      },
      {
        id: 73,
        name: 'Nunavut',
        value: 'Nunavut',
        disabled: false
      },
      {
        id: 74,
        name: 'Ontario',
        value: 'Ontario',
        disabled: false
      },
      {
        id: 75,
        name: 'Prince Edward Island',
        value: 'Prince Edward Island',
        disabled: false
      },
      {
        id: 76,
        name: 'Quebec',
        value: 'Quebec',
        disabled: false
      },
      {
        id: 77,
        name: 'Saskatchewan',
        value: 'Saskatchewan',
        disabled: false
      },
      {
        id: 78,
        name: 'Yukon Territory',
        value: 'Yukon Territory',
        disabled: false
      }
    ],
    value: 'CAN',
    'country-code': '124',
    available: true,
    website: true,
    dial_code: 1,
    dialCode: '+1'
  },
  {
    name: 'Cayman Islands',
    alpha2: 'KY',
    alpha3: 'CYM',
    states: [],
    value: 'CYM',
    'country-code': '136',
    dial_code: 345,
    dialCode: '+345'
  },
  {
    name: 'Central African Republic',
    alpha2: 'CF',
    alpha3: 'CAF',
    states: [],
    value: 'CAF',
    'country-code': '140',
    dial_code: 236,
    dialCode: '+236'
  },
  {
    name: 'Chad',
    alpha2: 'TD',
    alpha3: 'TCD',
    states: [],
    value: 'TCD',
    'country-code': '148',
    dial_code: 235,
    dialCode: '+235'
  },
  {
    name: 'Chile',
    alpha2: 'CL',
    alpha3: 'CHL',
    states: [],
    value: 'CHL',
    'country-code': '152',
    dial_code: 56,
    dialCode: '+56'
  },
  {
    name: 'China',
    alpha2: 'CN',
    alpha3: 'CHN',
    states: [
      {
        id: 799,
        name: 'Anhui Sheng',
        value: 'Anhui Sheng',
        disabled: false
      },
      {
        id: 802,
        name: 'Beijing Shi',
        value: 'Beijing Shi',
        disabled: false
      },
      {
        id: 805,
        name: 'Chongqing Shi',
        value: 'Chongqing Shi',
        disabled: false
      },
      {
        id: 808,
        name: 'Fuijan Sheng',
        value: 'Fuijan Sheng',
        disabled: false
      },
      {
        id: 811,
        name: 'Gansu Sheng',
        value: 'Gansu Sheng',
        disabled: false
      },
      {
        id: 814,
        name: 'Guangdong Sheng',
        value: 'Guangdong Sheng',
        disabled: false
      },
      {
        id: 817,
        name: 'Guangxi Zhuangzu Zizhiqu',
        value: 'Guangxi Zhuangzu Zizhiqu',
        disabled: false
      },
      {
        id: 820,
        name: 'Guizhou Sheng',
        value: 'Guizhou Sheng',
        disabled: false
      },
      {
        id: 823,
        name: 'Hainan Sheng',
        value: 'Hainan Sheng',
        disabled: false
      },
      {
        id: 826,
        name: 'Hebei Sheng',
        value: 'Hebei Sheng',
        disabled: false
      },
      {
        id: 829,
        name: 'Heilongjiang Sheng',
        value: 'Heilongjiang Sheng',
        disabled: false
      },
      {
        id: 832,
        name: 'Henan Sheng',
        value: 'Henan Sheng',
        disabled: false
      },
      {
        id: 835,
        name: 'Hong Kong SAR',
        value: 'Hong Kong SAR',
        disabled: false
      },
      {
        id: 838,
        name: 'Hubei Sheng',
        value: 'Hubei Sheng',
        disabled: false
      },
      {
        id: 841,
        name: 'Hunan Sheng',
        value: 'Hunan Sheng',
        disabled: false
      },
      {
        id: 844,
        name: 'Jiangsu Sheng',
        value: 'Jiangsu Sheng',
        disabled: false
      },
      {
        id: 847,
        name: 'Jiangxi Sheng',
        value: 'Jiangxi Sheng',
        disabled: false
      },
      {
        id: 850,
        name: 'Jilin Sheng',
        value: 'Jilin Sheng',
        disabled: false
      },
      {
        id: 853,
        name: 'Liaoning Sheng',
        value: 'Liaoning Sheng',
        disabled: false
      },
      {
        id: 856,
        name: 'Macao SAR',
        value: 'Macao SAR',
        disabled: false
      },
      {
        id: 859,
        name: 'Nei Mongol Zizhiqu',
        value: 'Nei Mongol Zizhiqu',
        disabled: false
      },
      {
        id: 862,
        name: 'Ningxia Huizi Zizhiqu',
        value: 'Ningxia Huizi Zizhiqu',
        disabled: false
      },
      {
        id: 865,
        name: 'Qinghai Sheng',
        value: 'Qinghai Sheng',
        disabled: false
      },
      {
        id: 868,
        name: 'Shaanxi Sheng',
        value: 'Shaanxi Sheng',
        disabled: false
      },
      {
        id: 871,
        name: 'Shandong Sheng',
        value: 'Shandong Sheng',
        disabled: false
      },
      {
        id: 874,
        name: 'Shanghai Sheng',
        value: 'Shanghai Sheng',
        disabled: false
      },
      {
        id: 865,
        name: 'Qinghai Sheng',
        value: 'Qinghai Sheng',
        disabled: false
      },
      {
        id: 880,
        name: 'Sichuan Sheng',
        value: 'Sichuan Sheng',
        disabled: false
      },
      {
        id: 883,
        name: 'Taiwan Sheng',
        value: 'Taiwan Sheng',
        disabled: false
      },
      {
        id: 8886,
        name: 'Tianjin Shi',
        value: 'Tianjin Shi',
        disabled: false
      },
      {
        id: 889,
        name: 'Xinjiang Uygur Zizhiqu',
        value: 'Xinjiang Uygur Zizhiqu',
        disabled: false
      },
      {
        id: 892,
        name: 'Xizang Zizhiqu',
        value: 'Xizang Zizhiqu',
        disabled: false
      },
      {
        id: 895,
        name: 'Yunnan Sheng',
        value: 'Yunnan Sheng',
        disabled: false
      },
      {
        id: 898,
        name: 'Zhejiang Sheng',
        value: 'Zhejiang Sheng',
        disabled: false
      }
    ],
    value: 'CHN',
    'country-code': '156',
    available: true,
    dial_code: 86,
    dialCode: '+86'
  },
  {
    name: 'Christmas Island',
    alpha2: 'CX',
    alpha3: 'CXR',
    states: [],
    value: 'CXR',
    'country-code': '162',
    dial_code: 61,
    dialCode: '+61'
  },
  {
    name: 'Cocos (Keeling) Islands',
    alpha2: 'CC',
    alpha3: 'CCK',
    states: [],
    value: 'CCK',
    'country-code': '166',
    dial_code: 61,
    dialCode: '+61'
  },
  {
    name: 'Colombia',
    alpha2: 'CO',
    alpha3: 'COL',
    states: [],
    value: 'COL',
    'country-code': '170',
    dial_code: 57,
    dialCode: '+57'
  },
  {
    name: 'Comoros',
    alpha2: 'KM',
    alpha3: 'COM',
    states: [],
    value: 'COM',
    'country-code': '174',
    dial_code: 269,
    dialCode: '+269'
  },
  {
    name: 'Congo',
    alpha2: 'CG',
    alpha3: 'COG',
    states: [],
    value: 'COG',
    'country-code': '178',
    dial_code: 242,
    dialCode: '+242'
  },
  {
    name: 'Congo, Democratic Republic of the',
    alpha2: 'CD',
    alpha3: 'COD',
    states: [],
    value: 'COD',
    'country-code': '180',
    dial_code: 243,
    dialCode: '+243'
  },
  {
    name: 'Cook Islands',
    alpha2: 'CK',
    alpha3: 'COK',
    states: [],
    value: 'COK',
    'country-code': '184',
    dial_code: 682,
    dialCode: '+682'
  },
  {
    name: 'Costa Rica',
    alpha2: 'CR',
    alpha3: 'CRI',
    states: [],
    value: 'CRI',
    'country-code': '188',
    dial_code: 506,
    dialCode: '+506'
  },
  {
    name: "Côte d'Ivoire",
    alpha2: 'CI',
    alpha3: 'CIV',
    states: [],
    value: 'CIV',
    'country-code': '384',
    dial_code: 225,
    dialCode: '+225'
  },
  {
    name: 'Croatia',
    alpha2: 'HR',
    alpha3: 'HRV',
    states: [],
    value: 'HRV',
    'country-code': '191',
    dial_code: 385,
    dialCode: '+385'
  },
  {
    name: 'Cuba',
    alpha2: 'CU',
    alpha3: 'CUB',
    states: [],
    value: 'CUB',
    'country-code': '192',
    dial_code: 53,
    dialCode: '+53'
  },
  {
    name: 'Cyprus',
    alpha2: 'CY',
    alpha3: 'CYP',
    states: [],
    value: 'CYP',
    'country-code': '196',
    dial_code: 537,
    dialCode: '+537'
  },
  {
    name: 'Czechia',
    alpha2: 'CZ',
    alpha3: 'CZE',
    states: [],
    value: 'CZE',
    'country-code': '203',
    dial_code: 420,
    dialCode: '+420'
  },
  {
    name: 'Denmark',
    alpha2: 'DK',
    alpha3: 'DNK',
    states: [],
    value: 'DNK',
    'country-code': '208',
    dial_code: 45,
    dialCode: '+45'
  },
  {
    name: 'Djibouti',
    alpha2: 'DJ',
    alpha3: 'DJI',
    states: [],
    value: 'DJI',
    'country-code': '262',
    dial_code: 253,
    dialCode: '+253'
  },
  {
    name: 'Dominica',
    alpha2: 'DM',
    alpha3: 'DMA',
    states: [],
    value: 'DMA',
    'country-code': '212',
    dial_code: 1767,
    dialCode: '+1767'
  },
  {
    name: 'Dominican Republic',
    alpha2: 'DO',
    alpha3: 'DOM',
    states: [],
    value: 'DOM',
    'country-code': '214',
    dial_code: 1849,
    dialCode: '+1849'
  },
  {
    name: 'Ecuador',
    alpha2: 'EC',
    alpha3: 'ECU',
    states: [],
    value: 'ECU',
    'country-code': '218',
    dial_code: 593,
    dialCode: '+593'
  },
  {
    name: 'Egypt',
    alpha2: 'EG',
    alpha3: 'EGY',
    states: [],
    value: 'EGY',
    'country-code': '818',
    dial_code: 20,
    dialCode: '+20'
  },
  {
    name: 'El Salvador',
    alpha2: 'SV',
    alpha3: 'SLV',
    states: [],
    value: 'SLV',
    'country-code': '222',
    dial_code: 503,
    dialCode: '+503'
  },
  {
    name: 'Equatorial Guinea',
    alpha2: 'GQ',
    alpha3: 'GNQ',
    states: [],
    value: 'GNQ',
    'country-code': '226',
    dial_code: 240,
    dialCode: '+240'
  },
  {
    name: 'Eritrea',
    alpha2: 'ER',
    alpha3: 'ERI',
    states: [],
    value: 'ERI',
    'country-code': '232',
    dial_code: 291,
    dialCode: '+291'
  },
  {
    name: 'Estonia',
    alpha2: 'EE',
    alpha3: 'EST',
    states: [],
    value: 'EST',
    'country-code': '233',
    dial_code: 372,
    dialCode: '+372'
  },
  {
    name: 'Ethiopia',
    alpha2: 'ET',
    alpha3: 'ETH',
    states: [],
    value: 'ETH',
    'country-code': '231',
    dial_code: 251,
    dialCode: '+251'
  },
  {
    name: 'Falkland Islands (Malvinas)',
    alpha2: 'FK',
    alpha3: 'FLK',
    states: [],
    value: 'FLK',
    'country-code': '238',
    dial_code: 500,
    dialCode: '+500'
  },
  {
    name: 'Faroe Islands',
    alpha2: 'FO',
    alpha3: 'FRO',
    states: [],
    value: 'FRO',
    'country-code': '234',
    dial_code: 298,
    dialCode: '+298'
  },
  {
    name: 'Fiji',
    alpha2: 'FJ',
    alpha3: 'FJI',
    states: [],
    value: 'FJI',
    'country-code': '242',
    dial_code: 679,
    dialCode: '+679'
  },
  {
    name: 'Finland',
    alpha2: 'FI',
    alpha3: 'FIN',
    states: [],
    value: 'FIN',
    'country-code': '246',
    dial_code: 358,
    dialCode: '+358'
  },
  {
    name: 'France',
    alpha2: 'FR',
    alpha3: 'FRA',
    states: [],
    value: 'FRA',
    'country-code': '250',
    dial_code: 33,
    dialCode: '+33'
  },
  {
    name: 'French Guiana',
    alpha2: 'GF',
    alpha3: 'GUF',
    states: [],
    value: 'GUF',
    'country-code': '254',
    dial_code: 594,
    dialCode: '+594'
  },
  {
    name: 'French Polynesia',
    alpha2: 'PF',
    alpha3: 'PYF',
    states: [],
    value: 'PYF',
    'country-code': '258',
    dial_code: 689,
    dialCode: '+689'
  },
  {
    name: 'Gabon',
    alpha2: 'GA',
    alpha3: 'GAB',
    states: [],
    value: 'GAB',
    'country-code': '266',
    dial_code: 241,
    dialCode: '+241'
  },
  {
    name: 'Gambia',
    alpha2: 'GM',
    alpha3: 'GMB',
    states: [],
    value: 'GMB',
    'country-code': '270',
    dial_code: 220,
    dialCode: '+220'
  },
  {
    name: 'Georgia',
    alpha2: 'GE',
    alpha3: 'GEO',
    states: [],
    value: 'GEO',
    'country-code': '268',
    dial_code: 995,
    dialCode: '+995'
  },
  {
    name: 'Germany',
    alpha2: 'DE',
    alpha3: 'DEU',
    states: [],
    value: 'DEU',
    'country-code': '276',
    dial_code: 49,
    dialCode: '+49'
  },
  {
    name: 'Ghana',
    alpha2: 'GH',
    alpha3: 'GHA',
    states: [],
    value: 'GHA',
    'country-code': '288',
    dial_code: 233,
    dialCode: '+233'
  },
  {
    name: 'Gibraltar',
    alpha2: 'GI',
    alpha3: 'GIB',
    states: [],
    value: 'GIB',
    'country-code': '292',
    dial_code: 350,
    dialCode: '+350'
  },
  {
    name: 'Greece',
    alpha2: 'GR',
    alpha3: 'GRC',
    states: [],
    value: 'GRC',
    'country-code': '300',
    dial_code: 30,
    dialCode: '+30'
  },
  {
    name: 'Greenland',
    alpha2: 'GL',
    alpha3: 'GRL',
    states: [],
    value: 'GRL',
    'country-code': '304',
    dial_code: 299,
    dialCode: '+299'
  },
  {
    name: 'Grenada',
    alpha2: 'GD',
    alpha3: 'GRD',
    states: [],
    value: 'GRD',
    'country-code': '308',
    dial_code: 1473,
    dialCode: '+1473'
  },
  {
    name: 'Guadeloupe',
    alpha2: 'GP',
    alpha3: 'GLP',
    states: [],
    value: 'GLP',
    'country-code': '312',
    dial_code: 590,
    dialCode: '+590'
  },
  {
    name: 'Guam',
    alpha2: 'GU',
    alpha3: 'GUM',
    states: [],
    value: 'GUM',
    'country-code': '316',
    dial_code: 1671,
    dialCode: '+1671'
  },
  {
    name: 'Guatemala',
    alpha2: 'GT',
    alpha3: 'GTM',
    states: [],
    value: 'GTM',
    'country-code': '320',
    dial_code: 502,
    dialCode: '+502'
  },
  {
    name: 'Guernsey',
    alpha2: 'GG',
    alpha3: 'GGY',
    states: [],
    value: 'GGY',
    'country-code': '831',
    dial_code: 44,
    dialCode: '+44'
  },
  {
    name: 'Guinea',
    alpha2: 'GN',
    alpha3: 'GIN',
    states: [],
    value: 'GIN',
    'country-code': '324',
    dial_code: 224,
    dialCode: '+224'
  },
  {
    name: 'Guinea-Bissau',
    alpha2: 'GW',
    alpha3: 'GNB',
    states: [],
    value: 'GNB',
    'country-code': '624',
    dial_code: 245,
    dialCode: '+245'
  },
  {
    name: 'Guyana',
    alpha2: 'GY',
    alpha3: 'GUY',
    states: [],
    value: 'GUY',
    'country-code': '328',
    dial_code: 595,
    dialCode: '+595'
  },
  {
    name: 'Haiti',
    alpha2: 'HT',
    alpha3: 'HTI',
    states: [],
    value: 'HTI',
    'country-code': '332',
    dial_code: 509,
    dialCode: '+509'
  },
  {
    name: 'Holy See',
    alpha2: 'VA',
    alpha3: 'VAT',
    states: [],
    value: 'VAT',
    'country-code': '336',
    dial_code: 379,
    dialCode: '+379'
  },
  {
    name: 'Honduras',
    alpha2: 'HN',
    alpha3: 'HND',
    states: [],
    value: 'HND',
    'country-code': '340',
    dial_code: 504,
    dialCode: '+504'
  },
  {
    name: 'Hong Kong SAR China',
    alpha2: 'HK',
    alpha3: 'HKG',
    states: [
      {
        name: 'Hong Kong Island',
        value: 'disabled'
      },
      {
        name: 'Central and Western',
        value: 'Central and Western',
        'country-code': 'HKG'
      },
      {
        name: 'Eastern',
        value: 'Eastern',
        'country-code': 'HKG'
      },
      {
        name: 'Southern',
        value: 'Southern',
        'country-code': 'HKG'
      },
      {
        name: 'Wan Chai',
        value: 'Wan Chai',
        'country-code': 'HKG'
      },
      {
        value: 'divider'
      },
      {
        name: 'Kowloon',
        value: 'disabled'
      },
      {
        name: 'Kowloon City',
        value: 'Kowloon City',
        'country-code': 'HKG'
      },
      {
        name: 'Kwun Tong',
        value: 'Kwun Tong',
        'country-code': 'HKG'
      },
      {
        name: 'Sham Shui Po',
        value: 'Sham Shui Po',
        'country-code': 'HKG'
      },
      {
        name: 'Wong Tai Sin',
        value: 'Wong Tai Sin',
        'country-code': 'HKG'
      },
      {
        name: 'Yau Tsim Mong',
        value: 'Yau Tsim Mong',
        'country-code': 'HKG'
      },
      {
        value: 'divider'
      },
      {
        name: 'New Territories',
        value: 'disabled'
      },
      {
        name: 'Islands',
        value: 'Islands',
        'country-code': 'HKG'
      },
      {
        name: 'Kwai Tsing',
        value: 'Kwai Tsing',
        'country-code': 'HKG'
      },
      {
        name: 'North',
        value: 'North',
        'country-code': 'HKG'
      },
      {
        name: 'Sai Kung',
        value: 'Sai Kung',
        'country-code': 'HKG'
      },
      {
        name: 'Sha Tin',
        value: 'Sha Tin',
        'country-code': 'HKG'
      },
      {
        name: 'Tai Po',
        value: 'Tai Po',
        'country-code': 'HKG'
      },
      {
        name: 'Tsuen Wan',
        value: 'Tsuen Wan',
        'country-code': 'HKG'
      },
      {
        name: 'Tuen Mun',
        value: 'Tuen Mun',
        'country-code': 'HKG'
      },
      {
        name: 'Yuen Long',
        value: 'Yuen Long',
        'country-code': 'HKG'
      }
    ],
    value: 'HKG',
    'country-code': '344',
    available: true,
    localCurrency: 'HK$',
    website: true,
    dial_code: 852,
    dialCode: '+852'
  },
  {
    name: 'Hungary',
    alpha2: 'HU',
    alpha3: 'HUN',
    states: [],
    value: 'HUN',
    'country-code': '348',
    dial_code: 36,
    dialCode: '+36'
  },
  {
    name: 'Iceland',
    alpha2: 'IS',
    alpha3: 'ISL',
    states: [],
    value: 'ISL',
    'country-code': '352',
    dial_code: 354,
    dialCode: '+354'
  },
  {
    name: 'India',
    alpha2: 'IN',
    alpha3: 'IND',
    states: [],
    value: 'IND',
    'country-code': '356',
    dial_code: 91,
    dialCode: '+91'
  },
  {
    name: 'Indonesia',
    alpha2: 'ID',
    alpha3: 'IDN',
    states: [
      {
        name: 'Bangka BelitungBali',
        value: 'Bangka BelitungBali',
        disabled: false,
        code: 'ID-BA'
      },
      {
        name: 'Bangka Belitung',
        value: 'Bangka Belitung',
        disabled: false,
        code: 'ID-BB'
      },
      {
        name: 'Banten',
        value: 'Banten',
        disabled: false,
        code: 'ID-BT'
      },
      {
        name: 'Bengkulu',
        value: 'Bengkulu',
        disabled: false,
        code: 'ID-BE'
      },
      {
        name: 'DI Yogyakarta',
        value: 'DI Yogyakarta',
        disabled: false,
        code: 'ID-YO'
      },
      {
        name: 'DKI Jakarta',
        value: 'DKI Jakarta',
        disabled: false,
        code: 'ID-JK'
      },
      {
        name: 'Gorontalo',
        value: 'Gorontalo',
        disabled: false,
        code: 'ID-GO'
      },
      {
        name: 'Jambi',
        value: 'Jambi',
        disabled: false,
        code: 'ID-JA'
      },
      {
        name: 'Jawa Barat',
        value: 'Jawa Barat',
        disabled: false,
        code: 'ID-JB'
      },
      {
        name: 'Jawa Tengah',
        value: 'Jawa Tengah',
        disabled: false,
        code: 'ID-JT'
      },
      {
        name: 'Jawa Timur',
        value: 'Jawa Timur',
        disabled: false,
        code: 'ID-JI'
      },
      {
        name: 'Kalimantan Barat',
        value: 'Kalimantan Barat',
        disabled: false,
        code: 'ID-KB'
      },
      {
        name: 'Kalimantan Selatan',
        value: 'Kalimantan Selatan',
        disabled: false,
        code: 'ID-KS'
      },
      {
        name: 'Kalimantan Tengah',
        value: 'Kalimantan Tengah',
        disabled: false,
        code: 'ID-KT'
      },
      {
        name: 'Kalimantan Timur',
        value: 'Kalimantan Timur',
        disabled: false,
        code: 'ID-KI'
      },
      {
        name: 'Kalimantan Utara',
        value: 'Kalimantan Utara',
        disabled: false,
        code: 'ID-KU'
      },
      {
        name: 'Kepulauan Riau',
        value: 'Kepulauan Riau',
        disabled: false,
        code: 'ID-KR'
      },
      {
        name: 'Lampung',
        value: 'Lampung',
        disabled: false,
        code: 'ID-LA'
      },
      {
        name: 'Maluku',
        value: 'Maluku',
        disabled: false,
        code: 'ID-MA'
      },
      {
        name: 'Maluku Utara',
        value: 'Maluku Utara',
        disabled: false,
        code: 'ID-MU'
      },
      {
        name: 'Nanggroe Aceh Darussalam (NAD)',
        value: 'Nanggroe Aceh Darussalam (NAD)',
        disabled: false,
        code: 'ID-AC'
      },
      {
        name: 'Nusa Tenggara Barat (NTB)',
        value: 'Nusa Tenggara Barat (NTB)',
        disabled: false,
        code: 'ID-NB'
      },
      {
        name: 'Nusa Tenggara Timur (NTT)',
        value: 'Nusa Tenggara Timur (NTT)',
        disabled: false,
        code: 'ID-NT'
      },
      {
        name: 'Papua',
        value: 'Papua',
        disabled: false,
        code: 'ID-PA'
      },
      {
        name: 'Papua Barat',
        value: 'Papua Barat',
        disabled: false,
        code: 'ID-PB'
      },
      {
        name: 'Riau',
        value: 'Riau',
        disabled: false,
        code: 'ID-RI'
      },
      {
        name: 'Sulawesi Barat',
        value: 'Sulawesi Barat',
        disabled: false,
        code: 'ID-SR'
      },
      {
        name: 'Sulawesi Selatan',
        value: 'Sulawesi Selatan',
        disabled: false,
        code: 'ID-SN'
      },
      {
        name: 'Sulawesi Tengah',
        value: 'Sulawesi Tengah',
        disabled: false,
        code: 'ID-ST'
      },
      {
        name: 'Sulawesi Tenggara',
        value: 'Sulawesi Tenggara',
        disabled: false,
        code: 'ID-SG'
      },
      {
        name: 'Sulawesi Utara',
        value: 'Sulawesi Utara',
        disabled: false,
        code: 'ID-SA'
      },
      {
        name: 'Sumatera Barat',
        value: 'Sumatera Barat',
        disabled: false,
        code: 'ID-SB'
      },
      {
        name: 'Sumatera Selatan',
        value: 'Sumatera Selatan',
        disabled: false,
        code: 'ID-SS'
      },
      {
        name: 'Sumatera Utara',
        value: 'Sumatera Utara',
        disabled: false,
        code: 'ID-SU'
      }
    ],
    value: 'IDN',
    'country-code': '360',
    available: true,
    localCurrency: 'IDR',
    website: true,
    dial_code: 62,
    dialCode: '+62'
  },
  {
    name: 'Iran (Islamic Republic of)',
    alpha2: 'IR',
    alpha3: 'IRN',
    states: [],
    value: 'IRN',
    'country-code': '364',
    dial_code: 98,
    dialCode: '+98'
  },
  {
    name: 'Iraq',
    alpha2: 'IQ',
    alpha3: 'IRQ',
    states: [],
    value: 'IRQ',
    'country-code': '368',
    dial_code: 964,
    dialCode: '+964'
  },
  {
    name: 'Ireland',
    alpha2: 'IE',
    alpha3: 'IRL',
    states: [],
    value: 'IRL',
    'country-code': '372',
    dial_code: 353,
    dialCode: '+353'
  },
  {
    name: 'Israel',
    alpha2: 'IL',
    alpha3: 'ISR',
    states: [],
    value: 'ISR',
    'country-code': '376',
    dial_code: 972,
    dialCode: '+972'
  },
  {
    name: 'Italy',
    alpha2: 'IT',
    alpha3: 'ITA',
    states: [],
    value: 'ITA',
    'country-code': '380',
    dial_code: 39,
    dialCode: '+39'
  },
  {
    name: 'Jamaica',
    alpha2: 'JM',
    alpha3: 'JAM',
    states: [],
    value: 'JAM',
    'country-code': '388',
    dial_code: 1876,
    dialCode: '+1876'
  },
  {
    name: 'Japan',
    alpha2: 'JP',
    alpha3: 'JPN',
    states: [],
    value: 'JPN',
    'country-code': '392',
    available: true,
    localCurrency: 'USD',
    website: false,
    dial_code: 81,
    dialCode: '+81'
  },
  {
    name: 'Jordan',
    alpha2: 'JO',
    alpha3: 'JOR',
    states: [],
    value: 'JOR',
    'country-code': '400',
    dial_code: 962,
    dialCode: '+962'
  },
  {
    name: 'Kazakhstan',
    alpha2: 'KZ',
    alpha3: 'KAZ',
    states: [],
    value: 'KAZ',
    'country-code': '398',
    dial_code: 77,
    dialCode: '+77'
  },
  {
    name: 'Kenya',
    alpha2: 'KE',
    alpha3: 'KEN',
    states: [],
    value: 'KEN',
    'country-code': '404',
    dial_code: 254,
    dialCode: '+254'
  },
  {
    name: 'Kiribati',
    alpha2: 'KI',
    alpha3: 'KIR',
    states: [],
    value: 'KIR',
    'country-code': '296',
    dial_code: 686,
    dialCode: '+686'
  },
  {
    name: 'North Korea',
    alpha2: 'KP',
    alpha3: 'PRK',
    states: [],
    value: 'PRK',
    'country-code': '408',
    dial_code: 850,
    dialCode: '+850'
  },
  {
    name: 'South Korea',
    alpha2: 'KR',
    alpha3: 'KOR',
    states: [],
    value: 'KOR',
    'country-code': '410',
    available: true,
    dial_code: 82,
    dialCode: '+82'
  },
  {
    name: 'Kuwait',
    alpha2: 'KW',
    alpha3: 'KWT',
    states: [],
    value: 'KWT',
    'country-code': '414',
    dial_code: 965,
    dialCode: '+965'
  },
  {
    name: 'Kyrgyzstan',
    alpha2: 'KG',
    alpha3: 'KGZ',
    states: [],
    value: 'KGZ',
    'country-code': '417',
    dial_code: 996,
    dialCode: '+996'
  },
  {
    name: "Lao People's Democratic Republic",
    alpha2: 'LA',
    alpha3: 'LAO',
    states: [],
    value: 'LAO',
    'country-code': '418',
    dial_code: 856,
    dialCode: '+856'
  },
  {
    name: 'Latvia',
    alpha2: 'LV',
    alpha3: 'LVA',
    states: [],
    value: 'LVA',
    'country-code': '428',
    dial_code: 371,
    dialCode: '+371'
  },
  {
    name: 'Lebanon',
    alpha2: 'LB',
    alpha3: 'LBN',
    states: [],
    value: 'LBN',
    'country-code': '422',
    dial_code: 961,
    dialCode: '+961'
  },
  {
    name: 'Lesotho',
    alpha2: 'LS',
    alpha3: 'LSO',
    states: [],
    value: 'LSO',
    'country-code': '426',
    dial_code: 266,
    dialCode: '+266'
  },
  {
    name: 'Liberia',
    alpha2: 'LR',
    alpha3: 'LBR',
    states: [],
    value: 'LBR',
    'country-code': '430',
    dial_code: 231,
    dialCode: '+231'
  },
  {
    name: 'Libya',
    alpha2: 'LY',
    alpha3: 'LBY',
    states: [],
    value: 'LBY',
    'country-code': '434',
    dial_code: 218,
    dialCode: '+218'
  },
  {
    name: 'Liechtenstein',
    alpha2: 'LI',
    alpha3: 'LIE',
    states: [],
    value: 'LIE',
    'country-code': '438',
    dial_code: 423,
    dialCode: '+423'
  },
  {
    name: 'Lithuania',
    alpha2: 'LT',
    alpha3: 'LTU',
    states: [],
    value: 'LTU',
    'country-code': '440',
    dial_code: 370,
    dialCode: '+370'
  },
  {
    name: 'Luxembourg',
    alpha2: 'LU',
    alpha3: 'LUX',
    states: [],
    value: 'LUX',
    'country-code': '442',
    dial_code: 352,
    dialCode: '+352'
  },
  {
    name: 'Macao SAR China',
    alpha2: 'MO',
    alpha3: 'MAC',
    states: [
      {
        name: 'Macao',
        value: 'Macao',
        'country-code': 'MAC'
      },
      {
        name: 'Taipa',
        value: 'Taipa',
        'country-code': 'MAC'
      },
      {
        name: 'Coloane',
        value: 'Coloane',
        'country-code': 'MAC'
      },
      {
        name: 'Others',
        value: 'Others',
        'country-code': 'MAC'
      }
    ],
    value: 'MAC',
    'country-code': '446',
    available: true,
    localCurrency: 'HKD',
    website: true,
    dial_code: 853,
    dialCode: '+853'
  },
  {
    name: 'Madagascar',
    alpha2: 'MG',
    alpha3: 'MDG',
    states: [],
    value: 'MDG',
    'country-code': '450',
    dial_code: 261,
    dialCode: '+261'
  },
  {
    name: 'Malawi',
    alpha2: 'MW',
    alpha3: 'MWI',
    states: [],
    value: 'MWI',
    'country-code': '454',
    dial_code: 265,
    dialCode: '+265'
  },
  {
    name: 'Malaysia',
    alpha2: 'MY',
    alpha3: 'MYS',
    states: [
      {
        name: 'Johor',
        value: 'Johor',
        disabled: false
      },
      {
        name: 'Kedah',
        value: 'Kedah',
        disabled: false
      },
      {
        name: 'Kelantan',
        value: 'Kelantan',
        disabled: false
      },
      {
        name: 'Melaka',
        value: 'Melaka',
        disabled: false
      },
      {
        name: 'Negeri Sembilan',
        value: 'Negeri Sembilan',
        disabled: false
      },
      {
        name: 'Pahang',
        value: 'Pahang',
        disabled: false
      },
      {
        name: 'Penang',
        value: 'Penang',
        disabled: false
      },
      {
        name: 'Perak',
        value: 'Perak',
        disabled: false
      },
      {
        name: 'Perlis',
        value: 'Perlis',
        disabled: false
      },
      {
        name: 'Sabah',
        value: 'Sabah',
        disabled: false
      },
      {
        name: 'Sarawak',
        value: 'Sarawak',
        disabled: false
      },
      {
        name: 'Selangor',
        value: 'Selangor',
        disabled: false
      },
      {
        name: 'Terengganu  ',
        value: 'Terengganu',
        disabled: false
      },
      {
        name: 'Wilayah Persekutuan  ',
        value: 'Wilayah Persekutuan',
        disabled: false
      }
    ],
    value: 'MYS',
    'country-code': '458',
    available: true,
    localCurrency: 'MYR',
    website: true,
    dial_code: 60,
    dialCode: '+60'
  },
  {
    name: 'Maldives',
    alpha2: 'MV',
    alpha3: 'MDV',
    states: [],
    value: 'MDV',
    'country-code': '462',
    dial_code: 960,
    dialCode: '+960'
  },
  {
    name: 'Mali',
    alpha2: 'ML',
    alpha3: 'MLI',
    states: [],
    value: 'MLI',
    'country-code': '466',
    dial_code: 223,
    dialCode: '+223'
  },
  {
    name: 'Malta',
    alpha2: 'MT',
    alpha3: 'MLT',
    states: [],
    value: 'MLT',
    'country-code': '470',
    dial_code: 356,
    dialCode: '+356'
  },
  {
    name: 'Marshall Islands',
    alpha2: 'MH',
    alpha3: 'MHL',
    states: [],
    value: 'MHL',
    'country-code': '584',
    dial_code: 692,
    dialCode: '+692'
  },
  {
    name: 'Martinique',
    alpha2: 'MQ',
    alpha3: 'MTQ',
    states: [],
    value: 'MTQ',
    'country-code': '474',
    dial_code: 596,
    dialCode: '+596'
  },
  {
    name: 'Mauritania',
    alpha2: 'MR',
    alpha3: 'MRT',
    states: [],
    value: 'MRT',
    'country-code': '478',
    dial_code: 222,
    dialCode: '+222'
  },
  {
    name: 'Mauritius',
    alpha2: 'MU',
    alpha3: 'MUS',
    states: [],
    value: 'MUS',
    'country-code': '480',
    dial_code: 230,
    dialCode: '+230'
  },
  {
    name: 'Mayotte',
    alpha2: 'YT',
    alpha3: 'MYT',
    states: [],
    value: 'MYT',
    'country-code': '175',
    dial_code: 262,
    dialCode: '+262'
  },
  {
    name: 'Mexico',
    alpha2: 'MX',
    alpha3: 'MEX',
    states: [],
    value: 'MEX',
    'country-code': '484',
    dial_code: 52,
    dialCode: '+52'
  },
  {
    name: 'Micronesia (Federated States of)',
    alpha2: 'FM',
    alpha3: 'FSM',
    states: [],
    value: 'FSM',
    'country-code': '583',
    dial_code: 691,
    dialCode: '+691'
  },
  {
    name: 'Moldova, Republic of',
    alpha2: 'MD',
    alpha3: 'MDA',
    states: [],
    value: 'MDA',
    'country-code': '498',
    dial_code: 373,
    dialCode: '+373'
  },
  {
    name: 'Monaco',
    alpha2: 'MC',
    alpha3: 'MCO',
    states: [],
    value: 'MCO',
    'country-code': '492',
    dial_code: 377,
    dialCode: '+377'
  },
  {
    name: 'Mongolia',
    alpha2: 'MN',
    alpha3: 'MNG',
    states: [],
    value: 'MNG',
    'country-code': '496',
    dial_code: 976,
    dialCode: '+976'
  },
  {
    name: 'Montenegro',
    alpha2: 'ME',
    alpha3: 'MNE',
    states: [],
    value: 'MNE',
    'country-code': '499',
    dial_code: 382,
    dialCode: '+382'
  },
  {
    name: 'Montserrat',
    alpha2: 'MS',
    alpha3: 'MSR',
    states: [],
    value: 'MSR',
    'country-code': '500',
    dial_code: 1664,
    dialCode: '+1664'
  },
  {
    name: 'Morocco',
    alpha2: 'MA',
    alpha3: 'MAR',
    states: [],
    value: 'MAR',
    'country-code': '504',
    dial_code: 212,
    dialCode: '+212'
  },
  {
    name: 'Mozambique',
    alpha2: 'MZ',
    alpha3: 'MOZ',
    states: [],
    value: 'MOZ',
    'country-code': '508',
    dial_code: 258,
    dialCode: '+258'
  },
  {
    name: 'Myanmar (Burma)',
    alpha2: 'MM',
    alpha3: 'MMR',
    states: [],
    value: 'MMR',
    'country-code': '104',
    dial_code: 95,
    dialCode: '+95'
  },
  {
    name: 'Namibia',
    alpha2: 'NA',
    alpha3: 'NAM',
    states: [],
    value: 'NAM',
    'country-code': '516',
    dial_code: 264,
    dialCode: '+264'
  },
  {
    name: 'Nauru',
    alpha2: 'NR',
    alpha3: 'NRU',
    states: [],
    value: 'NRU',
    'country-code': '520',
    dial_code: 674,
    dialCode: '+674'
  },
  {
    name: 'Nepal',
    alpha2: 'NP',
    alpha3: 'NPL',
    states: [],
    value: 'NPL',
    'country-code': '524',
    dial_code: 977,
    dialCode: '+977'
  },
  {
    name: 'Netherlands',
    alpha2: 'NL',
    alpha3: 'NLD',
    states: [],
    value: 'NLD',
    'country-code': '528',
    dial_code: 31,
    dialCode: '+31'
  },
  {
    name: 'New Caledonia',
    alpha2: 'NC',
    alpha3: 'NCL',
    states: [],
    value: 'NCL',
    'country-code': '540',
    dial_code: 687,
    dialCode: '+687'
  },
  {
    name: 'New Zealand',
    alpha2: 'NZ',
    alpha3: 'NZL',
    states: [],
    value: 'NZL',
    'country-code': '554',
    available: true,
    dial_code: 64,
    dialCode: '+64'
  },
  {
    name: 'Nicaragua',
    alpha2: 'NI',
    alpha3: 'NIC',
    states: [],
    value: 'NIC',
    'country-code': '558',
    dial_code: 505,
    dialCode: '+505'
  },
  {
    name: 'Niger',
    alpha2: 'NE',
    alpha3: 'NER',
    states: [],
    value: 'NER',
    'country-code': '562',
    dial_code: 227,
    dialCode: '+227'
  },
  {
    name: 'Nigeria',
    alpha2: 'NG',
    alpha3: 'NGA',
    states: [],
    value: 'NGA',
    'country-code': '566',
    dial_code: 234,
    dialCode: '+234'
  },
  {
    name: 'Niue',
    alpha2: 'NU',
    alpha3: 'NIU',
    states: [],
    value: 'NIU',
    'country-code': '570',
    dial_code: 683,
    dialCode: '+683'
  },
  {
    name: 'Norfolk Island',
    alpha2: 'NF',
    alpha3: 'NFK',
    states: [],
    value: 'NFK',
    'country-code': '574',
    dial_code: 672,
    dialCode: '+672'
  },
  {
    name: 'North Macedonia',
    alpha2: 'MK',
    alpha3: 'MKD',
    states: [],
    value: 'MKD',
    'country-code': '807',
    dial_code: 389,
    dialCode: '+389'
  },
  {
    name: 'Northern Mariana Islands',
    alpha2: 'MP',
    alpha3: 'MNP',
    states: [],
    value: 'MNP',
    'country-code': '580',
    dial_code: 1670,
    dialCode: '+1670'
  },
  {
    name: 'Norway',
    alpha2: 'NO',
    alpha3: 'NOR',
    states: [],
    value: 'NOR',
    'country-code': '578',
    dial_code: 47,
    dialCode: '+47'
  },
  {
    name: 'Oman',
    alpha2: 'OM',
    alpha3: 'OMN',
    states: [],
    value: 'OMN',
    'country-code': '512',
    dial_code: 968,
    dialCode: '+968'
  },
  {
    name: 'Pakistan',
    alpha2: 'PK',
    alpha3: 'PAK',
    states: [],
    value: 'PAK',
    'country-code': '586',
    dial_code: 92,
    dialCode: '+92'
  },
  {
    name: 'Palau',
    alpha2: 'PW',
    alpha3: 'PLW',
    states: [],
    value: 'PLW',
    'country-code': '585',
    dial_code: 680,
    dialCode: '+680'
  },
  {
    name: 'Panama',
    alpha2: 'PA',
    alpha3: 'PAN',
    states: [],
    value: 'PAN',
    'country-code': '591',
    dial_code: 507,
    dialCode: '+507'
  },
  {
    name: 'Papua New Guinea',
    alpha2: 'PG',
    alpha3: 'PNG',
    states: [],
    value: 'PNG',
    'country-code': '598',
    dial_code: 675,
    dialCode: '+675'
  },
  {
    name: 'Paraguay',
    alpha2: 'PY',
    alpha3: 'PRY',
    states: [],
    value: 'PRY',
    'country-code': '600',
    dial_code: 595,
    dialCode: '+595'
  },
  {
    name: 'Peru',
    alpha2: 'PE',
    alpha3: 'PER',
    states: [],
    value: 'PER',
    'country-code': '604',
    dial_code: 51,
    dialCode: '+51'
  },
  {
    name: 'Philippines',
    isGoogleAutocompleteEnabled: true,
    alpha2: 'PH',
    alpha3: 'PHL',
    states: [],
    value: 'PHL',
    'country-code': '608',
    available: true,
    dial_code: 63,
    dialCode: '+63'
  },
  {
    name: 'Pitcairn',
    alpha2: 'PN',
    alpha3: 'PCN',
    states: [],
    value: 'PCN',
    'country-code': '612',
    dial_code: 872,
    dialCode: '+872'
  },
  {
    name: 'Poland',
    alpha2: 'PL',
    alpha3: 'POL',
    states: [],
    value: 'POL',
    'country-code': '616',
    dial_code: 48,
    dialCode: '+48'
  },
  {
    name: 'Portugal',
    alpha2: 'PT',
    alpha3: 'PRT',
    states: [],
    value: 'PRT',
    'country-code': '620',
    dial_code: 351,
    dialCode: '+351'
  },
  {
    name: 'Puerto Rico',
    alpha2: 'PR',
    alpha3: 'PRI',
    states: [],
    value: 'PRI',
    'country-code': '630',
    dial_code: 1939,
    dialCode: '+1939'
  },
  {
    name: 'Qatar',
    alpha2: 'QA',
    alpha3: 'QAT',
    states: [],
    value: 'QAT',
    'country-code': '634',
    dial_code: 974,
    dialCode: '+974'
  },
  {
    name: 'Réunion',
    alpha2: 'RE',
    alpha3: 'REU',
    states: [],
    value: 'REU',
    'country-code': '638',
    dial_code: 262,
    dialCode: '+262'
  },
  {
    name: 'Romania',
    alpha2: 'RO',
    alpha3: 'ROU',
    states: [],
    value: 'ROU',
    'country-code': '642',
    dial_code: 40,
    dialCode: '+40'
  },
  {
    name: 'Russian Federation',
    alpha2: 'RU',
    alpha3: 'RUS',
    states: [],
    value: 'RUS',
    'country-code': '643',
    dial_code: 7,
    dialCode: '+7'
  },
  {
    name: 'Rwanda',
    alpha2: 'RW',
    alpha3: 'RWA',
    states: [],
    value: 'RWA',
    'country-code': '646',
    dial_code: 250,
    dialCode: '+250'
  },
  {
    name: 'Saint Barthélemy',
    alpha2: 'BL',
    alpha3: 'BLM',
    states: [],
    value: 'BLM',
    'country-code': '652',
    dial_code: 590,
    dialCode: '+590'
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha2: 'SH',
    alpha3: 'SHN',
    states: [],
    value: 'SHN',
    'country-code': '654',
    dial_code: 290,
    dialCode: '+290'
  },
  {
    name: 'Saint Kitts and Nevis',
    alpha2: 'KN',
    alpha3: 'KNA',
    states: [],
    value: 'KNA',
    'country-code': '659',
    dial_code: 1869,
    dialCode: '+1869'
  },
  {
    name: 'Saint Lucia',
    alpha2: 'LC',
    alpha3: 'LCA',
    states: [],
    value: 'LCA',
    'country-code': '662',
    dial_code: 1758,
    dialCode: '+1758'
  },
  {
    name: 'Saint Martin (French part)',
    alpha2: 'MF',
    alpha3: 'MAF',
    states: [],
    value: 'MAF',
    'country-code': '663',
    dial_code: 590,
    dialCode: '+590'
  },
  {
    name: 'Saint Pierre and Miquelon',
    alpha2: 'PM',
    alpha3: 'SPM',
    states: [],
    value: 'SPM',
    'country-code': '666',
    dial_code: 508,
    dialCode: '+508'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    alpha2: 'VC',
    alpha3: 'VCT',
    states: [],
    value: 'VCT',
    'country-code': '670',
    dial_code: 1784,
    dialCode: '+1784'
  },
  {
    name: 'Samoa',
    alpha2: 'WS',
    alpha3: 'WSM',
    states: [],
    value: 'WSM',
    'country-code': '882',
    dial_code: 685,
    dialCode: '+685'
  },
  {
    name: 'San Marino',
    alpha2: 'SM',
    alpha3: 'SMR',
    states: [],
    value: 'SMR',
    'country-code': '674',
    dial_code: 378,
    dialCode: '+378'
  },
  {
    name: 'Sao Tome and Principe',
    alpha2: 'ST',
    alpha3: 'STP',
    states: [],
    value: 'STP',
    'country-code': '678',
    dial_code: 239,
    dialCode: '+239'
  },
  {
    name: 'Saudi Arabia',
    alpha2: 'SA',
    alpha3: 'SAU',
    states: [],
    value: 'SAU',
    'country-code': '682',
    available: true,
    dial_code: 966,
    dialCode: '+966'
  },
  {
    name: 'Senegal',
    alpha2: 'SN',
    alpha3: 'SEN',
    states: [],
    value: 'SEN',
    'country-code': '686',
    dial_code: 221,
    dialCode: '+221'
  },
  {
    name: 'Serbia',
    alpha2: 'RS',
    alpha3: 'SRB',
    states: [],
    value: 'SRB',
    'country-code': '688',
    dial_code: 381,
    dialCode: '+381'
  },
  {
    name: 'Seychelles',
    alpha2: 'SC',
    alpha3: 'SYC',
    states: [],
    value: 'SYC',
    'country-code': '690',
    dial_code: 248,
    dialCode: '+248'
  },
  {
    name: 'Sierra Leone',
    alpha2: 'SL',
    alpha3: 'SLE',
    states: [],
    value: 'SLE',
    'country-code': '694',
    dial_code: 232,
    dialCode: '+232'
  },
  {
    name: 'Singapore',
    alpha2: 'SG',
    alpha3: 'SGP',
    states: [],
    value: 'SGP',
    'country-code': '702',
    available: true,
    localCurrency: 'SGD',
    website: true,
    dial_code: 65,
    dialCode: '+65'
  },
  {
    name: 'Sint Maarten (Dutch part)',
    alpha2: 'SX',
    alpha3: 'SXM',
    states: [],
    value: 'SXM',
    'country-code': '534',
    dial_code: 721,
    dialCode: '+721'
  },
  {
    name: 'Slovakia',
    alpha2: 'SK',
    alpha3: 'SVK',
    states: [],
    value: 'SVK',
    'country-code': '703',
    dial_code: 421,
    dialCode: '+421'
  },
  {
    name: 'Slovenia',
    alpha2: 'SI',
    alpha3: 'SVN',
    states: [],
    value: 'SVN',
    'country-code': '705',
    dial_code: 386,
    dialCode: '+386'
  },
  {
    name: 'Solomon Islands',
    alpha2: 'SB',
    alpha3: 'SLB',
    states: [],
    value: 'SLB',
    'country-code': '090',
    dial_code: 677,
    dialCode: '+677'
  },
  {
    name: 'Somalia',
    alpha2: 'SO',
    alpha3: 'SOM',
    states: [],
    value: 'SOM',
    'country-code': '706',
    dial_code: 252,
    dialCode: '+252'
  },
  {
    name: 'South Africa',
    alpha2: 'ZA',
    alpha3: 'ZAF',
    states: [],
    value: 'ZAF',
    'country-code': '710',
    dial_code: 27,
    dialCode: '+27'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    alpha2: 'GS',
    alpha3: 'SGS',
    states: [],
    value: 'SGS',
    'country-code': '239',
    dial_code: 500,
    dialCode: '+500'
  },
  {
    name: 'Spain',
    alpha2: 'ES',
    alpha3: 'ESP',
    states: [],
    value: 'ESP',
    'country-code': '724',
    dial_code: 34,
    dialCode: '+34'
  },
  {
    name: 'Sri Lanka',
    alpha2: 'LK',
    alpha3: 'LKA',
    states: [],
    value: 'LKA',
    'country-code': '144',
    dial_code: 94,
    dialCode: '+94'
  },
  {
    name: 'Sudan',
    alpha2: 'SD',
    alpha3: 'SDN',
    states: [],
    value: 'SDN',
    'country-code': '729',
    dial_code: 249,
    dialCode: '+249'
  },
  {
    name: 'Suriname',
    alpha2: 'SR',
    alpha3: 'SUR',
    states: [],
    value: 'SUR',
    'country-code': '740',
    dial_code: 597,
    dialCode: '+597'
  },
  {
    name: 'Svalbard and Jan Mayen',
    alpha2: 'SJ',
    alpha3: 'SJM',
    states: [],
    value: 'SJM',
    'country-code': '744',
    dial_code: 47,
    dialCode: '+47'
  },
  {
    name: 'Sweden',
    alpha2: 'SE',
    alpha3: 'SWE',
    states: [],
    value: 'SWE',
    'country-code': '752',
    dial_code: 46,
    dialCode: '+46'
  },
  {
    name: 'Switzerland',
    alpha2: 'CH',
    alpha3: 'CHE',
    states: [],
    value: 'CHE',
    'country-code': '756',
    dial_code: 41,
    dialCode: '+41'
  },
  {
    name: 'Syrian Arab Republic',
    alpha2: 'SY',
    alpha3: 'SYR',
    states: [],
    value: 'SYR',
    'country-code': '760',
    dial_code: 963,
    dialCode: '+963'
  },
  {
    name: 'Taiwan, Republic Of China',
    alpha2: 'TW',
    alpha3: 'TWN',
    states: [],
    value: 'TWN',
    'country-code': '158',
    available: true,
    dial_code: 886,
    dialCode: '+886'
  },
  {
    name: 'Tajikistan',
    alpha2: 'TJ',
    alpha3: 'TJK',
    states: [],
    value: 'TJK',
    'country-code': '762',
    dial_code: 992,
    dialCode: '+992'
  },
  {
    name: 'Tanzania, United Republic of',
    alpha2: 'TZ',
    alpha3: 'TZA',
    states: [],
    value: 'TZA',
    'country-code': '834',
    dial_code: 255,
    dialCode: '+255'
  },
  {
    name: 'Thailand',
    alpha2: 'TH',
    alpha3: 'THA',
    states: [],
    value: 'THA',
    'country-code': '764',
    dial_code: 66,
    dialCode: '+66'
  },
  {
    name: 'Timor-Leste',
    alpha2: 'TL',
    alpha3: 'TLS',
    states: [],
    value: 'TLS',
    'country-code': '626',
    dial_code: 670,
    dialCode: '+670'
  },
  {
    name: 'Togo',
    alpha2: 'TG',
    alpha3: 'TGO',
    states: [],
    value: 'TGO',
    'country-code': '768',
    dial_code: 228,
    dialCode: '+228'
  },
  {
    name: 'Tokelau',
    alpha2: 'TK',
    alpha3: 'TKL',
    states: [],
    value: 'TKL',
    'country-code': '772',
    dial_code: 690,
    dialCode: '+690'
  },
  {
    name: 'Tonga',
    alpha2: 'TO',
    alpha3: 'TON',
    states: [],
    value: 'TON',
    'country-code': '776',
    dial_code: 676,
    dialCode: '+676'
  },
  {
    name: 'Trinidad and Tobago',
    alpha2: 'TT',
    alpha3: 'TTO',
    states: [],
    value: 'TTO',
    'country-code': '780',
    dial_code: 1868,
    dialCode: '+1868'
  },
  {
    name: 'Tunisia',
    alpha2: 'TN',
    alpha3: 'TUN',
    states: [],
    value: 'TUN',
    'country-code': '788',
    dial_code: 216,
    dialCode: '+216'
  },
  {
    name: 'Turkey',
    alpha2: 'TR',
    alpha3: 'TUR',
    states: [],
    value: 'TUR',
    'country-code': '792',
    dial_code: 90,
    dialCode: '+90'
  },
  {
    name: 'Turkmenistan',
    alpha2: 'TM',
    alpha3: 'TKM',
    states: [],
    value: 'TKM',
    'country-code': '795',
    dial_code: 993,
    dialCode: '+993'
  },
  {
    name: 'Turks and Caicos Islands',
    alpha2: 'TC',
    alpha3: 'TCA',
    states: [],
    value: 'TCA',
    'country-code': '796',
    dial_code: 1649,
    dialCode: '+1649'
  },
  {
    name: 'Tuvalu',
    alpha2: 'TV',
    alpha3: 'TUV',
    states: [],
    value: 'TUV',
    'country-code': '798',
    dial_code: 688,
    dialCode: '+688'
  },
  {
    name: 'Uganda',
    alpha2: 'UG',
    alpha3: 'UGA',
    states: [],
    value: 'UGA',
    'country-code': '800',
    dial_code: 256,
    dialCode: '+256'
  },
  {
    name: 'Ukraine',
    alpha2: 'UA',
    alpha3: 'UKR',
    states: [],
    value: 'UKR',
    'country-code': '804',
    dial_code: 380,
    dialCode: '+380'
  },
  {
    name: 'United Arab Emirates',
    alpha2: 'AE',
    alpha3: 'ARE',
    states: [],
    value: 'ARE',
    'country-code': '784',
    available: true,
    dial_code: 971,
    dialCode: '+971'
  },
  {
    name: 'United Kingdom',
    alpha2: 'GB',
    alpha3: 'GBR',
    states: [],
    value: 'GBR',
    'country-code': '826',
    dial_code: 44,
    dialCode: '+44'
  },
  {
    name: 'United States',
    alpha2: 'US',
    alpha3: 'USA',
    isGoogleAutocompleteEnabled: true,
    states: [
      {
        id: 1,
        name: 'Alabama',
        value: 'Alabama',
        disabled: false
      },
      {
        id: 2,
        name: 'Alaska',
        value: 'Alaska',
        disabled: false
      },
      {
        id: 3,
        name: 'American Samoa',
        value: 'American Samoa',
        disabled: false
      },
      {
        id: 4,
        name: 'Arizona',
        value: 'Arizona',
        disabled: false
      },
      {
        id: 5,
        name: 'Arkansas',
        value: 'Arkansas',
        disabled: false
      },
      {
        id: 12,
        name: 'California',
        value: 'California',
        disabled: false
      },
      {
        id: 13,
        name: 'Colorado',
        value: 'Colorado',
        disabled: false
      },
      {
        id: 14,
        name: 'Connecticut',
        value: 'Connecticut',
        disabled: false
      },
      {
        id: 15,
        name: 'Delaware',
        value: 'Delaware',
        disabled: false
      },
      {
        id: 16,
        name: 'District of Columbia',
        value: 'District of Columbia',
        disabled: false
      },
      {
        id: 17,
        name: 'Federated States of Micronesia',
        value: 'Federated States of Micronesia',
        disabled: false
      },
      {
        id: 18,
        name: 'Florida',
        value: 'Florida',
        disabled: false
      },
      {
        id: 19,
        name: 'Georgia',
        value: 'Georgia',
        disabled: false
      },
      {
        id: 20,
        name: 'Guam',
        value: 'Guam',
        disabled: false
      },
      {
        id: 21,
        name: 'Hawaii',
        value: 'Hawaii',
        disabled: false
      },
      {
        id: 22,
        name: 'Idaho',
        value: 'Idaho',
        disabled: false
      },
      {
        id: 23,
        name: 'Illinois',
        value: 'Illinois',
        disabled: false
      },
      {
        id: 24,
        name: 'Indiana',
        value: 'Indiana',
        disabled: false
      },
      {
        id: 25,
        name: 'Iowa',
        value: 'Iowa',
        disabled: false
      },
      {
        id: 26,
        name: 'Kansas',
        value: 'Kansas',
        disabled: false
      },
      {
        id: 27,
        name: 'Kentucky',
        value: 'Kentucky',
        disabled: false
      },
      {
        id: 28,
        name: 'Louisiana',
        value: 'Louisiana',
        disabled: false
      },
      {
        id: 29,
        name: 'Maine',
        value: 'Maine',
        disabled: false
      },
      {
        id: 30,
        name: 'Marshall Islands',
        value: 'Marshall Islands',
        disabled: false
      },
      {
        id: 31,
        name: 'Maryland',
        value: 'Maryland',
        disabled: false
      },
      {
        id: 32,
        name: 'Massachusetts',
        value: 'Massachusetts',
        disabled: false
      },
      {
        id: 33,
        name: 'Michigan',
        value: 'Michigan',
        disabled: false
      },
      {
        id: 34,
        name: 'Minnesota',
        value: 'Minnesota',
        disabled: false
      },
      {
        id: 35,
        name: 'Mississippi',
        value: 'Mississippi',
        disabled: false
      },
      {
        id: 36,
        name: 'Missouri',
        value: 'Missouri',
        disabled: false
      },
      {
        id: 37,
        name: 'Montana',
        value: 'Montana',
        disabled: false
      },
      {
        id: 38,
        name: 'Nebraska',
        value: 'Nebraska',
        disabled: false
      },
      {
        id: 39,
        name: 'Nevada',
        value: 'Nevada',
        disabled: false
      },
      {
        id: 40,
        name: 'New Hampshire',
        value: 'New Hampshire',
        disabled: false
      },
      {
        id: 41,
        name: 'New Jersey',
        value: 'New Jersey',
        disabled: false
      },
      {
        id: 42,
        name: 'New Mexico',
        value: 'New Mexico',
        disabled: false
      },
      {
        id: 43,
        name: 'New York',
        value: 'New York',
        disabled: false
      },
      {
        id: 44,
        name: 'North Carolina',
        value: 'North Carolina',
        disabled: false
      },
      {
        id: 45,
        name: 'North Dakota',
        value: 'North Dakota',
        disabled: false
      },
      {
        id: 46,
        name: 'Northern Mariana Islands',
        value: 'Northern Mariana Islands',
        disabled: false
      },
      {
        id: 47,
        name: 'Ohio',
        value: 'Ohio',
        disabled: false
      },
      {
        id: 48,
        name: 'Oklahoma',
        value: 'Oklahoma',
        disabled: false
      },
      {
        id: 49,
        name: 'Oregon',
        value: 'Oregon',
        disabled: false
      },
      {
        id: 51,
        name: 'Pennsylvania',
        value: 'Pennsylvania',
        disabled: false
      },
      {
        id: 52,
        name: 'Puerto Rico',
        value: 'Puerto Rico',
        disabled: false
      },
      {
        id: 53,
        name: 'Rhode Island',
        value: 'Rhode Island',
        disabled: false
      },
      {
        id: 54,
        name: 'South Carolina',
        value: 'South Carolina',
        disabled: false
      },
      {
        id: 55,
        name: 'South Dakota',
        value: 'South Dakota',
        disabled: false
      },
      {
        id: 56,
        name: 'Tennessee',
        value: 'Tennessee',
        disabled: false
      },
      {
        id: 57,
        name: 'Texas',
        value: 'Texas',
        disabled: false
      },
      {
        id: 58,
        name: 'Utah',
        value: 'Utah',
        disabled: false
      },
      {
        id: 59,
        name: 'Vermont',
        value: 'Vermont',
        disabled: false
      },
      {
        id: 60,
        name: 'Virgin Islands',
        value: 'Virgin Islands',
        disabled: false
      },
      {
        id: 61,
        name: 'Virginia',
        value: 'Virginia',
        disabled: false
      },
      {
        id: 62,
        name: 'Washington',
        value: 'Washington',
        disabled: false
      },
      {
        id: 63,
        name: 'West Virginia',
        value: 'West Virginia',
        disabled: false
      },
      {
        id: 64,
        name: 'Wisconsin',
        value: 'Wisconsin',
        disabled: false
      },
      {
        id: 65,
        name: 'Wyoming',
        value: 'Wyoming',
        disabled: false
      }
    ],
    value: 'USA',
    'country-code': '840',
    available: true,
    localCurrency: 'USD',
    website: true,
    dial_code: 1,
    dialCode: '+1'
  },
  {
    name: 'Uruguay',
    alpha2: 'UY',
    alpha3: 'URY',
    states: [],
    value: 'URY',
    'country-code': '858',
    dial_code: 598,
    dialCode: '+598'
  },
  {
    name: 'Uzbekistan',
    alpha2: 'UZ',
    alpha3: 'UZB',
    states: [],
    value: 'UZB',
    'country-code': '860',
    dial_code: 998,
    dialCode: '+998'
  },
  {
    name: 'Vanuatu',
    alpha2: 'VU',
    alpha3: 'VUT',
    states: [],
    value: 'VUT',
    'country-code': '548',
    dial_code: 678,
    dialCode: '+678'
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    alpha2: 'VE',
    alpha3: 'VEN',
    states: [],
    value: 'VEN',
    'country-code': '862',
    dial_code: 58,
    dialCode: '+58'
  },
  {
    name: 'Vietnam',
    alpha2: 'VN',
    alpha3: 'VNM',
    states: [],
    value: 'VNM',
    'country-code': '704',
    available: true,
    dial_code: 84,
    dialCode: '+84'
  },
  {
    name: 'Virgin Islands (British)',
    alpha2: 'VG',
    alpha3: 'VGB',
    states: [],
    value: 'VGB',
    'country-code': '092',
    dial_code: 1284,
    dialCode: '+1284'
  },
  {
    name: 'Virgin Islands (U.S.)',
    alpha2: 'VI',
    alpha3: 'VIR',
    states: [],
    value: 'VIR',
    'country-code': '850',
    dial_code: 1340,
    dialCode: '+1340'
  },
  {
    name: 'Wallis and Futuna',
    alpha2: 'WF',
    alpha3: 'WLF',
    states: [],
    value: 'WLF',
    'country-code': '876',
    dial_code: 681,
    dialCode: '+681'
  },
  {
    name: 'Yemen',
    alpha2: 'YE',
    alpha3: 'YEM',
    states: [],
    value: 'YEM',
    'country-code': '887',
    dial_code: 967,
    dialCode: '+967'
  },
  {
    name: 'Zambia',
    alpha2: 'ZM',
    alpha3: 'ZMB',
    states: [],
    value: 'ZMB',
    'country-code': '894',
    dial_code: 260,
    dialCode: '+260'
  },
  {
    name: 'Zimbabwe',
    alpha2: 'ZW',
    alpha3: 'ZWE',
    states: [],
    value: 'ZWE',
    'country-code': '716',
    dial_code: 263,
    dialCode: '+263'
  }
];

export function getCountryByCode (code) {
  const uppderCaseCode = code?.toUpperCase();
  return countries.find(({ alpha2, alpha3 }) => alpha2 === uppderCaseCode || alpha3 === uppderCaseCode);
}
