import config from 'config'

export const countries = [
  { name: 'Afghanistan', value: 'AFG', 'country-code': '004' },
  { name: 'Albania', value: 'ALB', 'country-code': '008' },
  { name: 'Algeria', value: 'DZA', 'country-code': '012' },
  { name: 'American Samoa', value: 'ASM', 'country-code': '016' },
  { name: 'Andorra', value: 'AND', 'country-code': '020' },
  { name: 'Angola', value: 'AGO', 'country-code': '024' },
  { name: 'Anguilla', value: 'AIA', 'country-code': '660' },
  { name: 'Antarctica', value: 'ATA', 'country-code': '010' },
  { name: 'Antigua and Barbuda', value: 'ATG', 'country-code': '028' },
  { name: 'Argentina', value: 'ARG', 'country-code': '032' },
  { name: 'Armenia', value: 'ARM', 'country-code': '051' },
  { name: 'Aruba', value: 'ABW', 'country-code': '533' },
  { name: 'Australia', value: 'AUS', 'country-code': '036', available: true },
  { name: 'Austria', value: 'AUT', 'country-code': '040' },
  { name: 'Azerbaijan', value: 'AZE', 'country-code': '031' },
  { name: 'Bahamas', value: 'BHS', 'country-code': '044' },
  { name: 'Bahrain', value: 'BHR', 'country-code': '048' },
  { name: 'Bangladesh', value: 'BGD', 'country-code': '050' },
  { name: 'Barbados', value: 'BRB', 'country-code': '052' },
  { name: 'Belarus', value: 'BLR', 'country-code': '112' },
  { name: 'Belgium', value: 'BEL', 'country-code': '056' },
  { name: 'Belize', value: 'BLZ', 'country-code': '084' },
  { name: 'Benin', value: 'BEN', 'country-code': '204' },
  { name: 'Bermuda', value: 'BMU', 'country-code': '060' },
  { name: 'Bhutan', value: 'BTN', 'country-code': '064' },
  {
    name: 'Bolivia (Plurinational State of)',
    value: 'BOL',
    'country-code': '068'
  },
  { name: 'Bosnia and Herzegovina', value: 'BIH', 'country-code': '070' },
  { name: 'Botswana', value: 'BWA', 'country-code': '072' },
  { name: 'Brazil', value: 'BRA', 'country-code': '076' },
  {
    name: 'British Indian Ocean Territory',
    value: 'IOT',
    'country-code': '086'
  },
  { name: 'Brunei', value: 'BRN', 'country-code': '096', available: true },
  { name: 'Bulgaria', value: 'BGR', 'country-code': '100' },
  { name: 'Burkina Faso', value: 'BFA', 'country-code': '854' },
  { name: 'Burundi', value: 'BDI', 'country-code': '108' },
  { name: 'Cabo Verde', value: 'CPV', 'country-code': '132' },
  { name: 'Cambodia', value: 'KHM', 'country-code': '116', available: true },
  { name: 'Cameroon', value: 'CMR', 'country-code': '120' },
  { name: 'Canada',
    value: 'CAN',
    'country-code': '124',
    available: true,
    website: true
  },
  { name: 'Cayman Islands', value: 'CYM', 'country-code': '136' },
  { name: 'Central African Republic', value: 'CAF', 'country-code': '140' },
  { name: 'Chad', value: 'TCD', 'country-code': '148' },
  { name: 'Chile', value: 'CHL', 'country-code': '152' },
  { name: 'China', value: 'CHN', 'country-code': '156', available: true },
  { name: 'Christmas Island', value: 'CXR', 'country-code': '162' },
  { name: 'Cocos (Keeling) Islands', value: 'CCK', 'country-code': '166' },
  { name: 'Colombia', value: 'COL', 'country-code': '170' },
  { name: 'Comoros', value: 'COM', 'country-code': '174' },
  { name: 'Congo', value: 'COG', 'country-code': '178' },
  {
    name: 'Congo, Democratic Republic of the',
    value: 'COD',
    'country-code': '180'
  },
  { name: 'Cook Islands', value: 'COK', 'country-code': '184' },
  { name: 'Costa Rica', value: 'CRI', 'country-code': '188' },
  { name: "Côte d'Ivoire", value: 'CIV', 'country-code': '384' },
  { name: 'Croatia', value: 'HRV', 'country-code': '191' },
  { name: 'Cuba', value: 'CUB', 'country-code': '192' },
  { name: 'Cyprus', value: 'CYP', 'country-code': '196' },
  { name: 'Czechia', value: 'CZE', 'country-code': '203' },
  { name: 'Denmark', value: 'DNK', 'country-code': '208' },
  { name: 'Djibouti', value: 'DJI', 'country-code': '262' },
  { name: 'Dominica', value: 'DMA', 'country-code': '212' },
  { name: 'Dominican Republic', value: 'DOM', 'country-code': '214' },
  { name: 'Ecuador', value: 'ECU', 'country-code': '218' },
  { name: 'Egypt', value: 'EGY', 'country-code': '818' },
  { name: 'El Salvador', value: 'SLV', 'country-code': '222' },
  { name: 'Equatorial Guinea', value: 'GNQ', 'country-code': '226' },
  { name: 'Eritrea', value: 'ERI', 'country-code': '232' },
  { name: 'Estonia', value: 'EST', 'country-code': '233' },
  { name: 'Ethiopia', value: 'ETH', 'country-code': '231' },
  {
    name: 'Falkland Islands (Malvinas)',
    value: 'FLK',
    'country-code': '238'
  },
  { name: 'Faroe Islands', value: 'FRO', 'country-code': '234' },
  { name: 'Fiji', value: 'FJI', 'country-code': '242' },
  { name: 'Finland', value: 'FIN', 'country-code': '246' },
  { name: 'France', value: 'FRA', 'country-code': '250' },
  { name: 'French Guiana', value: 'GUF', 'country-code': '254' },
  { name: 'French Polynesia', value: 'PYF', 'country-code': '258' },
  { name: 'Gabon', value: 'GAB', 'country-code': '266' },
  { name: 'Gambia', value: 'GMB', 'country-code': '270' },
  { name: 'Georgia', value: 'GEO', 'country-code': '268' },
  { name: 'Germany', value: 'DEU', 'country-code': '276' },
  { name: 'Ghana', value: 'GHA', 'country-code': '288' },
  { name: 'Gibraltar', value: 'GIB', 'country-code': '292' },
  { name: 'Greece', value: 'GRC', 'country-code': '300' },
  { name: 'Greenland', value: 'GRL', 'country-code': '304' },
  { name: 'Grenada', value: 'GRD', 'country-code': '308' },
  { name: 'Guadeloupe', value: 'GLP', 'country-code': '312' },
  { name: 'Guam', value: 'GUM', 'country-code': '316' },
  { name: 'Guatemala', value: 'GTM', 'country-code': '320' },
  { name: 'Guernsey', value: 'GGY', 'country-code': '831' },
  { name: 'Guinea', value: 'GIN', 'country-code': '324' },
  { name: 'Guinea-Bissau', value: 'GNB', 'country-code': '624' },
  { name: 'Guyana', value: 'GUY', 'country-code': '328' },
  { name: 'Haiti', value: 'HTI', 'country-code': '332' },
  { name: 'Holy See', value: 'VAT', 'country-code': '336' },
  { name: 'Honduras', value: 'HND', 'country-code': '340' },
  {
    name: 'Hong Kong SAR China',
    value: 'HKG',
    'country-code': '344',
    available: true,
    localCurrency: 'HK$',
    website: true
  },
  { name: 'Hungary', value: 'HUN', 'country-code': '348' },
  { name: 'Iceland', value: 'ISL', 'country-code': '352' },
  { name: 'India', value: 'IND', 'country-code': '356' },
  {
    name: 'Indonesia',
    value: 'IDN',
    'country-code': '360',
    available: true,
    localCurrency: 'IDR',
    website: true
  },
  {
    name: 'Iran (Islamic Republic of)',
    value: 'IRN',
    'country-code': '364'
  },
  { name: 'Iraq', value: 'IRQ', 'country-code': '368' },
  { name: 'Ireland', value: 'IRL', 'country-code': '372' },
  { name: 'Israel', value: 'ISR', 'country-code': '376' },
  { name: 'Italy', value: 'ITA', 'country-code': '380' },
  { name: 'Jamaica', value: 'JAM', 'country-code': '388' },
  { name: 'Japan',
    value: 'JPN',
    'country-code': '392',
    available: true,
    localCurrency: 'USD',
    website: false
  },
  { name: 'Jordan', value: 'JOR', 'country-code': '400' },
  { name: 'Kazakhstan', value: 'KAZ', 'country-code': '398' },
  { name: 'Kenya', value: 'KEN', 'country-code': '404' },
  { name: 'Kiribati', value: 'KIR', 'country-code': '296' },
  {
    name: 'North Korea',
    value: 'PRK',
    'country-code': '408'
  },
  { name: 'South Korea', value: 'KOR', 'country-code': '410', available: true },
  { name: 'Kuwait', value: 'KWT', 'country-code': '414' },
  { name: 'Kyrgyzstan', value: 'KGZ', 'country-code': '417' },
  {
    name: "Lao People's Democratic Republic",
    value: 'LAO',
    'country-code': '418'
  },
  { name: 'Latvia', value: 'LVA', 'country-code': '428' },
  { name: 'Lebanon', value: 'LBN', 'country-code': '422' },
  { name: 'Lesotho', value: 'LSO', 'country-code': '426' },
  { name: 'Liberia', value: 'LBR', 'country-code': '430' },
  { name: 'Libya', value: 'LBY', 'country-code': '434' },
  { name: 'Liechtenstein', value: 'LIE', 'country-code': '438' },
  { name: 'Lithuania', value: 'LTU', 'country-code': '440' },
  { name: 'Luxembourg', value: 'LUX', 'country-code': '442' },
  {
    name: 'Macao SAR China',
    value: 'MAC',
    'country-code': '446',
    available: true,
    localCurrency: 'HKD',
    website: true
  },
  { name: 'Madagascar', value: 'MDG', 'country-code': '450' },
  { name: 'Malawi', value: 'MWI', 'country-code': '454' },
  {
    name: 'Malaysia',
    value: 'MYS',
    'country-code': '458',
    available: true,
    localCurrency: 'MYR',
    website: true
  },
  { name: 'Maldives', value: 'MDV', 'country-code': '462' },
  { name: 'Mali', value: 'MLI', 'country-code': '466' },
  { name: 'Malta', value: 'MLT', 'country-code': '470' },
  { name: 'Marshall Islands', value: 'MHL', 'country-code': '584' },
  { name: 'Martinique', value: 'MTQ', 'country-code': '474' },
  { name: 'Mauritania', value: 'MRT', 'country-code': '478' },
  { name: 'Mauritius', value: 'MUS', 'country-code': '480' },
  { name: 'Mayotte', value: 'MYT', 'country-code': '175' },
  { name: 'Mexico', value: 'MEX', 'country-code': '484' },
  {
    name: 'Micronesia (Federated States of)',
    value: 'FSM',
    'country-code': '583'
  },
  { name: 'Moldova, Republic of', value: 'MDA', 'country-code': '498' },
  { name: 'Monaco', value: 'MCO', 'country-code': '492' },
  { name: 'Mongolia', value: 'MNG', 'country-code': '496' },
  { name: 'Montenegro', value: 'MNE', 'country-code': '499' },
  { name: 'Montserrat', value: 'MSR', 'country-code': '500' },
  { name: 'Morocco', value: 'MAR', 'country-code': '504' },
  { name: 'Mozambique', value: 'MOZ', 'country-code': '508' },
  {
    name: 'Myanmar (Burma)',
    value: 'MMR',
    'country-code': '104'
  },
  { name: 'Namibia', value: 'NAM', 'country-code': '516' },
  { name: 'Nauru', value: 'NRU', 'country-code': '520' },
  { name: 'Nepal', value: 'NPL', 'country-code': '524' },
  { name: 'Netherlands', value: 'NLD', 'country-code': '528' },
  { name: 'New Caledonia', value: 'NCL', 'country-code': '540' },
  { name: 'New Zealand', value: 'NZL', 'country-code': '554', available: true },
  { name: 'Nicaragua', value: 'NIC', 'country-code': '558' },
  { name: 'Niger', value: 'NER', 'country-code': '562' },
  { name: 'Nigeria', value: 'NGA', 'country-code': '566' },
  { name: 'Niue', value: 'NIU', 'country-code': '570' },
  { name: 'Norfolk Island', value: 'NFK', 'country-code': '574' },
  { name: 'North Macedonia', value: 'MKD', 'country-code': '807' },
  { name: 'Northern Mariana Islands', value: 'MNP', 'country-code': '580' },
  { name: 'Norway', value: 'NOR', 'country-code': '578' },
  { name: 'Oman', value: 'OMN', 'country-code': '512' },
  { name: 'Pakistan', value: 'PAK', 'country-code': '586' },
  { name: 'Palau', value: 'PLW', 'country-code': '585' },
  { name: 'Panama', value: 'PAN', 'country-code': '591' },
  { name: 'Papua New Guinea', value: 'PNG', 'country-code': '598' },
  { name: 'Paraguay', value: 'PRY', 'country-code': '600' },
  { name: 'Peru', value: 'PER', 'country-code': '604' },
  { name: 'Philippines',
    value: 'PHL',
    'country-code': '608',
    available: true,
    website: true,
    localCurrency: 'PHP' },
  { name: 'Pitcairn', value: 'PCN', 'country-code': '612' },
  { name: 'Poland', value: 'POL', 'country-code': '616' },
  { name: 'Portugal', value: 'PRT', 'country-code': '620' },
  { name: 'Puerto Rico', value: 'PRI', 'country-code': '630' },
  { name: 'Qatar', value: 'QAT', 'country-code': '634' },
  { name: 'Réunion', value: 'REU', 'country-code': '638' },
  { name: 'Romania', value: 'ROU', 'country-code': '642' },
  { name: 'Russian Federation', value: 'RUS', 'country-code': '643' },
  { name: 'Rwanda', value: 'RWA', 'country-code': '646' },
  { name: 'Saint Barthélemy', value: 'BLM', 'country-code': '652' },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SHN',
    'country-code': '654'
  },
  { name: 'Saint Kitts and Nevis', value: 'KNA', 'country-code': '659' },
  { name: 'Saint Lucia', value: 'LCA', 'country-code': '662' },
  {
    name: 'Saint Martin (French part)',
    value: 'MAF',
    'country-code': '663'
  },
  {
    name: 'Saint Pierre and Miquelon',
    value: 'SPM',
    'country-code': '666'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    value: 'VCT',
    'country-code': '670'
  },
  { name: 'Samoa', value: 'WSM', 'country-code': '882' },
  { name: 'San Marino', value: 'SMR', 'country-code': '674' },
  { name: 'Sao Tome and Principe', value: 'STP', 'country-code': '678' },
  {
    name: 'Saudi Arabia',
    value: 'SAU',
    'country-code': '682',
    available: true
  },
  { name: 'Senegal', value: 'SEN', 'country-code': '686' },
  { name: 'Serbia', value: 'SRB', 'country-code': '688' },
  { name: 'Seychelles', value: 'SYC', 'country-code': '690' },
  { name: 'Sierra Leone', value: 'SLE', 'country-code': '694' },
  {
    name: 'Singapore',
    value: 'SGP',
    'country-code': '702',
    available: true,
    localCurrency: 'SGD',
    website: true
  },
  {
    name: 'Sint Maarten (Dutch part)',
    value: 'SXM',
    'country-code': '534'
  },
  { name: 'Slovakia', value: 'SVK', 'country-code': '703' },
  { name: 'Slovenia', value: 'SVN', 'country-code': '705' },
  { name: 'Solomon Islands', value: 'SLB', 'country-code': '090' },
  { name: 'Somalia', value: 'SOM', 'country-code': '706' },
  { name: 'South Africa', value: 'ZAF', 'country-code': '710' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    value: 'SGS',
    'country-code': '239'
  },
  { name: 'Spain', value: 'ESP', 'country-code': '724' },
  { name: 'Sri Lanka', value: 'LKA', 'country-code': '144' },
  { name: 'Sudan', value: 'SDN', 'country-code': '729' },
  { name: 'Suriname', value: 'SUR', 'country-code': '740' },
  { name: 'Svalbard and Jan Mayen', value: 'SJM', 'country-code': '744' },
  { name: 'Sweden', value: 'SWE', 'country-code': '752' },
  { name: 'Switzerland', value: 'CHE', 'country-code': '756' },
  { name: 'Syrian Arab Republic', value: 'SYR', 'country-code': '760' },
  {
    name: 'Taiwan, Republic Of China',
    value: 'TWN',
    'country-code': '158',
    available: true
  },
  { name: 'Tajikistan', value: 'TJK', 'country-code': '762' },
  {
    name: 'Tanzania, United Republic of',
    value: 'TZA',
    'country-code': '834'
  },
  { name: 'Thailand', value: 'THA', 'country-code': '764' },
  { name: 'Timor-Leste', value: 'TLS', 'country-code': '626' },
  { name: 'Togo', value: 'TGO', 'country-code': '768' },
  { name: 'Tokelau', value: 'TKL', 'country-code': '772' },
  { name: 'Tonga', value: 'TON', 'country-code': '776' },
  { name: 'Trinidad and Tobago', value: 'TTO', 'country-code': '780' },
  { name: 'Tunisia', value: 'TUN', 'country-code': '788' },
  { name: 'Turkey', value: 'TUR', 'country-code': '792' },
  { name: 'Turkmenistan', value: 'TKM', 'country-code': '795' },
  { name: 'Turks and Caicos Islands', value: 'TCA', 'country-code': '796' },
  { name: 'Tuvalu', value: 'TUV', 'country-code': '798' },
  { name: 'Uganda', value: 'UGA', 'country-code': '800' },
  { name: 'Ukraine', value: 'UKR', 'country-code': '804' },
  {
    name: 'United Arab Emirates',
    value: 'ARE',
    'country-code': '784',
    available: true
  },
  {
    name: 'United Kingdom',
    value: 'GBR',
    'country-code': '826'
  },
  {
    name: 'United States',
    value: 'USA',
    'country-code': '840',
    available: true,
    localCurrency: 'USD',
    website: true
  },
  { name: 'Uruguay', value: 'URY', 'country-code': '858' },
  { name: 'Uzbekistan', value: 'UZB', 'country-code': '860' },
  { name: 'Vanuatu', value: 'VUT', 'country-code': '548' },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    value: 'VEN',
    'country-code': '862'
  },
  { name: 'Vietnam', value: 'VNM', 'country-code': '704', available: true },
  { name: 'Virgin Islands (British)', value: 'VGB', 'country-code': '092' },
  { name: 'Virgin Islands (U.S.)', value: 'VIR', 'country-code': '850' },
  { name: 'Wallis and Futuna', value: 'WLF', 'country-code': '876' },
  { name: 'Yemen', value: 'YEM', 'country-code': '887' },
  { name: 'Zambia', value: 'ZMB', 'country-code': '894' },
  { name: 'Zimbabwe', value: 'ZWE', 'country-code': '716' }
];
export const availableCountries = [
  {
    name: 'Singapore',
    value: 'SGP',
    'country-code': '702',
    standardCountryCode: 'SG',
    available: true,
    localCurrency: 'SGD',
    website: true
  },
  {
    name: 'Malaysia',
    value: 'MYS',
    standardCountryCode: 'MY',
    'country-code': '458',
    available: true,
    localCurrency: 'MYR',
    website: true
  },
  {
    name: 'Hong Kong SAR China',
    value: 'HKG',
    standardCountryCode: 'HK',
    'country-code': '344',
    available: true,
    localCurrency: 'HKD',
    website: true
  },
  {
    name: 'Japan',
    value: 'JPN',
    standardCountryCode: 'Japan',
    'country-code': '392',
    available: true,
    localCurrency: 'USD',
    website: false
  },
  {
    name: 'Indonesia',
    value: 'IDN',
    standardCountryCode: 'ID',
    'country-code': '360',
    available: true,
    localCurrency: 'IDR',
    website: true
  },
  {
    name: 'Australia',
    value: 'AUS',
    standardCountryCode: 'AU',
    'country-code': '036',
    available: true
  },
  {
    name: 'Brunei',
    value: 'BRN',
    standardCountryCode: 'BN',
    'country-code': '096',
    available: true
  },
  {
    name: 'Cambodia',
    value: 'KHM',
    standardCountryCode: 'KH',
    'country-code': '116',
    available: true
  },
  {
    name: 'Canada',
    value: 'CAN',
    standardCountryCode: 'CA',
    'country-code': '124',
    available: true,
    website: true
  },
  {
    name: 'China',
    value: 'CHN',
    standardCountryCode: 'CN',
    'country-code': '156',
    available: true
  },
  {

    name: 'Macao SAR China',
    value: 'MAC',
    standardCountryCode: 'MO',
    'country-code': '446',
    available: true,
    localCurrency: 'HKD',
    website: true
  },
  {
    name: 'New Zealand',
    value: 'NZL',
    standardCountryCode: 'NZ',
    'country-code': '554',
    available: true
  },
  {
    name: 'Philippines',
    value: 'PHL',
    standardCountryCode: 'PH',
    'country-code': '608',
    available: true,
    website: true,
    localCurrency: 'PHP'
  },
  {
    name: 'Saudi Arabia',
    value: 'SAU',
    standardCountryCode: 'SA',
    'country-code': '682',
    available: true
  },
  {

    name: 'South Korea',
    value: 'KOR',
    standardCountryCode: 'KR',
    'country-code': '410',
    available: true
  },
  {
    name: 'Taiwan, Republic Of China',
    value: 'TWN',
    standardCountryCode: 'TW',
    'country-code': '158',
    available: true
  },
  {
    name: 'United Arab Emirates',
    value: 'ARE',
    standardCountryCode: 'AE',
    'country-code': '784',
    available: true
  },
  {
    name: 'United States',
    value: 'USA',
    standardCountryCode: 'US',
    'country-code': '840',
    available: true,
    localCurrency: 'USD',
    website: true
  },
  {

    name: 'Vietnam',
    value: 'VNM',
    standardCountryCode: 'VN',
    'country-code': '704',
    available: true
  }
];
export const states = [
  {
    country: 'HKG',
    states: [
      { name: 'Hong Kong Island', value: 'disabled' },
      { name: 'Central and Western', value: 'Central and Western', 'country-code': 'HKG' },
      { name: 'Eastern', value: 'Eastern', 'country-code': 'HKG' },
      { name: 'Southern', value: 'Southern', 'country-code': 'HKG' },
      { name: 'Wan Chai', value: 'Wan Chai', 'country-code': 'HKG' },
      { value: 'divider' },
      { name: 'Kowloon', value: 'disabled' },
      { name: 'Kowloon City', value: 'Kowloon City', 'country-code': 'HKG' },
      { name: 'Kwun Tong', value: 'Kwun Tong', 'country-code': 'HKG' },
      { name: 'Sham Shui Po', value: 'Sham Shui Po', 'country-code': 'HKG' },
      { name: 'Wong Tai Sin', value: 'Wong Tai Sin', 'country-code': 'HKG' },
      { name: 'Yau Tsim Mong', value: 'Yau Tsim Mong', 'country-code': 'HKG' },
      { value: 'divider' },
      { name: 'New Territories', value: 'disabled' },
      { name: 'Islands', value: 'Islands', 'country-code': 'HKG' },
      { name: 'Kwai Tsing', value: 'Kwai Tsing', 'country-code': 'HKG' },
      { name: 'North', value: 'North', 'country-code': 'HKG' },
      { name: 'Sai Kung', value: 'Sai Kung', 'country-code': 'HKG' },
      { name: 'Sha Tin', value: 'Sha Tin', 'country-code': 'HKG' },
      { name: 'Tai Po', value: 'Tai Po', 'country-code': 'HKG' },
      { name: 'Tsuen Wan', value: 'Tsuen Wan', 'country-code': 'HKG' },
      { name: 'Tuen Mun', value: 'Tuen Mun', 'country-code': 'HKG' },
      { name: 'Yuen Long', value: 'Yuen Long', 'country-code': 'HKG' }
    ]
  },
  {
    country: 'MAC',
    states: [
      { name: 'Macao', value: 'Macao', 'country-code': 'MAC' },
      { name: 'Taipa', value: 'Taipa', 'country-code': 'MAC' },
      { name: 'Coloane', value: 'Coloane', 'country-code': 'MAC' },
      { name: 'Others', value: 'Others', 'country-code': 'MAC' }
    ]
  }
]
