import { preConfigureProduct } from '@vue-storefront/core/modules/catalog/helpers/search'
import {
  filterOutUnavailableVariants
} from '@vue-storefront/core/modules/catalog/helpers'
import { Logger } from '@vue-storefront/core/lib/logger';
import * as types from './mutation-types'
import config from 'config'

const actions = {
  /**
   * Reset current configuration and selected variatnts
   */
  reset (context) {
    Logger.warn('`product/reset` deprecated, will be not used from 1.12')()
    const originalProduct = Object.assign({}, context.getters.getOriginalProduct)
    context.commit(types.PRODUCT_RESET_CURRENT, originalProduct)
  },
  filterUnavailableVariants (context, { product }) {
    Logger.warn('`product/filterUnavailableVariants` deprecated, will be not used from 1.12')()
    return filterOutUnavailableVariants(context, product)
  },
  async preConfigureProduct (context, { product, populateRequestCacheTags, configuration }) {
    Logger.warn('`product/preConfigureProduct` deprecated, will be not used from 1.12')()
    let _product = preConfigureProduct({ product, populateRequestCacheTags })

    if (configuration) {
      const selectedVariant = await context.dispatch('getProductVariant', { product: _product, configuration })
      _product = Object.assign({}, _product, selectedVariant)
    }

    return _product
  },
  /**
   * Configure product with given configuration and set it as current
   * @param {Object} context
   * @param {Object} product
   * @param {Array} configuration
   */
  async configure (context, { product = null, configuration, selectDefaultVariant = true, fallbackToDefaultWhenNoAvailable = false }) {
    Logger.warn('`product/configure` deprecated, will be not used from 1.12, use "product/getProductVariant"')()
    const result = await context.dispatch('getProductVariant', { product, configuration })
    return result
  },

  /**
   * Load product attributes
   */
  async loadProductAttributes ({ dispatch }, { product }) {
    Logger.warn('`product/loadProductAttributes` deprecated, will be not used from 1.12')()
    const productFields = Object.keys(product).filter(fieldName => {
      return !config.entities.product.standardSystemFields.includes(fieldName) // don't load metadata info for standard fields
    })
    const { product: { useDynamicAttributeLoader }, optimize, attribute } = config.entities
    return dispatch('attribute/list', { // load attributes to be shown on the product details - the request is now async
      filterValues: useDynamicAttributeLoader ? productFields : null,
      only_visible: !!useDynamicAttributeLoader,
      only_user_defined: true,
      includeFields: optimize ? attribute.includeFields : null
    }, { root: true })
  }
}

export default actions