import { TaskQueue } from '@vue-storefront/core/lib/sync';
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';
import { UserProfile } from '@vue-storefront/core/modules/user/types/UserProfile';
import Task from '@vue-storefront/core/lib/sync/types/Task';
import config from 'config';
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import { DataResolver } from '@vue-storefront/core/data-resolver/types/DataResolver';
import rootStore from '@vue-storefront/core/store';

const headers = {
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
};
export const getAttributeData = async (): Promise<Task> => {
  const url = processLocalizedURLAddress(
    getApiEndpointUrl(config.customer, 'customer_attribute')
  );

  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers
    },
    silent: true
  });
};

export const login = async (
  username: string,
  password: string
): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(
      getApiEndpointUrl(config.users, 'login_endpoint')
    ),
    silent: true,
    payload: {
      method: 'POST',
      mode: 'cors',
      headers,
      body: JSON.stringify({ username, password })
    }
  });

export const updateProfile = async (
  userProfile: UserProfile,
  actionName: string
): Promise<any> => {
  userProfile.customer.email = btoa(btoa(btoa(btoa(userProfile.customer.email))));
  return TaskQueue.execute({
    url: processLocalizedURLAddress(
      getApiEndpointUrl(config.users, 'me_endpoint')
    ),
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify(userProfile)
    },
    callback_event: `store:${actionName}`
  });
}

export const fetchStoreCreditsNext = async (): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(
      getApiEndpointUrl(config.cart, 'checkstorecredit_endpoint')
    ),
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: false
  });

export const subscribeNewsletterNext = async (email: string): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(
      getApiEndpointUrl(config.customer, 'newsletter_endpoint')
    ),
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({ email })
    },
    silent: true
  });

export const refreshToken = async (refreshToken: string): Promise<string> =>
  fetch(processLocalizedURLAddress(getApiEndpointUrl(config.users, 'refresh_endpoint')), {
    method: 'POST',
    mode: 'cors',
    headers,
    body: JSON.stringify({ refreshToken })
  }).then(resp => resp.ok ? resp.json() : { result: '' })
    .then(({ result }) => result)

export const getNotifiedDifferentCountryNext = async (email: string, countryId: string): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(
      getApiEndpointUrl(config.customer, 'different_country_endpoint')
    ),
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({
        subscriptionData: {
          store_id: config.storeViews.hk.storeId,
          email: email,
          country: countryId
        }
      })
    },
    silent: true
  });

export const getSingleOrder = async (magentoOrderId: string, orderEncodedData = ''): Promise<Task> => {
  return TaskQueue.execute({
    url: processLocalizedURLAddress(
      getApiEndpointUrl(config.users, 'singleOrder_endpoint').replace('{{magentoOrderId}}', magentoOrderId + '').replace('{{lb_order_data}}', encodeURIComponent(orderEncodedData))
    ),
    payload: {
      method: 'GET',
      mode: 'cors',
      headers
    }
  })
}

export const register = async (customer: DataResolver.Customer, password: string, offlineStore?: string): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(getApiEndpointUrl(config.users, 'create_endpoint')),
    payload: {
      method: 'POST',
      headers,
      body: JSON.stringify({ customer, password, offline_store: offlineStore })
    }
  })
