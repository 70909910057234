const megaMenuBlockProps = props => {
  return {
    slug: props.slug,
    images: props.blocks
  };
};

const MegaMenuBlock = () => ({
  type: 'mega_menu_banner_block',
  props: megaMenuBlockProps
});

export default MegaMenuBlock;
