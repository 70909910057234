import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { beforeRegistration } from './hooks/beforeRegistration'
import { MidtransStore } from './store';

export const KEY = 'midtrans'

export const MidtransModule: StorefrontModule = function ({ store, router, appConfig }) {
  beforeRegistration(appConfig, store);
  store.registerModule(KEY, MidtransStore)
}
