import { getColor } from '../helpers'

function getPromoCodeSectionProps (props) {
  const {
    title,
    promo_code,
    text,
    use_code,
    terms,
    background_color,
    code_color,
    text_color
  } = props;

  return {
    title: title,
    promoCode: promo_code,
    text: text,
    useCode: use_code,
    terms: terms || {},
    background: getColor(background_color),
    codeColor: getColor(code_color),
    colorText: getColor(text_color),
    editable: props
  }
}

const PromoCode = () => ({
  type: 'promo_code',
  props: getPromoCodeSectionProps
});

export default PromoCode
