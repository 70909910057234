import { GET_REGION, SET_SELECTED_COUNTRY } from './mutation-types';
import { extendStore } from '@vue-storefront/core/helpers';
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { regionData } from 'common/config/regionData';
import rootStore from '@vue-storefront/core/store'
import DataService from 'common/services/DataService';

const ADDRESS_URLS = {
  idCities: '/api/ext/address/region/cities'
};

const KEY = 'address';

const addressStore = {
  namespaced: true
};

const addressModule = {
  state: {
    label: '',
    selectedCountry: 'USA',
    countryCodes: [],
    districts: null
  },
  getters: {
    selectedCountry: (state): string => {
      return state.selectedCountry
    },
    regionStates: (state): string[] => {
      return state.districts
    },
    countryCodes: (state): string[] => {
      return state.countryCodes
    }
  },
  mutations: {
    [GET_REGION] (state, region) {
      state.countryCodes = region.countryCodes
      state.districts = region.states
    },
    [SET_SELECTED_COUNTRY] (state, country) {
      state.selectedCountry = country
    }
  },
  actions: {
    setSelectedCountry ({ commit }, country) {
      commit(SET_SELECTED_COUNTRY, country)
    },
    async getRegionData ({ state, commit }) {
      if (state.region) {
        return
      }
      const currentStoreCode = rootStore.getters['getCurrentStoreView']?.storeCode;
      const result = regionData[currentStoreCode];
      commit(GET_REGION, result)
    },
    async getCities ({ commit }, { searchText, regionCode }: { searchText: string, regionCode: string }) {
      return DataService.get(`${ADDRESS_URLS.idCities}?search=${searchText}&regionCode=${regionCode}`)
    }
  }
}

export const AddressModule: StorefrontModule = function ({
  app,
  store,
  appConfig,
  router,
  moduleConfig
}) {
  store.registerModule(KEY, addressStore);

  extendStore('address', addressModule);
};
