import { getThumbnailPath, productThumbnailPath } from '@vue-storefront/core/helpers';
import { Category } from './types/Category';
import { currentStoreView, localizedDispatcherRoute, localizedRoute } from '@vue-storefront/core/lib/multistore';
import { LocalizedRoute } from '@vue-storefront/core/lib/types'
import { getProductPrice } from './price';
import config from 'config';
import store from '@vue-storefront/core/store';
import { htmlDecode } from '@vue-storefront/core/filters';

function prepareCategoryProductCommon (product) {
  const secondImageUrl = product?.media_gallery?.filter(media => media?.typ === 'image' && media.image !== product.image)?.[0]?.image || ''
  let swatchImage = ''
  const { width, height, mobile } = config.products.thumbnails
  if (secondImageUrl) {
    swatchImage = getThumbnailPath(secondImageUrl, width, height)
  }
  return {
    id: product.id,
    title: htmlDecode(product.name),
    image: getThumbnailPath(productThumbnailPath(product), width, height),
    mobileImage: getThumbnailPath(productThumbnailPath(product), mobile?.width, mobile?.height),
    swatchImage
  }
}

export function formatProductLink (
  product: {
    parentSku?: string,
    sku: string,
    url_key?: string,
    type_id: string,
    slug: string,
    options?: [],
    configurable_children?: []
  },
  storeCode
): string | LocalizedRoute {
  if (config.seo.useUrlDispatcher && product.url_key) {
    let routeData: LocalizedRoute;
    if ((product.options && product.options.length > 0) || (product.configurable_children && product.configurable_children.length > 0)) {
      routeData = {
        path: product['url_path']?.indexOf('colorCode') >= 0 ? product['url_path'] : product.url_key + '.html'
        // params: { childSku: product.sku }
      }
    }
    return localizedDispatcherRoute(routeData, storeCode)
  } else {
    const routeData: LocalizedRoute = {
      name: product.type_id + '-product', // we should use here localizedDispatcherRouteName?
      params: {
        parentSku: product.parentSku ? product.parentSku : product.sku,
        slug: product.slug,
        childSku: product.sku
      }
    }
    return localizedRoute(routeData, storeCode)
  }
}

export function getCategoryThumbnailUrl (category, width, height) {
  let categoryThumbnailUrl = ''

  if (category.mega_menu_image) {
    const url = category.mega_menu_image.replace('/media', '')
    let { useCloudflareImage } = config.server
    let { baseUrl, baseUrlCf } = config.images
    categoryThumbnailUrl = useCloudflareImage ? baseUrlCf.replace('{{url}}', url) : baseUrl.replace('{{url}}', url)
    categoryThumbnailUrl = categoryThumbnailUrl.replace('{{width}}', width.toString() || '')
    categoryThumbnailUrl = categoryThumbnailUrl.replace('{{height}}', height.toString() || '')
  }

  return categoryThumbnailUrl || ''
}

function getSubcategory (product) {
  // Right now there is no information for subcategory, so lets just get the category.
  const category = product.erp_subcategory;
  const erp_getters = store.getters['attribute/attributeListByCode'].erp_subcategory;
  const result = erp_getters.options.filter(getters_value => getters_value.value === String(category));
  if (!result) return 'COLLECTION';
  return result;
}

function prepareCategoryProductConfigurableOptions (product, code) {
  let default_configuration = {};

  const {
    configurable_options
  } = product;
  const transformedConfigurableOptions = configurable_options &&
    configurable_options.filter(({ attribute_code }) => attribute_code === code);
  if (transformedConfigurableOptions && transformedConfigurableOptions.length) {
    const {
      attribute_code,
      values
    } = transformedConfigurableOptions[0];
    const attributeValue = values[0];

    default_configuration = {
      [code]: {
        id: attributeValue.value_index,
        type: attribute_code,
        label: attributeValue.label
      }
    };
  }
  return {
    default_configuration,
    configurable_options// : transformedConfigurableOptions
  }
}
function assignImsStock (product) {
  if (product?.imsStock) {
    if (!product.stock) {
      product.stock = {}
    }
    // console.log(`ES: ${product.stock.qty}, IMS: ${product?.imsStock?.qty}`)
    product.stock.qty = product?.imsStock?.qty || 0;
    product.stock.is_in_stock = product?.imsStock?.is_in_stock || false;
  }
}
function prepareConfigurableChildren ({ configurable_children }, imsOn = false) {
  if (!configurable_children) return [];

  return configurable_children.map((product) => {
    const retProduct = {
      sku: product.sku,
      color: product.color,
      color_family: product.color_family,
      size: product.size,
      stock: product.stock,
      imsStock: product.imsStock,
      price: getProductPrice(product),
      ...prepareCategoryProductCommon(product)
    };
    if (imsOn) {
      assignImsStock(retProduct);
    }
    return retProduct
  })
}
export function prepareCategoryProduct (product, imsOn = false) {
  return {
    ...prepareCategoryProductCommon(product),
    ...prepareCategoryProductConfigurableOptions(product, 'color'),
    category_ids: product.category_ids,
    type_id: product.type_id,
    link: formatProductLink(product, currentStoreView().storeCode),
    stock: product.stock,
    isPreOrder: product.is_pre_order,
    qty: product.qty,
    price: getProductPrice(product),
    parentSku: product.type_id === 'giftcard' ? product.sku : product.parentSku || product.sku?.split('-')?.[0],
    rating: {
      max: 5,
      score: 5
    },
    recommendedSku: product.recommendedSku,
    subcategory: getSubcategory(product),
    configurable_children: prepareConfigurableChildren(product, imsOn),
    cover_image: product.cover_image,
    product_label_2: product.product_label_2,
    lb_benefits: product?.lb_benefits

  }
}

export function filterOnlySaleVariantColor (productList) {
  productList.forEach((product) => {
    if (product?.type_id === 'configurable') {
      const totalChildProducts = product?.configurable_children.length;
      const onSaleVariantColors = []
      // find colors variants which are on sale
      product?.configurable_children.forEach(element => {
        if (element.price.special && !onSaleVariantColors.find(i => i === element.color)) {
          onSaleVariantColors.push(element.color);
        }
      });
      // include only on sale variant colors in configurable_options
      product?.configurable_options.forEach(config => {
        if (config.attribute_code === 'color') {
          config.values = config.values.filter(item => onSaleVariantColors.includes(Number(item?.value_index)))
        }
      })
      // include only on sale variant in configurable_children
      product.configurable_children = product?.configurable_children.filter(item => item.price.special);
      // update the image
      if (totalChildProducts > product?.configurable_children?.length) {
        const firstColorCode = product.configurable_options
          .find(option => option.attribute_code === 'color')
          ?.values[0]?.value_index;

        const firstChildProduct = product.configurable_children.find(item => item.color === Number(firstColorCode));
        if (firstChildProduct?.image && firstChildProduct?.mobileImage) {
          product.image = firstChildProduct?.image;
          product.mobileImage = firstChildProduct?.mobileImage;
        }
      }
    }
  })
  return productList;
}

export function formatCategoryLink (category: Category, storeCode: string = currentStoreView().storeCode): string {
  storeCode ? storeCode += '/' : storeCode = '';

  if (currentStoreView().appendStoreCode === false) {
    storeCode = ''
  }

  if (category) {
    const url = category.headless_url_key || category.url_path
    return config.seo.useUrlDispatcher ? ('/' + storeCode + url) : ('/' + storeCode + 'c/' + category.slug)
  }
  return '/' + storeCode;
}

export function prepareCategoryMenuItem (category) {
  if (!category) return;

  return {
    id: category.id,
    name: category.name,
    link: formatCategoryLink(category),
    count: category.product_count || '',
    position: category.position,
    path: category.path
  };
}
