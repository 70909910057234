import { COUNTRY_CODE_STORAGE_KEY } from '../constants';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import { mapActions, mapGetters } from 'vuex';
import { availableCountries } from 'common/config/countries';
import isOnPage from 'common/mixins/isOnPage';

const shoppingCountryStorage = StorageManager.get('shoppingCountry')

export default {
  mixin: [isOnPage],
  async created () {
    if (this.onIntl || this.currentStoreCode === 'us') await this.setIntlVisitorCountry();
  },
  computed: {
    ...mapGetters({
      getUserCurrentLocation: 'user/getUserCurrentLocation'
    }),
    isInternationalCountry () {
      if (!this.onIntl || this.getSelectedCountryDetails?.website) return false
      return true;
    },
    internationalCountries () {
      return availableCountries.filter(availableCountry => !availableCountry.website && availableCountry.available)
    }
  },
  methods: {
    ...mapActions('address', ['setSelectedCountry']),
    async setIntlVisitorCountry () {
      const code = await shoppingCountryStorage.getItem(COUNTRY_CODE_STORAGE_KEY)
      if (!code) {
        if (!this.userCurrentLocation) return
        const country = this.internationalCountries.find(({ standardCountryCode }) => standardCountryCode === this.userCurrentLocation)
        if (!country) return
        this.selectedCountryCode = country.value
        shoppingCountryStorage.setItem(COUNTRY_CODE_STORAGE_KEY, this.selectedCountryCode)
        this.setSelectedCountry(this.selectedCountryCode)
      } else {
        const isInternationalCountry = this.internationalCountries.some(({ value }) => value === code);
        if ((this.currentStoreCode === 'us' && !isInternationalCountry) || (this.onIntl && isInternationalCountry)) {
          this.setSelectedCountry(code);
          this.$store.commit('currencyconversion/setCurrency', code);
          this.selectedCountryCode = code;
        }
      }
    }
  }
}
