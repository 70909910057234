<template>
  <footer v-if="!isOnStyleQuizePage" class="footer-section">
    <ClientOnly v-if="!onOrderSuccessPage && !onCartPage && !onCheckoutPage && !onHomePage">
      <MFooterLoyaltySectionNew />
    </ClientOnly>
    <MFooterLBSection />
    <FooterLinks />
  </footer>
</template>

<script>
import isOnPage from 'src/common/mixins/isOnPage';
import ClientOnly from 'vue-client-only';

export default {
  name: 'ONewFooter',
  mixins: [isOnPage],
  components: {

    ClientOnly,
    MFooterLBSection: () => import('common/components/molecules/footer/m-footer-lb-section'),
    FooterLinks: () => import('src/modules/storyblok/components/FooterLinks'),
    MFooterLoyaltySectionNew: () => import('common/components/molecules/footer/m-footer-loyalty-section-new')
  },
  computed: {
    isOnStyleQuizePage: function () {
      return this.$route?.name?.includes('style-quiz') && !this.$route?.name?.includes('style-quiz-results')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.footer-section {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
}
</style>
