import DataService from 'src/common/services/DataService';
import * as types from './mutation-types';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import config from 'config'

const actions = {
  resetCheckoutModule ({ commit }) {
    commit(types.RESET_CHECKOUT_MODULE);
  },
  selectShippingAddress ({ commit }, address) {
    commit(types.SET_SHIPPING_ADDRESS, address);
  },
  selectBillingAddress ({ commit }, address) {
    commit(types.SET_BILLING_ADDRESS, address);
  },
  selectShippingMethod ({ commit }, shippingMethod) {
    commit(types.SELECT_SHIPPING_METHOD, shippingMethod);
  },
  setExtensionAttributes () {},
  setForterToken ({ commit }, forterToken: string) {
    commit(types.SET_FORTER_TOKEN, forterToken)
  },
  setCardDetails ({ commit }, cardDetails: {
    lastFour: number,
    issuerBin: string
  }) {
    commit(types.SET_CARD_DETAILS, cardDetails)
  },
  resetCardDetails ({ commit }) {
    commit(types.SET_CARD_DETAILS, {
      lastFour: null,
      issuerBin: ''
    })
  },
  async setPaymentMethodInformation ({ getters, rootGetters, dispatch }, payload) {
    if (!config.featureFlags['PAYMENT_EXLUSIVE_PROMO_CODE_ENABLED'].isActive) {
      return
    }
    let payloadWithEmail = {
      ...payload,
      extension_attributes: {
        forter: getters['getForterToken'],
        getCardDetails: getters['getCardDetails']
      }
    }

    // Need to pass email for guest checkout
    if (rootGetters['checkout/getIsGuestCheckout']) {
      payloadWithEmail = {
        ...payloadWithEmail,
        email: rootGetters['checkout/getCustomerEmail']
      }
    }

    try {
      EventBus.$emit('notification-progress-start')
      const response = await DataService.post('/api/cart/set-payment-information', {
        params: {
          cartId: rootGetters['cart-v1/getCartId']
        },
        body: { ...payloadWithEmail }
      });
      if (response.result) {
        await dispatch('cart-v1/recalculateTotals', null, { root: true })
      }
    } finally {
      EventBus.$emit('notification-progress-stop');
    }
  }
};

export default actions;
