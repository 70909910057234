function getProps (props) {
  const {
    no_gap
  } = props;
  return {
    noGap: no_gap
  }
}
const DividerLine = () => ({
  type: 'divider_line',
  props: getProps
});

export default DividerLine;
