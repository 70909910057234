<template>
  <div v-if="recentSearchedTerms && recentSearchedTerms.length" class="search-history">
    <div class="heading label-1">
      <p class="title">
        {{ $t("RECENT SEARCHES") }}
      </p>
      <p class="clear-btn" @click="clearAllSearches">
        {{ $t("CLEAR") }}
      </p>
    </div>
    <div class="text-list">
      <div v-for="(item, idx) in recentSearchedTerms" :key="idx" class="text paragraph-2">
        <p @click="searchTextClicked(item)">
          {{ item }}
        </p>
        <svg class="lb-icon account-icon" @click="deleteText(idx)">
          <use xlink:href="#icon_cross" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { getSearchHistory, setSearchHistory } from 'common/helpers';

export default {
  name: 'MSearchHistory',
  data: () => ({
    recentSearchedTerms: []
  }),
  created () {
    this.recentSearchedTerms = getSearchHistory();
  },
  methods: {
    clearAllSearches () {
      this.recentSearchedTerms = [];
      setSearchHistory([]);
    },
    searchTextClicked (text) {
      this.$emit('searchTextClicked', text);
    },
    deleteText (idx) {
      this.recentSearchedTerms.splice(idx, 1);
      setSearchHistory(this.recentSearchedTerms);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.search-history {
  .heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    @include for-desktop {
      max-width: 230px;
    }
    p {
      margin-top: 0;
    }

    .clear-btn {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .text-list {
    display: inline-flex;
    flex-wrap: wrap;
    .text {
      display: flex;
      justify-content: space-between;
      padding: 8px;
      align-items: center;
      border: 1px solid var(--neutral-100);
      p {
        margin: 0;
        cursor: pointer;
      }

      .lb-icon {
        height: 11px;
      }
      margin-right: 10px;
      @include for-desktop {
        p {
          margin-right: 10px;
        }
      }

      @include for-mobile {
          margin-bottom: 25px;

      }
    }
  }
}
</style>
