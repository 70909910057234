<template>
  <div
    class="o-sub-footer"
    v-if="!isOnStyleQuizePage"
  >
    <div class="terms">
      <div class="paragraph-2 terms-and-conditions">
        <router-link :to="localizedRoute(termsAndConditionLink)">
          {{ $t('Terms & Conditions') }}
        </router-link>
        <router-link :to="localizedRoute(privacyPolicyLink)">
          {{ $t('Privacy Policy') }}
        </router-link>
      </div>
      <div class="paragraph-2">
        {{ $t(`© 2010-${new Date().getFullYear()}. Love, Bonito is a registered trademark of LOVEBONITO SINGAPORE PTE. LTD. All rights reserved.`) }}
      </div>
    </div>
  </div>
</template>

<script>
import isOnPage from 'common/mixins/isOnPage';

export default {
  name: 'ONewSubFooter',
  components: {
  },
  mixins: [isOnPage],
  computed: {
    isOnStyleQuizePage: function () {
      return this.$route?.name?.includes('style-quiz') && !this.$route?.name?.includes('style-quiz-results')
    },
    termsAndConditionLink () {
      if (this.onID) return '/pages/terms-conditions'
      return '/terms-and-conditions';
    },
    privacyPolicyLink () {
      if (this.onUS) return '/pages/privacy-policy'
      return '/global/privacy-policy';
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-sub-footer {
  background: var(--cool-pink-50);
  align-items: flex-end;
  justify-content: space-between;

  .terms {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    @include for-mobile {
      padding: 28px 20px;
      flex-direction: column;
      text-align: center;
      gap: 32px;
    }
    .terms-and-conditions {
      display: flex;
      gap: 40px;
    }
  }

  @include for-desktop {
    padding: 28px 80px;
  }
}
</style>
