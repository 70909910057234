


























import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import MCountrySelection from 'common/components/molecules/m-country-selection';
import MyProfile from '@vue-storefront/core/compatibility/components/blocks/MyAccount/MyProfile';
import MegaMenuTracking from 'src/common/mixins/MegaMenuTracking';
import { storesShortCode } from 'common/constants';
import isOnPage from 'common/mixins/isOnPage';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

export default Vue.extend({
  name: 'OMegaMenuMobileFooter',
  mixins: [MyProfile, MegaMenuTracking, isOnPage],
  data () {
    return {
      pagesLinkStoreCodes: ['hk', 'id', 'my']
    };
  },
  components: {
    MCountrySelection
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    storesLink () {
      const shouldOpenPagesLink = this.pagesLinkStoreCodes.includes(
        this.currentStoreCode.toLowerCase()
      );
      let suffix =
        this.currentStoreCode !== storesShortCode.intl && !shouldOpenPagesLink
          ? `?tab=${this.currentStoreCode}`
          : '';
      return this.localizedRoute(
        `/${shouldOpenPagesLink ? 'pages' : 'global'}/stores${suffix}`
      );
    }
  },
  methods: {
    ...mapActions('user', {
      toggleAuthModal: 'toggleAuthModal'
    }),
    handleWishlistClick () {
      EventBus.$emit('close-megamenu')
      this.$gtm?.trackEvent({
        event: `click_megamenu`,
        megamenu_path: `wishlist`
      });
      if (!this.isLoggedIn) {
        this.toggleAuthModal();
      } else { this.$router.push(this.localizedRoute('/my-account?page=my-wishlist')); }
    }
  }
});
