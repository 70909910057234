import { $fetch, FetchOptions } from 'ohmyfetch';
import config from 'config';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { isServer } from 'common/utils';
import { ApiError } from './ApiError';
import { handleUnAuthorizedResponse } from './HandleResponseError';

const fetchConfig: FetchOptions = {
  baseURL: config.api.url,
  headers: {
    Accept: 'application/json'
  },
  onRequest: ({ options }: { options: any }): any => {
    const { storeCode } = currentStoreView();
    if (storeCode) {
      options.params = {
        ...options.params,
        storeCode: storeCode
      };
    }

    // Remove authorization token from headers and always get it from local storage if it's available
    const { authorization = '', ...otherHeaders } = options.headers;
    options.headers = { ...otherHeaders };

    // Return as local storage is not accessible on server
    if (isServer) { return; }

    const USER_TOKEN_KEY = `${storeCode}-shop/user/current-token`;
    const token = localStorage.getItem(USER_TOKEN_KEY);
    if (token && token !== 'null') {
      options.headers.authorization = `${JSON.parse(token)}`;
    }
  },
  onResponseError: (ctx): Promise<void> => {
    if (isServer) throw new ApiError(ctx.response);
    handleUnAuthorizedResponse(ctx);
    throw new ApiError(ctx.response);
  }
}

export default {
  get: $fetch.create({ method: 'GET', ...fetchConfig }),
  put: $fetch.create({ method: 'PUT', ...fetchConfig }),
  post: $fetch.create({ method: 'POST', ...fetchConfig }),
  delete: $fetch.create({ method: 'DELETE', ...fetchConfig })
}
