









































import { mapGetters } from 'vuex';

import { formatCategoryLink } from 'src/modules/catalog-es/categoryHelper';
import MegaMenuTracking from 'src/common/mixins/MegaMenuTracking';

import OMegaMenuSubCategoryChild from './o-mega-menu-sub-category-child.vue';

import Vue from 'vue';
export default Vue.extend({
  name: 'OMegaMenuSubCategory',
  mixins: [MegaMenuTracking],
  components: {
    OMegaMenuSubCategoryChild
  },
  props: {
    currentCategory: {
      type: String,
      default: ''
    },
    categoryId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters('catalog-es', ['getSubCategories']),
    children () {
      return this.getSubCategories
        ?.filter((c) => c.parent_id === this.categoryId)
        .sort((a, b) => a?.position - b?.position);
    },
    visibleSubCategories () {
      return this.children
        ?.filter((category) => {
          return (
            (category?.product_count > 0 ||
              category?.children_count > 0 ||
              category?.url_key === 'my-style-quiz') &&
            category?.is_active &&
            category?.include_in_menu
          );
        })
        .sort((a, b) => a?.position - b?.position);
    }
  },
  methods: {
    categoryLink (category) {
      return formatCategoryLink(category);
    },
    handleClose () {
      this.$emit('handleClose');
    }
  }
});
