<template>
  <div class="m-star-rating-minimal paragraph-2" v-if="rating.avgRating ">
    <span class="average-rating"> {{ rating.avgRating }}</span>
    <div class="m-star-ratings" />
    <span class="rating-count">({{ rating.reviewCount }})</span>
  </div>
</template>
<script>
export default {
  name: 'MRatingStarsMinimal',
  props: {
    rating: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
<style lang="scss" scoped>
.m-star-ratings {
  display: inline-block;
  line-height: 1;

  &::before {
    content: '★';
    background: var(--bright-gold-500);
    font-size: var(--font_size_100);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
