import * as types from './mutation-types';
import Vue from 'vue';
import { getTopLevelCategories } from './categoryHelper';

const mutations = {
  [types.SET_SEARCH_EMPTY_RESULT] (state, { isEmpty }: { isEmpty: boolean }) {
    state.isSearchEmpty = isEmpty;
  },
  [types.SET_SEARCH_LOADING] (state, { loading }: { loading: boolean }) {
    state.getSearchLoadingStatus = loading;
  },
  [types.ADD_SEARCH_PRODUCTS] (state, { products }: { products: any[] }) {
    state.searchProducts = products;
  },
  [types.SEARCH_KEYWORD_REDIRECT_URL] (state, { redirectUrl }: { redirectUrl: string }) {
    state.searchRedirectUrl = redirectUrl;
  },
  [types.SET_SEARCH_PRODUCT_COUNT] (state, { count }: { count: number }) {
    state.searchProductsCount = count;
  },
  [types.SET_SEARCH_KEYWORD] (state, { keyword }: { keyword: string }) {
    state.searchKeyword = keyword;
  },
  [types.ADD_SEARCH_CACHE_PRODUCTS] (state, { results, key }) {
    const result = {
      [key]: results
    };
    state.searchProductsCache.unshift(result);
    if (state.searchProductsCache.length > 10) {
      state.searchProductsCache.pop();
    }
  },
  [types.SET_SEARCH_FILTER] (state, { id, filters }) {
    Vue.set(state.filtersMap, id, filters);
  },
  [types.ADD_PLP_CACHE_PRODUCTS] (state, { results, key }) {
    const result = {
      [key]: results
    };
    state.plpProductsCache.unshift(result);
    if (state.plpProductsCache.length > 10) {
      state.plpProductsCache.pop();
    }
  },
  [types.ADD_SIMILAR_PRODUCTS] (state, { items }) {
    state.similarProducts = items;
  },
  [types.SET_SIMILAR_PRODUCTS_LOADING] (state, { isLoading }) {
    state.similarProductsLoading = isLoading;
  },
  [types.ADD_PLP_PRODUCTS_TOTAL_COUNT] (state, { totalResults }) {
    state.totalResults = totalResults;
  },
  [types.ADD_PLP_PRODUCTS] (state, { products }) {
    state.products = products;
  },
  [types.SET_PLP_PRODUCTS_LOADING] (state, { isLoading }) {
    state.productsLoading = isLoading;
  },
  [types.SET_PRODUCT_DETAILS_LOADING] (state, { isLoading }) {
    state.productDetailsLoading = isLoading;
  },
  [types.SET_PRODUCT_DETAILS] (state, { result }) {
    state.productDetails = result;
  },
  [types.SET_PRODUCT_GALLERY] (state, { gallery }) {
    state.productGallery = gallery;
  },
  [types.SET_CURRENT_CONFIGURATION] (state, configuration = {}) {
    Vue.set(state, 'current_configuration', configuration || {});
  },
  [types.SET_MEGAMENU_CATEGORIES] (state, { categories }) {
    categories = getTopLevelCategories(categories);
    state.topLevelCategories = categories;
  },
  [types.SET_MEGAMENU_SUBCATEGORIES] (state, { categories }) {
    state.subCategories = categories;
  },
  [types.SET_PRODUCT_BREADCRUMBS] (state, { productBreadCrumbs }) {
    state.productBreadCrumbs = productBreadCrumbs
  },
  [types.SET_CATEGORY_BREADCRUMBS] (state, { categoryBreadCrumbs }) {
    state.categoryBreadCrumbs = categoryBreadCrumbs
  },
  [types.SET_CURRENT_CATEGORY] (state, { category }) {
    state.currentCategory = category
  },
  [types.LOADING_RELATED_PRODUCTS] (state, { isLoading }: { isLoading: boolean }) {
    state.loadingRelatedProducts = isLoading
  },
  [types.ADD_RELATED_PRODUCTS] (state, { products }: { products: any[] }) {
    state.relatedProducts = products
  },
  [types.ADD_RELATED_PRODUCTS_CACHE] (state, { results, key }) {
    const result = {
      [key]: results
    };
    state.relatedProductsCache.unshift(result);
    if (state.relatedProductsCache.length > 10) {
      state.relatedProductsCache.pop();
    }
  },
  [types.SET_RESTRICTED_CATEGORY] (state, { isRestricted }) {
    state.restrictedCategory = isRestricted;
  },
  [types.SET_IS_ALL_OOS] (state, { isAllOOS }) {
    state.isAllSearchOOS = isAllOOS;
  },
  [types.SET_RECENTLY_VIEWED_PRODUCTS] (state, recentlyViewedProductList = []) {
    state.recentlyViewedProductList = recentlyViewedProductList || [];
  },
  [types.SET_PLP_BANNER] (state, { storyBlokContent }) {
    state.plpBannerContent = storyBlokContent || {};
  },
  [types.SET_PLP_FOOTER] (state, { storyBlokContent }) {
    state.plpFooterContent = storyBlokContent || {};
  },
  [types.SET_SERVER_TIME] (state, serverTime) {
    state.serverTime = serverTime || {};
  },
  [types.SET_PLP_INLINE_BANNER] (state, { inlineBanners }) {
    state.plpInlineBanners = inlineBanners || [];
  },
  [types.PRICE_FILTER_RANGE_VALUES] (state, priceRange) {
    state.priceFilterRangeValues = priceRange || {};
  }
};

export default mutations;
