import { isServer } from '@vue-storefront/core/helpers';
import i18n from '@vue-storefront/i18n';
import debounce from 'lodash-es/debounce';
import { SET_PREVIOUS_CART_DATA } from './types'
import { emarsysCurrencyEvents, PURCHASE_EVENT, DISPLAY_CURRENCY, CART } from 'src/common/constants';
import { ScarabQueuePayload } from './interface';
declare global {
  interface Window {
    ScarabQueue: any
  }
}

const showErrorMessage = message => {
  return {
    type: 'error',
    message: i18n.t(message),
    action1: { label: i18n.t('OK') }
  };
};

const actions = {
  scarabGoDebounce: debounce(({ commit }) => {
    if (isServer) { return }
    window?.ScarabQueue?.push(['go']);
    commit(SET_PREVIOUS_CART_DATA, '')
  }, 1500),

  async scarabQueuePush ({ state, commit, dispatch, rootGetters }, payload: ScarabQueuePayload) {
    if (isServer) return;
    try {
      if (!payload.key || !payload.value) { throw new Error('Payload is missing for emarsys event!'); }
      if (!window.ScarabQueue) {
        window.ScarabQueue = []
      }

      if (payload.key === CART && payload.value?.length) {
        const alreadyExist = state.previousCartData === JSON.stringify(payload)
        if (alreadyExist) {
          return
        }
        commit(SET_PREVIOUS_CART_DATA, JSON.stringify(payload))
      }

      if (payload.key === PURCHASE_EVENT) {
        window?.ScarabQueue?.push(['cart', []]);
      }

      if (payload.key in emarsysCurrencyEvents) {
        window?.ScarabQueue?.push([
          DISPLAY_CURRENCY,
          rootGetters.getCurrentStoreView?.i18n?.currencyCode || 'USD'
        ]);
      }

      window?.ScarabQueue?.push([payload.key, payload.value]);
    } catch (err) {
      dispatch(
        'notification/spawnNotification',
        showErrorMessage(err.message),
        { root: true }
      );
    } finally {
      await dispatch('scarabGoDebounce')
    }
  }
};

export default actions;
