import { TaskQueue } from '@vue-storefront/core/lib/sync'
import Task from '@vue-storefront/core/lib/sync/types/Task'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import config from 'config'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import { GetRecommendationsInput } from '../types/GetRecommendationsInput';

const headers = {
  'Accept': 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
};

const encodeInputs = (object) => {
  if (!object || (!object.itemId)) {
    throw new Error('Recommendation params can not be null or undefined')
  }

  return `&${Object.keys(object)
    .filter(v => object[v])
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(object[k])}`)
    .join('&')}`;
};

const getRecommendations = async (recommendationsInput: GetRecommendationsInput): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(
      getApiEndpointUrl(config.awsPersonalize, 'getRecommendations_endpoint')
    ) + encodeInputs(recommendationsInput),
    payload: {
      method: 'GET',
      mode: 'cors',
      headers
    }
  });

export const AwsPersonalizeService = {
  encodeInputs,
  getRecommendations
};
