import has from 'lodash-es/has';
import toString from 'lodash-es/toString'
import trim from 'lodash-es/trim'
import forEach from 'lodash-es/forEach'
import { compareByLabel, getSearchOptionsFromRouteParams, optionLabel } from './helper';
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { products } from 'config'

const getters = {
  isSearchEmpty: state => state.isSearchEmpty,
  getSearchProducts: state => state.searchProducts,
  getSearchRedirectUrl: state => state.searchRedirectUrl,
  getSearchKeyword: state => state.searchKeyword,
  getSearchCacheResults: state => state.searchProductsCache,
  getSearchProductCount: state => state.searchProductsCount,
  getSearchLoadingStatus: state => state.getSearchLoadingStatus,
  getRecentlyViewedProductList: state => state.recentlyViewedProductList || [],
  getAvailableFiltersFrom: (state, getters, rootState) => (aggregations) => {
    const filters = {}
    if (aggregations) { // populate filter aggregates
      for (let attrToFilter of products.defaultFilters) { // fill out the filter options
        let filterOptions = []

        let uniqueFilterValues = new Set<string>()
        if (attrToFilter !== 'price') {
          if (aggregations['agg_terms_' + attrToFilter]) {
            let buckets = aggregations['agg_terms_' + attrToFilter].buckets
            if (aggregations['agg_terms_' + attrToFilter + '_options']) {
              buckets = buckets.concat(aggregations['agg_terms_' + attrToFilter + '_options'].buckets)
            }

            for (let option of buckets) {
              uniqueFilterValues.add(toString(option.key))
            }
          }

          uniqueFilterValues.forEach(key => {
            const label = optionLabel(rootState.attribute, { attributeKey: attrToFilter, optionId: key })
            if (trim(label) !== '') { // is there any situation when label could be empty and we should still support it?
              filterOptions.push({
                id: key,
                label: label,
                type: attrToFilter
              })
            }
          });
          filters[attrToFilter] = filterOptions.sort(compareByLabel)
        } else { // special case is range filter for prices
          const currencySign = currentStoreView().i18n.currencySign

          if (aggregations['agg_range_' + attrToFilter]) {
            let index = 0
            let count = aggregations['agg_range_' + attrToFilter].buckets.length
            for (let option of aggregations['agg_range_' + attrToFilter].buckets) {
              filterOptions.push({
                id: option.key,
                type: attrToFilter,
                from: option.from,
                to: option.to,
                label: (index === 0 || (index === count - 1)) ? (option.to ? '< ' + currencySign + option.to : '> ' + currencySign + option.from) : currencySign + option.from + (option.to ? ' - ' + option.to : ''), // TODO: add better way for formatting, extract currency sign
                single: true
              })
              index++
            }
            filters[attrToFilter] = filterOptions
          }
        }
      }
      // Add sort to available filters
      let variants = []
      Object.keys(products.sortByAttributes).map(label => {
        variants.push({
          label: label,
          id: products.sortByAttributes[label],
          type: 'sort'
        })
      })
      filters['sort'] = variants
    }
    return filters
  },
  getCategoryListByParams: (state, getters, rootState) => (params: { [key: string]: string } = {}) => {
    return getters.getCategories.filter(category => {
      let valueCheck = []
      const searchOptions = getSearchOptionsFromRouteParams(params)
      forEach(searchOptions, (value, key) => valueCheck.push(category[key] && category[key] === (category[key].constructor)(value)))
      return valueCheck.filter(check => check === true).length === Object.keys(searchOptions).length
    }) || []
  },
  getCurrentCategoryList: (state, getters, rootState, rootGetters) => {
    return getters.getCategoryListByParams({ ...rootGetters['url/getCurrentRoute'].params })
  },
  getPlpProductsCache: state => state.plpProductsCache,
  getSimilarProducts: state => state.similarProducts,
  isSimilarProductsLoading: state => state.similarProductsLoading,
  isPlpProductsLoading: state => state.productsLoading,
  getPlpProducts: state => state.products,
  getPlpProductsTotalCount: state => state.totalResults,
  getProductDetails: state => state.productDetails,
  getProductConfiguration: state => state.current_configuration,
  isProductDetailsLoading: state => state.productDetailsLoading,
  getProductGallery: state => {
    const { productGallery } = state;
    try {
      // In case of no color option, we should return the gallery without any modification.
      if (!has(state, 'current_configuration.color')) {
        return productGallery;
      }
      const { current_configuration: { color } } = state;
      const attributeCode = 'color'
      return productGallery.filter(image => image.id[attributeCode] === color.label);
    } catch (e) {
      return productGallery;
    }
  },
  getOriginalProduct: (state, getters) => {
    if (!getters.getProductDetails) return null
    return state.original || {
      ...getters.getProductDetails,
      id: getters.getProductDetails.parentId || getters.getProductDetails.id
    }
  },
  getTopLevelCategories: state => state.topLevelCategories,
  getSubCategories: state => state.subCategories,
  getAvailableCategories: state => {
    return state.topLevelCategories.filter((category) => category.include_in_menu > 0);
  },
  getProductBreadCrumbs: state => {
    return state.productBreadCrumbs;
  },
  getCategoryBreadCrumbs: state => {
    return state.categoryBreadCrumbs;
  },
  getCurrentCategory: state => {
    return state.currentCategory;
  },
  getRelatedProducts: state => state.relatedProducts,
  getRelatedProductsLoading: state => state.loadingRelatedProducts,
  getRelatedProductsCache: state => state.relatedProductsCache,
  getIsRestrictedCategory: state => state.restrictedCategory,
  isAllItemsOOS: state => state.isAllSearchOOS,
  getPlpBannerContent: state => state.plpBannerContent,
  getPlpFooterContent: state => state.plpFooterContent,
  getServerTime: state => state.serverTime,
  getPlpInlineBanners: state => state.plpInlineBanners,
  priceFilterRangeValues: state => state.priceFilterRangeValues
}

export default getters;
