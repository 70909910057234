function getProps (props) {
  const {
    banners,
    change_time,
    show_pagination_dots: showPaginationDots,
    show_navigation_arrows: showNavigationArrows
  } = props;

  return {
    banners,
    showPaginationDots,
    showNavigationArrows,
    changeTime: parseInt(change_time)
  }
}

const CategoryBannerCarousel = () => ({
  type: 'category_banner_carousel',
  props: getProps
});

export default CategoryBannerCarousel
