import { fetchCmsContent, fetchDataSourceEntries } from '../services';
import { transpileCmsContent } from '../transpilers';
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore'
import * as next from './types'
import Vue from 'vue'
import config from 'config';
import rootStore from '@vue-storefront/core/store'
import { Logger } from '@vue-storefront/core/lib/logger';

const getStoryData = story => {
  let components = transpileCmsContent(story);

  let { title, description, keywords, full_width } = story.content;
  return {
    id: story.id,
    components: components,
    title: title,
    description: description,
    keywords: keywords,
    isFullWidth: full_width
  };
};

const getCmsStoryBySlug = async (slug, version, routeName = '', transpileRequired = true) => {
  if (!slug) {
    return
  }
  const homeKey = '-home';
  let response: any;
  response = await fetchCmsContent(`${slug}?version=${version}`);
  if (response.resultCode !== 200) {
    if (routeName.includes(homeKey)) {
      // If route is home page and story block not created
      // fallback to story block setup in global
      response = await fetchCmsContent(`global/homepage?version=${version}`);
    } else {
      throw new Error('Something went wrong fetchCmsData');
    }
  }
  const page = response?.result?.data;
  const story = transpileRequired ? getStoryData(page.story) : page?.story?.content?.body;
  return story;
};

const actions = {
  async fetchCmsData ({ commit }, { route, version, isHome = false }) {
    let key = route.params.slug;
    const globalKey = 'global';
    let slug = route.path.includes(globalKey)
      ? `${globalKey}/${key}`
      : route.path.substring(1);

    // for home SIT , load version which is in pages folder of storyblok
    if (!config.server.isProduction && isHome) {
      const storeCode = currentStoreView().storeCode;
      slug = `${storeCode}/pages/${storeCode}-sit`
    }
    key = key || slug
    commit('loadingStory', { key });
    const story = await getCmsStoryBySlug(slug, version, route.name);
    commit('setStory', { key, story });
    // cache only published comntent
    if (version === 'published') {
      // use story id as cache tag to make caching more dynamic
      await rootStore.dispatch('cache-tags/addTags', { tags: [`sb_${story.id}`, 'storyblok'] })
    }
    return story;
  },

  async fetchCmsBlock ({ commit }, { slug, version, transpileRequired = true }) {
    const story = await getCmsStoryBySlug(slug, version, '', transpileRequired);
    return story;
  },
  async fetchCachedCmsBlock ({ commit, getters }, { slug, version, transpileRequired = true }) {
    let blockData = getters.getStoriesByKey(`${slug}-${version}`)?.story;
    if (blockData) {
      return blockData;
    }
    blockData = await getCmsStoryBySlug(slug, version, '', transpileRequired);
    await commit('setStory', { key: `${slug}-${version}`, story: blockData });
    return blockData;
  },
  async fetchStoryBlok ({ dispatch, commit, getters }, { storeCode, blockKey, version, transpileRequired = true }) {
    let blockData = getters.getStoriesByKey(blockKey)?.story;
    let stillProcessing = getters.isProcessing(blockKey);
    if (blockData || stillProcessing) {
      return;
    }
    commit(next.IN_PROGRESS, { blockKey, isProcessing: true });
    blockData = await dispatch('fetchCmsBlock',
      { slug: `${storeCode}/${blockKey}`, version: version, transpileRequired });
    commit('setStory', { key: `${blockKey}`, story: blockData });
    commit(next.IN_PROGRESS, { blockKey, isProcessing: false });
  },
  async fetchDataSource ({ commit }, label) {
    const response: any = await fetchDataSourceEntries(label);

    if (response.resultCode !== 200) {
      throw new Error('Something went wrong fetchDataSource');
    }

    return response?.result;
  },

  async updateCmsData ({ commit }, { eventStory, key }) {
    const story = getStoryData(eventStory);
    commit('updateStory', { key, story });

    return story;
  },

  async fetchCategoryBannerData ({ dispatch, commit }, storeId) {
    const bannerMapping = {
      'default': ''
    }
    storeId = config.server.isProduction ? storeId : `${storeId}-sit`
    const bannerData = await dispatch('fetchDataSource', `plp-banners-${storeId}`)
    bannerData?.forEach(({ name, value }) => {
      bannerMapping[name] = value
    });
    commit('categoryBannerMapping', { bannerMapping });
  },
  async fetchSBSuggestedCategories ({ dispatch, commit, getters }) {
    try {
      const storeCode = currentStoreView().storeCode;
      const blockKey = 'suggested-categories';

      if (getters.getStories[`${storeCode}-${blockKey}`]?.story?.length) return;

      const result = await dispatch('fetchCmsBlock', {
        slug: `${storeCode}/blocks/${blockKey}`,
        version: 'published',
        transpileRequired: false
      });
      if (result?.length) {
        commit('setStory', { key: `${storeCode}-${blockKey}`, story: result });
      }
    } catch (e) {
      Logger.error('Error loading suggested categories storyblok', 'storyblok')()
    }
  },
  async fetchSBFooter ({ dispatch, commit }) {
    const storeCode = currentStoreView().storeCode;
    const result: next.FooterGroup[] = await dispatch('fetchCmsBlock', {
      slug: `${storeCode}/blocks/footer`,
      version: 'published',
      transpileRequired: false
    });
    if (result?.length) {
      result?.forEach(group => {
        group?.links?.forEach((linkItem: next.FooterItem) => {
          if (linkItem.component === next.FooterItemType.Text) return;
          linkItem.external = linkItem.link.linktype === 'url';
          linkItem.href = linkItem.link.url;
          if (!linkItem.external) {
            linkItem.href = localizedRoute(linkItem.link.url);
          }
        });
      });
      commit('setStory', { key: `${storeCode}-footer`, story: result });
    }
  },
  async fetchSeoFooterCategoryData ({ commit, dispatch }, { version, transpileRequired = true, blockIdentifier }) {
    try {
      let blockData = await dispatch('fetchCmsBlock', { slug: blockIdentifier, version: version });
      commit('setStory', { key: blockIdentifier, story: blockData });
    } catch (err) { }
  },
  async fetchSeoFooterDataHome ({ commit, dispatch }, { version, transpileRequired = true, blockIdentifier }) {
    try {
      const storeCode = currentStoreView().storeCode;
      const key = `seo-footer-${blockIdentifier}`
      let blockData = await dispatch('fetchCmsBlock', { slug: `${storeCode}/blocks/${key}`, version: version });
      commit('setStory', { key: `${storeCode}-${key}`, story: blockData });
    } catch (err) { }
  }
};

export default actions;
