import { getLinkUrl } from '../helpers'
const getBodyProps = (props) => {
  return {
    title: props.title,
    description: props.description,
    image: props.image?.filename,
    link: props.link?.[0]?.link || {}
  };
};

const ProductUVP = () => ({
  type: 'product_uvp',
  props: getBodyProps
});

export default ProductUVP;
