import { MutationTree } from 'vuex'
import AwsPersonalizeState from '../types/AwsPersonalizeState'
import {
  AWS_PERSONALIZE_RECOMMENDATION,
  AWS_PERSONALIZE_LAST_VIEWED_PRODUCT,
  AWS_PERSONALIZE_MY_HOME_PAGE_PRODUCTS,
  AWS_PERSONALIZE_MY_HOME_PAGE_PRODUCTS_LOADING
} from './mutation-types';

const mutations: MutationTree<AwsPersonalizeState> = {
  [AWS_PERSONALIZE_RECOMMENDATION] (state, recommendations: any[]) {
    state.recommendations = recommendations
  },
  [AWS_PERSONALIZE_LAST_VIEWED_PRODUCT] (state, productId: string) {
    state.lastViewedProduct = productId
  },
  [AWS_PERSONALIZE_MY_HOME_PAGE_PRODUCTS] (state, myHomePageRecommendations: any[]) {
    state.myHomePageRecommendations = myHomePageRecommendations
  },
  [AWS_PERSONALIZE_MY_HOME_PAGE_PRODUCTS_LOADING] (state, loadingMyHomePageRecommendations: boolean) {
    state.loadingMyHomePageRecommendations = loadingMyHomePageRecommendations
  }
};

export default mutations
