import { mapGetters } from 'vuex';
import { storesShortCode } from './../../common/constants'
export default {
  computed: {
    ...mapGetters(['getCurrentStoreView']),
    currentPage () {
      return this.$route?.name
    },
    currentStoreCode () {
      return this.getCurrentStoreView?.storeCode
    },
    onIntl () {
      return this.currentStoreCode === storesShortCode.intl
    },
    onUS () {
      return this.currentStoreCode === storesShortCode.us
    },
    onHK () {
      return this.currentStoreCode === storesShortCode.hk
    },
    onSG () {
      return this.currentStoreCode === storesShortCode.sg
    },
    onMY () {
      return this.currentStoreCode === storesShortCode.my
    },
    onID () {
      return this.currentStoreCode === storesShortCode.id
    },
    onPH () {
      return this.currentStoreCode === storesShortCode.ph
    },
    onCartPage () {
      return this.currentPage === `${this.currentStoreCode}-cart`
    },
    onCheckoutPage () {
      return this.currentPage === `${this.currentStoreCode}-checkout`
    },
    onOrderSuccessPage () {
      return this.currentPage === `${this.currentStoreCode}-order-confirmed`
    },
    onHomePage () {
      return this.currentPage === 'home' ||
      this.currentPage === `${this.currentStoreCode}-home` ||
      this.currentPage === `${this.currentStoreCode}-${this.currentStoreCode}-headless-home`;
    },
    onSearchPage () {
      return this.currentPage === `${this.currentStoreCode}-search-result`
    }
  }
}
