import * as types from './mutation-types';
import { isServer } from '@vue-storefront/core/helpers'

const actions = {
  initTag ({ commit, getters }, { set }) {
    if (isServer && set && !getters.getTags) { commit(types.INIT_CACHE_TAG, { set }); }
  },
  addTags ({ commit }, { tags }) {
    if (isServer) commit(types.ADD_CACHE_TAGS, { tags });
  }
}
export default actions
