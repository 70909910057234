import uniqBy from 'lodash-es/uniqBy'
import config from 'config';
import { isServer } from 'common/utils'

export const getThumbnailPath = (relativeUrl: string, width: number = 0, height: number = 0, pathType: string = 'product') => {
  const path = config.images.paths[pathType] !== undefined ? config.images.paths[pathType] : ''
  relativeUrl = path + relativeUrl
  if (relativeUrl && (relativeUrl.indexOf('://') > 0 || relativeUrl.indexOf('?') > 0 || relativeUrl.indexOf('&') > 0)) relativeUrl = encodeURIComponent(relativeUrl)
  let { baseS3ImageUrl, cfImageResizeBaseUrl } = config.server
  let { pdpQuality, cartWidth, cartQuality, format } = config.images;
  let imageWidth = width
  let imageQuality = pdpQuality

  // on cart page, we're showing images inside modal, we don't want to show high quality images as that'll slow down rendering
  if (!isServer && location?.pathname?.endsWith?.('cart')) {
    imageWidth = cartWidth
    imageQuality = cartQuality
  }

  let imageBaseUrl = `${cfImageResizeBaseUrl}/width=${imageWidth},quality=${imageQuality},format=${format}/${baseS3ImageUrl}/media${relativeUrl}`;
  return imageBaseUrl
}

const getMediaGallery = (product) => {
  let mediaGallery = []
  if (product.media_gallery) {
    for (let mediaItem of product.media_gallery) {
      if (mediaItem.image) {
        let video = mediaItem.vid

        if (video && video.video_id) {
          video.id = video.video_id
          delete video.video_id
        }

        mediaGallery.push({
          'src': getThumbnailPath(mediaItem.image, config.products.gallery.width, config.products.gallery.height, 'product'),
          'loading': getThumbnailPath(mediaItem.image, config.products.thumbnails.width, config.products.thumbnails.height, 'product'),
          'video': video
        })
      }
    }
  }
  return mediaGallery
}

function configurableChildrenImages (product) {
  const hasImage = (product) => product && product.image && product.image !== 'no_selection'
  const childHasImage = (children = []) => children.some(hasImage)

  let configurableChildrenImages = []
  if (childHasImage(product.configurable_children)) {
    let configurableAttributes = product.configurable_options.map(option => option.attribute_code)
    configurableChildrenImages = product.configurable_children.map(child =>
      getMediaGallery(child).map(media => ({
        ...media,
        'id': configurableAttributes.reduce((result, attribute) => {
          result[attribute] = child[attribute]
          return result
        }, {})
      }))
    ).reduce((medias, media) => [...medias, ...media])
  }
  return configurableChildrenImages
}

export const getProductGallery = (product) => {
  if (product.type_id === 'configurable' && product.hasOwnProperty('configurable_children')) {
    if (product.is_configured) {
      const configurableChildren = uniqBy(product.configurable_children, 'color');
      const newProduct = {
        ...product,
        configurable_children: configurableChildren
      }
      return uniqBy(configurableChildrenImages(newProduct), 'src').filter(
        f => f.src && f.src !== config.images.productPlaceholder
      )
    }
  } else {
    return uniqBy(configurableChildrenImages(product).concat(getMediaGallery(product)), 'src')
      .filter(f => f.src && f.src !== config.images.productPlaceholder)
  }
}
