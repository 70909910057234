import config from 'config'
import { GetterTree } from 'vuex';

export const getters: GetterTree<any, any> = {

  getShippingDetails: (state, getters, rootState, rootGetters) => rootState.checkout.shippingDetails,

  getPaymentDetails: (state, getters, rootState, rootGetters) => rootState.checkout.paymentDetails,

  getCustomerDetials: (state, getters, rootState, rootGetters) => {
    const shippingDetails = getters.getShippingDetails
    return {
      first_name: shippingDetails.firstname,
      last_name: shippingDetails.lastname,
      email: rootGetters['checkout/getCustomerEmail'] || getters.getShippingAddress?.email,
      // phone: "+628123456",
      // billing_address: getters.getBillingAddress, // TODO: send billing address
      shipping_address: getters.getShippingAddress
    }
  },

  getCartToken: (state, getters, rootState, rootGetters) => rootGetters['cart/getCartToken'],

  getBillingAddress: (state, getters, rootState, rootGetters) => {
    const shippingDetails = getters.getShippingDetails
    const paymentDetails = getters.getPaymentDetails
    return {
      first_name: shippingDetails.firstname,
      last_name: shippingDetails.lastname,
      email: rootGetters['checkout/getCustomerEmail'],
      city: paymentDetails.city,
      address: paymentDetails.street?.join(' ') || '',
      postal_code: paymentDetails.postcode,
      country_code: paymentDetails.country_id === 'ID' ? 'IDN' : paymentDetails.country_id
    }
  },

  getShippingAddress: (state, getters, rootState, rootGetters) => {
    const shippingDetails = getters.getShippingDetails
    return {
      first_name: shippingDetails.firstname,
      last_name: shippingDetails.lastname,
      email: rootGetters['checkout/getCustomerEmail'],
      city: shippingDetails.city,
      address: shippingDetails.street?.join(' ') || '',
      postal_code: shippingDetails.postcode,
      country_code: shippingDetails.country_id === 'ID' ? 'IDN' : shippingDetails.country_id
    }
  },
  chargeRequestData: (state, getters, rootState, rootGetters) => ({
    customerDetails: getters['getCustomerDetials']
  })
}
