
function getProps (props) {
  const {
    column_desktop: columnDesktop,
    column_mobile: columnMobile,
    contents
  } = props;

  return {
    columnDesktop,
    columnMobile,
    contents
  }
}

const CommonCarousel = () => ({
  type: 'common_carousel',
  props: getProps
});

export default CommonCarousel;
