import config from 'config';
import { TaskQueue } from '@vue-storefront/core/lib/sync';
import Task from '@vue-storefront/core/lib/sync/types/Task';
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import rootStore from '@vue-storefront/core/store';
import { VoteForRestock } from './interface';

export const fetchAllVotes = async (): Promise<Task> => {
  const token = rootStore.getters['user/getUserToken'];
  if (!token) throw new Error('token is missing');

  const url = getApiEndpointUrl(
    config?.restockNotification?.endpoint,
    'getAllVotes'
  );
  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
};

export const voteForRestock = async (
  payload: VoteForRestock
): Promise<Task> => {
  const url = `${getApiEndpointUrl(config.api, 'url')}/${getApiEndpointUrl(config?.restockNotification?.endpoint, 'vote')}`
  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify(payload)
    },
    silent: true
  });
};

export const deleteVote = async (productID: string): Promise<Task> => {
  const token = rootStore.getters['user/getUserToken'];
  if (!token) throw new Error('token is missing');
  if (!productID) throw new Error('productID is missing');

  const url = `${getApiEndpointUrl(config.api, 'url')}/${getApiEndpointUrl(
    config?.restockNotification?.endpoint,
    'deleteVote'
  )}`
    .replace('{{productAlertStockId}}', productID);
  return TaskQueue.execute({
    url,
    payload: {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });
};
