<template>
  <div>
    <SfOverlay
      class="overlay"
      :visible="isOpen"
      @click="onStay()"
    />
    <SfButton
      v-if="mode === 'desktop'"
      class="sf-button--pure country-icon"
      @click="isOpen = !isOpen"
    >
      <img
        :src="getCountryFlag"
        :alt="currentCountryCode"
        class="country-image"
        :class="promptLocationChange ? 'border-dark' : 'border-light'"
      >
      <span class="paragraph-1 country-name-desktop">{{ $t(getCountryName) }}</span>
      <svg class="lb-icon no-fill stroke dropdown-icon">
        <use :xlink:href="`#CountryDropdown-${promptLocationChange ? 'dark' : 'light'}`" />
      </svg>
    </SfButton>

    <div
      v-if="mode === 'mobile'"
      @click="isOpen = !isOpen; handleCountrySelectorGtmEvent();"
    >
      <p class="shipping-info paragraph-2">
        <span>I'm shipping to</span>
        <img
          :src="getCountryFlag"
          :alt="currentCountryCode"
          class="country-image country-image-sm border-dark"
        >
        <span id="country-name">{{ getCountryName }}</span>
      </p>
    </div>

    <SfDropdown
      v-if="isOpen"
      :is-open="isOpen"
      :persistent="false"
      :class="mode == 'mobile' ? 'mobile-mode' : 'desktop-mode'"
      @click:close="onStay"
    >
      <div class="dropdown_container">
        <div
          v-if="mode === 'mobile'"
          class="close"
          @click="onStay"
        >
          <SfIcon
            :icon="'cross'"
            :size="'15px'"
            :color="color"
          />
        </div>
        <h6 class="popup-title">
          I'm shipping to
        </h6>
        <div class="select">
          <select
            v-model="selectedCountryCode"
            name="countries"
            class="country-selection"
          >
            <option
              v-for="(item, index) in availableCountries"
              :key="index"
              :value="item.value"
              :selected="item.value === selectedCountryCode"
              class="country-selection-option"
              @change="changeCountry(item.value)"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div style="padding:2px">
          <p
            v-if="!currentCountrySelected && !isInternationalCountry"
            class="paragraph-2 shipping-notes"
          >
            {{ countrySwitcherMessage }}
          </p>
          <p class="paragraph-2 shipping-notes-important">
            {{
              `Please note your final order will be billed in ${getCurrencyName}`
            }}
          </p>
          <SfButton
            class="sf-button--full-width shipp-button"
            @click="changeShippingCountry()"
          >
            {{ shippingText }}
          </SfButton>
          <a
            class="paragraph-2 stay-text"
            @click="onStay(true)"
          >
            Stay on this website
          </a>
        </div>
      </div>
    </SfDropdown>
  </div>
</template>

<script>
import { SfButton, SfIcon, SfDropdown, SfOverlay } from '@storefront-ui/vue';
import { availableCountries } from 'common/config/countries';
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import FeatureFlag from 'common/mixins/featureFlag'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { COUNTRY_CODE_STORAGE_KEY } from 'common/constants.js'
import matchMedia from 'common/mixins/matchMedia';
import setSelectedCountryMixin from 'common/mixins/setSelectedCountryMixin';
import { isServer } from '@vue-storefront/core/helpers'

const shoppingCountryStorage = StorageManager.get('shoppingCountry')
export default {
  components: { SfButton, SfIcon, SfDropdown, SfOverlay },
  mixins: [FeatureFlag, matchMedia, setSelectedCountryMixin],
  props: {
    floatingIcon: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'desktop'
    },
    promptLocationChange: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: false,
      availableCountries,
      currentStore: currentStoreView().storeCode,
      currentCountryCode: currentStoreView().countryCode,
      selectedCountryCode: currentStoreView().countryCode,
      color: '#f6e9e3',
      checkdefaultmode: 'desktop'
    };
  },
  async created () {
    if (isServer) {
      return
    }
    if (!this.getUserCurrentLocation) {
      await this.$store.dispatch('user/setUserCurrentLocation')
    }
  },
  computed: {
    countrySwitcherMessage () {
      const message = 'Hey, you are now being redirected to the'
      let country = this.getCountryNameForRedirection
      if (country === 'Canada') {
        country = 'United States'
      }
      return `${message} ${country} site.`
    },
    getSelectedCountryDetails () {
      return this.availableCountries.find(({ value }) => value === this.selectedCountryCode) || {};
    },
    getCountryNameForRedirection () {
      const selectedCountryDetails = this.getSelectedCountryDetails

      return selectedCountryDetails?.website ? selectedCountryDetails?.name : 'International'
    },
    getCountryName () {
      const selectedCountryDetails = this.getSelectedCountryDetails

      return selectedCountryDetails?.name
    },
    shippingText () {
      return this.currentCountrySelected ? 'Continue Shopping' : 'Take Me There'
    },
    getCurrencyName () {
      const selectedCountryDetails = this.getSelectedCountryDetails

      return selectedCountryDetails?.localCurrency || 'USD'
    },
    getCountryFlag () {
      const image = require.context(
        'common/assets/country_icons/',
        false,
        /\.svg$/
      );
      const countryCode = this.getSelectedCountryDetails?.value
      if (countryCode) {
        return image('./' + countryCode + '.svg');
      }
      return image('./USA.svg')
    },
    currentCountrySelected () {
      if (this.isInternationalCountry) return true
      const selectedCountryDetails = this.getSelectedCountryDetails
      return this.currentCountryCode === selectedCountryDetails?.value ||
        (selectedCountryDetails?.value === 'MAC' && this.currentCountryCode === 'HKG') || // Macau uses HK store
        (selectedCountryDetails?.value === 'CAN' && this.currentCountryCode === 'USA'); // Canada uses US store
    }
  },
  watch: {
    selectedCountryCode (newVal) {
      this.updateIntlCurrency(newVal)
    },
    getUserCurrentLocation (newVal) {
      if (newVal) {
        this.checkForLocationPrompt();
      }
    }
  },
  mounted () {
    this.$root.$on('changeIntlShoppingCountry', () => {
      this.updateIntlCountry();
      this.updateIntlCurrency(this.selectedCountryCode);
    })
  },
  methods: {
    async onStay (doSaveUserPreferredStore = false) {
      this.isOpen = false
      const code = await shoppingCountryStorage.getItem(COUNTRY_CODE_STORAGE_KEY)
      const isInternationalCountry = this.internationalCountries.some(({ value }) => value === code);
      if ((this.currentStoreCode === 'us' && !isInternationalCountry) || (this.onIntl && isInternationalCountry)) {
        this.selectedCountryCode = code;
      } else {
        this.selectedCountryCode = currentStoreView().countryCode;
      }
      if (doSaveUserPreferredStore) {
        localStorage.setItem('userPreferredStore', this.currentStore);
      }
    },
    async updateIntlCountry () {
      const code = await shoppingCountryStorage.getItem(COUNTRY_CODE_STORAGE_KEY)
      if (code) {
        this.setSelectedCountry(code)
        this.selectedCountryCode = code
      } else {
        const defaultCountry = 'AUS'
        this.setSelectedCountry(defaultCountry)
        shoppingCountryStorage.setItem(COUNTRY_CODE_STORAGE_KEY, defaultCountry)
      }
    },
    async changeShippingCountry () {
      const url = new URL(window.location.href);
      const segments = url.pathname.split('/');
      let urlWithoutOriginAndStoreCode = segments.slice(2).join('/') + url.search;
      urlWithoutOriginAndStoreCode = urlWithoutOriginAndStoreCode ? `/${urlWithoutOriginAndStoreCode}` : '';

      // clear any previous stored values from 2.0
      localStorage?.removeItem('CURRENT_COUNTRY_KEY')
      const selectedCountryDetails = this.getSelectedCountryDetails
      // extracting the storecode
      const storeCode = selectedCountryDetails?.value.substring(0, 2).toLowerCase();
      if (this.currentCountrySelected) { // do nothing
      } else if (selectedCountryDetails?.value === 'MAC') {
        window.location = `${location.origin}/hk/${urlWithoutOriginAndStoreCode}` // redirect to HK if Macao selected
      } else if (selectedCountryDetails?.website && selectedCountryDetails?.available && selectedCountryDetails?.value === 'CAN') {
        window.location = `${location.origin}/us${urlWithoutOriginAndStoreCode}` // Canada uses US store
      } else if (selectedCountryDetails?.website && selectedCountryDetails?.available) {
        window.location = `${location.origin}/${storeCode}${urlWithoutOriginAndStoreCode}`
      } else { // Rest of the location choices are redirected to the International website
        shoppingCountryStorage.setItem(COUNTRY_CODE_STORAGE_KEY, selectedCountryDetails?.value)
        window.location = `${location.origin}/intl${urlWithoutOriginAndStoreCode}`;
      }

      if ((!this.getSelectedCountryDetails?.website && this.getSelectedCountryDetails?.available) || ['USA', 'CAN'].includes(selectedCountryDetails?.value)) {
        shoppingCountryStorage.setItem(COUNTRY_CODE_STORAGE_KEY, selectedCountryDetails?.value)
        this.$root.$emit('changeIntlShoppingCountry');
      }
      this.isOpen = false;
    },
    changeCountry (countryToSelect) {
      if (!countryToSelect) return
      shoppingCountryStorage.setItem(COUNTRY_CODE_STORAGE_KEY, countryToSelect)
      this.updateIntlCountry()
    },
    updateIntlCurrency (country) {
      if (this.isInternationalCountry) {
        this.$store.commit('currencyconversion/setCurrency', country);
      }
    },
    handleCountrySelectorGtmEvent () {
      if (this.isMobile) {
        this.$gtm?.trackEvent({
          event: `click_megamenu`,
          megamenu_path: `country_selector`
        });
      }
    },
    checkForLocationPrompt () {
      if (!this.promptLocationChange) return;
      let userPreferredStore;
      try {
        userPreferredStore = localStorage.getItem('userPreferredStore') || this.getUserCurrentLocation;
      } catch (_) {
        userPreferredStore = this.getUserCurrentLocation;
      }
      const isCurrentLocationSameAsStoreLocation = this.getUserCurrentLocation.toLowerCase() === this.currentStore.toLowerCase();
      const isUserPreferredStoreSameAsStoreLocation = userPreferredStore.toLowerCase() === this.currentStore.toLowerCase();
      const [currentLocationCountryHasStore, currentLocationCountryStoreCode] = this.checkIfCurrentLocationHasStore();
      this.isOpen = !isCurrentLocationSameAsStoreLocation && !isUserPreferredStoreSameAsStoreLocation && currentLocationCountryHasStore;
      if (this.isOpen) this.selectedCountryCode = currentLocationCountryStoreCode || this.selectedCountryCode;
    },
    checkIfCurrentLocationHasStore () {
      const out_of_sync_country_codes = {
        'Japan': 'jp'
      };
      for (const country of availableCountries) {
        const countryCode = out_of_sync_country_codes[country.standardCountryCode] || country.standardCountryCode.toLowerCase();
        const isMatched = countryCode === this.getUserCurrentLocation.toLowerCase();
        if (isMatched) {
          return this.currentStore.toLowerCase() !== 'intl' || country.website ? [true, country.value] : [false, null];
        }
      }
      return [false, null];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.close {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
}
::v-deep .sf-dropdown {
  z-index: 6;
}
::v-deep .sf-dropdown.desktop-mode {
  position: absolute;
  width: 521px;
  left: 90px;
  bottom: 60px;
}
::v-deep .sf-dropdown.mobile-mode {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  @include for-mobile {
    width: 80%;
  }
}
::v-deep .sf-dropdown__overlay {
  display: none;
}
::v-deep .sf-dropdown__container {
  background: transparent;
}
::v-deep .sf-dropdown__cancel {
  display: none;
}
.country-image-sm {
  margin-bottom: -6px;
}
.country-image {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  box-sizing: content-box;
  display: inline-block;
}
.border-dark {
  border: 1px solid var(--neutral-900);
}
.border-light {
  border: 1px solid var(--soft-beige-100);
}
.country-selection {
  appearance: none;
  width: 100%;
  background: transparent;
  border: 1px solid var(--neutral-200);
  color: var(--neutral-900);
  height: 46px;
  padding: 0 30px 0 20px;
  margin-top: var(--spacing-lg, 16px);
  font-size: var(--font_size_75);
}
.country-selection:focus {
  outline: none;
}
.country-selection-option {
  font-size: calc(+ 2px);
  background: var(--white);
  color: var(--neutral-900);
}
.select {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--neutral-700);
    top: 36px;
    right: 15px;
    color: var(--neutral-700);
    z-index: 1;
    font-size: var(--font_size_75);
    font-weight: 700;
  }
}
.stay-text {
  color: var(--neutral-900);
  text-decoration: underline;
  letter-spacing: 0;
}
.shipping-notes {
  color: var(--neutral-900);
  text-align: left;
  margin-top: var(--spacing-lg, 16px);
}
.shipping-notes-important {
  text-align: left;
  color: var(--heart-red-600);
  padding: 0;
  margin-top: var(--spacing-lg, 16px);
}
.shipp-button {
  background: var(--bold-brown-base);
  font-weight: var(--font-normal);
  font-family: var(--font-secondary);
  font-size: var(--font_size_75);
  color: var(--white);
  margin-top: var(--spacing-md, 10px);
  margin-bottom: var(--spacing-md, 10px);
  height: 45px;
  line-height: 28px;
  letter-spacing: 1.4px;
  text-decoration: none;
  padding: 7px 15px;
  text-transform: capitalize;
}
.dropdown_container {
  padding: 40px 80px;
  text-align: center;
  background: var(--white);
  color: var(--white);
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
  @include for-mobile {
    padding: 40px 20px;
  }
  .popup-title {
    color: var(--neutral-900);
  }
}
.sf-overlay {
  z-index: 6;
  overflow: hidden;
  background-color: rgba(51, 51, 51, 0.55);
  opacity: 1;
  transform: unset;
  visibility: visible;
}
.shipping-info {
  cursor: pointer;
}
#country-name {
  text-decoration: underline;
}
.country-name-desktop {
  margin-left: 8px;
}
.dropdown-icon {
  margin-left: 2px;
  width: 8px;
}
</style>
