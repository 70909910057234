import { StoryblokState } from '../types/State'

const state: StoryblokState = {
  previewToken: '',
  storeCode: '',
  stories: {},
  currentStoryKey: '',
  supportsWebp: true,
  categoryBannerMap: {},
  isProcessing: {}
};

export default state;
