import { StorefrontModule } from '@vue-storefront/core/lib/modules';

import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const KEY = 'restock-notification';

const restockStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
export const restockNotificationModule: StorefrontModule = function ({ store }) {
  store.registerModule(KEY, restockStore);
};
