<template>
  <ul class="menu-body">
    <li v-for="(item, index) in visibleMenu" :key="index" class="menu-item" @click="handleMenuClick(item)">
      <div v-if="item.children_count > 0" @click="toggleCollapse(item, menu, index)" :class="item.expanded ? 'expanded' : ''" class="item-header">
        <span class="label title-2" :class="{'highlight': item.lb_megamenu_message === 'red'}">  {{ item.name }} </span>
        <svg
          class="toggle-icon no-fill"
        >
          <use :xlink:href="item.expanded? '#minus-sign' : '#plus-sign'" />
        </svg>
      </div>
      <router-link
        v-else
        :to="categoryLink(item)"
        class="category"
        :class="{'fireworks': item.name === 'Best of the Best'}"
      >
        <span class="label" :class="{'highlight': item.lb_megamenu_message === 'red', 'title-2': isLevelOneItem, 'paragraph-1': !isLevelOneItem}">
          {{ item.name }}
        </span>
      </router-link>
      <template v-if="item.children_count > 0 && (item.expanded)">
        <OMenuItem :menu="item.children_data" />
      </template>
    </li>
  </ul>
</template>

<script>
import {
  formatCategoryLink,
  getCategoryThumbnailUrl
} from 'src/modules/catalog-es/categoryHelper';

import MegaMenuTracking from 'src/common/mixins/MegaMenuTracking';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

export default {
  name: 'OMenuItem',
  mixins: [MegaMenuTracking],
  props: {
    menu: {
      type: Array,
      default: () => []
    },
    isLevelOneItem: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visibleMenu () {
      return this.menu?.filter((menuItem) => menuItem?.include_in_menu > 0)
        ?.sort((a, b) => a?.position - b?.position)
    }
  },
  methods: {
    toggleCollapse (item, menu, index) {
      this.$set(item, 'expanded', !item.expanded);
      if (item.expanded) {
        this.triggerGtmMobileMegaMenuClick(item?.url_path, this.isLevelOneItem ? 1 : 2, false);
        menu.forEach((currentItem) => {
          this.$set(currentItem, 'expanded', currentItem.id === item.id);
        });
        item?.children_data?.forEach((currentChild) => {
          this.$set(currentChild, 'expanded', false);
        });
      }
    },
    thumbnailImagePath (category) {
      return getCategoryThumbnailUrl(category, 30, 30);
    },
    categoryLink (category) {
      return formatCategoryLink(category);
    },
    handleMenuClick (category) {
      if (category.children_count <= 0) {
        this.$emit('handleClose');
        this.triggerGtmMobileMegaMenuClick(this.categoryLink(category), this.isLevelOneItem ? 1 : 2, true);
        EventBus.$emit('close-megamenu')
      }
    }
  },
  components: {
    OMenuItem: {
      props: ['menu'],
      template: '<OMenuItem :menu="menu"></OMenuItem>'
    }
  }
}
</script>

<style lang="scss" scoped>
 .collapsed {
    cursor: pointer;
  }
  .menu-body {
    padding: 0;
    margin: 10px;
  }
  .menu-item {
    display: flex;
    flex-flow: column;
    border-bottom: 1px solid var(--light-gray-2);
    padding: 16px;
  }
  .item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .expanded + .menu-body {
    margin-top: 16px;
  }
  .toggle-icon {
    height: 20px;
    width: 20px;
  }
  ul {
    list-style: none;
    padding-left: 1rem;
  }
  .highlight {
     color: var(--heart-red-base);
  }
</style>
