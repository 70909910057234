import { pluck } from './object'

export const freshAddress = (additional = {}) => ({
  id: 0,
  firstname: '',
  lastname: '',
  country_id: '',
  city: '',
  postcode: '',
  telephone: '',
  street: ['', ''],
  region: {
    region: ''
  },
  custom_attributes: [
    { attribute_code: 'lb_address_type', value: '1' },
    { attribute_code: 'country_code', value: '' },
    { attribute_code: 'subarea', value: '' }
  ],
  ...additional
})

// update complete address
const ADDRESS_KEYS = [
  'id',
  'firstname',
  'lastname',
  'street',
  'city',
  'region',
  'postcode',
  'country_id',
  'custom_attributes',
  'telephone'
]

const ADDITIONAL_KEYS = [
  'save_address'
]

export function changeAddress (prevAddress, newAddress, onlyKeys = [...ADDRESS_KEYS, ...ADDITIONAL_KEYS]) {
  return {
    ...prevAddress,
    ...pluck(newAddress, onlyKeys)
  }
}

export function copyAddress (prevAddress, newAddress, onlyKeys = ADDRESS_KEYS) {
  return {
    ...prevAddress,
    ...pluck(newAddress, onlyKeys)
  }
}
