const getters = {
  getStories: state => state.stories,
  getStoriesByKey: (state) => (storyKey) => {
    return state.stories?.[storyKey];
  },
  isProcessing: (state) => (blockKey) => {
    return state.isProcessing?.[blockKey];
  },
  getCurrentStoryKey: state => state.currentStoryKey,
  getCategoryBannerMap: state => state.categoryBannerMap
};

export default getters;
