import { extendStore } from '@vue-storefront/core/helpers';
import { StorefrontModule } from '@vue-storefront/core/lib/modules';

import { fetchProductStockFromIms } from 'src/modules/catalog/store/product/service';

const KEY = 'stock-next';

const stockNextStore = {
  namespaced: true
};

const stockNextModule = {
  state: {
    imsStockList: []
  },
  getters: {
    getImsStockList: (state) => state.imsStockList
  },
  mutations: {
    setImsStockList (state, imsStockList) {
      state.imsStockList = imsStockList
    }
  },
  actions: {
    async checkProductsStockFromIms ({ commit, rootGetters }) {
      const cartItems = rootGetters['cart/getCartItems']
      if (!cartItems?.length) return []
      const skus = cartItems.map((cartItem) => cartItem.sku)
      const { result } = await fetchProductStockFromIms(skus);
      const results = result?.checkStockByChildSkus?.map((item) => ({
        sku: item.sku,
        quantity: item?.data[0]?.quantity,
        isInStock: item?.data[0]?.isInStock
      }), {}) || []
      commit('setImsStockList', results)
      return results
    }
  }
};

export const StockNextModule: StorefrontModule = function ({
  app,
  store,
  appConfig,
  router,
  moduleConfig
}) {
  store.registerModule(KEY, stockNextStore);
  extendStore('stock', stockNextModule);
};
