import findConfigurableVariant from './findConfigurableVariant'

/**
 * Returns product based on configuration or if there is no match then return first variant as default.
 */
export default function getSelectedVariant (product, configuration, { fallbackToDefaultWhenNoAvailable, availabilityCheck }) {
  // The override on this is to just simple call the function ```findConfigurableVariant```
  // with ```availabilityCheck``` as a variable on its arguments. That was done
  // to allow the user to select an OOS variant on PDP once ```listOutOfStockProducts: false```
  // is used to not show OOS products on PLP it also prevents the user to select it on PDP.
  //
  // Please check core/modules/catalog/helpers/variant/getSelectedVariant.ts file to
  // see the original implementation.
  let selectedVariant = findConfigurableVariant({ product, configuration, availabilityCheck })
  if (!selectedVariant) {
    if (fallbackToDefaultWhenNoAvailable) {
      selectedVariant = findConfigurableVariant({ product, selectDefaultChildren: true, availabilityCheck: false }) // return first available child
    }
  }

  return selectedVariant
}
