import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import AwsPersonalizeState from '../types/AwsPersonalizeState'
import { AwsPersonalizeService } from '../data-resolver/AwsPersonalizeService'
import {
  AWS_PERSONALIZE_LAST_VIEWED_PRODUCT,
  AWS_PERSONALIZE_MY_HOME_PAGE_PRODUCTS,
  AWS_PERSONALIZE_RECOMMENDATION,
  AWS_PERSONALIZE_MY_HOME_PAGE_PRODUCTS_LOADING
} from './mutation-types';
import { GetRecommendationsInput } from '../types/GetRecommendationsInput';
import { Logger } from '@vue-storefront/core/lib/logger';
import { isServer } from '@vue-storefront/core/helpers'

const prepareProductRecommendations = (recommendations, itemId) => recommendations
  .map(product => product._source)
  .filter(product => !itemId.startsWith(product.sku));

const prepareMyHomePageProducts = (myHomePageRecommendations) => myHomePageRecommendations
  .map(product => product._source);

const getItemId = (itemId) => {
  if (isServer) return itemId;
  const key = 'recommendation-item-id';

  if (itemId) {
    localStorage.setItem(key, itemId)
  }

  return itemId || localStorage.getItem(key);
};

const actions: ActionTree<AwsPersonalizeState, RootState> = {
  async getRecommendations ({ commit, state }, { itemId, userId }: GetRecommendationsInput) {
    const currentItemId = getItemId(itemId);

    // it means this recommendation was already fetched.
    if (
      state.lastViewedProduct === currentItemId &&
      state.recommendations.length
    ) {
      return;
    }

    try {
      const response = await AwsPersonalizeService.getRecommendations({
        itemId: currentItemId,
        userId: ''
      });
      if (response.code === 200) {
        commit(AWS_PERSONALIZE_RECOMMENDATION, prepareProductRecommendations(response.result, currentItemId));
      }
    } catch (error) {
      Logger.error(error.message, 'aws-personalize')();
    }

    commit(AWS_PERSONALIZE_LAST_VIEWED_PRODUCT, currentItemId);
  },

  async getMyPageRecommendations ({ commit, state }, userId) {
    commit(AWS_PERSONALIZE_MY_HOME_PAGE_PRODUCTS_LOADING, true)
    try {
      const response = await AwsPersonalizeService.getRecommendations({
        userId
      });

      if (response.code === 200) {
        commit(AWS_PERSONALIZE_MY_HOME_PAGE_PRODUCTS, prepareMyHomePageProducts(response.result))
      }
    } catch (error) {
      Logger.error(error.message, 'aws-personalize')();
    }
    commit(AWS_PERSONALIZE_MY_HOME_PAGE_PRODUCTS_LOADING, false)
  }
};

export default actions
