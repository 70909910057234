import { getColor } from '../helpers';

function getProps (props) {
  const {
    title,
    columns: propColumns,
    columns_length: columnsLength,
    background_color,
    no_gap: noGap
  } = props;

  const columns = propColumns

  return {
    title,
    columns,
    columnsLength,
    backgroundColor: getColor(background_color),
    noGap
  }
}

const ColumnGrid = () => ({
  type: 'grid',
  props: getProps
});

export default ColumnGrid
