import config from 'config'
import { SearchQuery } from 'storefront-query-builder'
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { STOCK, CC_IMS_STOCK } from 'common/constants';

/**
 * Creates simple query that will search product by skus list
 */
export default function buildQuery (skus: string[]): SearchQuery {
  let productsQuery = new SearchQuery()
  productsQuery = productsQuery
    .applyFilter({ key: 'sku', value: { 'in': skus } })
    .applyFilter({ key: 'status', value: { 'in': [1] } })
  if (config.products.listOutOfStockProducts === false) {
    productsQuery = productsQuery.applyFilter({ key: currentStoreView()?.ims ? CC_IMS_STOCK : STOCK, value: { 'eq': true } })
  }
  return productsQuery
}
