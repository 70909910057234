const getPopularCategoryProps = props => {
  return { image: props.image, title: props.title, link: props.link };
};

const popularCategory = () => ({
  type: 'popular_category',
  props: getPopularCategoryProps
});

export default popularCategory;
