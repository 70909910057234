import config from 'config'

export function loadScript (src: string, id: string) {
  return new Promise<void>((resolve, reject) => {
    if (document.getElementById(id)) {
      resolve()
      return
    }
    var script = document.createElement('script')
    script.async = true
    script.src = src
    script.onerror = function onError () {
      reject(new Error('Failed to load' + src))
    }
    script.onload = function onLoad () {
      script.id = id
      resolve()
    }
    document.getElementsByTagName('head')[0].appendChild(script)
  })
}

export const getColor = (prop) => {
  return prop?.color || 'transparent';
}

export const getImage = ({ filename, alt }) => {
  try {
    return { 'filename': filename, 'alt': alt };
  } catch (e) {
    return { 'filename': '', 'alt': '' };
  }
}

export const getImageDimensions = (imagePath) => {
  const paths = imagePath.split('/');

  return paths ? paths[5].split('x') : false
}

export const isSmallImage = (imagePath): boolean => {
  try {
    const dimension = getImageDimensions(imagePath)

    if (!dimension || !Number.isInteger(parseInt(dimension[0])) || !Number.isInteger(parseInt(dimension[1]))) {
      return false;
    }

    if (dimension[0] <= 290) {
      return true;
    }
  } catch (e) {}
  return false;
}

export const resizeImage = (image, newDimension = '1280x0'): string => {
  if (image?.filename) {
    let width = newDimension.split('x')?.[0];
    if (Number(width) === 0) {
      width = 'auto';
    }
    const { format, quality } = config?.storyblok?.image;
    return `${config?.server?.cfImageResizeBaseUrl}/width=${width},format=${format},quality=${quality}/${image.filename}`;
  }
  return '';
}

export const getLinkUrl = (link): string => {
  if (!link?.url && !link?.cached_url) {
    return ''
  }
  return link?.url || link?.cached_url
}
