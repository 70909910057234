import { mapState, mapGetters } from 'vuex';
import isOnPage from './isOnPage';

export default {
  mixins: [isOnPage],
  computed: {
    ...mapState({
      countrySelected: state => state.currencyconversion.localValue.country
    }),
    ...mapGetters({
      currentSelectedCountry: 'address/selectedCountry',
      isFlagActive: 'featurehub/isFlagActive',
      paymentDetails: 'checkout/getPaymentDetails',
      getCurrentStoreView: 'getCurrentStoreView'
    }),
    isUsCustomer () {
      if (this.paymentDetails.country_id) {
        return this.paymentDetails?.country_id === 'US';
      }
      return this.currentSelectedCountry === 'USA'
    },
    isKlarnaActive () {
      return false;
    },
    ...mapState({
      isFeatureActive (state) {
        return state.featurehub?.featureFlags[this.flag]?.isActive
      }
    })
  }
};
