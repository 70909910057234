const getProps = props => {
  return {
    title: props.title,
    description: props.description,
    bannerDesktop: props.banner_desktop?.filename || '',
    bannerMobile: props.banner_mobile?.filename || '',
    cta: props.CTA?.[0]
  };
};
const AmplifySignatureCard = () => ({
  type: 'amplify_signature_card',
  props: getProps
});

export default AmplifySignatureCard;
