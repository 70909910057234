export const ADD_SEARCH_CACHE_PRODUCTS = 'ADD_SEARCH_CACHE_PRODUCTS'
export const SET_SEARCH_PRODUCT_COUNT = 'SET_SEARCH_PRODUCT_COUNT'
export const SET_SEARCH_EMPTY_RESULT = 'SET_SEARCH_EMPTY_RESULT'
export const ADD_SEARCH_PRODUCTS = 'ADD_SEARCH_PRODUCTS'
export const SEARCH_KEYWORD_REDIRECT_URL = 'SEARCH_KEYWORD_REDIRECT_URL'
export const SET_SEARCH_LOADING = 'SET_SEARCH_LOADING'
export const SET_SEARCH_KEYWORD = 'SET_SEARCH_KEYWORD'
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER'
export const SET_IS_ALL_OOS = 'SET_IS_ALL_OOS'

export const ADD_PLP_PRODUCTS = 'ADD_PLP_PRODUCTS'
export const ADD_PLP_CACHE_PRODUCTS = 'ADD_PLP_CACHE_PRODUCTS'
export const SET_PLP_PRODUCTS_LOADING = 'SET_PLP_PRODUCTS_LOADING'
export const ADD_PLP_PRODUCTS_TOTAL_COUNT = 'ADD_PLP_PRODUCTS_TOTAL_COUNT'
export const ADD_SIMILAR_PRODUCTS = 'ADD_SIMILAR_PRODUCTS'
export const SET_SIMILAR_PRODUCTS_LOADING = 'SET_SIMILAR_PRODUCTS_LOADING'
export const SET_PRODUCT_DETAILS_LOADING = 'SET_PRODUCT_DETAILS_LOADING'
export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS'
export const SET_PRODUCT_GALLERY = 'SET_PRODUCT_GALLERY'
export const SET_CURRENT_CONFIGURATION = 'SET_CURRENT_CONFIGURATION'
export const SET_MEGAMENU_CATEGORIES = 'SET_MEGAMENU_CATEGORIES'
export const SET_MEGAMENU_SUBCATEGORIES = 'SET_MEGAMENU_SUBCATEGORIES'
export const SET_PRODUCT_BREADCRUMBS = 'SET_PRODUCT_BREADCRUMBS'
export const SET_CATEGORY_BREADCRUMBS = 'SET_CATEGORY_BREADCRUMBS'
export const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY'

export const ADD_RELATED_PRODUCTS = 'ADD_RELATED_PRODUCTS'
export const ADD_RELATED_PRODUCTS_CACHE = 'ADD_RELATED_PRODUCTS_CACHE'
export const LOADING_RELATED_PRODUCTS = 'LOADING_RELATED_PRODUCTS'
export const SET_RESTRICTED_CATEGORY = 'SET_RESTRICTED_CATEGORY'
export const SET_RECENTLY_VIEWED_PRODUCTS = 'SET_RECENTLY_VIEWED_PRODUCTS'

export const SET_PLP_BANNER = 'SET_PLP_BANNER'
export const SET_PLP_FOOTER = 'SET_PLP_FOOTER'
export const SET_SERVER_TIME = 'SET_SERVER_TIME'
export const SET_PLP_INLINE_BANNER = 'SET_PLP_INLINE_BANNER'
export const PRICE_FILTER_RANGE_VALUES = 'PRICE_FILTER_RANGE_VALUES'
